import seoRedirect from '@/middleware/async-middlewares/seo-redirect'
import redirects from '@/middleware/async-middlewares/redirects'
import pageSeo from '@/middleware/async-middlewares/page-seo'
import { compareRoutes } from '@/plugins/helper'

export default async function(ctx) {
  const { from, route } = ctx

  const { isOnlyHashChanged } = compareRoutes(from, route)

  if (isOnlyHashChanged) return

  await Promise.all([seoRedirect(ctx), redirects(ctx), pageSeo(ctx)])
}
