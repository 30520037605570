/** Previously we imported variables from scss files, but it leads to the issue described bolow:  **/
/**
 WARNING: Do not use :export {} inside file, which you pass to nuxt-style-resources module !!!!!
 ------------------------------------
 Reason: We use nuxt-style-resources module, which allows us to use variables/mixins inside component,
 without need to define 'import' in component style.
 If you use :export {} inside files, which are passed to nuxt-style-resources module -  this leads
 to situation that all variables/mixins duplicated to each component with scoped style.
 **/

export const breakpointNames = {
  mobile: 'mobile',
  tablet: 'tablet',
  desktopSm: 'desktopSm',
  desktopSm2: 'desktopSm2',
  desktopSm3: 'desktopSm3',
  desktopMd: 'desktopMd',
  desktopLg: 'desktopLg',
  sidebarSmallSize: 'sidebarSmallSize',
  desktopXl: 'desktopXl',
  aboveDesktopXl: 'aboveDesktopXl'
}

export const breakpointsValues = {
  [breakpointNames.mobile]: 767,
  [breakpointNames.tablet]: 1023,
  [breakpointNames.desktopSm]: 1199,
  [breakpointNames.desktopSm2]: 1259,
  [breakpointNames.desktopSm3]: 1419,
  [breakpointNames.desktopMd]: 1449,
  [breakpointNames.desktopLg]: 1599,
  [breakpointNames.sidebarSmallSize]: 1700,
  [breakpointNames.desktopXl]: 1919,
  [breakpointNames.aboveDesktopXl]: Infinity
}

const colors = {
  gray: '#727272',
  grayLight: '#9b9b9b',
  grayDark: '#221e20',
  white: '#fff',
  main: '#69f',
  mobileSearchBtn: '#173ec2',
  error: '#f56123',
  email: 'gray',
  followTag: '#313030',
  save: '#ff910e',
  footer: '#ededed',
  notificationCount: '#3cf',
  twitter: '#0099d3',
  facebook: '#3b5998',
  linkedin: '#0073b1',
  whatsapp: '#2cb742',
  multiselectTextColor: '#626262',
  eventsSocial: '#464646'
}

export default {
  breakpoints: breakpointsValues,
  breakpointNames,
  colors
}
