/**
 * There is a very weird bug with mobile devices explained in details here:
 * https://stackoverflow.com/questions/64280854/android-chrome-click-after-keyboard-entry-not-working
 *
 * In short, there is a glitch when mobile keyboard covers clickable elements
 * and there is a focus on the text input. Regular clicks won't fire for
 * elements that were covered by the keyboard, the only event we can rely on
 * in this case is touchend which fires correctly.
 *
 * (!) We only need to consider the touchend event in case there was no
 * scroll event.
 *
 * We've faced this bug while working on:
 * https://adraba.atlassian.net/browse/FMP-13695
 *
 * This directive aims to fix it.
 */

import { checkIfFunction } from '@/plugins/helper'
import { scrollTouchEventListenerOptions } from '@/utils/helpers/events'

let touchEndTimeoutId = null
let numberOfActiveElements = 0
let touchEndCancelled = false
let isClickTriggered = false
const CHECK_CLICK_AFTER_TOUCH_END_DELAY = 300

function onScroll() {
  touchEndCancelled = true
}

function onTouchMove() {
  touchEndCancelled = true
}

function onTouchStart() {
  isClickTriggered = false
  touchEndCancelled = false
  clearTimeout(touchEndTimeoutId)
}

export const click = (() => {
  const state = new WeakMap()

  return {
    inserted(el, binding) {
      const handler =
        binding.value && checkIfFunction(binding.value)
          ? binding.value
          : () => {}

      function onTouchEnd() {
        if (touchEndCancelled) return

        /**
         * If there was no click, we know it's a buggy state and trigger it
         * manually
         */
        touchEndTimeoutId = setTimeout(() => {
          if (!isClickTriggered) {
            el.click()
          }
        }, CHECK_CLICK_AFTER_TOUCH_END_DELAY)
      }

      function onClick(event) {
        isClickTriggered = true
        handler(event)
      }

      const data = {
        onTouchEnd,
        onClick
      }

      state.set(el, data)

      el.addEventListener('touchend', onTouchEnd)
      el.addEventListener('click', onClick)
      el.addEventListener(
        'touchstart',
        onTouchStart,
        scrollTouchEventListenerOptions
      )
      el.addEventListener(
        'touchmove',
        onTouchMove,
        scrollTouchEventListenerOptions
      )

      /**
       * We register one scroll listener for all elements
       */
      if (!numberOfActiveElements) {
        window.addEventListener('scroll', onScroll, true)
      }

      numberOfActiveElements++
    },
    unbind(el) {
      numberOfActiveElements--

      if (!numberOfActiveElements) {
        window.removeEventListener('scroll', onScroll, true)
      }

      const data = state.get(el)
      if (!data) return

      const { onTouchEnd, onClick } = data

      el.removeEventListener('touchend', onTouchEnd)
      el.removeEventListener('click', onClick)
      el.removeEventListener('touchstart', onTouchStart)
      el.removeEventListener('touchmove', onTouchMove)

      state.delete(el)
    }
  }
})()
