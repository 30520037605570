export const BOT_USER_AGENT_LIST = [
  'Mozilla/5.0 (compatible; LinkpadBot/2.3; +http://linkpad.org/robot/)',
  'Mozilla/5.0 (compatible; Clarabot/1.4; +http://www.clarabot.info/bots)',
  'Mozilla/5.0 (compatible; Cocolyzebot/1.0; https://cocolyze.com/bot)',
  'Mozilla/5.0 (compatible; Cliqzbot/3.0; +http://cliqz.com/company/cliqzbot)',
  'Mozilla/5.0 (compatible; oBot/2.3.1; http://www.xforce-security.com/crawler/)',
  'Mozilla/5.0 (compatible; MojeekBot/0.7; +https://www.mojeek.com/bot.html)',
  'Mozilla/5.0 (compatible; NetpeakCheckerBot/3.2; +https://netpeaksoftware.com/checker)',
  'Mozilla/5.0 (compatible; Snapbot/1.0; +http://www.snapchat.com)',
  'Mozilla/5.0 (compatible; GetHPinfo.com-Bot/0.1; +http://www.gethpinfo.com/bot/',
  'Mozilla/5.0 (compatible; Dun&amp;amp;BradstreetBot/1.0;)',
  'Mozilla/5.0 (compatible; iSec_Bot/2.0; +http://www.xforce-security.com/crawler/iSec_Bot/)',
  'Mozilla/5.0 (compatible; ONBbot/3.3.0 +https://webarchiv.onb.ac.at/robot.html)',
  'Mozilla/5.0 (Windows NT 6.1; Win64; x64; rv:64.0) RedirectBot/1.0',
  'Mozilla/5.0 (compatible; oBot/2.3.1; +http://www.xforce-security.com/crawler/)',
  'Mozilla/5.0 (compatible; Dun&amp;BradstreetBot/1.0;)',
  'Mozilla/5.0 (compatible; Checkbot/1.2.3; +https://www.checkbot.io)',
  'Mozilla/5.0 (compatible; CouponWCode Bot/2.1; +https://www.couponwcode.com)',
  'Mozilla/5.0 (compatible; oBot/2.3.2; +http://www.xforce-security.com/crawler/)',
  'Mozilla/5.0 (compatible; LXRbot/1.0;http://www.lxrmarketplace.com/,support@lxrmarketplace.com)',
  'Mozilla/5.0 Mozilla/5.0 (compatible; Centricbot/1.0; centricbot@centricdigital.com)',
  'Mozilla/5.0 (compatible; Indexbot/3.0; +indexing.the.web)',
  'Mozilla/5.0 (compatible; Jooblebot/2.0; Windows NT 6.1; WOW64; +http://jooble.org/jooble-bot) Mobile',
  'Mozilla/5.0 (compatible; Konturbot/1.0; +http://kontur.ru; n.ilinykh@skbkontur.ru)',
  'Mozilla/5.0 (compatible; iSec_v2_Bot/1.0;)',
  'Mozilla/5.0 (compatible; QuickSproutBot/1.0; +http://www.quicksprout.com/bot/)',
  'Mozilla/5.0 (compatible; LinkisBot/1.0; bot@linkis.com) (iPhone; CPU iPhone OS 8_4_1 like Mac OS X) Mobile/12H321',
  'Mozilla/5.0 (compatible; DonkeyBot/a4.5; +https://www.dasdonkey.com)',
  'Mozilla/5.0 (compatible; FLinkhubbot/1.1; +hello@flinkhub.com )',
  'Mozilla/5.0 (compatible; Orbbot/1.1; +http://orb-intelligence.com)',
  'Mozilla/5.0 (compatible; Huaweibot/1.0; +http://www.huawei.com)',
  'Mozilla/5.0 (compatible; SchemaBot/1.2; https://www.schemaapp.com/bot/)',
  'Mozilla/5.0 (compatible; NetpeakSpiderBot/3.1; +https://netpeaksoftware.com/spider)',
  'Mozilla/5.0 (compatible; OdklBot/1.0 like Linux; klass@odnoklassniki.ru)',
  'Mozilla/5.0 (compatible; CouponWCode Bot/2.1; submit coupon; +https://www.couponwcode.com/submit-coupon)',
  'Mozilla/5.0 (compatible; Coveobot/2.0;+http://www.coveo.com/bot.html)',
  'Mozilla/5.0+(compatible;TigerBot/7.33;++https://tiger.ch)',
  'Mozilla/5.0 (compatible; Republerbot/1.0; +https://republer.com/bot.html)',
  'MeWeBot/1.0 (compatible; Mozilla/5.0; +https://mewe.com/externalhit)',
  'Mozilla/5.0 (compatible; Taboolabot/3.7; +http://www.taboola.com)',
  'Mozilla/5.0(compatible; SchemaBot/1.2; https://www.schemaapp.com/bot/)',
  'Mozilla/5.0 (compatible; TechieJobsBot/1.0; +https://www.techiejobs.co/bot.htm)',
  'Mozilla/5.0 (compatible;FindITAnswersbot/1.0;+http://search.it-influentials.com/bot.htm)',
  'Mozilla/5.0 (compatible; Detectify) +https://detectify.com/bot/a8c6a0f05d4897ce90febdc687b9a2974e4c4543',
  'Mozilla/5.0 (compatible; digitalshadowsbot/1.0; +http://www.digitalshadows.com/bot/',
  'Mozilla/5.0 (compatible; idealo-bot pricevalidator; https://partner.idealo.com/de/bot/)',
  'Mozilla/5.0+(compatible;TigerBot/7.14;++https://tiger.ch)',
  'Mozilla/5.0 (compatible; sc_bot/2.0)',
  'Mozilla/5.0 (compatible; HifiSharkBot/1.0; +https://www.hifishark.com/bot)',
  'Mozilla/5.0 (compatible; NetpeakSpiderBot/3.2; +https://netpeaksoftware.com/spider)',
  'Mozilla/5.0 (compatible; MakeMoneyTeamWorkBot/1.0; +http://www.makemoneyteamwork.com)',
  'Mozilla/5.0 (compatible; Echobot/1.0RC1; Mode/PCNTL;)',
  'Mozilla/5.0 (compatible; TodoExpertosBot/4.0.440)',
  'Mozilla/5.0 (compatible; TodoExpertosBot/4.0.386)',
  'Mozilla/5.0 (compatible;SViewBot/0.2; +http://ws.nju.edu.cn)',
  'Mozilla/5.0 (compatible; TodoExpertosBot/4.0.436)',
  'Mozilla/5.0 (compatible; BrightLocalBot/1.0; +https://www.brightlocal.com/)',
  'Mozilla/5.0 (compatible; GimmeUSAbot/1.0; +http://gimmeusa.com/crawler.html',
  'Mozilla/5.0 (compatible; WebMasterbot/3.1; +http://xtele.me/wmaster_bot)',
  'Mozilla/5.0 (compatible; TT_SNBReact_Bot/0.1; +http://www.snbreact.org/)',
  'Mozilla/5.0 (compatible; SuperdogBot/1~bl; +http://www.superdog.com/bot.html)',
  'Mozilla/5.0 (compatible; SeznamBot/4.0-RC1; +http://napoveda.seznam.cz/seznambot-intro/)',
  'Mozilla/5.0 (compatible; Riverbot/1.0; +http://www.useriver.com/bot.html)',
  'Mozilla/5.0+(compatible;+Dun&amp;BradstreetBot/1.0;)',
  'Mozilla/5.0 (compatible; TodoExpertosBot/4.0.409)',
  'Mozilla/5.0 (compatible; RegmarketsBotXML/2.0; +https://regmarkets.ru/bot/)',
  'Mozilla/5.0 (compatible; ProductAdsBot/1.0 https://sellercentral.amazon.com/gp/help/help.html?ie=UTF8&amp;itemID=200137680)',
  'Mozilla/5.0 (compatible; PWeBot/3.1; http://www.programacionweb.net/robot.php)',
  'Mozilla/5.0 (compatible; Yellowbrandprotectionbot/1.0; +https://www.yellowbp.com/bot.html)',
  'Mozilla/5.0 (compatible; Detectify) +https://detectify.com/bot/d57a54c1e4f4d772a5d1b539f4ecdaafaec92b2e',
  'Mozilla/5.0 (compatible; LetsearchBot/1.0; +https://letsearch.ru/bots)',
  'Mozilla/5.0 (compatible; GalagoBot/0.1)',
  'Mozilla/5.0 (compatible; RoxieyBot/1.0; +http://www.roxiey.com/RoxieyBot)',
  'Mozilla/5.0 (compatible; TodoExpertosBot/4.0.421)',
  'Mozilla/5.0 (compatible; svetabot/1.0; +http://svetabot.online/index.php',
  'Mozilla/5.0 (compatible; SiteAnalyzerBot/4.0; +https://www.site-analyzer.com/)',
  'Mozilla/5.0 (compatible; oBot/2.3.1; +w4csbot)',
  'Mozilla/5.0+(compatible;TigerBot/7.20;++https://tiger.ch)',
  'Mozilla/5.0 (compatible; hlshbot/1.0; +https://halle-leaks.de/shua.html)',
  'Mozilla/5.0+(compatible;TigerBot/7.66;++https://tiger.ch)',
  'Mozilla/5.0 (compatible; PlurkBot/1.0; +https://www.plurk.com/)',
  'Mozilla/5.0 (compatible; GlyanecBot/1.0; +http://www.glyanec.net)',
  'Mozilla/5.0 (compatible; TodoExpertosBot/4.0.407)',
  'Mozilla/5.0 (compatible; AndStartBot/2.1; +http://andstart.ro/bot.html)',
  'Mozilla/5.0 (compatible; Webliobot/0.1; +http://www.weblio.jp/info/crawler.jsp)',
  'Mozilla/5.0 (compatible; AlmaBot/1.0; +https://alma.app/)',
  'Mozilla/5.0 (compatible; TodoExpertosBot/4.0.396)',
  'Mozilla/5.0 (compatible; Googlrbot/2.1; +https://google.de/bot.html)',
  'Mozilla/5.0 (compatible; KAZ.KZ_Bot/3.0)',
  'Mozilla/5.0 (compatible; TodoExpertosBot/4.0.432)',
  'Mozilla/5.0 (compatible; KantarSifoMediaAuditBot/1.1; +https://www.kantarsifo.se/mediaaudit)',
  'Mozilla/5.0 (compatible; Linux x86_64; Mail.RU_Bot/2.0; +//go.mail.ru/help/robots)',
  'EmailCheckerBot/0.1 (compatible; Mozilla/5.0; +https://www.brokenemailchecker.com/bot.html)',
  'Mozilla/5.0 (compatible; b2bYPBot/1.0;)',
  'Mozilla/5.0 (compatible; Konturbot/1.0; +http://kontur.ru; n.ilinykh@skbkontur.ru',
  'Mozilla/5.0 (compatible; YandexAccessibilityBot/3.0; +http://yandex.com/bots),gzip(gfe)',
  'Mozilla/5.0 (compatible; Applebot/0.4; +http://www.apple.com/go/applebot)',
  'Mozilla/5.0 (compatible; Cocolyzebot/1.0; https://cocolyze.io/bot)',
  'Mozilla/5.0 (compatible; Yooplaabot/1.0; +http://www.yooplaa.com/bot)',
  'Halebot (Mozilla/5.0 compatible; Halebot/2.1; http://www.tacitknowledge.com/halebot/)',
  'Mozilla/5.0 (compatible; TodoExpertosBot/4.0.415)',
  'Moreoverbot/5.1 ( http://w.moreover.com; webmaster@moreover.com) Mozilla/5.0',
  'Mozilla/5.0 (compatible; tzt-securityscanbot/0.1)',
  'Mozilla/5.0 (compatible; TodoExpertosBot/4.0.399)',
  'Mozilla/5.0 (compatible; oBot/1.43; +http://filterdb.iss.net/crawler/)',
  'Mozilla/5.0 (compatible; AdoBot/1.0; +https://adolab.com/bot)',
  'Mozilla/5.0 (compatible; MotoMinerBot/1.0; +https://motominer.com/Bot)',
  'Mozilla/5.0 (compatible; Yooplaabot/1.0; +http://www.yooplaa.com/bot',
  'Mozilla/5.0 (compatible; Linkbot/1.0; +http://ir.ee/linkboat)',
  'Mozilla/5.0 (compatible; WeDJ.com-LinkBot/1.0; +http://www.wedj.com/dj-photo-video.nsf/contact.html)',
  'Mozilla/5.0 (compatible; CrawlerBot/1.0;)',
  'Mozilla/5.0 (Windows NT 5.1; rv:11.0) Gecko Firefox/11.0 (via ggpht.com GoogleImageProxy)',
  'Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
  'Mozilla/5.0 (compatible; Baiduspider/2.0; +http://www.baidu.com/search/spider.html)',
  'Mozilla/5.0 (compatible; bingbot/2.0; +http://www.bing.com/bingbot.htm)',
  'Mozilla/5.0 (compatible; MJ12bot/v1.4.5; http://www.majestic12.co.uk/bot.php?+)',
  'Mozilla/5.0 (compatible; Yahoo! Slurp; http://help.yahoo.com/help/us/ysearch/slurp)',
  'Mozilla/5.0 (compatible; MegaIndex.ru/2.0; +http://megaindex.com/crawler)',
  'Mozilla/5.0 (compatible; AhrefsBot/5.2; +http://ahrefs.com/robot/)',
  'Mozilla/5.0 (X11; U; Linux Core i7-4980HQ; de; rv:32.0; compatible; JobboerseBot; http://www.jobboerse.com/bot.htm) Gecko/20100101 Firefox/38.0',
  'Mozilla/5.0 (compatible; DotBot/1.1; http://www.opensiteexplorer.org/dotbot, help@moz.com)',
  'Mozilla/5.0 (compatible; SemrushBot/2~bl; +http://www.semrush.com/bot.html)',
  'Mozilla/5.0 (compatible; YandexBot/3.0; +http://yandex.com/bots)',
  'Mozilla/5.0 (compatible; MJ12bot/v1.4.7; http://mj12bot.com/)',
  'Mozilla/5.0 (Linux; Android 6.0.1; Nexus 5X Build/MMB29P) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/41.0.2272.96 Mobile Safari/537.36 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
  'Mozilla/5.0 (compatible; MJ12bot/v1.4.8; http://mj12bot.com/)',
  'Mozilla/5.0 (compatible; SemrushBot/1.2~bl; +http://www.semrush.com/bot.html)',
  'Googlebot-Image/1.0',
  'Mozilla/5.0 (compatible; YandexImages/3.0; +http://yandex.com/bots)',
  'Mozilla/5.0 (compatible; Yahoo! Slurp/3.0; http://help.yahoo.com/help/us/ysearch/slurp)',
  'msnbot/1.0 (+http://search.msn.com/msnbot.htm)',
  'msnbot/2.0b (+http://search.msn.com/msnbot.htm)',
  'Mozilla/5.0 (compatible; AhrefsBot/5.0; +http://ahrefs.com/robot/)',
  'Mozilla/5.0 (iPhone; CPU iPhone OS 7_0 like Mac OS X) AppleWebKit/537.51.1 (KHTML, like Gecko) Version/7.0 Mobile/11A465 Safari/9537.53 (compatible; bingbot/2.0; +http://www.bing.com/bingbot.htm)',
  'Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/534+ (KHTML, like Gecko) BingPreview/1.0b',
  'Mozilla/5.0 (compatible; AhrefsBot/5.1; +http://ahrefs.com/robot/)',
  'Mozilla/5.0 (compatible; SemrushBot/1.1~bl; +http://www.semrush.com/bot.html)',
  'Mozilla/5.0 (X11; U; Linux Core i7-4980HQ; de; rv:32.0; compatible; JobboerseBot; https://www.jobboerse.com/bot.htm) Gecko/20100101 Firefox/38.0',
  'msnbot/1.1 (+http://search.msn.com/msnbot.htm)',
  'facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)',
  'Mozilla/5.0 (iPhone; CPU iPhone OS 7_0 like Mac OS X) AppleWebKit/537.51.1 (KHTML, like Gecko) Version/7.0 Mobile/11A465 Safari/9537.53 BingPreview/1.0b',
  'Baiduspider+(+http://www.baidu.com/search/spider.htm)',
  'Mozilla/5.0 (Windows; U; Windows NT 5.1; fr; rv:1.8.1) VoilaBot BETA 1.2 (support.voilabot@orange-ftgroup.com)',
  'Googlebot/2.1 (+http://www.google.com/bot.html)',
  'Mozilla/5.0 (compatible; Ask Jeeves/Teoma; +http://about.ask.com/en/docs/about/webmasters.shtml)',
  'msnbot-media/1.0 (+http://search.msn.com/msnbot.htm)',
  'Mozilla/5.0 (iPhone; U; CPU iPhone OS 4_1 like Mac OS X; en-us) AppleWebKit/532.9 (KHTML, like Gecko) Version/4.0.5 Mobile/8B117 Safari/6531.22.7 (compatible; Googlebot-Mobile/2.1; +http://www.google.com/bot.html)',
  'msnbot-media/1.1 (+http://search.msn.com/msnbot.htm)',
  'msnbot/0.01 (+http://search.msn.com/msnbot.htm)',
  'Mozilla/5.0 (X11; U; Linux Core i7-4980HQ; de; rv:32.0; compatible; Jobboerse.com; http://www.xn--jobbrse-d1a.com) Gecko/20100401 Firefox/24.0',
  'Mozilla/5.0 (compatible; DotBot/1.1; http://www.dotnetdotcom.org/, crawler@dotnetdotcom.org)',
  'Sogou web spider/4.0(+http://www.sogou.com/docs/help/webmasters.htm#07)',
  'Mozilla/2.0 (compatible; Ask Jeeves/Teoma)',
  'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/49.0.2623.75 Safari/537.36 Google Favicon',
  'Mozilla/5.0 (compatible; Exabot/3.0; +http://www.exabot.com/go/robot)',
  'Mozilla/5.0 AppleWebKit/537.36 (KHTML, like Gecko; compatible; Googlebot/2.1; +http://www.google.com/bot.html) Safari/537.36',
  'Mozilla/2.0 (compatible; Ask Jeeves/Teoma; +http://sp.ask.com/docs/about/tech_crawling.html)',
  'Mozilla/5.0 (iPhone; CPU iPhone OS 8_3 like Mac OS X) AppleWebKit/600.1.4 (KHTML, like Gecko) Version/8.0 Mobile/12F70 Safari/600.1.4 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
  'Jyxobot/1',
  'msnbot/0.3 (+http://search.msn.com/msnbot.htm)',
  'Mozilla/5.0 (Windows; U; Windows NT 5.1; fr; rv:1.8.1) VoilaBot BETA 1.2 (http://www.voila.com/)',
  'Mozilla/4.0 (compatible; MSIE 5.0; Windows 95) VoilaBot BETA 1.2 (http://www.voila.com/)',
  'Mozilla/5.0 (iPhone; CPU iPhone OS 6_0 like Mac OS X) AppleWebKit/536.26 (KHTML, like Gecko) Version/6.0 Mobile/10A5376e Safari/8536.25 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
  'Mozilla/5.0 (compatible; MJ12bot/v1.4.3; http://www.majestic12.co.uk/bot.php?+)',
  'Feedfetcher-Google; (+http://www.google.com/feedfetcher.html; 3 subscribers; feed-id=17583705103843181935)',
  'Yahoo-MMCrawler/3.x (mms dash mmcrawler dash support at yahoo dash inc dot com)',
  'ia_archiver',
  'Mozilla/5.0 (compatible; AhrefsBot/4.0; +http://ahrefs.com/robot/)',
  'Mozilla/5.0 (compatible; DuckDuckGo-Favicons-Bot/1.0; +http://duckduckgo.com)',
  'Mozilla/2.0 (compatible; Ask Jeeves/Teoma; +http://about.ask.com/en/docs/about/webmasters.shtml)',
  'ZoominfoBot (zoominfobot at zoominfo dot com)',
  'FAST Enterprise Crawler 6 / Scirus scirus-crawler@fast.no; http://www.scirus.com/srsapp/contactus/',
  'Mozilla/5.0 (compatible; SemrushBot/3~bl; +http://www.semrush.com/bot.html)',
  'Pinterest/0.2 (+http://www.pinterest.com/)',
  'Mozilla/5.0 (compatible; AhrefsBot/6.1; +http://ahrefs.com/robot/)',
  'Mozilla/5.0 (compatible; Konqueror/3.5; Linux) KHTML/3.5.5 (like Gecko) (Exabot-Thumbnails)',
  'Mozilla/5.0 (compatible; SemrushBot/0.98~bl; +http://www.semrush.com/bot.html)',
  'FAST MetaWeb Crawler (helpdesk at fastsearch dot com)',
  'msnbot/0.9 (+http://search.msn.com/msnbot.htm)',
  'msnbot/0.11 (+http://search.msn.com/msnbot.htm)',
  'AdsBot-Google (+http://www.google.com/adsbot.html)',
  'Mozilla/5.0 (compatible; SemrushBot/1~bl; +http://www.semrush.com/bot.html)',
  'Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/50.0.2661.102 Safari/537.36; 360Spider',
  'Mozilla/5.0 (compatible; AhrefsSiteAudit/6.1; +http://ahrefs.com/robot/)',
  'panscient.com',
  'Mozilla/5.0 (compatible; Pinterestbot/1.0; +http://www.pinterest.com/bot.html)',
  'Mozilla/5.0 (X11; U; Linux Core i7-4980HQ; de; rv:32.0; compatible; JobboerseBot; https://www.jobboerse.com/bot.htm) Gecko/20100401 Firefox/24.0',
  'SAMSUNG-SGH-E250/1.0 Profile/MIDP-2.0 Configuration/CLDC-1.1 UP.Browser/6.2.3.3.c.1.101 (GUI) MMP/2.0 (compatible; Googlebot-Mobile/2.1; +http://www.google.com/bot.html)',
  'Mozilla/5.0 (iPhone; CPU iPhone OS 9_1 like Mac OS X) AppleWebKit/601.1.46 (KHTML, like Gecko) Version/9.0 Mobile/13B143 Safari/601.1 (compatible; AdsBot-Google-Mobile; +http://www.google.com/mobile/adsbot.html)',
  'Mozilla/5.0 (compatible; archive.org_bot +http://www.archive.org/details/archive.org_bot)',
  'DoCoMo/2.0 N905i(c100;TB;W24H16) (compatible; Googlebot-Mobile/2.1; +http://www.google.com/bot.html)',
  'Mediapartners-Google',
  'Mozilla/5.0 (compatible; SemrushBot/1.0~bm; +http://www.semrush.com/bot.html)',
  'Mozilla/5.0 (compatible; MSIE 9.0; Windows NT 6.1; Trident/5.0); 360Spider(compatible; HaosouSpider; http://www.haosou.com/help/help_3_2.html)',
  'Mozilla/5.0 (compatible; Charlotte/1.1; http://www.searchme.com/support/)',
  'Mozilla/5.0 (compatible; BecomeBot/3.0; +http://www.become.com/site_owners.html)',
  'Mozilla/5.0 (iPhone; CPU iPhone OS 7_0 like Mac OS X) AppleWebKit/537.51.1 (KHTML, like Gecko) Version/7.0 Mobile/11A465 Safari/9537.53 (compatible; bingbot/2.0;  http://www.bing.com/bingbot.htm)',
  'Mozilla/5.0 (compatible; MJ12bot/v1.4.6; http://mj12bot.com/)',
  'Mozilla/5.0 (compatible; SemrushBot/0.99~bl; +http://www.semrush.com/bot.html)',
  'Mozilla/5.0 (compatible; Baiduspider/2.0; +http://www.baidu.com/search/spider.html',
  'Mozilla/5.0 (compatible; Yahoo! Slurp China; http://misc.yahoo.com.cn/help.html)',
  'facebookexternalhit/1.1',
  'Mozilla/5.0 (compatible; special_archiver/3.1.1 +http://www.archive.org/details/archive.org_bot)',
  'Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.1 (KHTML, like Gecko) Chrome/21.0.1180.89 Safari/537.1; 360Spider(compatible; HaosouSpider; http://www.haosou.com/help/help_3_2.html)',
  'Mozilla/5.0 (compatible; proximic; +http://www.proximic.com/info/spider.php)',
  'Mozilla/5.0 (compatible) Feedfetcher-Google; (+http://www.google.com/feedfetcher.html)',
  'Mozilla/5.0 zgrab/0.x',
  'Slackbot-LinkExpanding 1.0 (+https://api.slack.com/robots)',
  'Twitterbot/1.0',
  'Mozilla/5.0 (compatible; proximic; +https://www.comscore.com/Web-Crawler)',
  'ia_archiver-web.archive.org',
  'Mozilla/5.0 (Windows; U; Windows NT 5.1; en; rv:1.9.0.13) Gecko/2009073022 Firefox/3.5.2 (.NET CLR 3.5.30729) SurveyBot/2.3 (DomainTools)',
  'Mozilla/5.0 (compatible; GrapeshotCrawler/2.0; +http://www.grapeshot.co.uk/crawler.php)',
  'Sosospider+(+http://help.soso.com/webspider.htm)',
  'Feedfetcher-Google; (+http://www.google.com/feedfetcher.html; 1 subscribers; feed-id=18446718903677612666)',
  'Mozilla/5.0 (compatible; SemrushBot/6~bl; +http://www.semrush.com/bot.html)',
  'FAST-WebCrawler/3.8/Scirus (scirus-crawler@fast.no; http://www.scirus.com/srsapp/contactus/)',
  'Mozilla/5.0 (compatible; MSIE 9.0; Windows NT 6.1; Trident/5.0); 360Spider',
  'Mozilla/5.0 (compatible; Baiduspider-render/2.0; +http://www.baidu.com/search/spider.html)',
  'Mozilla/5.0 (compatible; Exabot/3.0 (BiggerBetter); +http://www.exabot.com/go/robot)',
  'Mozilla/5.0 (compatible; SemrushBot-SA/0.97; +http://www.semrush.com/bot.html)',
  'Mozilla/5.0 (compatible; MJ12bot/v1.3.3; http://www.majestic12.co.uk/bot.php?+)',
  'Mozilla/5.0 (Windows NT 6.1; rv:6.0) Gecko/20110814 Firefox/6.0 Google Favicon',
  'Feedfetcher-Google; (+http://www.google.com/feedfetcher.html; 3 subscribers; feed-id=8106656927266266008)',
  'AdsBot-Google ( http://www.google.com/adsbot.html)',
  'Feedfetcher-Google; (+http://www.google.com/feedfetcher.html; 4 subscribers; feed-id=17583705103843181935)',
  'Pinterest/0.2 (+http://www.pinterest.com/bot.html)',
  'Mozilla/5.0 AppleWebKit/537.36 (KHTML, like Gecko; Google Web Preview Analytics) Chrome/41.0.2272.118 Safari/537.36 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
  'ia_archiver (+http://www.alexa.com/site/help/webmasters; crawler@alexa.com)',
  'Feedfetcher-Google; (+http://www.google.com/feedfetcher.html; 1 subscribers; feed-id=7103972955513800065)',
  'Mozilla/5.0 (compatible; discobot/1.1; +http://discoveryengine.com/discobot.html',
  'SEMrushBot',
  'Mozilla/5.0 (compatible; meanpathbot/1.0; +http://www.meanpath.com/meanpathbot.html)',
  'Mozilla/5.0 AppleWebKit/537.36 (KHTML, like Gecko; Google Web Preview Analytics) Chrome/27.0.1453 Safari/537.36 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
  'ConveraCrawler/0.9d (+http://www.authoritativeweb.com/crawl)',
  'msnbot/2.0b (+http://search.msn.com/msnbot.htm)._',
  'Mozilla/5.0 (compatible; MJ12bot/v1.4.4; http://www.majestic12.co.uk/bot.php?+)',
  'Googlebot/2.1 (+http://www.googlebot.com/bot.html)',
  'Accoona-AI-Agent/1.1.2 (aicrawler at accoonabot dot com)',
  'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/56.0.2924.87 Safari/537.36 Google (+https://developers.google.com/+/web/snippet/)',
  'Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.1 (KHTML, like Gecko) Chrome/21.0.1180.89 Safari/537.1; 360Spider',
  'Baiduspider-image+(+http://www.baidu.com/search/spider.htm)',
  'Feedfetcher-Google; (+http://www.google.com/feedfetcher.html; 1 subscribers; feed-id=16616261776245151958)',
  'Feedfetcher-Google; (+http://www.google.com/feedfetcher.html; 1 subscribers; feed-id=5563361533657150793)',
  'Mozilla/5.0 (compatible; Genieo/1.0 http://www.genieo.com/webfilter.html)',
  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_11_1) AppleWebKit/601.2.4 (KHTML, like Gecko) Version/9.0.1 Safari/601.2.4 facebookexternalhit/1.1 Facebot Twitterbot/1.0',
  'Feedfetcher-Google; (+http://www.google.com/feedfetcher.html; 1 subscribers; feed-id=6744396557326865490)',
  'Feedfetcher-Google; (+http://www.google.com/feedfetcher.html; 1 subscribers; feed-id=4296914164355380091)',
  'Feedfetcher-Google; (+http://www.google.com/feedfetcher.html; 1 subscribers; feed-id=1425217532937691821)',
  'Mozilla/5.0 (compatible; BecomeBot/2.3; MSIE 6.0 compatible; +http://www.become.com/site_owners.html)',
  'Mozilla/5.0 (Windows NT 5.1; U; Win64; fr; rv:1.8.1) VoilaBot BETA 1.2 (support.voilabot@orange-ftgroup.com)',
  'Feedly/1.0 (+http://www.feedly.com/fetcher.html; like FeedFetcher-Google)',
  'OnPageBot (compatible; Googlebot 2.1; +https://bot.onpage.org/)',
  'ConveraCrawler/0.9e (+http://www.authoritativeweb.com/crawl)',
  'Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/50.4.2661.102 Safari/537.36; 360Spider',
  'Mozilla/5.0 (compatible; 008/0.83; http://www.80legs.com/spider.html;) Gecko/2008032620',
  'Mozilla/5.0 (TweetmemeBot/4.0; +http://datasift.com/bot.html) Gecko/20100101 Firefox/31.0',
  'Mozilla/5.0 (compatible; MJ12bot/v1.2.1; http://www.majestic12.co.uk/bot.php?+)',
  'Mozilla/5.0 (compatible; Googlebot/2.1;  http://www.google.com/bot.html)',
  'Y!J-ASR/0.1 crawler (http://www.yahoo-help.jp/app/answers/detail/p/595/a_id/42716/)',
  'Mozilla/5.0 (compatible; Baiduspider/2.0; +http://www.baidu.com/search/spider.html£©',
  'FAST Enterprise Crawler/6.4 (helpdesk at fast.no)',
  'Accoona-AI-Agent/1.1.1 (crawler at accoona dot com)',
  'Mozilla/5.0 (compatible; discobot/1.0; +http://discoveryengine.com/discobot.html)',
  'Mozilla/5.0 (compatible; YodaoBot/1.0; http://www.yodao.com/help/webmaster/spider/; )',
  'facebookexternalhit/1.0 (+http://www.facebook.com/externalhit_uatext.php)',
  'Mozilla/5.0 (iPhone; CPU iPhone OS 6_0 like Mac OS X) AppleWebKit/536.26 (KHTML, like Gecko) Version/6.0 Mobile/10A5376e Safari/8536.25 (compatible; Googlebot-Mobile/2.1; +http://www.google.com/bot.html)',
  'ADmantX Platform Semantic Analyzer - ADmantX Inc. - www.admantx.com - support@admantx.com',
  'Mozilla/5.0 (compatible; aiHitBot/2.9; +https://www.aihitdata.com/about)',
  'MJ12bot/v1.0.7 (http://majestic12.co.uk/bot.php?+)',
  'Baiduspider+(+http://www.baidu.jp/spider/)',
  'Mozilla/5.0 (iPhone; CPU iPhone OS 9_1 like Mac OS X) AppleWebKit/601.1.46 (KHTML, like Gecko) Version/9.0 Mobile/13B143 Safari/601.1 (compatible; AdsBot-Google-Mobile;  http://www.google.com/mobile/adsbot.html)',
  'MJ12bot/v1.2.0 (http://majestic12.co.uk/bot.php?+)',
  'FAST Enterprise Crawler/6.3 (www.fastsearch.com)',
  'Mozilla/5.0 (compatible; YandexBot/3.0; MirrorDetector; +http://yandex.com/bots)',
  'admantx-sap/2.4 (+http://www.admantx.com/service-fetcher.html)',
  'Mozilla/5.0 (Yahoo-MMCrawler/4.0; mailto:vertical-crawl-support@yahoo-inc.com)',
  'Mozilla/5.0 (compatible; discobot/1.1; +http://discoveryengine.com/discobot.html)',
  'Sogou web spider/3.0(+http://www.sogou.com/docs/help/webmasters.htm#07)',
  'Mozilla/5.0 (Windows; U; Windows NT 5.1; zh-CN; rv:1.8.0.11) Gecko/20070312 Firefox/1.5.0.11; 360Spider',
  'Mozilla/5.0 (compatible; MJ12bot/v1.4.0; http://www.majestic12.co.uk/bot.php?+)',
  'bitlybot/3.0 (+http://bit.ly/)',
  'Mozilla/5.0 (compatible; SemrushBot-BA; +http://www.semrush.com/bot.html)',
  'Jeffrey&#39;s Exif Viewer (http://regex.info/exif). Lemmeknow if load is too high. jfriedl@yahoo.com. Thanks dude.',
  'Mozilla/5.0 (Linux;u;Android 4.2.2;zh-cn;) AppleWebKit/534.46 (KHTML,like Gecko) Version/5.1 Mobile Safari/10600.6.3 (compatible; Baiduspider/2.0; +http://www.baidu.com/search/spider.html)',
  'Mozilla/5.0 (compatible; archive.org_bot/1.13.1x +http://crawler.archive.org)',
  'FeedFetcher-Google-CoOp; (+http://www.google.com/coop/cse/cref)',
  'Mozilla/5.0 (compatible; archive.org_bot/heritrix-1.15.4 +http://www.archive.org)',
  'facebookexternalhit/1.1;line-poker/1.0',
  'Mozilla/5.0 (compatible; Googlebot/2.1; startmebot/1.0; +https://start.me/bot)',
  'librabot/1.0 (+http://search.msn.com/msnbot.htm)',
  'Mozilla/5.0 (Windows NT 6.2) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/31.0.1650.63 Safari/537.36 QIHU 360SE; 360Spider',
  'compatible;Baiduspider/2.0; +http://www.baidu.com/search/spider.html',
  'Mozilla/4.0 (compatible; MSIE 8.0; Windows NT 6.1; Trident/4.0; SLCC2; .NET CLR 2.0.50727; .NET CLR 3.5.30729; .NET CLR 3.0.30729; Media Center PC 6.0; MDDR; .NET4.0C; .NET4.0E; .NET CLR 1.1.4322; Tablet PC 2.0); 360Spider',
  'Mozilla/5.0 (compatible; AhrefsSiteAudit/6.1; +http://ahrefs.com/robot/site-audit)',
  'Mozilla/5.0 (Linux; Android 6.0.1; Nexus 5X Build/MMB29P) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/41.0.2272.96 Mobile Safari/537.36 (compatible; Googlebot/2.1;  http://www.google.com/bot.html)',
  'FAST Enterprise Crawler/6 (www.fastsearch.com)',
  'admantx-usastn/2.4 (+http://www.admantx.com/service-fetcher.html)',
  'Nokia6820/2.0 (4.83) Profile/MIDP-1.0 Configuration/CLDC-1.0 (compatible; Googlebot-Mobile/2.1; +http://www.google.com/bot.html)',
  'Mozilla/4.0 (compatible; Netcraft Web Server Survey)',
  'ADmantX Platform Sync US Semantic Analyzer - ADmantX Inc. - www.admantx.com - support@admantx.com',
  'Mozilla/5.0 (compatible; MJ12bot/v1.4.2; http://www.majestic12.co.uk/bot.php?+)',
  'FeedFetcher-Google; (+http://www.google.com/feedfetcher.html)',
  'YahooCacheSystem',
  'Mozilla/5.0 (compatible; Charlotte/1.0b; http://www.searchme.com/support/)',
  'ADmantX Platform Semantic Analyzer US - Turn - ADmantX Inc. - www.admantx.com - support@admantx.com',
  'Mozilla/5.0 (compatible; news bot /2.1)',
  'ConveraCrawler/0.4',
  'Mozilla/5.0 (compatible; Sosospider/2.0; +http://help.soso.com/webspider.htm)',
  'Mozilla/5.0 (compatible; archive.org_bot; Wayback Machine Live Record; +http://archive.org/details/archive.org_bot)',
  'Googlebot-Video/1.0',
  'Mozilla/5.0 (compatible; SemrushBot-SI/0.97; +http://www.semrush.com/bot.html)',
  'Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/41.0.2228.0 Safari/537.36 evaliant',
  'Mozilla/5.0 (compatible; AhrefsBot/3.1; +http://ahrefs.com/robot/)',
  'Mozilla/5.0 (iPhone; CPU iPhone OS 9_3_2 like Mac OS X) AppleWebKit/601.1 (KHTML, like Gecko) CriOS/51.0.2704.104 Mobile/13F69 Safari/601.1.46 evaliant',
  'Mozilla/5.0 (compatible; Baiduspider/2.0; +http://www.baidu.com/search/spider.html\xef\xbc\x89',
  'schibstedsokbot (compatible; Mozilla/5.0; MSIE 5.0; FAST FreshCrawler 6; +http://www.schibstedsok.no/bot/)',
  'ADmantX Platform Semantic Analyzer Appnexus - ADmantX Inc. - www.admantx.com - support@admantx.com',
  'Mozilla/5.0 (Linux; Android 6.0.1; Nexus 5X Build/MMB29P) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/41.0.2272.96 Mobile Safari/537.36 (compatible; Pinterestbot/1.0; +http://www.pinterest.com/bot.html)',
  'DoCoMo/1.0/N505i/c20/TB/W20H10 (compatible; Googlebot-Mobile/2.1; +http://www.google.com/bot.html)',
  'Mozilla/4.7 [en](Exabot@exava.com)',
  'Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/50.2.2661.102 Safari/537.36; 360Spider',
  'Mozilla/5.0 (compatible; BecomeBot/3.0; MSIE 6.0 compatible; +http://www.become.com/site_owners.html)',
  'MSNBOT_Mobile MSMOBOT Mozilla/2.0 (compatible; MSIE 4.02; Windows CE; Default)/1.1 (+http://search.msn.com/msnbot.htm)',
  'Mozilla/5.0 (Windows NT 6.1; rv:6.0) Gecko/20110814 Firefox/6.0 Google (+https://developers.google.com/+/web/snippet/)',
  'envolk/1.7 (+http://www.envolk.com/envolkspiderinfo.html)',
  'GeoHasher/Nutch-1.0 (GeoHasher Web Search Engine; geohasher.gotdns.org; geo_hasher at yahoo * com)',
  'Feedfetcher-Google; (+http://www.google.com/feedfetcher.html; 1 subscribers; feed-id=10999776237757854603)',
  'Mozilla/5.0 (compatible; AhrefsBot/1.0; +http://ahrefs.com/robot/)',
  'Mozilla/5.0 (compatible&amp; Googlebot/2.1&amp; +http://www.google.com/bot.html)',
  'Mozilla/5.0 (compatible; MJ12bot/v1.4.1; http://www.majestic12.co.uk/bot.php?+)',
  'Mozilla/5.0 (compatible; alexa site audit/1.0; +http://www.alexa.com/help/webmasters; )',
  'Pinterest/0.1 +http://pinterest.com/',
  'Exabot/2.0',
  'FAST Enterprise Crawler 6 used by Singapore Press Holdings (crawler@sphsearch.sg)',
  'msnbot-Products/1.0 (+http://search.msn.com/msnbot.htm)',
  'Mediapartners-Google/2.1',
  'MJ12bot/v1.0.8 (http://majestic12.co.uk/bot.php?+)',
  'admantx-adform/3.1 (+http://www.admantx.com/service-fetcher.html)',
  'Mozilla/5.0 (compatible; Charlotte/1.0t; http://www.searchme.com/support/)',
  'Mozilla/5.0 (compatible; MJ12bot/v1.2.3; http://www.majestic12.co.uk/bot.php?+)',
  'Mozilla/5.0 AppleWebKit/537.36 (KHTML, like Gecko; compatible; Googlebot/2.1;  http://www.google.com/bot.html) Safari/537.36',
  'Mozilla/5.0 (compatible; YandexBot/3.0; +http://yandex.com/bots) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/51.0.2704.106',
  'Mozilla/5.0 (iPhone; CPU iPhone OS 7_1 like Mac OS X) AppleWebKit (KHTML, like Gecko) Mobile (compatible; Yahoo! Slurp; http://help.yahoo.com/help/us/ysearch/slurp)',
  'ADmantX Platform Semantic Analyzer - ADform - ADmantX Inc. - www.admantx.com - support@admantx.com',
  'admantx-adform/2.4 (+http://www.admantx.com/service-fetcher.html)',
  'Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.110 Safari/537.36 Squider/0.01',
  'Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/42.0.2311.90 Safari/537.36 evaliant',
  'Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/534  (KHTML, like Gecko) BingPreview/1.0b',
  'Mozilla/5.0 (compatible; Baiduspider/2.0; +http://www.baidu.com/search/spider.html',
  'Mozilla/5.0 (compatible; Discordbot/2.0; +https://discordapp.com)',
  'Mozilla/5.0 (compatible; AhrefsBot/3.0; +http://ahrefs.com/robot/)',
  'Mozilla/5.0 (Linux; Android 5.0.2; LG-V410/V41020c Build/LRX22G) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/34.0.1847.118 Safari/537.36 evaliant',
  'Mozilla/5.0(compatible; Sosospider/2.0; +http://help.soso.com/webspider.htm)',
  'Yahoo Pipes 1.0',
  'admantx-euastn/2.4 (+http://www.admantx.com/service-fetcher.html)',
  'admantx-adform/3.0 (+http://www.admantx.com/service-fetcher.html)',
  'Mozilla/5.0 (compatible; Yahoo! DE Slurp; http://help.yahoo.com/help/us/ysearch/slurp)',
  'admantx-adform/2.5 (+http://www.admantx.com/service-fetcher.html)',
  'Mozilla/5.0 (Linux; Android 4.0.3; HTC One X Build/IML74K) AppleWebKit/535.19 (KHTML, like Gecko) Chrome/18.0.1025.133 Mobile Safari/535.19 evaliant',
  'Mozilla/5.0 (compatible; CareerBot/1.1; +http://www.career-x.de/bot.html)',
  'Mozilla/5.0 (linux; u; android 4.1.1; ja-jp; sgpt12 build/tjs0166) applewebkit/534.30 (khtml, like gecko) version/4.0 safari/534.30 yjapp-android jp.co.yahoo.android.yjtop/2.1.8 evaliant',
  'Mozilla/5.0 (iPad; CPU OS 7_0 like Mac OS X) AppleWebKit/536.26 (KHTML, like Gecko) Version/6.0 Mobile/10A406 Safari/8536.25 evaliant',
  'Mozilla/5.0 (compatible; Yahoo Link Preview; https://help.yahoo.com/kb/mail/yahoo-link-preview-SLN23615.html)',
  'Mozilla/5.0 (compatible; MJ12bot/v1.4.7; http://www.majestic12.co.uk/bot.php?+)',
  'ExB Language Crawler 2.1.5 (+http://www.exb.de/crawler)',
  'Mozilla/5.0 (compatible; Baiduspider/2.0; +http://www.baidu.com/search/spider.html\xa3\xa9',
  'Mozilla/5.0 (compatible; YodaoBot/1.0; http://www.youdao.com/help/webmaster/spider/; )',
  'Mozilla/5.0 (compatible;Baiduspider/2.0;+http://www.baidu.com/search/spider.html)',
  'Googlebot/2.1 ( http://www.googlebot.com/bot.html)',
  'Googlebot/Nutch-1.7',
  'Mozilla/5.0 (Linux; U; Android 4.4.2; id; SM-G900 Build/KOT49H) AppleWebKit/534.30 (KHTML, like Gecko) Version/4.0 UCBrowser/9.9.2.467 U3/0.8.0 Mobile Safari/534.30 evaliant',
  'Exabot/3.0',
  'Mozilla/5.0 (compatible; AhrefsBot/2.0; +http://ahrefs.com/robot/)',
  'Mozilla/5.0 (X11; U; Linux i686 (x86_64); en-US; rv:1.8.1.11) Gecko/20080109 (Charlotte/0.9t; http://www.searchme.com/support/)',
  'Mozilla/5.0 (Linux; Android 6.1.1; SAMSUNG SM-G925F Build/LMY47X) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/4.0 Chrome/51.0.2704.81 Mobile Safari/537.36 evaliant',
  'Mozilla/5.0 (compatible; NetSeer crawler/2.0; +http://www.netseer.com/crawler.html; crawler@netseer.com)',
  'Mozilla/5.0 (iPhone; CPU iPhone OS 9_3_2 like Mac OS X) AppleWebKit/601.1.46 (KHTML, like Gecko) Mobile/13F69 [FBAN/FBIOS;FBAV/59.0.0.51.142;FBBV/33266808;FBRV/0;FBDV/iPhone7,1;FBMD/iPhone;FBSN/iPhone OS;FBSV/9.3.2;FBSS/3;FBCR/Telkomsel;FBID/phone;FBLC/en_US;FBOP/5] evaliant',
  'renlifangbot/1.0 (+http://search.msn.com/msnbot.htm)',
  'T-Mobile Dash Mozilla/4.0 (compatible; MSIE 4.01; Windows CE; Smartphone; 320x240; MSNBOT-MOBILE/1.1; +http://search.msn.com/msnbot.htm)',
  'ADmantX Platform Semantic Analyzer - Turn EU - ADmantX Inc. - www.admantx.com - support@admantx.com',
  'Mozilla/5.0 (Linux; U; Android 6.0.1; en-US; SM-G920F Build/LMY47X) AppleWebKit/534.30 (KHTML, like Gecko) Version/4.0 UCBrowser/10.10.0.796 U3/0.8.0 Mobile Safari/534.30 evaliant',
  'Mozilla/5.0 (Linux; U; Android 5.0.1; en-US; GT-I9505 Build/LRX22C) AppleWebKit/534.30 (KHTML, like Gecko) Version/4.0 UCBrowser/10.9.8.770 U3/0.8.0 Mobile Safari/534.30 evaliant',
  'Y!J-ASR/1.0 crawler (https://www.yahoo-help.jp/app/answers/detail/p/595/a_id/42716/)',
  'Mozilla/5.0 (iPhone; CPU iPhone OS 8_1_1 like Mac OS X) AppleWebKit/600.1.4 (KHTML, like Gecko) CriOS/47.0.2526.70 Mobile/12B436 Safari/600.1.4 (000410) evaliant',
  'Mozilla/5.0 (Linux; Android 4.0.4; Galaxy Nexus Build/IMM76B) AppleWebKit/537.36 (KHTML, like Gecko; Google Web Preview Analytics) Chrome/41.0.2272.118 Mobile Safari/537.36 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
  'Mozilla/5.0 (compatible; OrangeBot/2.0; support.voilabot@orange.com)',
  'Mozilla/5.0 (Linux; Android 5.0.2; SAMSUNG SM-T550 Build/LRX22G) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/3.3 Chrome/38.0.2125.102 Safari/537.36 evaliant',
  'Mozilla/5.0 (Linux; Android 6.0.1; SAMSUNG SM-T715Y Build/MMB29K) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/4.0 Chrome/44.0.2403.133 Safari/537.36 evaliant',
  'Mozilla/5.0 (compatible; Exabot-Images/3.0; +http://www.exabot.com/go/robot)',
  'Mozilla/5.0 (Windows; U; Windows NT 5.1; zh-CN; rv:1.8.0.11)  Firefox/1.5.0.11; 360Spider',
  'msnbot/2.0b',
  'DotBot/1.0.1 (http://www.dotnetdotcom.org/, crawler@dotnetdotcom.org)',
  'ADmantX Platform Semantic Analyzer - APAC - ADmantX Inc. - www.admantx.com - support@admantx.com',
  'Mozilla/5.0 (iPhone; CPU iPhone OS 9_1 like Mac OS X) AppleWebKit/601.1.46 (KHTML, like Gecko) Version/9.0 Mobile/13B143 Safari/601.1 (compatible; Baiduspider-render/2.0; +http://www.baidu.com/search/spider.html)',
  'Mozilla/5.0 (compatible; BecomeBot/1.23; +http://www.become.com/webmasters.html)',
  'Mozilla/5.0 (compatible; bingbot/3.0-alpha; +http://www.bing.com/bingbot.htm)',
  'Mozilla/5.0 (compatible; Googlebot/2.1; +http://import.io)',
  'MJ12bot/v1.0.6 (http://majestic12.co.uk/bot.php?+)',
  'Mozilla/5.0 (Windows NT 6.1; WOW64; rv:35.0) Gecko/20100101 Firefox/35.0 evaliant',
  'Mozilla/5.0 (iPad; CPU OS 9_1 like Mac OS X) AppleWebKit/600.1.4 (KHTML, like Gecko) GSA/8.0.57838 Mobile/13B5110e Safari/600.1.4 evaliant',
  'MJ12bot/v1.0.1 (http://majestic12.co.uk/bot.php?+)',
  'envolk[ITS]spider/1.6 (+http://www.envolk.com/envolkspider.html)',
  'Mozilla/5.0 (compatible) Feedfetcher-Google;(+http://www.google.com/feedfetcher.html)',
  'boitho.com-dc/0.86 ( http://www.boitho.com/dcbot.html )',
  'Mozilla/5.0 (compatible; MJ12bot/v1.2.5; http://www.majestic12.co.uk/bot.php?+)',
  'Mozilla/4.0 (MSIE 6.0; Windows NT 5.1; Search)',
  'MJ12bot/v1.1.0 (http://majestic12.co.uk/bot.php?+)',
  'Mozilla/5.0 (compatible; FAST Enterprise Crawler 6 used by www.schibsted.no. Contact: webcrawl@schibstedsok.no)',
  'Mozilla/5.0 (compatible; 008/0.83; http://www.80legs.com/webcrawler.html) Gecko/2008032620',
  'YahooCacheSystem; YahooWebServiceClient',
  'Baiduspider/2.0',
  'ConveraCrawler/0.9e (+http://ews.converasearch.com/crawl.htm)',
  'lanshanbot/1.0 (+http://search.msn.com/msnbot.htm)',
  'Mozilla/5.0 (Linux; Android 6.0.1; Nexus 5X Build/MMB29P) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453 Mobile Safari/537.36 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
  'Mozilla/5.0 (iPad; CPU OS 8_0 like Mac OS X) AppleWebKit/537.51.3 (KHTML, like Gecko) Version/8.0 Mobile/11A4132 Safari/9537.145 evaliant',
  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_7_3) AppleWebKit/537.36 (KHTML, like Gecko, Mediapartners-Google) Chrome/41.0.2272.118 Safari/537.36',
  'Mozilla/5.0 (compatible; Yahoo! Slurp/3.0; http://help.yahoo.com/help/us/ysearch/slurp) NOT Firefox/3.5',
  'findlinks/2.6 (+http://wortschatz.uni-leipzig.de/findlinks/)',
  'Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/534+ (KHTML, like Gecko) MsnBot-Media /1.0b',
  'LinkedInBot/1.0 (compatible; Mozilla/5.0; Jakarta Commons-HttpClient/3.1 +http://www.linkedin.com)',
  'findlinks/2.0.2 (+http://wortschatz.uni-leipzig.de/findlinks/)',
  'findlinks/1.1.3-beta9 (+http://wortschatz.uni-leipzig.de/findlinks/)',
  'Mozilla/5.0 (iPad; CPU OS 9_3_3 like Mac OS X) AppleWebKit/601.1.46 (KHTML, like Gecko) Version/9.0 Mobile/13G34 Safari/601.1 evaliant',
  'DotBot/1.0.1 (http://www.dotnetdotcom.org/#info, crawler@dotnetdotcom.org)',
  'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko; Google Page Speed Insights) Chrome/27.0.1453 Safari/537.36 GoogleBot/2.1',
  'admantx-apacas/2.4 (+http://www.admantx.com/service-fetcher.html)',
  'Mozilla/5.0 (iPhone; CPU iPhone OS 6_0_1 like Mac OS X) AppleWebKit/537.36 (KHTML, like Gecko; Google Page Speed Insights) Version/6.0 Mobile/10A525 Safari/8536.25 GoogleBot/2.1',
  'ConveraCrawler/0.5 (+http://www',
  'Mozilla/5.0 (compatible; MJ12bot/v1.3.2; http://www.majestic12.co.uk/bot.php?+)',
  'MJ12bot/v1.0.5 (http://majestic12.co.uk/bot.php?+)',
  'Mozilla/5.0 (compatible; archive.org_bot +http://archive.org/details/archive.org_bot)',
  'NetSeer/Nutch-0.9 (NetSeer Crawler; http://www.netseer.com; crawler@netseer.com)',
  'admantx-eusyncbatch01/3.1 (+http://www.admantx.com/service-fetcher.html)',
  'Googlebot (gocrawl v0.4)',
  'Mozilla/5.0 (compatible;YodaoBot-Image/1.0;http://www.youdao.com/help/webmaster/spider/;)',
  'Feedfetcher-Google; (+http://www.google.com/feedfetcher.html; 2 subscribers; feed-id=17583705103843181935)',
  ': Mozilla/5.0 (compatible; YandexBot/3.0; +http://yandex.com/bots)',
  'TelegramBot (like TwitterBot)',
  'Mozilla/5.0 (compatible;bingbot/2.0;+http://www.bing.com/bingbot.htm)',
  'Mozilla/5.0 (compatible; JikeSpider; +http://shoulu.jike.com/spider.html)',
  'Mozilla/5.0 (Linux; Android 4.0.4; Galaxy Nexus Build/IMM76B) AppleWebKit/[WEBKIT_VERSION] (KHTML, like Gecko; Mediapartners-Google) Chrome/[CHROME_VERSION] Mobile Safari/[WEBKIT_VERSION]',
  'Mozilla/5.0 (compatible; MJ12bot/v1.2.4; http://www.majestic12.co.uk/bot.php?+)',
  'Exabot-Images/1.0',
  'Mozilla/5.0 (compatible;acapbot/0.1;treat like Googlebot)',
  'Yahoo! Slurp China',
  'boitho.com-dc/0.85 ( http://www.boitho.com/dcbot.html )',
  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_9_3) AppleWebKit/537.75.14 (KHTML, like Gecko) Version/7.0.3 Safari/7046A194A evaliant',
  'Sogou Pic Spider/3.0(+http://www.sogou.com/docs/help/webmasters.htm#07)',
  'SurveyBot/2.3 (Whois Source)',
  'Mozilla/5.0 (compatible; Googlebot/2.1; +http://enlite.ai)',
  'facebookexternalhit/1.1;kakaotalk-scrap/1.0; +https://devtalk.kakao.com/t/scrap/33984',
  'admantx-euastn/2.5 (+http://www.admantx.com/service-fetcher.html)',
  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_7_3) AppleWebKit/[WEBKIT_VERSION] (KHTML, like Gecko, Mediapartners-Google) Chrome/[CHROME_VERSION] Safari/[WEBKIT_VERSION]',
  'Mozilla/5.0 (compatible; BecomeBot/2.2.5; MSIE 6.0 compatible; +http://www.become.com/site_owners.html)',
  'Mozilla/5.0 (compatible; aiHitBot/2.8; +http://endb-consolidated.aihit.com/)',
  'Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html',
  'Mozilla/5.0 (compatible; aiHitBot/1.0; +http://www.aihit.com/)',
  'Mozilla/5.0 (Windows; U; Windows NT 6.1; en-US) AppleWebKit/533.20.25 (KHTML, like Gecko) Version/5.0.4 Safari/533.20.27 evaliant',
  'Googlebot/2.1 (+http://www.googlebawt.com/bot.html)',
  'Mozilla/2.0 (compatible: Ask Jeeves/Teoma)',
  'findlinks/0.87 (+http://wortschatz.uni-leipzig.de/findlinks/)',
  'Mozilla/5.0 (compatible; SemrushBot/0.97; +http://www.semrush.com/bot.html)',
  'Mozilla/5.0 (compatible; discobot/2.0; +http://discoveryengine.com/discobot.html)',
  'admantx-apacas/2.5 (+http://www.admantx.com/service-fetcher.html)',
  'Mozilla/5.0 (compatible;YodaoBot-Image/1.0;http://www.yodao.com/help/webmaster/spider/;)',
  'YahooMobile/1.0 (Resource; Server; 1.0.0)',
  'findlinks/1.0 (+http://wortschatz.uni-leipzig.de/findlinks/)',
  'findlinks/0.945 (+http://wortschatz.uni-leipzig.de/findlinks/)',
  'LinkedInBot/1.0 (compatible; Mozilla/5.0; Jakarta Commons-HttpClient/4.3 +http://www.linkedin.com)',
  'MSNBOT_Mobile MSMOBOT Mozilla/2.0 (compatible; MSIE 4.02; Windows CE; Default)',
  'ConveraCrawler/0.9 (+http://www.authoritativeweb.com/crawl)',
  'FAST Enterprise Crawler 6 used by www.schibsted.no. Contact: support@fast.no',
  'FAST-WebCrawler/3.8',
  'ConveraCrawler/0.7 (+http://www.authoritativeweb.com/crawl)',
  'Sogou web spider/4.0',
  'Baiduspider+(+http://help.baidu.jp/system/05.html)',
  'Mozilla/5.0 (iPhone; U; CPU iPhone OS 4_1 like Mac OS X; en-us) AppleWebKit/532.9 (KHTML, like Gecko) Version/4.0.5 Mobile/8B117 Safari/6531.22.7 (compatible; Mediapartners-Google/2.1; +http://www.google.com/bot.html)',
  'Slackbot-LinkExpanding (+https://api.slack.com/robots)',
  'Mozilla/5.0 (compatible; SemrushBot/0.96.3; +http://www.semrush.com/bot.html)',
  'Mozilla/4.0 (Add Catalog; Windows NT 5.1; infobot.4942@yahoo.com)',
  'Mozilla/5.0 (compatible; ia_archiver/1.0; +http://www.alexa.com/help/webmasters; crawler@alexa.com)',
  'UnwindFetchor/1.0 (+http://www.gnip.com/)',
  'findlinks/2.0.1 (+http://wortschatz.uni-leipzig.de/findlinks/)',
  'Mozilla/5.0 (compatible; bingbot/2.0;  http://www.bing.com/bingbot.htm)',
  'findlinks/1.1.1-a1 (+http://wortschatz.uni-leipzig.de/findlinks/)',
  'AdsBot-Google-Mobile (+http://www.google.com/mobile/adsbot.html) Mozilla (iPhone; U; CPU iPhone OS 3 0 like Mac OS X) AppleWebKit (KHTML, like Gecko) Mobile Safari',
  'findlinks/0.89 (+http://wortschatz.uni-leipzig.de/findlinks/)',
  'Mozilla/5.0 (compatible; Charlotte/1.0b; http://www.betaspider.com/)',
  'Mozilla/5.0 (compatible; Charlotte/1.0b; charlotte@betaspider.com)',
  'Mozilla/5.0 (compatible; 008/0.83; http://www.80legs.com/webcrawler.html;) Gecko/2008032620',
  'Mozilla/5.0 (compatible; Butterfly/1.0; +http://labs.topsy.com/butterfly/) Gecko/2009032608 Firefox/3.0.8',
  'ConveraCrawler/0.8 (+http://www.authoritativeweb.com/crawl)',
  '//help.yahoo.com/help/us/ysearch/slurp)',
  'Mozilla/5.0 (compatible; TweetmemeBot/3.0; +http://tweetmeme.com/)',
  'MSMOBOT/1.1 (+http://search.msn.com/msnbot.htm)',
  'Mozilla/5.0 (compatible; Baiduspider/2.0;+http://www.baidu.com/search/spider.html)',
  'Mozilla/5.0 (compatible; Butterfly/1.0; +http://labs.topsy.com/butterfly.html) Gecko/2009032608 Firefox/3.0.8',
  'boitho.com-dc/0.82 ( http://www.boitho.com/dcbot.html )',
  'LinkedInBot/1.0 (compatible; Mozilla/5.0; Apache-HttpClient +http://www.linkedin.com), libot/Nutch-1.9 (http://www.linkedin.com; libot@linkedin.com)',
  'ia_archiver(OS-Wayback)',
  'Mozilla/5.0 (Linux; Android 4.0.4; Galaxy Nexus Build/IMM76B) AppleWebKit/537.36 (KHTML, like Gecko; Mediapartners-Google) Chrome/41.0.2272.118 Mobile Safari/537.36',
  'SentiBot www.sentibot.eu (compatible with Googlebot)',
  'admantx-usasab/3.0 (+http://www.admantx.com/service-fetcher.html)',
  'Mozilla/5.0 (compatible; Yahoo Ad monitoring; https://help.yahoo.com/kb/yahoo-ad-monitoring-SLN24857.html)',
  'findlinks/1.0.9 (+http://wortschatz.uni-leipzig.de/findlinks/)',
  'Googlebot/2.1; +http://www.google.com/bot.html)',
  'Mozilla/5.0 (Slurp/cat; slurp@inktomi.com; http://www.inktomi.com/slurp.html)',
  'Mozilla/5.0 (compatible; 008/0.85; http://www.80legs.com/webcrawler.html) Gecko/2008032620',
  'envolk/1.7 (+http://www.envolk.com/envolkspiderinfo.php)',
  'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/49.0.2623.75 Safari/537.36 Google (+https://developers.google.com/+/web/snippet/)',
  'GoogleBot 1.0',
  'Mozilla/5.0 (compatible; aiHitBot-BP/1.1; +http://www.aihit.com/)',
  'Mozilla/4.0 (compatible; MSIE 6.0;Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
  'Mozilla/5.0 (Admin Catalog; Windows NT 5.1; infobot.4942@yahoo.com)',
  'facebookexternalhit/1.1 (compatible;)',
  'Mozilla/5.0 (compatible; BecomeBot/1.85; MSIE 6.0 compatible; +http://www.become.com/site_owners.html)',
  'Mozilla/5.0 (compatible; 80bot/0.71; http://www.80legs.com/spider.html;) Gecko/2008032620',
  'Mozilla/5.0 (compatible; AhrefsBot/5.2; News; +http://ahrefs.com/robot/)',
  'Mozilla/5.0 (compatible; archive.org_bot; Archive-It; +http://archive-it.org/files/site-owners.html)',
  'boitho.com-dc/0.79 ( http://www.boitho.com/dcbot.html )',
  'Mozilla/5.0 (compatible; AhrefsBot/6.1; SA; +http://ahrefs.com/robot/)',
  'Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html),gzip(gfe) (via docs.google.com/viewer)',
  'Sogou-Test-Spider/4.0 (compatible; MSIE 5.5; Windows 98)',
  'findlinks/0.966 (+http://wortschatz.uni-leipzig.de/findlinks/)',
  'Mozilla/4.0 (compatible; MSIE 6.0; Windows NT 5.1; SV1; TeomaBar 2.01; .NET CLR 1.1.4322)',
  'Mozilla/5.0 (compatible; Googlebot/2.1 +http://www.googlebot.com/bot.html)',
  'FAST Enterprise Crawler 6 used by Schibsted Sok (webcrawl@schibstedsok.no)',
  'Mozilla/5.0 AppleWebKit/537.36 (KHTML, like Gecko; compatible; bingbot/2.0; +http://www.bing.com/bingbot.htm) Safari/537.36',
  'Mozilla/5.0 (compatible; AhrefsBot/6.1; News; +http://ahrefs.com/robot/)',
  'Mozilla/5.0 (compatible; MJ12bot/v1.4.7; http://www.majestic12.co.uk/bot.php?+)/Nutch-1.13',
  'Mozilla/4.0 (compatible; MSIE 6.0; Windows NT 5.1; TeomaBar 2.01)',
  'Mozilla/5.0 (compatible; MSIE 6.0b; Windows NT 5.0) Gecko/2009011913 Firefox/3.0.6 TweetmemeBot',
  'librabot/2.0/1.0 (+http://search.msn.com/msnbot.htm)',
  'FAST Enterprise Crawler 6 used by Comperio AS (sts@comperio.no)',
  'Feedfetcher-Google; (+http://www.google.com/feedfetcher.html; 1 subscribers; feed-id=16289112842985372545)',
  'Charlotte/0.05',
  'Nutraspace/Nutch-1.2 (www.nutraspace.com)',
  'admantx-ussy02/2.4 (+http://www.admantx.com/service-fetcher.html)',
  'Mozilla/5.0 (Windows; U; Windows NT 5.1; zh-CN; )  Firefox/1.5.0.11; 360Spider',
  'findlinks/1.1.6-beta1 (+http://wortschatz.uni-leipzig.de/findlinks/)',
  'Mozilla/5.0 (Slurp/si; slurp@inktomi.com; http://www.inktomi.com/slurp.html)',
  'ADmantX Platform Semantic Analyzer - Turn - ADmantX Inc. - www.admantx.com - support@admantx.com',
  'MJ12bot/v1.0.2 (http://majestic12.co.uk/bot.php?+)',
  'findlinks/1.1-a8 (+http://wortschatz.uni-leipzig.de/findlinks/)',
  'YahooExternalCache',
  'MJ12bot/v1.0.0 (http://majestic12.co.uk/bot.php?+)',
  'sogou blog spider(+http://www.sogou.com/docs/help/webmasters.htm#07)',
  'Mozilla/5.0 (compatible; SemrushBot/0.97~bl; +http://www.semrush.com/bot.html)',
  'Mozilla/5.0 (compatible; BecomeBot/1.86; MSIE 6.0 compatible; +http://www.become.com/site_owners.html)',
  'Mozilla/5.0 (compatible; bingbot/2.0; +http://www.bing.com/bingbot.htm) SitemapProbe',
  'mp3Spider cn-search-devel at yahoo-inc dot com',
  'Mozilla/5.0 (Yahoo-Test/4.0; mailto:vertical-crawl-support@yahoo-inc.com)',
  'msnbot-NewsBlogs/2.0b (+http://search.msn.com/msnbot.htm)',
  'Mozilla/5.0 (compatible; YandexBot/3.0;  http://yandex.com/bots)',
  'Googlebot-Image/1.0,gzip(gfe) (via docs.google.com/viewer)',
  'Mozilla/5.0 (Windows; U; Windows NT 5.1; de; rv:1.9.0.7) Gecko/2009021910 Firefox/3.0.7 (via ggpht.com GoogleImageProxy)',
  'LinkedInBot/1.0 (compatible; Mozilla/5.0; Apache-HttpClient +http://www.linkedin.com)',
  'CatchBot/2.0; +http://www.catchbot.com',
  'Baiduspider+(+http://www.baidu.com/search/spider_jp.html)',
  'librabot/2.0 (+http://search.msn.com/msnbot.htm)',
  'Mozilla/5.0 (YahooYSMcm/3.0.0; http://help.yahoo.com)',
  'FAST-WebCrawler/3.8 (crawler at trd dot overture dot com; http://www.alltheweb.com/help/webmaster/crawler)',
  'FAST-WebCrawler/2.2.5 - Lycos/Alltheweb/Fast',
  'Teoma/Nutch-1.2 ( Question and Answer Search; Mozilla/2.0 (compatible; Ask Jeeves/Teoma; +http://sp.ask.com/docs/about/tech_crawling.html); bot@afarm.com)',
  'LinkedInBot/1.0 (compatible; Mozilla/5.0; Jakarta Commons-HttpClient/3.1 +http://www.linkedin.com)/1.0 (LinkedInBot; https://www.linkedin.com/; omni-crawler@linkedin.com)',
  'facebookexternalhit/1.1;kakaotalk-scrap/1.0;',
  'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.4 (KHTML, like Gecko; Google Page Speed Insights) Chrome/22.0.1229 Safari/537.4 GoogleBot/2.1',
  'Mozilla/5.0 AppleWebKit/537.36 (KHTML, like Gecko; Google Web Preview Analytics) Chrome/41.0.2272.118 Safari/537.36 (compatible; Googlebot/2.1;  http://www.google.com/bot.html)',
  'Mozilla/5.0 (iPhone; CPU iPhone OS 8_3 like Mac OS X) AppleWebKit/537.36 (KHTML, like Gecko) Version/8.0 Mobile/12F70 Safari/600.1.4 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
  'findlinks/0.939 (+http://wortschatz.uni-leipzig.de/findlinks/)',
  'Mozilla/5.0 (compatible; proximic; +http://www.proximic.com)',
  'Twitterbot/0.1',
  'Covario-IDS/1.0 (Covario; http://www.covario.com/ids; support at covario dot com)',
  'ConveraCrawler/0.9c (+http://www.authoritativeweb.com/crawl)',
  'findlinks/1.1.1-a5 (+http://wortschatz.uni-leipzig.de/findlinks/)',
  'Mozilla/5.0 (compatible; Alexabot/1.0; +http://www.alexa.com/help/certifyscan;)&#39;',
  'admantx-euaspb/3.1 (+http://www.admantx.com/service-fetcher.html)',
  'yahoo/Nutch-1.2 (yahoo; yahoo.com)',
  'ExB Language Crawler 2.1.3 (+http://www.exb.de/crawler)',
  'MJ12bot/v0.8.7 (http://www.majestic12.co.uk/projects/dsearch/mj12bot.php?V=v0.8.7&amp;NID=758683373DC2E832&amp;MID=84F54A2F5D676CC5&amp;BID=C44343390A4C8AFB014C94EEA8E1C672&amp;+)',
  'Yahoo! Mindset',
  'Mozilla/5.0 (compatible; AhrefsBot/5.2; SA; +http://ahrefs.com/robot/)',
  'Googlebot/2.1',
  'xef\xbb\xbfMozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
  'Mozilla/5.0 (iPhone; CPU iPhone OS 6_0 like Mac OS X) AppleWebKit/537.36 (KHTML, like Gecko) Version/6.0 Mobile/10A5376e Safari/8536.25 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
  'findlinks/1.06 (+http://wortschatz.uni-leipzig.de/findlinks/)',
  'Mozilla/5.0 (Linux; Android 4.1.1; HTC Butterfly Build/JRO03C) AppleWebKit/535.19 (KHTML, like Gecko) Chrome/18.0.1025.166 Mobile Safari/535.19',
  'Mozilla/5.0 (compatible; bingbot/2.0; +http://www.bing.com/bingbot.htm',
  'Sogou Orion spider/3.0(+http://www.sogou.com/docs/help/webmasters.htm#07)',
  'Mozilla/5.0 (compatible; BecomeBot/2.0beta; +http://www.become.com/webmasters.html)',
  'Mozilla/5.0 (compatible; Discordbot/1.0; +https://discordapp.com)',
  'findlinks/2.1.5 (+http://wortschatz.uni-leipzig.de/findlinks/)',
  'Feedfetcher-Google; (+http://www.google.com/feedfetcher.html; feed-id=4296914164355380091)',
  'boitho.com-dc/0.75 ( http://www.boitho.com/dcbot.html )',
  'Mozilla/4.0 (compatible; MSIE 6.0; Windows NT 5.0; TeomaBar 2.01)',
  'Mozilla/5.0 (compatible; aiHitBot/1.1; +http://www.aihit.com/)',
  'msnbot/1.0-MM (+http://search.msn.com/msnbot.htm)',
  'Feedfetcher-Google; (+http://www.google.com/feedfetcher.html; feed-id=1425217532937691821)',
  'Mozilla/4.0 (compatible; MSIE 6.0; Windows NT 5.1; SV1; TeomaBar 2.01; (R1 1.5); Media Center PC 3.0; .NET CLR 1.0.3705; .NET CLR 1.1.4322)',
  'Mozilla/4.0 (compatible; MSIE 6.0; Windows NT 5.1; TeomaBar 2.01; FunWebProducts)',
  'Mozilla/5.0 (compatible; AhrefsBot/2.0; +http://ahrefs.com/robot/),gzip(gfe) (via docs.google.com/viewer)',
  'Mozilla/5.0 (iPhone; CPU iPhone OS 6_0 like Mac OS X) AppleWebKit/536.26 (KHTML, like Gecko) Version/6.0 Mobile/10A5376e Safari/8536.25 (compatible; SemrushBot-SA/0.97; +http://www.semrush.com/bot.html)',
  'Feedfetcher-Google; (+http://www.google.com/feedfetcher.html; feed-id=5563361533657150793)',
  'findlinks/0.976 (+http://wortschatz.uni-leipzig.de/findlinks/)',
  'Mozilla/4.0 (compatible: FDSE robot)',
  'Pinterest/0.2 (http://www.pinterest.com/)',
  'Yahoo Pipes 2.0',
  'New-Sogou-Spider/1.0 (compatible; MSIE 5.5; Windows 98)',
  'Mozilla/5.0 (compatible; TweetmemeBot/2.11; +http://tweetmeme.com/)',
  'Mozilla/5.0 (Linux; Android 4.3; HTC Butterfly Build/JSS15J) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/48.0.2564.95 Mobile Safari/537.36'
]

export const BOT_USER_AGENT_REGEX = /bot|google|crawler|spider|robot|crawling|slurp|mediapartners|baidu|bing|msn|duckduckbot|teoma|yandex/
