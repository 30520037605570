<template>
  <div v-if="isPageNavigationInProgress" class="progress-bar" />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AProgressBar',
  computed: {
    ...mapGetters({
      isPageNavigationInProgress: 'isPageNavigationInProgress'
    })
  }
}
</script>

<style lang="scss" scoped>
@keyframes slide-continuous {
  0% {
    left: 0;
    right: auto;
    width: 0;
  }

  50% {
    width: 100%;
  }

  100% {
    left: auto;
    right: 0;
    width: 0;
  }
}

.progress-bar {
  position: absolute;
  z-index: $z-index-progress-bar;
  transition: top 0.3s;
  top: 100%;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: $c--main;
  animation: 6s ease-in-out slide-continuous;
  animation-iteration-count: infinite;

  @include tablet {
    top: 60px;
    animation-duration: 3s;
  }
}

.header-collapsed .progress-bar {
  top: 65px;
}
</style>
