import api from '~/utils/api'
import * as types from './mutation-types'
import { FL_GROUP_TYPE, FL_STATIC_PAGE } from 'enums/fl-seo'
import { cachedRequest } from '@/utils/jobRequestApiMethods'
import { ROUTE } from 'enums/routes'
import { prettifyPath } from '@/plugins/helper'
import { generateHomepageTitle } from '@/plugins/custom-generate-head'
import { API_VERSION } from 'enums/api'

const ROUTES_WITH_CACHED_SEO_REDIRECT = [ROUTE.ALL_NEWS]
const ROUTE_PATHS_WITH_CACHED_SEO_REDIRECT = ROUTES_WITH_CACHED_SEO_REDIRECT.map(
  route => prettifyPath(route)
)

const ROUTES_WITH_CACHED_SEO = []
const ROUTE_PATHS_WITH_CACHED_SEO = ROUTES_WITH_CACHED_SEO.map(route =>
  prettifyPath(route)
)

const PAGE_SEO_MODEL = {
  MetaTitle: '',
  MetaDescription: '',
  Directives: '',
  Keyphrases: []
}

export const generateInternalApiUrl = (ctx, path) => {
  return `${ctx.$env.DOMAIN_URL}/${path}`
}

export const state = () => ({
  pageSeo: { ...PAGE_SEO_MODEL },
  errorPageSeo: { ...PAGE_SEO_MODEL },
  homepageSeo: { ...PAGE_SEO_MODEL },
  ogSiteName: ''
})

export const getters = {
  pageSeo: state => state.pageSeo,
  errorPageSeo: state => state.errorPageSeo,
  homepageSeo: state => state.homepageSeo,
  ogSiteName: state => state.ogSiteName
}

export const actions = {
  async requestFlRedirect(_, params) {
    try {
      const isCachedRequest = ROUTE_PATHS_WITH_CACHED_SEO_REDIRECT.includes(
        params.from
      )

      let res
      if (isCachedRequest) {
        res = await cachedRequest({
          method: this.$axios.get,
          args: [api.seo.getRedirect(), { params }],
          apiVersion: API_VERSION.V1
        })
      } else {
        res = await this.$axios.get(api.seo.getRedirect(), {
          params
        })
      }

      return res.data || {}
    } catch (err) {
      throw err
    }
  },
  async requestFlDirRedirect(_, params) {
    try {
      const { data } = await this.$axios.get(api.directorySeo.getRedirect(), {
        params
      })
      return data || {}
    } catch (err) {
      throw err
    }
  },
  async requestFlPageSeo({ commit }, params) {
    try {
      const isCachedRequest =
        params.possibleTypes[0] === FL_GROUP_TYPE.STATIC &&
        ROUTE_PATHS_WITH_CACHED_SEO.includes(prettifyPath(params.value))

      let res
      if (isCachedRequest) {
        res = await cachedRequest({
          method: this.$axios.get,
          args: [api.seo.getPageSeo(), { params }],
          apiVersion: API_VERSION.V1
        })
      } else {
        res = await this.$axios.get(api.seo.getPageSeo(), {
          params
        })
      }

      commit(types.SET_PAGE_SEO, res.data)
    } catch (err) {
      throw err
    }
  },
  async requestFlDirPageSeo({ commit }, params) {
    try {
      const { data } = await this.$axios.get(api.directorySeo.getPageSeo(), {
        params
      })

      commit(types.SET_PAGE_SEO, data)
    } catch (err) {
      throw err
    }
  },
  async requestErrorPageSeo({ commit }) {
    try {
      const params = {
        possibleTypes: [FL_GROUP_TYPE.STATIC],
        value: FL_STATIC_PAGE.ERROR
      }

      const { data } = await cachedRequest({
        method: this.$axios.get,
        args: [
          api.seo.getPageSeo(),
          {
            params
          }
        ],
        apiVersion: API_VERSION.V1
      })

      commit(types.SET_ERROR_PAGE_SEO, data)
    } catch (err) {
      throw err
    }
  },
  async requestHomepageSeo({ commit }) {
    try {
      const params = {
        possibleTypes: [FL_GROUP_TYPE.STATIC],
        value: FL_STATIC_PAGE.HOMEPAGE
      }

      const { data } = await cachedRequest({
        method: this.$axios.get,
        args: [
          api.seo.getPageSeo(),
          {
            params
          }
        ],
        apiVersion: API_VERSION.V1
      })

      commit(types.SET_HOMEPAGE_SEO, data)
      commit(types.SET_OG_SITE_NAME, data.MetaTitle)
    } catch (err) {
      throw err
    }
  },
  async requestMigratedRedirectSettings(_, { queryParams }) {
    if (!process.client) {
      throw new Error(
        'seo/requestMigratedRedirectSettings action must not be called from the server-side!'
      )
    }

    try {
      const requestUrl = generateInternalApiUrl(
        this,
        'api/get-migrated-redirect-by-path'
      )

      const { data } = await this.$axios.get(requestUrl, {
        params: queryParams
      })

      return data
    } catch (err) {
      throw err
    }
  }
}

export const mutations = {
  [types.SET_PAGE_SEO](state, seo) {
    state.pageSeo = seo || {}
  },
  [types.SET_ERROR_PAGE_SEO](state, seo) {
    state.errorPageSeo = seo || {}
  },
  [types.SET_HOMEPAGE_SEO](state, seo) {
    state.homepageSeo = seo || {}
  },
  [types.SET_OG_SITE_NAME](state, metaTitle) {
    state.ogSiteName = generateHomepageTitle(metaTitle)
  }
}
