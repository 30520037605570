<template>
  <a-image
    :image="image"
    :src="src"
    :alt="alt"
    :object-fit="objectFit"
    :aspect-ratio="aspectRatio"
    :url="url"
    :link-trailing-slash="linkTrailingSlash"
    :directives="directives"
    :open-in-new-tab="openInNewTab"
    :force-url="forceUrl"
    :srcset-location="srcsetLocation"
    :viewport-optimization="viewportOptimization"
    :debug="debug"
    enlargeable
    v-on="$listeners"
    v-bind="$attrs"
  />
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { REL_ATTRIBUTE_BACKEND_ENUM } from '@fmpedia/enums'

export default {
  name: 'AEnlargeableImage',
  inheritAttrs: false,
  props: {
    image: propValidator([PROP_TYPES.OBJECT], false),
    src: propValidator([PROP_TYPES.STRING, PROP_TYPES.FILE], false),
    alt: propValidator([PROP_TYPES.STRING], false),
    objectFit: propValidator([PROP_TYPES.STRING], false, 'contain'),
    aspectRatio: propValidator(
      [PROP_TYPES.NUMBER, PROP_TYPES.STRING],
      false,
      null
    ),
    url: propValidator([PROP_TYPES.STRING, PROP_TYPES.OBJECT], false, ''),
    linkTrailingSlash: propValidator([PROP_TYPES.BOOLEAN], false),
    directives: propValidator(
      [PROP_TYPES.STRING],
      false,
      REL_ATTRIBUTE_BACKEND_ENUM.NOFOLLOW
    ),
    openInNewTab: propValidator(
      [PROP_TYPES.BOOLEAN, PROP_TYPES.STRING],
      false,
      null
    ),
    forceUrl: propValidator([PROP_TYPES.BOOLEAN], false, false),
    srcsetLocation: propValidator([PROP_TYPES.STRING], false, null),
    viewportOptimization: propValidator([PROP_TYPES.BOOLEAN], false, false),
    debug: propValidator([PROP_TYPES.BOOLEAN], false, false),
    enlargeable: propValidator(
      [PROP_TYPES.BOOLEAN, PROP_TYPES.STRING],
      false,
      false
    )
  }
}
</script>
