import api from '~/utils/api'

export const actions = {
  async requestFollowTags(_, { payload }) {
    try {
      await this.$axios.post(api.users.followTags(), payload)
    } catch (err) {
      throw err
    }
  },
  async requestUnfollowTags(_, { payload }) {
    try {
      await this.$axios.post(api.users.unfollowTags(), payload)
    } catch (err) {
      throw err
    }
  },
  async requestSaveForLater(_, { payload }) {
    try {
      await this.$axios.post(api.users.saveArticleForLater(), payload)
    } catch (err) {
      throw err
    }
  },
  async requestDeleteSavedArticle(_, { payload }) {
    try {
      await this.$axios.post(api.users.deleteSavedArticle(), payload)
    } catch (err) {
      throw err
    }
  },
  async requestFollowAuthors(_, { payload }) {
    try {
      await this.$axios.post(api.users.followAuthors(), payload)
    } catch (err) {
      throw err
    }
  },
  async requestUnfollowAuthors(_, { payload }) {
    try {
      await this.$axios.post(api.users.unfollowAuthors(), payload)
    } catch (err) {
      throw err
    }
  },
  async requestSaveSearchHistory(_, { payload }) {
    try {
      await this.$axios.post(api.users.saveSearchHistory(), payload)
    } catch (err) {
      throw err
    }
  }
}
