export const MIGRATED_CATEGORY_SLUG = {
  CRYPTOCURRENCY: 'Cryptocurrency',
  EDUCATION: 'Education',
  ORDERS: 'Orders',
  CENTRAL_BANKS: 'CentralBanks',
  TECHNICAL_ANALYSIS: 'technical-analysis',
  NEWS: 'news'
}

const MIGRATED_CATEGORY_SLUG_VALUES = Object.values(MIGRATED_CATEGORY_SLUG)

export function findMigratedCategorySlug(category) {
  return MIGRATED_CATEGORY_SLUG_VALUES.find(
    specialCategory =>
      specialCategory.toLowerCase() === (category && category.toLowerCase())
  )
}

export const ARTICLE_CATEGORY_SLUG_BY_CATEGORY_SLUG = {
  [MIGRATED_CATEGORY_SLUG.CENTRAL_BANKS]: 'centralbank',
  [MIGRATED_CATEGORY_SLUG.EDUCATION.toLowerCase()]: MIGRATED_CATEGORY_SLUG.EDUCATION,
  [MIGRATED_CATEGORY_SLUG.CRYPTOCURRENCY.toLowerCase()]: MIGRATED_CATEGORY_SLUG.CRYPTOCURRENCY,
  [MIGRATED_CATEGORY_SLUG.ORDERS.toLowerCase()]: MIGRATED_CATEGORY_SLUG.ORDERS
}

const ARTICLE_CATEGORY_SLUG_BY_LOWERCASE_CATEGORY_SLUG = Object.entries(
  ARTICLE_CATEGORY_SLUG_BY_CATEGORY_SLUG
).reduce((acc, [key, val]) => ({ ...acc, [key.toLowerCase()]: val }), {})

const CATEGORY_SLUG_BY_ARTICLE_CATEGORY_SLUG = Object.entries(
  ARTICLE_CATEGORY_SLUG_BY_CATEGORY_SLUG
).reduce((acc, [key, val]) => ({ ...acc, [val.toLowerCase()]: key }), {})

export function findArticleCategorySlugByCategorySlug(category = '') {
  return ARTICLE_CATEGORY_SLUG_BY_LOWERCASE_CATEGORY_SLUG[
    category.toLowerCase()
  ]
}

export function findCategorySlugByArticleCategorySlug(category = '') {
  return CATEGORY_SLUG_BY_ARTICLE_CATEGORY_SLUG[category.toLowerCase()]
}

export function handleCategorySlug(categorySlug) {
  return findMigratedCategorySlug(categorySlug) || categorySlug
}
