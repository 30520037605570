import { ROUTE_NAME } from 'enums/routes'
import scss from '@/utils/scss'

const { breakpoints } = scss

export const PAGES_WITHOUT_SIDEBAR = [
  ROUTE_NAME.SHARED_COMPONENTS,
  ROUTE_NAME.TERMS_OF_USE,
  ROUTE_NAME.PRIVACY,
  ROUTE_NAME.COOKIES,
  ROUTE_NAME.NEWSLETTER,
  ROUTE_NAME.FORGOT_PASSWORD,
  ROUTE_NAME.RESET_PASSWORD,
  ROUTE_NAME.REACTIVATION,
  ROUTE_NAME.AUTHOR,
  ROUTE_NAME.AUTHOR_AUTHOR,
  ROUTE_NAME.CONTACT_US,
  ROUTE_NAME.FOREX_BROKERS,
  ROUTE_NAME.DIRECTORY_COMPANY,
  ROUTE_NAME.BECOME_AN_AUTHOR
]

export const WIDGET_PAGES_BREAKPOINTS_TO_HIDE_SIDEBAR_ON = [
  breakpoints.mobile,
  breakpoints.tablet,
  breakpoints.desktopSm
]

export const DEFAULT_BREAKPOINTS_TO_HIDE_SIDEBAR_ON = [
  breakpoints.mobile,
  breakpoints.tablet
]

export const BREAKPOINTS_TO_HIDE_SIDEBAR_ON_BY_PAGE = {
  [ROUTE_NAME.EDUCATION]: WIDGET_PAGES_BREAKPOINTS_TO_HIDE_SIDEBAR_ON,
  [ROUTE_NAME.LIVE_CHARTS]: WIDGET_PAGES_BREAKPOINTS_TO_HIDE_SIDEBAR_ON,
  [ROUTE_NAME.LIVE_QUOTES]: WIDGET_PAGES_BREAKPOINTS_TO_HIDE_SIDEBAR_ON,
  [ROUTE_NAME.LIVE_QUOTES_ASSET]: WIDGET_PAGES_BREAKPOINTS_TO_HIDE_SIDEBAR_ON,
  [ROUTE_NAME.ECONOMIC_CALENDAR]: WIDGET_PAGES_BREAKPOINTS_TO_HIDE_SIDEBAR_ON
}

export const ALL_BREAKPOINTS = Object.values(breakpoints)

export const CLASS_BY_BREAKPOINT = {
  [breakpoints.mobile]: 'full-width-on-mobile',
  [breakpoints.tablet]: 'full-width-on-tablet',
  [breakpoints.desktopSm]: 'full-width-on-desktop-sm',
  [breakpoints.desktopSm2]: 'full-width-on-desktop-sm2',
  [breakpoints.desktopSm3]: 'full-width-on-desktop-sm3',
  [breakpoints.desktopMd]: 'full-width-on-desktop-md',
  [breakpoints.desktopLg]: 'full-width-on-desktop-lg',
  [breakpoints.aboveDesktopXl]: 'full-width-on-desktop-full-hd'
}
