import { OBSERVER_CALLBACK_DEFAULT_THROTTLE } from 'enums/mutation-observer'
import { mapActions, mapGetters } from 'vuex'
import * as types from '~/store/mutation-types'
import { COOKIE_ENTITY_TYPE } from 'enums/oneTrust'
import { createOneTrustHandlerInstance } from 'enums/oneTrust/one-trust-handler'

export const captchaLazyLoad = {
  data() {
    return {
      formVisibilityOptions: {
        callback: this.$_captchaLazyLoad_loadCaptchaScript,
        throttle: OBSERVER_CALLBACK_DEFAULT_THROTTLE,
        once: true
      }
    }
  },
  computed: {
    ...mapGetters({
      isCaptchaLoaded: 'isCaptchaLoaded',
      isCaptchaLoadingInProgress: 'isCaptchaLoadingInProgress'
    })
  },
  methods: {
    ...mapActions({
      registerHandlerThatRequiresConsent:
        'one-trust/registerHandlerThatRequiresConsent'
    }),
    $_captchaLazyLoad_loadCaptchaScript(isVisible) {
      if (
        this.isCaptchaLoaded ||
        this.isCaptchaLoadingInProgress ||
        !isVisible
      ) {
        return
      }

      /**
       * We check this variable to ensure that captcha script is loaded
       * only once
       */
      this.$store.commit(types.SET_CAPTCHA_LOADING_IN_PROGRESS_STATUS, true)

      this.registerHandlerThatRequiresConsent(
        createOneTrustHandlerInstance({
          handler: this.$_captchaLazyLoad_addCaptchaScriptToHead,
          entityType: COOKIE_ENTITY_TYPE.RE_CAPTCHA
        })
      )
    },
    $_captchaLazyLoad_addCaptchaScriptToHead() {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.onload = this.$_captchaLazyLoad_onCaptchaScriptLoad
      script.src = `https://www.google.com/recaptcha/api.js?render=explicit`
      this.$helper.insertScriptToHead(script)
    },
    async $_captchaLazyLoad_onCaptchaScriptLoad() {
      if (window.grecaptcha) {
        /**
         * Just waiting for a script "api.js" to load is not enough since
         * another script is requested under the hood and the captcha
         * cannot be considered ready until that script is also loaded
         */
        window.grecaptcha.ready(() => {
          this.$store.commit(types.SET_CAPTCHA_LOAD_STATUS, true)
          this.$store.commit(
            types.SET_CAPTCHA_LOADING_IN_PROGRESS_STATUS,
            false
          )
        })
      }
    }
  }
}
