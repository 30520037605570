/**
 * [scrollBeharior]{@link https://nuxtjs.org/api/configuration-router#scrollbehavior}
 * [scrollToTop]{@link https://nuxtjs.org/api/pages-scrolltotop/}
 *
 * This file is copied from:
 * node_modules/@nuxt/vue-app/template/router.scrollBehavior.js
 *
 * We use original implementation + we do not scroll to savedPositions on pages
 * with pagination.
 */

import { pathOr } from 'ramda'

  import { getMatchedComponents } from './utils'

  if (process.client) {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'

      // reset scrollRestoration to auto when leaving page, allowing page reload
      // and back-navigation from other pages to use the browser to restore the
      // scrolling position.
      window.addEventListener('beforeunload', () => {
        window.history.scrollRestoration = 'auto'
      })

      // Setting scrollRestoration to manual again when returning to this page.
      window.addEventListener('load', () => {
        window.history.scrollRestoration = 'manual'
      })
    }
  }

const TOP_POSITION = { x: 0, y: 0 }

export default function (to, from, savedPosition) {
    const scrollToTopValue = getScrollToTopValue(to)

    /**
     * When the page is refreshed (or entered anew), we do not handle the scroll
     * position as it is handled by window.history.scrollRestoration = 'auto'
     * automatically.
     * Exception: we always scroll to top if there is a scrollToTop: true value
     * on the page.
     */
    if (isFreshLoad(from, to) && !scrollToTopValue) return false

    /**
     * We always scroll to top if there is "scrollToTop: true" on the page
     */
    if (scrollToTopValue) return TOP_POSITION

    /**
     * We do not scroll to top if there is "scrollToTop: false" on the page
     */
    if (scrollToTopValue === false) return savedPosition || false

    /**
     * We do not scroll if we redirect user to the same page with anchor (hash).
     * Scrolling is managed outside this function (like mixins - general -
     * initialScroll mixin)
     */
    if (isSameLinkWithDifferentHash(from, to))  return false

    /**
     * In all other cases we scroll user to saved position or to the top.
     */
    return savedPosition || TOP_POSITION
  }

function isFreshLoad(from, to) {
  return from.fullPath === to.fullPath
}

function getScrollToTopValue(to) {
  return pathOr(null, ['matched', to.matched.length - 1, 'components', 'default', 'options', 'scrollToTop'], to)
}

function isSameLinkWithDifferentHash(from, to) {
  const isSamePath = from.path === to.path
  const isDifferentHash = from.hash !== to.hash

  return isSamePath && isDifferentHash
}
