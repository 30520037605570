<template>
  <a-modal-wrapper
    :modal-name="MODAL.CONTACT_US"
    close-button
    :width="645"
    class="contact-us__modal"
  >
    <a-contact-us-form
      focus-on-mount
      class="contact-us__form"
      :closeOnSubmit="closeModal"
      :form-id="$options.consts.FORM_ID.CONTACT_US_MODAL"
    />
  </a-modal-wrapper>
</template>

<script>
import { MODAL } from 'modals/AModalWrapper'
import { FORM_ID } from 'enums/form-id'

export default {
  name: 'AContactUsModal',
  components: {
    AModalWrapper: () => import('modals/AModalWrapper'),
    AContactUsForm: () => import('shared/AContactUsForm')
  },
  consts: {
    FORM_ID
  },
  data() {
    return { MODAL }
  },
  methods: {
    closeModal() {
      this.$helper.closeModal(MODAL.CONTACT_US)
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-us__modal {
  .contact-us__form {
    position: relative;
    padding: 50px 30px 25px 25px;

    @include mobile {
      padding: 50px 20px 15px;
    }
  }
}

.modal-wrapper__overlay.contact-us__modal {
  z-index: 99999;
}
</style>
