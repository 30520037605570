<template>
  <amp-youtube
    data-block-on-consent
    :data-videoid="ampVideoId"
    layout="responsive"
    :width="widgetWidth"
    :height="widgetHeight"
    v-bind="$attrs"
  ></amp-youtube>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { urlFormatters } from '@/utils/mixins/general'
import { aspectRatios } from 'enums/aspectRatios'

const { isYoutubeUrl, getYoutubeVideoId } = urlFormatters.methods

export default {
  name: 'AmpYoutubeIframe',
  props: {
    src: propValidator([PROP_TYPES.STRING], true, '', isYoutubeUrl),
    width: propValidator([PROP_TYPES.STRING, PROP_TYPES.NUMBER], false, null),
    height: propValidator([PROP_TYPES.STRING, PROP_TYPES.NUMBER], false, null),
    aspectRatio: propValidator(
      [PROP_TYPES.STRING, PROP_TYPES.NUMBER],
      false,
      null
    )
  },
  computed: {
    aspectRatioWithDefault() {
      return this.aspectRatio || aspectRatios.youtubeVideo
    },
    widgetWidth() {
      if (this.width && this.height) {
        return this.width
      }

      return this.widgetHeight * this.aspectRatioWithDefault
    },
    widgetHeight() {
      if (this.width && this.height) {
        return this.height
      }

      return 1
    },
    ampVideoId() {
      if (!this.src) return null

      return getYoutubeVideoId(this.src)
    }
  }
}
</script>
