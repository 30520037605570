import { pathOr } from 'ramda'
import { processResponse, handleResponseImage } from '@/plugins/helper'
import { applyFallback } from '@/utils/helpers'
import { ARTICLE_SLOT_MODEL } from 'enums/responseModels'

export function generateSingleArticlePageResponse(content, metaData) {
  const featuredImage = content.FeaturedImage

  return processResponse({
    articleId: content.ArticleId,
    author: metaData.ArticleDetails.Author,
    isSaved: metaData.ArticleDetails.IsSaved,
    isMigrated: metaData.ArticleDetails.IsMigrated,
    isSessionWrap: metaData.ArticleDetails.IsSessionWrap,
    terms: metaData.Terms,
    body: content.Body,
    bodyImages: pathOr([], ['BodyImages'], metaData.ArticleDetails).map(image =>
      handleResponseImage(image)
    ),
    bodyVideos: metaData.ArticleDetails.BodyVideos,
    category: metaData.Category,
    sponsorshipCompany: metaData.ArticleDetails.SponsorshipCompany,
    image: handleResponseImage(featuredImage),
    video: content.FeaturedVideo,
    publishedOn: content.PublishedOn,
    modifiedOn: content.ModifiedOn,
    brief: content.Brief,
    tags: metaData.Tags,
    title: content.Topic,
    tldr: content.TLDR,
    tableData: metaData.TableData
  })
}

export function generateArticleSlotResponse(article) {
  const articleWithFallback = applyFallback(article, ARTICLE_SLOT_MODEL)

  const remap = {
    Id: 'articleId'
  }

  return processResponse(articleWithFallback, remap)
}
