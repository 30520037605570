<template>
  <vue-recaptcha
    class="invisible-recaptcha"
    ref="recaptcha"
    :sitekey="sitekey || $env.FL_CAPTCHA_SITE_KEY"
    size="invisible"
    badge="inline"
    @verify="onVerify"
    @expired="onExpired"
    @error="onError"
  />
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import { waitForElAppearsInDom } from '@fmpedia/helpers'

export const GOOGLE_CAPTCHA_CHALLENGE_SELECTOR =
  'iframe[title="recaptcha challenge"]'
const GOOGLE_CAPTCHA_CHALLENGE_Z_INDEX = 2147483001

const GRECAPTCHA_IFRAME_SELECTOR = '.grecaptcha-logo > iframe'
const GRECAPTCHA_WAIT_FOR_IFRAME_TIMEOUT = 10 * 1000

export default {
  name: 'AInvisibleCaptcha',
  props: {
    captchaKey: propValidator([PROP_TYPES.STRING], false, ''),
    sitekey: propValidator([PROP_TYPES.STRING], false, '')
  },
  components: {
    VueRecaptcha
  },
  watch: {
    captchaKey(newVal) {
      if (!newVal) {
        this.resetCaptcha()
      }
    }
  },
  methods: {
    async setIframeAsNotFocusable() {
      await waitForElAppearsInDom({
        selector: GRECAPTCHA_IFRAME_SELECTOR,
        elementToSearchIn: this.$el.parentElement,
        timeout: GRECAPTCHA_WAIT_FOR_IFRAME_TIMEOUT
      })

      const iframe = this.$el.parentElement.querySelector(
        GRECAPTCHA_IFRAME_SELECTOR
      )

      iframe.setAttribute('tabindex', '-1')
    },
    onVerify(key) {
      this.$emit('update:captchaKey', key)
      this.$emit('verify', key)

      /**
       * We need to reset captcha right after verification since otherwise "verify" event will not be triggered until
       * captcha is expired
       */
      this.resetCaptcha()
    },
    onExpired() {
      this.$emit('update:captchaKey', null)
    },
    onError(err) {
      this.$errorHandler(new Error(err), this, {
        userMessage: 'Error occurred while connecting to captcha'
      })
    },
    resetCaptcha() {
      this.$refs.recaptcha.reset()
    },
    execute() {
      this.setStylesForCaptchaChallenges()
      this.$refs.recaptcha.execute()
    },
    /**
     * There is no clean way to bind captcha challenge window to a form that
     * triggers it, (see issue: https://github.com/google/recaptcha/issues/289)
     * That's why, we set custom styles for this window (fixed to top)
     * Otherwise, when form appears in popup or is sticky, if we scroll, the captcha
     * can be moved away from a viewport
     */
    setStylesForCaptchaChallenges() {
      const googleCaptchaChallengeIframes = Array.from(
        document.querySelectorAll(GOOGLE_CAPTCHA_CHALLENGE_SELECTOR)
      )

      googleCaptchaChallengeIframes.forEach(iframe => {
        const challangeContainer = iframe.parentElement
        challangeContainer.style.position = 'fixed'
        challangeContainer.style.zIndex = GOOGLE_CAPTCHA_CHALLENGE_Z_INDEX
      })
    }
  },
  mounted() {
    this.setIframeAsNotFocusable()
  },
  beforeDestroy() {
    this.$refs.recaptcha.reset()
  }
}
</script>

<style lang="scss" scoped>
.invisible-recaptcha {
  height: 0;
  width: 0;
  overflow: hidden;
}
</style>
