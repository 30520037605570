import { getContext, getLinkToFmBucketFile, sliceText } from '@/plugins/helper'
import { ensureUTCForDateWithoutTimezone } from '@fmpedia/helpers'

export const PAGE_SCHEME_TYPE = {
  NEWS_ARTICLE: 'NewsArticle',
  ARTICLE: 'Article',
  WEB_SITE: 'WebSite',
  ORGANIZATION: 'Organization',
  WEB_PAGE: 'WebPage',
  CONTACT_PAGE: 'ContactPage',
  PERSON: 'Person',
  VIDEO_GENERAL: 'VideoObjectGeneral'
}

const VIDEO_DESCRIPTION_LIMIT = 300
const SCHEME_TEXT_LIMIT = 1000

function getDomain() {
  return `${getContext().store.$env.DOMAIN_URL}/`
}

const LOGO_WIDTH = '180'
const LOGO_HEIGHT = '36'

function getLogoPath() {
  return getLinkToFmBucketFile('fl-logo-dark.svg')
}

function getCurrentYear() {
  return new Date().getFullYear()
}

function formatVideoDescription(description) {
  return sliceText({ text: description, limit: VIDEO_DESCRIPTION_LIMIT }).trim()
}

function formatText(text) {
  return sliceText({ text, limit: SCHEME_TEXT_LIMIT })
}

function generateImageSchema({ url, width, height }) {
  const imageSchemaObject = `{
      "@type": "ImageObject",
      "url": "${url}",
      "width": ${Math.round(width)},
      "height": ${Math.round(height)}
    }`

  const imageSchemaRegular = `"${url}"`

  return width && height ? imageSchemaObject : imageSchemaRegular
}

export const PAGE_SCHEME = {
  [PAGE_SCHEME_TYPE.WEB_SITE]: ({
    genre = 'Online Trading Forex News, Analysis and Reviews',
    headline = 'Forexlive | Forex News, Technical Analysis and Trading Tools',
    isBasedOnUrl = getDomain(),
    text = 'Forex news from ForexLive. The fastest Foreign Exchange market reporting and analysis. Live Forex and economic news. Technical analysis, headlines, Live quotes.',
    thumbnailUrl = getLogoPath(),
    description = 'Forex news from ForexLive. The fastest Foreign Exchange market reporting and analysis. Live Forex and economic news. Technical analysis, headlines, Live quotes.'
  }) =>
    `"@type": "WebSite",
	   "genre": "${genre}",
	   "headline": "${headline}",
	   "isBasedOnUrl": "${isBasedOnUrl}",
	   "keywords": [
	   	"Forex News",
	   	"Forexlive"
	   ],
	   "text": "${formatText(text)}",
	   "thumbnailUrl": "${thumbnailUrl}",
	   "description": "${description}"`,
  [PAGE_SCHEME_TYPE.ARTICLE]: ({
    articleBody = '',
    articleSection = '',
    wordCount = '',
    alternativeHeadline = '',
    copyrightYear = getCurrentYear(),
    datePublished = '',
    dateModified = '',
    discussionUrl = '',
    headline = '',
    keywords = '',
    thumbnailUrl = getLogoPath(),
    thumbnailWidth = null,
    thumbnailHeight = null,
    timeRequired = '',
    alternateName = '',
    description = '',
    author = '',
    authorUrl = '',
    mainEntityOfPage = ''
  }) => `"@type": "Article",
	   "articleBody": "${articleBody}",
	   "articleSection": "${articleSection}",
	   "wordCount": "${wordCount}",
	   "alternativeHeadline": "${alternativeHeadline}",
	   "copyrightYear": "${copyrightYear}",
	   "datePublished": "${datePublished}",
	   "dateModified": "${dateModified}",
	   "discussionUrl": "${discussionUrl}",
	   "headline": "${headline}",
	   "keywords": "${keywords}",
	   "thumbnailUrl": "${thumbnailUrl}",
	   "timeRequired": "${timeRequired}",
	   "alternateName": "${alternateName}",
	   "description": "${description}",
	   "author": {
	      "@type": "Organization",
        "name": "${author}",
        "url": "${authorUrl}"
     },
	   "image": ${generateImageSchema({
       url: thumbnailUrl,
       width: thumbnailWidth,
       height: thumbnailHeight
     })},
	   "publisher": {
	      "@type": "Organization",
	      "name": "Forexlive",
	      "logo": {
	          "@type": "ImageObject",
            "url": "${getLogoPath()}",
            "width": "${LOGO_WIDTH}",
            "height": "${LOGO_HEIGHT}"
	      }
	   },
	   "mainEntityOfPage": "${mainEntityOfPage}"`,
  [PAGE_SCHEME_TYPE.NEWS_ARTICLE]: ({
    dateline = '',
    articleBody = '',
    articleSection = '',
    wordCount = '',
    alternativeHeadline = '',
    copyrightYear = getCurrentYear(),
    datePublished = '',
    dateModified = '',
    genre = '',
    headline = '',
    isFamilyFriendly = 'true',
    text = '',
    thumbnailUrl = '',
    thumbnailWidth = null,
    thumbnailHeight = null,
    description = '',
    author = '',
    authorUrl = '',
    mainEntityOfPage = ''
  }) => `"@type": "NewsArticle",
	   "dateline": "${dateline}",
	   "articleBody": "${articleBody}",
	   "articleSection": "${articleSection}",
	   "wordCount": "${wordCount}",
	   "alternativeHeadline": "${alternativeHeadline}",
	   "copyrightYear": "${copyrightYear}",
	   "datePublished": "${datePublished}",
	   "dateModified": "${dateModified}",
	   "genre": "${genre}",
	   "headline": "${headline}",
	   "isFamilyFriendly": "${isFamilyFriendly}",
	   "text": "${formatText(text)}",
	   "thumbnailUrl": "${thumbnailUrl}",
	   "description": "${description}",
	   "author": {
	      "@type": "Person",
        "name": "${author}",
        "url": "${authorUrl}"
     },
	   "image": ${generateImageSchema({
       url: thumbnailUrl,
       width: thumbnailWidth,
       height: thumbnailHeight
     })},
	   "publisher": {
	      "@type": "Organization",
	      "name": "Forexlive",
	      "logo": {
	          "@type": "ImageObject",
            "url": "${getLogoPath()}",
            "width": "${LOGO_WIDTH}",
            "height": "${LOGO_HEIGHT}"
	      }
	   },
	   "mainEntityOfPage": "${mainEntityOfPage}"`,
  [PAGE_SCHEME_TYPE.ORGANIZATION]: () =>
    `"@type": "Organization",
	   "brand": "${getDomain()}",
	   "founder": {
	   	"@type": "Person",
	   	"familyName": "Greenberg",
	   	"givenName": "Michael ",
	   	"jobTitle": "CEO"
	   },
	   "description": "Forex news from ForexLive. The fastest Foreign Exchange market reporting and analysis. Live Forex and economic news. Technical analysis, headlines, Live quotes.",
	   "name": "ForexLive"`,
  [PAGE_SCHEME_TYPE.WEB_PAGE]: ({
    relatedLink = '',
    significantLink = '',
    alternativeHeadline = '',
    copyrightYear = getCurrentYear(),
    headline = '',
    isAccessibleForFree = 'Yes',
    keywords = '',
    text = '',
    thumbnailUrl = '',
    description = '',
    mainEntityOfPage = '',
    name = ''
  }) =>
    `"@type": "WebPage",
	   "relatedLink": "${relatedLink}",
	   "significantLink": "${significantLink}",
	   "alternativeHeadline": "${alternativeHeadline}",
	   "copyrightYear": "${copyrightYear}",
	   "headline": "${headline}",
	   "isAccessibleForFree": "${isAccessibleForFree}",
	   "keywords": "${keywords}",
	   "text": "${formatText(text)}",
	   "thumbnailUrl": "${thumbnailUrl}",
	   "description": "${description}",
	   "mainEntityOfPage": "${mainEntityOfPage}",
	   "name": "${name}"`,
  [PAGE_SCHEME_TYPE.CONTACT_PAGE]: ({
    relatedLink = '',
    significantLink = '',
    headline = 'Contact Us',
    isBasedOnUrl = '',
    keywords = 'Contact Us',
    text = 'Thank you for using Forexlive. Please fill out the quick form and we will be in touch with lightning speed',
    description = 'contact us page',
    name = '/contact-us'
  }) =>
    `"@type": "ContactPage",
	   "relatedLink": "${relatedLink}",
	   "significantLink": "${significantLink}",
	   "headline": "${headline}",
	   "isBasedOnUrl": "${isBasedOnUrl}",
	   "keywords": "${keywords}",
	   "text": "${formatText(text)}",
	   "description": "${description}",
	   "name": "${name}"`,
  [PAGE_SCHEME_TYPE.PERSON]: ({ name = '', jobTitle = '', url = '' }) =>
    `"@type": "Person",
	   "name": "${name}",
	   "jobTitle": "${jobTitle}",
	   "url": "${url}"`,
  [PAGE_SCHEME_TYPE.VIDEO_GENERAL]: ({
    name = '',
    description = '',
    thumbnailUrl = '',
    uploadDate = '',
    embedUrl = ''
  }) => {
    const uploadDateFormatted = ensureUTCForDateWithoutTimezone(uploadDate)

    return `"@type": "VideoObject",
    "name": "${name}",
    "description": "${formatVideoDescription(description) || name}",
    "thumbnailUrl": "${thumbnailUrl}",
    "uploadDate": "${uploadDateFormatted}",
	  "embedUrl": "${embedUrl}"`
  }
}
