import { mapActions, mapGetters } from 'vuex'

import pendingAuthActionsMaps from './pending-auth-actions-maps'

import { MODAL } from '@/components/_modals/AModalWrapper'
import {
  ACTIVATOR_ID,
  ACTIVATOR_SELECTOR_BY_ACTIVATOR_ID
} from 'enums/activator-id'
import {
  PENDING_ACTION,
  PENDING_ACTION_EVENT,
  REFRESH_EVENT_BY_PENDING_ACTION
} from 'enums/pending-actions'
import { GUARDED_ROUTES, ROUTE } from 'enums/routes'

export default {
  mixins: [pendingAuthActionsMaps],
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      isAuthStatusRequested: 'auth/isAuthStatusRequested',
      isAuthStatusRequestInProgress: 'auth/isAuthStatusRequestInProgress'
    }),
    $_pendingAuthActions_pendingAction() {
      return PENDING_ACTION
    },
    $_pendingAuthActions_isAuthStatusReceived() {
      return !this.isAuthStatusRequestInProgress && this.isAuthStatusRequested
    }
  },
  methods: {
    ...mapActions({
      concurrentRouterPush: 'router/concurrentRouterPush'
    }),
    $_pendingAuthActions_waitForAuthStatusIsReceived() {
      return new Promise(resolve => {
        this.$helper.watchAndExecuteOnce({
          ctx: this,
          field: '$_pendingAuthActions_isAuthStatusReceived',
          handlerFn: resolve,
          conditionFn: newVal => !!newVal,
          immediate: true
        })
      })
    },
    /**
     * @param actionType    - (required) PENDING_ACTION enum value
     * @param actionData    - (optional) any data that may be used while generating
     *                      pending action handler, update payload and refresh payload
     * @param defaultUserLocation  - (optional) overwrites the default this.$route.fullpath
     *                      value to redirect user after successful login to.
     * @param authorizedUserLocation  - (optional) url to redirect only if user is logged in
     * @param props         - other props that are passed to the AAuthForm component
     * @returns {Promise<void>}
     */
    async $_pendingAuthActions_handleAction({
      actionType,
      actionData,
      defaultUserLocation,
      authorizedUserLocation,
      ...props
    }) {
      if (this.isAuthStatusRequestInProgress) {
        await this.$_pendingAuthActions_waitForAuthStatusIsReceived()
      }

      if (this.isLoggedIn) {
        this.$_pendingAuthAction_redirectUser(
          authorizedUserLocation || defaultUserLocation
        )
        this.$_pendingAuthActions_runAction({ actionType, actionData })
        return
      }

      this.$helper.openModal(MODAL.AUTH_FORM, {
        actionType,
        actionData,
        defaultUserLocation,
        authorizedUserLocation,
        activatorSelector:
          ACTIVATOR_SELECTOR_BY_ACTIVATOR_ID[ACTIVATOR_ID.AUTH],
        ...props
      })
    },
    $_pendingAuthActions_getFullPathFromUrl(url) {
      const domainRegexp = new RegExp(`^${this.$env.DOMAIN_URL}`, 'i')
      return url.replace(domainRegexp, '') || ROUTE.HOMEPAGE
    },
    $_pendingAuthActions_checkIfRedirectAllowed(fullPath) {
      try {
        const route = this.$router.match(fullPath)
        return this.isLoggedIn || !GUARDED_ROUTES.includes(route.name)
      } catch (err) {
        return true
      }
    },
    async $_pendingAuthAction_redirectUser(userLocation) {
      if (!userLocation) return

      const fullPath = this.$_pendingAuthActions_getFullPathFromUrl(
        userLocation
      )

      if (fullPath === this.$route.fullPath) return

      try {
        const { origin } = new URL(userLocation)
        if (origin === this.$env.DOMAIN_URL) {
          const isRedirectAllowed = this.$_pendingAuthActions_checkIfRedirectAllowed(
            fullPath
          )
          const redirectToPath = isRedirectAllowed ? fullPath : ROUTE.HOMEPAGE
          await this.concurrentRouterPush({
            args: { router: this.$router, to: redirectToPath }
          })
        } else {
          window.location.href = userLocation
        }
      } catch (err) {
        window.location.href = userLocation
      }
    },
    async $_pendingAuthActions_runAction({ actionType, actionData }) {
      if (!actionType) return

      const {
        handler,
        updatePayload,
        refreshPayload
      } = this.$_pendingAuthActions_getHandlerWithPayloadToUpdate({
        actionType,
        actionData
      })

      let isSuccess = false
      this.$_pendingAuthActions_emitStart(refreshPayload)
      if (handler) {
        try {
          await handler(updatePayload)
          isSuccess = true
          this.$_pendingAuthActions_emitRefresh(actionType, refreshPayload)
        } catch (err) {
          this.$errorHandler(err, this)
        }
      }
      const endPendingActionPayload = refreshPayload
        ? { ...refreshPayload, isSuccess }
        : { isSuccess }
      this.$_pendingAuthActions_emitEnd(endPendingActionPayload)
    },
    $_pendingAuthActions_emitStart(refreshPayload) {
      if (!refreshPayload) return

      this.$bus.$emit(PENDING_ACTION_EVENT.START_PENDING_ACTION, refreshPayload)
    },
    $_pendingAuthActions_emitRefresh(actionType, refreshPayload) {
      const event = REFRESH_EVENT_BY_PENDING_ACTION[actionType]

      if (!event || !refreshPayload) return

      this.$bus.$emit(event, refreshPayload)
    },
    $_pendingAuthActions_emitEnd(refreshPayload) {
      if (!refreshPayload) return

      this.$bus.$emit(PENDING_ACTION_EVENT.END_PENDING_ACTION, refreshPayload)
    },
    $_pendingAuthActions_getHandlerWithPayloadToUpdate({
      actionType,
      actionData
    }) {
      return {
        handler: this.getUpdateHandlerByActionType[actionType]?.(actionData),
        updatePayload: this.generateUpdatePayloadByActionType[actionType]?.(
          actionData
        ),
        refreshPayload: this.generateRefreshPayloadByActionType[actionType]?.(
          actionData
        )
      }
    }
  }
}
