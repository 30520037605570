<template>
  <div class="footer-bottom-text__wrapper">
    <p class="footer-bottom-text__section">
      <span class="footer-bottom-text__section-name font-sans bold">
        {{ HIGH_RISK_WARNING_TITLE }}
      </span>
      <span class="footer-bottom-text__section-text font-sans">
        {{ HIGH_RISK_WARNING_TEXT }}
      </span>
    </p>
    <p class="footer-bottom-text__section">
      <span class="footer-bottom-text__section-name font-sans bold">
        {{ ADVISORY_WARNING_TITLE }}
      </span>
      <span class="footer-bottom-text__section-text font-sans">
        {{ ADVISORY_WARNING_TEXT }}
      </span>
    </p>
  </div>
</template>

<script>
import {
  HIGH_RISK_WARNING_TITLE,
  HIGH_RISK_WARNING_TEXT,
  ADVISORY_WARNING_TITLE,
  ADVISORY_WARNING_TEXT
} from '@/utils/enums/footer'

export default {
  name: 'FooterBottomText',
  data() {
    return {
      HIGH_RISK_WARNING_TITLE,
      HIGH_RISK_WARNING_TEXT,
      ADVISORY_WARNING_TITLE,
      ADVISORY_WARNING_TEXT
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-bottom-text__wrapper {
  .footer-bottom-text__section {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .footer-bottom-text__section-name {
    font-size: 11px;
    text-transform: uppercase;
    text-decoration: underline;
  }

  .footer-bottom-text__section-text {
    font-size: 11px;
  }
}
</style>
