<template>
  <div class="hidden">
    <iframe
      v-for="singleSettings in changedSettings"
      :key="singleSettings.queryString"
      class="cross-domain-auth__iframe"
      :src="getTargetSource(singleSettings)"
      frameborder="0"
      @load="stopLoading(singleSettings)"
    />
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'

export default {
  name: 'ACrossDomainIframe',
  props: {
    site: propValidator([PROP_TYPES.STRING]),
    settings: propValidator([PROP_TYPES.ARRAY])
  },
  data() {
    return {
      inProgress: this.settings.map(() => false)
    }
  },
  computed: {
    changedSettings() {
      return this.settings.filter(
        (singleSettings, index) => this.inProgress[index]
      )
    }
  },
  watch: {
    settings: {
      immediate: true,
      deep: true,
      handler(oldValue, newValue) {
        if (!newValue) return

        /**
         * Wee need to update all cookies on all sites on page load
         */
        if (!oldValue) {
          this.inProgress = newValue.map(() => true)
          return
        }

        /**
         * We process only tasks where queryString was changed
         */
        this.inProgress = newValue.map(
          ({ queryString }, index) =>
            oldValue[index].queryString !== queryString
        )
      }
    }
  },
  methods: {
    getTargetSource(settings) {
      if (!this.site || !settings) return null

      return `${this.site}${settings.api}${settings.queryString}`
    },
    stopLoading(settings) {
      this.inProgress = this.settings.map((singleSettings, index) => {
        if (singleSettings.queryString === settings.queryString) {
          return false
        } else {
          return this.inProgress[index]
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cross-domain-auth__iframe {
  width: 0;
  height: 0;
  visibility: hidden;
  position: absolute;
  right: -5000px;
}
</style>
