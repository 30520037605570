export default {
  methods: {
    $_dataValidator_validate(data, settings) {
      if (!data || !settings || !(typeof settings.validate === 'function')) {
        return false
      }

      return settings.validate(data)
    }
  }
}
