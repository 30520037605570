import { ROUTE_NAME } from 'enums/routes'
import { BANNER_SETTINGS } from 'enums/banners/banner-settings'

export const BANNER_SETTINGS_BY_ROUTE_NAME = {
  [ROUTE_NAME.ALL_NEWS]: BANNER_SETTINGS.ALL_NEWS,
  [ROUTE_NAME.CATEGORY]: BANNER_SETTINGS.CATEGORY,
  [ROUTE_NAME.SESSION_WRAPS]: BANNER_SETTINGS.CATEGORY,
  [ROUTE_NAME.CATEGORY_ARTICLE]: BANNER_SETTINGS.ARTICLE,
  [ROUTE_NAME.RSS]: BANNER_SETTINGS.RSS,
  [ROUTE_NAME.TERMS_LETTER]: BANNER_SETTINGS.TERMS_LETTER,
  [ROUTE_NAME.TERMS]: BANNER_SETTINGS.TERMS_HP,
  [ROUTE_NAME.TERMS_LETTER_TERM]: BANNER_SETTINGS.TERM_PAGE,
  [ROUTE_NAME.TERMS_SEARCH_RESULTS]: BANNER_SETTINGS.TERMS_SEARCH_RESULTS,
  [ROUTE_NAME.TAG_TAG]: BANNER_SETTINGS.TAG_PAGE,
  [ROUTE_NAME.TAG_SEARCH_RESULTS]: BANNER_SETTINGS.TAG_SEARCH_RESULTS,
  [ROUTE_NAME.SEARCH_RESULTS]: BANNER_SETTINGS.SEARCH_RESULTS,
  [ROUTE_NAME.BECOME_AN_AUTHOR]: BANNER_SETTINGS.BECOME_AN_AUTHOR,
  [ROUTE_NAME.CONTACT_US]: BANNER_SETTINGS.CONTACT_US,
  [ROUTE_NAME.LIVE_CHARTS]: BANNER_SETTINGS.LIVE_CHARTS,
  [ROUTE_NAME.LIVE_QUOTES]: BANNER_SETTINGS.LIVE_QUOTES,
  [ROUTE_NAME.LIVE_QUOTES_ASSET]: BANNER_SETTINGS.LIVE_QUOTES,
  [ROUTE_NAME.ECONOMIC_CALENDAR]: BANNER_SETTINGS.ECONOMIC_CALENDAR,
  [ROUTE_NAME.AUTHOR_AUTHOR]: BANNER_SETTINGS.AUTHOR_PAGE,
  [ROUTE_NAME.FOREX_BROKERS]: BANNER_SETTINGS.DIRECTORY_CATEGORY,
  [ROUTE_NAME.DIRECTORY_COMPANY]: BANNER_SETTINGS.DIRECTORY_COMPANY,
  [ROUTE_NAME.EDUCATION]: BANNER_SETTINGS.EDUCATION
}
