<template>
  <a-link
    :to="newsletterPageLink"
    class="subscribe-daily-news__wrapper font-sans"
  >
    Subscribe to Forexlive's Daily News Wrap
    <a-icon
      :icon="ICON.ENVELOPE_WITH_CONTENT"
      :width="18"
      :height="18"
      class="subscribe-daily-news__icon"
    />
  </a-link>
</template>

<script>
import AIcon, { ICON } from 'shared/AIcon'
import { ROUTE_NAME } from 'enums/routes'

export default {
  name: 'FooterSubscribeDailyNews',
  components: { AIcon },
  data() {
    return { ICON, newsletterPageLink: { name: ROUTE_NAME.NEWSLETTER } }
  }
}
</script>

<style lang="scss" scoped>
.subscribe-daily-news__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  width: 280px;
  height: 30px;
  border: 1px solid $c--white;
  color: $c--white;
  font-size: 12px;

  .subscribe-daily-news__icon {
    margin-left: 10px;
  }

  /deep/ .subscribe-daily-news__icon .a-icon__icon {
    fill: $c--white;
  }

  &:hover {
    border-color: $c--main;
    color: $c--main;

    /deep/ .subscribe-daily-news__icon .a-icon__icon {
      fill: $c--main;
    }
  }
}
</style>
