/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bell-inactive': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data:
      '<g _fill="none" fill-rule="evenodd" transform="translate(1 1)"><circle pid="0" cx="15" cy="15" r="15" _stroke="#B8B8B8"/><path pid="1" _fill="#B8B8B8" fill-rule="nonzero" d="M15.993 8.085c2.562.443 4.508 2.567 4.508 5.114v2.86c0 .564.483 1.027 1.086 1.027.23 0 .413.179.413.39v2.133a.42.42 0 01-.427.391h-4.609c-.201 1.138-1.225 2-2.457 2-1.232 0-2.26-.866-2.469-2H8.413A.404.404 0 018 19.61v-2.133c0-.219.19-.39.413-.39.596 0 1.086-.458 1.086-1.027v-2.861c0-2.201 1.454-4.086 3.501-4.844v-.158C13 7.537 13.673 7 14.5 7c.78 0 1.422.477 1.493 1.085zM14.983 8a.563.563 0 00-.475-.23c-.252 0-.464.137-.525.319a5.875 5.875 0 011-.089zm.86.855a.55.55 0 01-.321.1.526.526 0 01-.397-.172l-.132-.002c-.44 0-.866.058-1.27.167a.577.577 0 01-.164.047c-1.873.574-3.233 2.24-3.233 4.197v2.861c0 .86-.644 1.59-1.5 1.768v1.39h3.22a.407.407 0 01.36-.211h4.189c.158 0 .292.087.36.212h4.204v-1.39c-.855-.18-1.5-.908-1.5-1.769v-2.86c0-2.157-1.648-3.959-3.816-4.338zM16.141 20h-3.268c.191.702.85 1.217 1.634 1.217.784 0 1.443-.515 1.634-1.217z"/></g>'
  }
})
