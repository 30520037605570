<template>
  <a-visibility show :from="$breakpoint.desktopSm2">
    <div :class="wrapperClasses">
      <div class="breadcrumbs__item text-caption semi-bold">
        <a-link :to="ROUTE.ALL_NEWS">
          Home
        </a-link>
      </div>
      <div
        class="breadcrumbs__item text-caption semi-bold"
        v-for="(item, index) in calculatedBreadcrumbs"
        :key="item.routeName"
      >
        <span class="breadcrumbs__divider text-caption">></span>
        <span v-if="isLastItem(index)" class="breadcrumbs__item-text font-sans">
          {{ prettifyName(item.name) }}
        </span>
        <a-link
          v-else
          :to="item.link"
          :trailing-slash="isTrailingSlashNeeded(item.link)"
          class="breadcrumbs__item-link"
        >
          {{ prettifyName(item.name) }}
        </a-link>
      </div>
    </div>
  </a-visibility>
</template>

<script>
import { mapGetters } from 'vuex'

import { propValidator, PROP_TYPES } from '@/utils/validators'
import { ROUTE } from 'enums/routes'

export default {
  name: 'ABreadcrumbs',
  props: {
    capitalizeWords: propValidator([PROP_TYPES.BOOLEAN], false, false),
    prettifyNames: propValidator([PROP_TYPES.BOOLEAN], false, true),
    settings: propValidator([PROP_TYPES.OBJECT], false, null),
    singleLine: propValidator([PROP_TYPES.BOOLEAN], false, false)
  },
  data() {
    return {
      ROUTE
    }
  },
  computed: {
    ...mapGetters({
      breadcrumbs: 'breadcrumbs/breadcrumbs'
    }),
    calculatedBreadcrumbs() {
      if (!this.settings) return this.breadcrumbs

      return this.breadcrumbs.map(breadcrumb => {
        const routeNameWithoutOptionalSymbol = this.getRouteNameWithoutOptionalSymbol(
          breadcrumb.routeName
        )
        const settingsForCurrentRoute = this.settings[
          routeNameWithoutOptionalSymbol
        ]

        const { nameHandler, linkHandler } = settingsForCurrentRoute || {}

        return {
          name: this.getBreadcrumbName(nameHandler, breadcrumb),
          link: this.getBreadcrumbLink(linkHandler, breadcrumb),
          routeName: breadcrumb.routeName
        }
      })
    },
    wrapperClasses() {
      return ['breadcrumbs__wrapper', { 'single-line': this.singleLine }]
    }
  },
  methods: {
    getBreadcrumbName(nameHandler, breadcrumb) {
      return this.$helper.checkIfFunction(nameHandler)
        ? nameHandler(breadcrumb)
        : breadcrumb.name
    },
    getBreadcrumbLink(linkHandler, breadcrumb) {
      return this.$helper.checkIfFunction(linkHandler)
        ? linkHandler(breadcrumb)
        : {
            name: breadcrumb.routeName,
            params: breadcrumb.params
          }
    },
    isLastItem(index) {
      return this.calculatedBreadcrumbs.length === index + 1
    },
    prettifyName(name = '') {
      if (!this.prettifyNames) return name

      return this.capitalizeWords
        ? name
            .split(' ')
            .map(v => this.$helper.capitalize(v.toLowerCase()))
            .join(' ')
        : this.$helper.capitalize(name.toLowerCase())
    },
    getNewNameForCurrentBreadcrumb(
      breadcrumb,
      dynamicNameForCurrentBreadcrumb
    ) {
      return {
        ...breadcrumb,
        name: dynamicNameForCurrentBreadcrumb
      }
    },
    validateProps() {
      if (!this.prettifyNames && this.capitalizeWords) {
        console.error(
          'Prop validation failed:\n capitalizeWords:true prop will not be used because it is passed is passed together with the prop prettifyNames:true'
        )
      }
    },
    /**
     * Some dynamic route names might include optional parameters marked with a "?" symbol at the end.
     * It causes a route name mismatching when parsed in breadcrumbs middleware.
     * Example: Article page has the following name: "category-company-article", whereas breadcrumbs treat it
     * as "category-company-article?" which will result in undesirable inequality
     */
    getRouteNameWithoutOptionalSymbol(routeName) {
      return routeName.replace(/\?/, '')
    },
    isTrailingSlashNeeded(link) {
      return this.$helper.isString(link)
        ? this.$helper.isLinkWithTrailingSlash(link)
        : undefined
    }
  },
  mounted() {
    this.validateProps()
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs__wrapper {
  padding-bottom: 20px;
  padding-right: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &.single-line {
    display: flex;
    flex-wrap: nowrap;

    .breadcrumbs__item {
      flex-shrink: 0;

      &:last-child {
        flex-shrink: 1;
        overflow: hidden;
        display: flex;
      }
    }

    .breadcrumbs__item-text {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .breadcrumbs__item {
    & > a {
      color: #4a4a4a;

      &:hover {
        color: $c--main;
      }
    }

    &-link,
    &-text {
      display: inline-block;
    }

    &-text {
      color: #4a4a4a;
    }
  }

  .breadcrumbs__divider {
    color: $c--main;
    padding: 0 7px;
  }
}
</style>
