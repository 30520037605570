/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  fl: {
    width: 200,
    height: 40,
    viewBox: '0 0 200 40',
    data:
      '<g data-name="Layer 2"><g data-name="Layer 1"><path pid="0" _fill="#009acf" d="M126 4.79l.33-.38-11.22.93 3.57 2.87-12.67 15.53-7.46-8.99h-4.58l9.66 11.92L92.67 40h4.48l8.8-10.56 8.7 10.56h4.58l-10.96-13.43 17.74-21.78H126z"/><path pid="1" d="M9.07 40H5.18V16.27H0v-3.56h5.18V8.14c0-5 2.65-8.14 7.46-8.14a12.55 12.55 0 015.5 1.07V4.8a11.57 11.57 0 00-4.53-1.13q-4.54 0-4.54 5.26v3.78h9.07v3.56H9.07zM32.56 13.5a13.74 13.74 0 0110.15 3.92 12.53 12.53 0 013.95 9.36 12.52 12.52 0 01-3.95 9.35A13.94 13.94 0 0132.56 40a14 14 0 01-10.15-3.87 12.55 12.55 0 01-3.94-9.35 12.56 12.56 0 013.94-9.36 13.75 13.75 0 0110.15-3.92zm7.24 6.22a9.86 9.86 0 00-7.24-2.82 9.85 9.85 0 00-7.23 2.82 9.57 9.57 0 00-2.86 7.06 9.54 9.54 0 002.86 7 9.85 9.85 0 007.23 2.82 9.86 9.86 0 007.24-2.82 9.54 9.54 0 002.86-7 9.57 9.57 0 00-2.86-7.06zM50.92 40V14.75h3.89v4.81c1.95-3.29 6.1-5.44 10.37-5.44v3.82a10.61 10.61 0 00-3.24.47 10.51 10.51 0 00-7.13 5.65V40zM70.09 36.29A12.18 12.18 0 0166 26.78a13.06 13.06 0 013.67-9.47 12.72 12.72 0 019.56-3.81c8.75 0 12.2 7.42 12.2 13.12v1H70c.43 5.44 4.86 8.94 11.12 8.94a15.66 15.66 0 008.37-2.35v3.34C87.32 39.22 84.3 40 80.35 40a15 15 0 01-10.26-3.71zm17.39-11.71a8.48 8.48 0 00-2.27-5.28c-1.35-1.62-3.4-2.4-6.15-2.4a8.94 8.94 0 00-6.37 2.29A8.21 8.21 0 0070 24.58zM122.89 33.78V14.49l3.9-4.79v23.19c0 2.46 1.13 3.71 3.4 3.71a5 5 0 002.54-.68v3.35a8.4 8.4 0 01-3.83.73c-3.41 0-6.01-1.78-6.01-6.22zM138.55 4.19a2.82 2.82 0 013.89 0 2.6 2.6 0 010 3.77 2.92 2.92 0 01-3.89 0 2.69 2.69 0 010-3.77zm0 35.81V14.75h3.89V40zM158.26 40l-12-25.4h4.37l9.51 20.33 9.46-20.33h4.21l-12 25.4zM178.67 36.29a12.18 12.18 0 01-4.1-9.51 13.06 13.06 0 013.67-9.47 12.71 12.71 0 019.56-3.81c8.74 0 12.2 7.42 12.2 13.12v1h-21.44c.44 5.44 4.86 8.94 11.13 8.94a15.66 15.66 0 008.37-2.35v3.34c-2.16 1.63-5.19 2.41-9.13 2.41a15 15 0 01-10.26-3.67zm17.39-11.71a8.54 8.54 0 00-2.27-5.28c-1.35-1.62-3.4-2.4-6.16-2.4a9 9 0 00-6.37 2.29 8.24 8.24 0 00-2.64 5.39z" _fill="#f1f1f2"/></g></g>'
  }
})
