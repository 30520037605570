/**
 * Should be used when banner position is defined per one chunk
 * (e.g. each 7 items in chunk)
 *
 * Example #1:
 *
 * Suppose we have to repeatedly rotate 5 banners inside a list of entities
 * (articles, for this example). When each banner has to be displayed after a
 * specific number of articles (7, for this example), then the formula to get
 * the right banner number is pretty straightforward:
 *
 * (position / bannerPosition) % bannersCount || bannersCount,
 * where bannerPosition (7) and bannersCount (5) are consts.
 *
 * But, if bannerPosition is not a const then the arithmetical calculations to
 * get the right bannerNumber might be complicated:
 *
 * Example #2
 *
 * Suppose we have to repeatedly rotate 5 banners inside chunks of articles.
 * Each chunk consists of 25 articles. The position of banners in the first
 * chunk must be: 7th, 14th and 21th. In the second chunk, the positions should
 * be 32th, 39th and 46th accordingly. The gap between banners within 1 chunk is
 * 7 articles, whereas the gap between the last banner in the first chunk and
 * the first banner in the second chunk is 11 articles. To calculate the
 * bannerNumber depending on current position, we would use the following
 * formula:
 *
 * (Math.floor((position - 1) / 25) * Math.floor(25 / bannerPositionInChunk) +
 * + (position % 25) / bannerPositionInChunk)) % bannersCount || bannersCount
 *
 * In cases like Example #2, this mixin should be used
 */

export const bannerRotation = {
  methods: {
    $_bannerRotation_isBannerVisible({ index, take, bannerPosition }) {
      const position = index + 1
      const lastChunkNumber = Math.floor(index / take)

      return (position - lastChunkNumber * take) % bannerPosition === 0
    },
    $_bannerRotation_getBannerNumber({
      index,
      take,
      bannerPosition,
      bannerCount,
      bannerOffset = 0
    }) {
      const position = index + 1

      const lastChunkNumber = Math.floor(index / take)

      const bannersDisplayedInPreviousChunks =
        lastChunkNumber * Math.floor(take / bannerPosition) + bannerOffset

      const bannerPositionInCurrentChunk = (position % take) / bannerPosition

      return (
        (bannersDisplayedInPreviousChunks + bannerPositionInCurrentChunk) %
          bannerCount || bannerCount
      )
    }
  }
}
