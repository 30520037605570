import { clone, isNil } from 'ramda'

function isObject(value) {
  return !!(value && typeof value === 'object' && !Array.isArray(value))
}

function getValueType(value) {
  return Array.isArray(value) ? 'array' : typeof value
}

function showErrorMessage(target, fallback, key, isArray = false) {
  if (isArray) {
    console.error(
      `Merge Array error: expected type of item on position '${key}' to be '${getValueType(
        fallback
      )}', got '${getValueType(target)}'`
    )
    return
  }

  console.error(
    `Merge error: expected type of property '${key}' to be '${getValueType(
      fallback
    )}', got '${getValueType(target)}'!`
  )
}

function isValueValid(targetVal, fallbackVal) {
  if (isNil(fallbackVal) || isNil(targetVal)) {
    return true
  }

  if (
    isObject(fallbackVal) !== isObject(targetVal) ||
    Array.isArray(fallbackVal) !== Array.isArray(targetVal)
  ) {
    return false
  }

  return typeof fallbackVal === typeof targetVal
}

function getValidPrimitiveValue(targetVal, fallbackVal, key) {
  if (isNil(targetVal)) {
    return clone(isNil(fallbackVal) ? targetVal : fallbackVal)
  }

  if (isValueValid(targetVal, fallbackVal)) return clone(targetVal)

  showErrorMessage(targetVal, fallbackVal, key)
  return clone(fallbackVal)
}

function mergeArray(target, fallbackModel, key) {
  if (isNil(target)) return [] // clone(fallbackModel)

  if (!Array.isArray(target)) {
    showErrorMessage(target, fallbackModel, key)
    return clone(fallbackModel)
  }

  const model = fallbackModel[0]
  if (model === undefined) return target

  return target.map((item, i) => {
    if (!isValueValid(item, model)) {
      showErrorMessage(item, model, i, true)
      return clone(model)
    }

    return applyFallback(item, model, i)
  })
}

function mergeObject(target, fallbackModel) {
  if (isNil(target)) return clone(fallbackModel)

  const result = Object.entries(fallbackModel).reduce((acc, [key, value]) => {
    return { ...acc, [key]: applyFallback(target[key], value, key) }
  }, {})

  return { ...(isObject(target) ? target : {}), ...result }
}

export function applyFallback(target, fallbackModel, key = 'INITIAL') {
  if (!isValueValid(target, fallbackModel)) {
    showErrorMessage(target, fallbackModel, key)
    return clone(fallbackModel)
  }

  const isObj = isObject(fallbackModel)
  const isArr = Array.isArray(fallbackModel)

  if (!isObj && !isArr) {
    return getValidPrimitiveValue(target, fallbackModel, key)
  }

  if (isArr) return mergeArray(target, fallbackModel)

  return mergeObject(target, fallbackModel)
}
