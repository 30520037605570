export const USER_STATUS = {
  DRAFT: 'Draft',
  ACTIVE: 'Active',
  DEACTIVATED: 'Deactivated'
}

export const USER_TYPE = {
  FM_CONTRIBUTOR: 'FMContributor',
  FM_SPONSORED_CONTRIBUTOR: 'FMSponsoredContributor',
  FL_CONTRIBUTOR: 'FLContributor',
  FL_SPONSORED_CONTRIBUTOR: 'FLSponsoredContributor',
  SUBSCRIBER: 'Subscriber',
  /** Needed to validate the role on FL Author page **/
  FL_ADMIN: 'FLAdmin',
  FL_EDITOR: 'FLEditor'
}

export const USER_TYPES = Object.values(USER_TYPE)

export const CONTRIBUTOR_TYPES = [
  USER_TYPE.FM_CONTRIBUTOR,
  USER_TYPE.FM_SPONSORED_CONTRIBUTOR,
  USER_TYPE.FL_CONTRIBUTOR,
  USER_TYPE.FL_SPONSORED_CONTRIBUTOR
]

export const REACTIVATION_TYPE = {
  FIRST_LOGIN_MIGRATED_SUBSCRIBER: 'FIRST_LOGIN_MIGRATED_SUBSCRIBER',
  FIRST_LOGIN_MIGRATED_CONTRIBUTOR: 'FIRST_LOGIN_MIGRATED_CONTRIBUTOR', // means FM/FL Contributor/Sponsored Contributor,
  GENERAL_REACTIVATION: 'GENERAL_REACTIVATION'
}
