export const REFS = {
  CONTENT_WRAPPER: 'content-wrapper',
  ARTICLE_BODY: 'article-body',
  ONE_SIGNAL_DESKTOP: 'one-signal-desktop',
  ONE_SIGNAL_MOBILE: 'one-signal-mobile',
  ONE_SIGNAL_ICON_DESKTOP: 'one-signal-icon-desktop',
  ONE_SIGNAL_ICON_MOBILE: 'one-signal-icon-mobile',
  BANNER: 'banner',
  LOGIN_ICON_WRAPPER: 'login-icon-wrapper',
  NEWSLETTER_BUTTON: 'newsletter-button',
  EBOOK_WRAPPER: 'ebook-wrapper',
  EBOOK_FORM: 'ebook-form',
  EBOOK_DUMMY_WRAPPER_SIDEBAR: 'ebook-dummy-wrapper-sidebar',
  EBOOK_DUMMY_WRAPPER_ARTICLE_BODY: 'ebook-dummy-wrapper-body',
  HEADER_ITEM_MORE: 'header-item-more',
  HEADER_ITEM_FOREX_TERMS: 'header-item-forex-terms',
  HEADER_BURGER_MENU_BUTTON: 'header-burger-menu-button',
  AUTH_FORM_SOCIAL_FACEBOOK_BTN: 'auth-form-social-facebook-btn'
}
