import { mapActions, mapMutations } from 'vuex'

import { COOKIE_ENTITY_TYPE } from 'enums/oneTrust'
import oneTrust from '@/utils/mixins/one-trust'
import * as types from '@/store/mutation-types'
import {
  DISQUS_SOURCE,
  REFRESH_COMMENT_COUNT_VALUE_INTERVAL,
  UPDATE_COMMENT_COUNT_EVENT,
  DISQUS_SCRIPT_IDENTIFY_STRING
} from 'shared/AActionIcon/_shared/CommentCount/enums'
import { createOneTrustHandlerInstance } from 'enums/oneTrust/one-trust-handler'

const DELAY_TO_RERENDER_COMMENT_COUNT_VALUE_AFTER_REQUEST = 1000

export default ({ source, runOnMount = true }) => ({
  mixins: [oneTrust],
  data() {
    return {
      disqusCommentCount: {
        shortnameBySource: {
          [DISQUS_SOURCE.FL]: this.$env.FL_DISQUS_SHORT_NAME
        },
        clearRequestCommentCountValueInterval: null
      }
    }
  },
  computed: {
    $_disqusCommentCount_shortname() {
      return this.disqusCommentCount.shortnameBySource[source]
    }
  },
  methods: {
    ...mapMutations({
      $_disqusCommentCount_setDisqusWidgetLoadStatus:
        types.SET_DISQUS_WIDGET_LOAD_STATUS
    }),
    ...mapActions({
      registerHandlerThatRequiresConsent:
        'one-trust/registerHandlerThatRequiresConsent'
    }),
    $_disqusCommentCount_initDisqusWidget() {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.onload = this.$_disqusCommentCount_onCommentCountWidgetLoad
      script.id = 'dsq-count-scr'
      script.src = `//${this.$_disqusCommentCount_shortname}.disqus.com/count.js`
      this.$helper.insertScriptToHead(script)
    },
    /**
     * Unfortunately Disqus doesn't support SPA and doesn't support multiple
     * accounts within the same site.
     * When we initialize disqus script, we configure it for some account.
     * To switch to another account (and initiate update scripts with the proper
     * disqus domain) we found an undocumented solution to update forum value in
     * DISQUSWIDGETS object in window. After this change all further requests
     * to update comment counts will use updated forum name in script path.
     */
    $_disqusCommentCount_updateShortNameForExistingDisqus() {
      window.DISQUSWIDGETS.forum = this.$_disqusCommentCount_shortname
    },
    $_disqusCommentCount_onCommentCountWidgetLoad() {
      if (window.DISQUSWIDGETS) {
        this.$_disqusCommentCount_setDisqusWidgetLoadStatus(true)
        this.$_disqusCommentCount_emitUpdateCommentCountIconEvent()
        this.$_disqusCommentCount_startRefreshCommentCountValueOnInterval()
      }
    },
    $_disqusCommentCount_refreshAllVisibleDisqusCommentCounts() {
      if (window && window.DISQUSWIDGETS) {
        window.DISQUSWIDGETS.getCount({ reset: true })
        this.$_disqusCommentCount_removeDisqusScriptsFromHead()
      }
    },
    $_disqusCommentCount_removeDisqusScriptsFromHead() {
      try {
        const head = document.head

        const disqusScriptsInHead = [...head.children].filter(
          (el, index) =>
            el.tagName === 'SCRIPT' &&
            (el?.src || '').includes(DISQUS_SCRIPT_IDENTIFY_STRING)
        )
        disqusScriptsInHead.forEach(script => head.removeChild(script))
      } catch (err) {
        this.$errorHandler(err, this, { showMessage: false })
      }
    },
    $_disqusCommentCount_startRefreshCommentCountValueOnInterval() {
      const { clearInterval } = this.$helper.runWithInterval(
        this.$_disqusCommentCount_updateCommentCounts,
        REFRESH_COMMENT_COUNT_VALUE_INTERVAL,
        false
      )

      this.disqusCommentCount.clearRequestCommentCountValueInterval = clearInterval
    },
    $_disqusCommentCount_updateCommentCounts() {
      /**
       * This method may be used on server-side on the page.
       * Disqus widget works only on client-side.
       * Example: Search Results page (immediate watch)
       */
      if (!process.client) return

      if (!this.disqusCommentCount.clearRequestCommentCountValueInterval) {
        this.$_disqusCommentCount_startRefreshCommentCountValueOnInterval()
      }

      this.$_disqusCommentCount_refreshAllVisibleDisqusCommentCounts()
      this.$_disqusCommentCount_emitUpdateCommentCountIconEvent()
    },
    $_disqusCommentCount_emitUpdateCommentCountIconEvent() {
      setTimeout(() => {
        this.$bus.$emit(UPDATE_COMMENT_COUNT_EVENT)
      }, DELAY_TO_RERENDER_COMMENT_COUNT_VALUE_AFTER_REQUEST)
    },
    $_disqusCommentCount_init() {
      if (!window.DISQUSWIDGETS) {
        this.$_disqusCommentCount_initDisqusWidget()
        return
      }

      if (window.DISQUSWIDGETS?.forum !== this.$_disqusCommentCount_shortname) {
        this.$_disqusCommentCount_updateShortNameForExistingDisqus()
      }

      /**
       * We wrap the method into requestAnimationFrame to await when comment
       * counters are rendered on the page after redirect to another page.
       */
      if (runOnMount) {
        requestAnimationFrame(this.$_disqusCommentCount_updateCommentCounts)
      }
    }
  },
  mounted() {
    if (!this.$_disqusCommentCount_shortname) {
      return
    }

    this.registerHandlerThatRequiresConsent(
      createOneTrustHandlerInstance({
        handler: this.$_disqusCommentCount_init,
        entityType: COOKIE_ENTITY_TYPE.DISQUS
      })
    )
  },
  beforeDestroy() {
    if (this.disqusCommentCount.clearRequestCommentCountValueInterval) {
      this.disqusCommentCount.clearRequestCommentCountValueInterval()
    }
  }
})
