export const FOREX_BROKERS_SLUG = 'ForexBrokers'

export const COMPANY_CATEGORY_SLUG_BY_CATEGORY_SLUG = {
  [FOREX_BROKERS_SLUG]: 'Brokers'
}

const COMPANY_CATEGORY_SLUG_BY_LOWERCASE_CATEGORY_SLUG = Object.entries(
  COMPANY_CATEGORY_SLUG_BY_CATEGORY_SLUG
).reduce((acc, [key, val]) => ({ ...acc, [key.toLowerCase()]: val }), {})

const CATEGORY_SLUG_BY_COMPANY_CATEGORY_SLUG = Object.entries(
  COMPANY_CATEGORY_SLUG_BY_CATEGORY_SLUG
).reduce((acc, [key, val]) => ({ ...acc, [val.toLowerCase()]: key }), {})

export function findCompanyCategorySlugByCategorySlug(category = '') {
  return COMPANY_CATEGORY_SLUG_BY_LOWERCASE_CATEGORY_SLUG[
    category.toLowerCase()
  ]
}

export function findCategorySlugByCompanyCategorySlug(category = '') {
  return CATEGORY_SLUG_BY_COMPANY_CATEGORY_SLUG[category.toLowerCase()]
}
