<template>
  <auth-form
    v-if="isLazyLoaded"
    @hook:mounted="$_lazyLoadModal_onComponentMounted"
  />
</template>

<script>
import mixins from '@/utils/mixins'
import { MODAL } from '@/components/_modals/AModalWrapper'

export default {
  name: 'AAuthFormLazyLoad',
  mixins: [mixins.lazyLoadModal(MODAL.AUTH_FORM)],
  components: {
    AuthForm: () => import('./index')
  }
}
</script>

<style lang="scss" scoped></style>
