export const REFRESH_REASON = {
  ARTICLE_PUBLISHED: 'ArticlePublished',
  ARTICLE_UPDATED: 'ArticleUpdated',
  ARTICLE_UNPUBLISHED: 'ArticleUnpublished',
  ARTICLE_POSITION_UPDATED: 'ArticlePositionUpdated',
  ARTICLE_TAGS_UPDATED: 'ArticleTagsUpdated',
  ARTICLE_PINNED: 'ArticlePinned',
  ARTICLE_UNPINNED: 'ArticleUnpinned',
  CATEGORY_UPDATED: 'CategoryUpdated',
  AUTHOR_UPDATED: 'AuthorUpdated'
}

export const REFRESH_ACTION = {
  DO_NOTHING: 'DoNothing',
  UPDATE_ARTICLE_FEED: 'UpdateArticleFeed',
  UPDATE_ARTICLE_FEED_SLOT: 'UpdateSingleArticle',
  UPDATE_ARTICLE_PAGE: 'UpdateArticlePage',
  THROW_PAGE_NOT_FOUND: 'ThrowPageNotFound'
}

export const SIGNAL_R_INITIATED_EVENT = 'signalr-initiated'
export const ARTICLES_FEED_UPDATED_EVENT = 'articles-feed-updated'
export const ARTICLE_PAGE_UPDATED_EVENT = 'article-page-updated'

export const SPECIAL_REFRESH_CATEGORY_SLUG = {
  ALL_NEWS: 'all-news',
  SESSION_WRAPS: 'session-wraps',
  ALL: 'all'
}

export const AUTO_REFRESH_UPDATE_CONDITION = {
  ALWAYS: 'ALWAYS',
  TARGET_MATCH: 'TARGET_MATCH',
  FEED_MATCH: 'FEED_MATCH'
}

export const AUTO_REFRESH_INSTANCE = {
  ARTICLE_ID: 'ARTICLE_ID',
  CATEGORY_SLUG: 'CATEGORY_SLUG',
  AUTHOR_SLUG: 'AUTHOR_SLUG',
  TAG_SLUG: 'TAG_SLUG',
  TERM_SLUG: 'TERM_SLUG'
}

export const ALL_NEWS_AUTO_REFRESH_SETTINGS = {
  pageRefreshSettings: null,
  feedRefreshSettings: {
    [REFRESH_REASON.ARTICLE_PUBLISHED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.ALWAYS],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.ARTICLE_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.ARTICLE_ID],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED_SLOT
    },
    [REFRESH_REASON.ARTICLE_UNPUBLISHED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.ARTICLE_ID],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.ARTICLE_POSITION_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.ALWAYS],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.ARTICLE_TAGS_UPDATED]: null,
    [REFRESH_REASON.ARTICLE_PINNED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.TARGET_MATCH],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.ARTICLE_UNPINNED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.TARGET_MATCH],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.CATEGORY_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.CATEGORY_SLUG],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.AUTHOR_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.AUTHOR_SLUG],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    }
  }
}

export const CATEGORY_PAGE_AUTO_REFRESH_SETTINGS = {
  pageRefreshSettings: null,
  feedRefreshSettings: {
    [REFRESH_REASON.ARTICLE_PUBLISHED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.TARGET_MATCH],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.ARTICLE_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.ARTICLE_ID],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED_SLOT
    },
    [REFRESH_REASON.ARTICLE_UNPUBLISHED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.ARTICLE_ID],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.ARTICLE_POSITION_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.ALWAYS],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.ARTICLE_TAGS_UPDATED]: null,
    [REFRESH_REASON.ARTICLE_PINNED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.TARGET_MATCH],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.ARTICLE_UNPINNED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.TARGET_MATCH],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.CATEGORY_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.CATEGORY_SLUG],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.AUTHOR_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.AUTHOR_SLUG],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    }
  }
}

export const AUTHOR_PAGE_AUTO_REFRESH_SETTINGS = {
  pageRefreshSettings: null,
  feedRefreshSettings: {
    [REFRESH_REASON.ARTICLE_PUBLISHED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.TARGET_MATCH],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.ARTICLE_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.ARTICLE_ID],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED_SLOT
    },
    [REFRESH_REASON.ARTICLE_UNPUBLISHED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.ARTICLE_ID],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.ARTICLE_POSITION_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.ARTICLE_ID],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED_SLOT
    },
    [REFRESH_REASON.ARTICLE_TAGS_UPDATED]: null,
    [REFRESH_REASON.ARTICLE_PINNED]: null,
    [REFRESH_REASON.ARTICLE_UNPINNED]: null,
    [REFRESH_REASON.CATEGORY_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.CATEGORY_SLUG],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.AUTHOR_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.TARGET_MATCH],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    }
  }
}

export const TAG_PAGE_AUTO_REFRESH_SETTINGS = {
  pageRefreshSettings: null,
  feedRefreshSettings: {
    [REFRESH_REASON.ARTICLE_PUBLISHED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.TARGET_MATCH],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.ARTICLE_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.ARTICLE_ID],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED_SLOT
    },
    [REFRESH_REASON.ARTICLE_UNPUBLISHED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.ARTICLE_ID],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.ARTICLE_POSITION_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.ARTICLE_ID],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED_SLOT
    },
    [REFRESH_REASON.ARTICLE_TAGS_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.TARGET_MATCH],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.ARTICLE_PINNED]: null,
    [REFRESH_REASON.ARTICLE_UNPINNED]: null,
    [REFRESH_REASON.CATEGORY_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.CATEGORY_SLUG],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.AUTHOR_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.AUTHOR_SLUG],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    }
  }
}

export const TERM_PAGE_AUTO_REFRESH_SETTINGS = {
  pageRefreshSettings: null,
  feedRefreshSettings: {
    [REFRESH_REASON.ARTICLE_PUBLISHED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.TARGET_MATCH],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.ARTICLE_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.ARTICLE_ID],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED_SLOT
    },
    [REFRESH_REASON.ARTICLE_UNPUBLISHED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.ARTICLE_ID],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.ARTICLE_POSITION_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.ARTICLE_ID],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED_SLOT
    },
    [REFRESH_REASON.ARTICLE_TAGS_UPDATED]: null,
    [REFRESH_REASON.ARTICLE_PINNED]: null,
    [REFRESH_REASON.ARTICLE_UNPINNED]: null,
    [REFRESH_REASON.CATEGORY_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.CATEGORY_SLUG],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.AUTHOR_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.AUTHOR_SLUG],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    }
  }
}

export const SEARCH_RESULTS_PAGE_AUTO_REFRESH_SETTINGS = {
  pageRefreshSettings: null,
  feedRefreshSettings: {
    [REFRESH_REASON.ARTICLE_PUBLISHED]: null,
    [REFRESH_REASON.ARTICLE_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.ARTICLE_ID],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED_SLOT
    },
    [REFRESH_REASON.ARTICLE_UNPUBLISHED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.ARTICLE_ID],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.ARTICLE_POSITION_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.ARTICLE_ID],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED_SLOT
    },
    [REFRESH_REASON.ARTICLE_TAGS_UPDATED]: null,
    [REFRESH_REASON.ARTICLE_PINNED]: null,
    [REFRESH_REASON.ARTICLE_UNPINNED]: null,
    [REFRESH_REASON.CATEGORY_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.CATEGORY_SLUG],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.AUTHOR_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.AUTHOR_SLUG],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    }
  }
}

export const ARTICLE_PAGE_AUTO_REFRESH_SETTINGS = {
  pageRefreshSettings: {
    [REFRESH_REASON.ARTICLE_PUBLISHED]: null,
    [REFRESH_REASON.ARTICLE_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.TARGET_MATCH],
      action: REFRESH_ACTION.UPDATE_ARTICLE_PAGE
    },
    [REFRESH_REASON.ARTICLE_UNPUBLISHED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.TARGET_MATCH],
      action: REFRESH_ACTION.THROW_PAGE_NOT_FOUND
    },
    [REFRESH_REASON.ARTICLE_POSITION_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.TARGET_MATCH],
      action: REFRESH_ACTION.UPDATE_ARTICLE_PAGE
    },
    [REFRESH_REASON.ARTICLE_TAGS_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.TARGET_MATCH],
      action: REFRESH_ACTION.UPDATE_ARTICLE_PAGE
    },
    [REFRESH_REASON.ARTICLE_PINNED]: null,
    [REFRESH_REASON.ARTICLE_UNPINNED]: null,
    [REFRESH_REASON.CATEGORY_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.TARGET_MATCH],
      action: REFRESH_ACTION.UPDATE_ARTICLE_PAGE
    },
    [REFRESH_REASON.AUTHOR_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.TARGET_MATCH],
      action: REFRESH_ACTION.UPDATE_ARTICLE_PAGE
    }
  },
  feedRefreshSettings: null
}

export const SIDEBAR_MOST_VIEWED_AUTO_REFRESH_SETTINGS = {
  pageRefreshSettings: null,
  feedRefreshSettings: {
    [REFRESH_REASON.ARTICLE_PUBLISHED]: null,
    [REFRESH_REASON.ARTICLE_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.ARTICLE_ID],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED_SLOT
    },
    [REFRESH_REASON.ARTICLE_UNPUBLISHED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.ARTICLE_ID],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.ARTICLE_POSITION_UPDATED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH],
      feedMatchEntities: [AUTO_REFRESH_INSTANCE.ARTICLE_ID],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED_SLOT
    },
    [REFRESH_REASON.ARTICLE_TAGS_UPDATED]: null,
    [REFRESH_REASON.ARTICLE_PINNED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.TARGET_MATCH],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.ARTICLE_UNPINNED]: {
      matchConditions: [AUTO_REFRESH_UPDATE_CONDITION.TARGET_MATCH],
      action: REFRESH_ACTION.UPDATE_ARTICLE_FEED
    },
    [REFRESH_REASON.CATEGORY_UPDATED]: null,
    [REFRESH_REASON.AUTHOR_UPDATED]: null
  }
}

export const AUTO_REFRESH_SETTINGS = {
  ALL_NEWS: ALL_NEWS_AUTO_REFRESH_SETTINGS,
  CATEGORY_PAGE: CATEGORY_PAGE_AUTO_REFRESH_SETTINGS,
  AUTHOR_PAGE: AUTHOR_PAGE_AUTO_REFRESH_SETTINGS,
  SEARCH_RESULTS_PAGE: SEARCH_RESULTS_PAGE_AUTO_REFRESH_SETTINGS,
  ARTICLE_PAGE: ARTICLE_PAGE_AUTO_REFRESH_SETTINGS,
  TAG_PAGE: TAG_PAGE_AUTO_REFRESH_SETTINGS,
  TERM_PAGE: TERM_PAGE_AUTO_REFRESH_SETTINGS,
  SIDEBAR_MOST_VIEWED: SIDEBAR_MOST_VIEWED_AUTO_REFRESH_SETTINGS
}
