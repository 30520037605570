import { endsWith, isNil, pathOr, pick, pipe, startsWith } from 'ramda'
import Cookies from 'js-cookie'
import querystring from 'querystring'
import { IdleValue } from 'idlize/IdleValue.mjs'
import { TOP_STICKY_CONTAINER_ID } from 'enums/header'

import {
  checkIfFunction,
  generateParsedDomElementsHandlerDeep,
  getBinaryStringFromBooleanArray,
  isArray,
  isObject,
  isString,
  remapObjectKeys,
  valuesToLowercase,
  addStartingSlash,
  addEndingSlash,
  removeStartingSlash,
  removeEndingSlashes,
  wrapIntoSlashes,
  getParsedDateString
} from '@fmpedia/helpers'

import { applyFallback, isMiddlewareSkipped } from '@/utils/helpers'
import { MEDIA_TYPE, HTML_TAG } from '@fmpedia/enums'
import { MEDIA_EXTENSIONS } from 'enums/media-center'
import { IMAGE_SIZE, IMAGE_SUFFIX } from 'enums/images'
import { MODAL } from '@/components/_modals/AModalWrapper'
import { GOOGLE_CAPTCHA_CHALLENGE_SELECTOR } from 'shared/AInvisibleCaptcha/index'
import { AMP_PAGES, FL_DIR_ROUTE_NAMES } from 'enums/routes'
import {
  QUERY_PARAM_NAME,
  QUERY_PARAM_VALUE,
  TAB_NAME
} from 'enums/personal-area'
import { PAGES_WITHOUT_SIDEBAR } from 'enums/sidebar'

import { SOURCE } from 'enums/source'
import { IMAGE_OBJECT_REMAP } from 'shared/AImage/enums'
import {
  checkIfLinkHasProtocol,
  getBackendErrorCode,
  getBackendErrorEvidence,
  getUrlHost,
  isHrefValid,
  parseUrl,
  randomIntFromInterval,
  removeAmpSuffix,
  removePaginationPartFromPath
} from '@/server/helper'
import Vue from 'vue'
import { isConsentGivenForEntity } from '@/utils/mixins/one-trust'
import { COOKIE_ENTITY_TYPE } from 'enums/oneTrust'
import { FALLBACK_CHAR_WIDTH } from 'shared/ACharWidth/enums'
import { PAGE_SCHEME_TYPE } from 'enums/pageSchemes'
import { VIDEO_OBJECT_REMAP } from 'shared/AVideo/enums'
import { REFS } from 'enums/external-refs'

export {
  getUrlHost,
  isHrefValid,
  parseUrl,
  removeStartingSlash,
  removeEndingSlashes,
  addEndingSlash,
  checkIfLinkHasProtocol,
  randomIntFromInterval,
  removePaginationPartFromPath,
  removeAmpSuffix,
  getBackendErrorCode,
  getBackendErrorEvidence
}

/* @fmpedia/helpers */
export {
  checkIfFunction,
  generateParsedDomElementsHandlerDeep,
  getBinaryStringFromBooleanArray,
  isArray,
  isObject,
  isString,
  remapObjectKeys,
  valuesToLowercase
}

/**
 * Resets the data of a Vue component for the specified fields.
 * @param {Object} component - The Vue context of the component to reset the data for.
 * @param {string[]} fieldList - An array of strings representing the names of the fields to reset.
 * @returns {void}
 */
export function resetComponentData(component, fieldList) {
  if (!fieldList || !isArray(fieldList)) {
    console.log('resetComponentData() - fieldList is not provided. Exit.')
    return
  }

  const initialData = component.$options.data.apply(component)
  const newData = pick(fieldList, initialData)
  Object.assign(component.$data, newData)
}

let storeCopy = null
let context = null

export function getContext() {
  return context
}

export function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function isStringStartsWith(searchStr = '', str = '') {
  return isString(str) && startsWith(searchStr, str)
}

export function isStringEndsWith(searchStr = '', str = '') {
  return isString(str) && endsWith(searchStr, str)
}

export function replaceString(pattern = '', newSubStr = '', str = '') {
  if (!isString(str)) return
  return str.replace(pattern, newSubStr)
}

export function encodeURIValues(val) {
  if (isString(val)) {
    return encodeURI(val)
  }

  if (isObject(val)) {
    return Object.entries(val).reduce((acc, [key, objVal]) => {
      return {
        ...acc,
        [key]: encodeURIValues(objVal)
      }
    }, {})
  }

  if (isArray(val)) {
    return val.map(item => encodeURIValues(item))
  }

  return val
}

const SPECIAL_CHARACTERS_TO_REPLACE_MAP = {
  $: '%24',
  ',': '%2C'
}

const SPECIAL_CHARACTERS_TO_REPLACE_MAP_KEYS_GROUPS = Object.keys(
  SPECIAL_CHARACTERS_TO_REPLACE_MAP
).map(key => `(\\${key})`)

/**
 * Some migrated entities' slugs include encoded characters, like "%25", "%3f" etc.
 * We use "route.path" as a source of truth before sending these slugs to BE. The reason
 * is that most of special symbols are being decoded in "route.params", and BE expects
 * encoded slugs. There are, however, a limited number of special symbols that are being
 * decoded in "route.path" as well. We use "SPECIAL_CHARACTERS_TO_REPLACE_MAP" to replace
 * those symbols with their encoded equivalent.
 *
 * By searching DB we found only 2 articles that require this processing:
 * 1) /institutional-forex/cfh+clearing+partners+with+beeks%2C+fnhancing+connectivity+to+hong+kong/
 * 2) /institutional-forex/regulation/Yorkshire-Group-Charged-%241.5m-for-Illegal-Transactions/
 */
function replaceSpecialSymbolsWithEncodedEquivalent(str) {
  if (!str) return str

  return str.replace(
    new RegExp(
      `${SPECIAL_CHARACTERS_TO_REPLACE_MAP_KEYS_GROUPS.join('|')}`,
      'g'
    ),
    match => SPECIAL_CHARACTERS_TO_REPLACE_MAP[match]
  )
}

function getPathParamsArrayFromString(str) {
  return pipe(
    removePaginationPartFromPath,
    removeAmpSuffix,
    removeEndingSlashes,
    removeStartingSlash
  )(replaceSpecialSymbolsWithEncodedEquivalent(str)).split('/')
}

export function getNthPathParam(str, n) {
  return getPathParamsArrayFromString(str)[n - 1]
}

export function getLastPathParam(str) {
  return getPathParamsArrayFromString(str).pop()
}

export function isInteger(value) {
  return (
    typeof value === 'number' && isFinite(value) && Math.floor(value) === value
  )
}

function isChildrenValidationPassed(children) {
  return children
    .reduce((acc, child) => {
      if ('isInternalValidationPassed' in child) {
        child.touchInnerValidation()
        return [...acc, child.isInternalValidationPassed]
      }
      if (child.$children) {
        return [...acc, isChildrenValidationPassed(child.$children)]
      }
      return acc
    }, [])
    .every(isValid => isValid)
}

function scrollToValidationError(
  component,
  { scrollToValidationError, scrollInContainer, searchInContainer }
) {
  if (scrollToValidationError) {
    component.$nextTick(() => {
      const searchContainer = searchInContainer ? `${searchInContainer} ` : ''
      component.$scrollTo(`${searchContainer}.input-group__error`, {
        offset: -screen.height / 2,
        container:
          scrollInContainer === 'body' ||
          !document.querySelector(scrollInContainer)
            ? 'body'
            : scrollInContainer
      })
    })
  }
}

export function joinStringsWithSpaces(stringArray) {
  if (!isArray(stringArray)) return ''

  return stringArray.filter(v => v).join(' ')
}

const defaultOptions = {
  scrollToValidationError: true,
  scrollInContainer: 'body'
}

export function isValidationFailed(component, validationOptions = {}) {
  if (!component) return

  let isInvalid
  const validationObject = pathOr(
    null,
    validationOptions.path || [],
    component.$v
  )

  if (validationObject) {
    validationObject.$touch()
    isInvalid = validationObject.$invalid
  }

  const options = Object.assign({}, defaultOptions, validationOptions)
  const isChildrenValidationFailed = !isChildrenValidationPassed(
    component.$children
  )
  isInvalid = isInvalid || isChildrenValidationFailed
  if (isInvalid) {
    scrollToValidationError(component, options)
  }
  return isInvalid
}

export function removeSpaces(value) {
  return value.replace(/\s/g, '')
}

function removePlusFromPhone(phone) {
  return phone.charAt(0) === '+' ? phone.slice(1) : phone
}

export function formatPhone(phone) {
  return removePlusFromPhone(removeSpaces(phone))
}

export function getLinkToFmBucketFile(fileName) {
  const STATIC_PATH = 'static'
  return `${storeCopy.$env.AMAZON_S3_FM_URL}/${STATIC_PATH}/${fileName}`
}

export function getLinkToFlBucketFile(fileName) {
  const STATIC_PATH = 'fl-static'
  return `${storeCopy.$env.AMAZON_S3_FL_URL}/${STATIC_PATH}/${fileName}`
}

export function getImageSuffixFromUrlRegexp(suffix) {
  suffix = suffix.replace('.', '')
  return new RegExp(`(^[^?]*)(${suffix})(\\.[^.]*)(\\?.+)?$`)
}

export function isImageUrlHasSizeSuffixes(url) {
  if (!url) return false

  const match = url.match(
    getImageSuffixFromUrlRegexp(IMAGE_SUFFIX[IMAGE_SIZE.ORIGINAL])
  )

  return (match && match[0]) || !!getUrlSizeSuffix(url)
}

function replaceImageSuffixWith({ url, oldSuffix, newSuffix }) {
  oldSuffix = oldSuffix.replace('.', '')
  newSuffix = newSuffix.replace('.', '')
  return url.replace(
    getImageSuffixFromUrlRegexp(oldSuffix),
    `$1${newSuffix}$3$4`
  )
}

export function replaceImageUrlSuffix(imageData) {
  try {
    const originalUrl = pathOr('', ['originalUrl'], imageData)
    const neededSize = pathOr('', ['neededSize'], imageData)

    if (Object.keys(IMAGE_SUFFIX).includes(neededSize)) {
      return replaceImageSuffixWith({
        url: originalUrl,
        oldSuffix: IMAGE_SUFFIX[IMAGE_SIZE.ORIGINAL],
        newSuffix: IMAGE_SUFFIX[neededSize]
      })
    } else {
      return originalUrl
    }
  } catch (err) {
    console.log(err)
  }
}

function getUrlSizeSuffix(url) {
  if (!url) return null

  const regexp = getImageSuffixFromUrlRegexp('_size\\d+')
  const match = url.match(regexp)

  if (!match?.[0]) return null

  return url.replace(regexp, '$2.')
}

export function replaceImageUrlSuffixWithOriginal(url) {
  if (!url) return null

  const suffixMatch = getUrlSizeSuffix(url)

  if (!suffixMatch) return url

  return replaceImageSuffixWith({
    url,
    oldSuffix: suffixMatch,
    newSuffix: IMAGE_SUFFIX[IMAGE_SIZE.ORIGINAL]
  })
}

export function encodeToBase64(string) {
  if (string) {
    return Buffer.from(string).toString('base64')
  }
  return null
}

export function getEncodedJwtBody(token) {
  if (!isString(token)) return ''

  const encodedBody = token.split('.')[1]

  return encodedBody || ''
}

export function decodeFromBase64(string) {
  if (string) {
    return Buffer.from(string, 'base64').toString('utf-8')
  }
  return null
}

export function getUserDetailsFromAccessToken(at) {
  const decodedUserDetails = pipe(
    getTokenValue,
    getEncodedJwtBody,
    decodeFromBase64
  )(at)

  try {
    return JSON.parse(decodedUserDetails)
  } catch (err) {
    console.log('Error parsing accessToken')
    return null
  }
}

export function getExtension(path) {
  if (!path) return ''

  return path.slice(path.lastIndexOf('.'))
}

export function checkIfRefreshTokenIsRelevant() {
  const isAuthStatusRequested = storeCopy.getters['auth/isAuthStatusRequested']

  if (!isAuthStatusRequested) return true

  const refreshTokenLastModified = storeCopy.getters['auth/rtlm']
  const refreshTokenLastModifiedFromCookies = Cookies.get('rtlm')
  console.log('refreshTokenLastModified', String(refreshTokenLastModified))
  console.log(
    'refreshTokenLastModifiedFromCookies',
    refreshTokenLastModifiedFromCookies
  )

  if (!process.browser) return true

  return (
    (isNil(refreshTokenLastModified) &&
      isNil(refreshTokenLastModifiedFromCookies)) ||
    String(refreshTokenLastModified) === refreshTokenLastModifiedFromCookies
  )
}

export function getMediaFileType(path) {
  const extension = getExtension(path)
  if (MEDIA_EXTENSIONS.IMAGE.includes(extension)) {
    return MEDIA_TYPE.IMAGE
  }
  if (MEDIA_EXTENSIONS.VIDEO.includes(extension)) {
    return MEDIA_TYPE.VIDEO
  }
  return MEDIA_TYPE.UNKNOWN
}

export function getOptanonActiveGroups() {
  return storeCopy.getters['one-trust/optanonActiveGroups']
}

export function fileTypeChecker(path) {
  return {
    isImage: getMediaFileType(path) === MEDIA_TYPE.IMAGE,
    isVideo: getMediaFileType(path) === MEDIA_TYPE.VIDEO,
    isUnknown: getMediaFileType(path) === MEDIA_TYPE.UNKNOWN
  }
}

function formatYoutubeIdToEmbedUrl(id) {
  if (!id) return null

  return `https://www.youtube.com/embed/${id}`
}

export function generateVideoSchemaPayloadFromResponse(video) {
  if (!video) return {}

  const remappedVideo = processResponse(video, VIDEO_OBJECT_REMAP)

  const {
    externalId,
    title,
    description,
    publishedOn,
    thumbnailUrl
  } = remappedVideo

  return {
    name: title,
    description: description,
    thumbnailUrl: thumbnailUrl,
    uploadDate: publishedOn,
    embedUrl: formatYoutubeIdToEmbedUrl(externalId)
  }
}

function isVideoValid(video) {
  if (!video) return false

  const { externalId, title, publishedOn, thumbnailUrl } = video

  return !!(externalId && title && publishedOn && thumbnailUrl)
}

export function generateVideoSchemasFromResponse(videos = []) {
  if (!isArray(videos)) return []

  videos = videos.map(video => remapObjectKeys(video, VIDEO_OBJECT_REMAP))

  return videos
    .filter(video => isVideoValid(video))
    .map(video => ({
      type: PAGE_SCHEME_TYPE.VIDEO_GENERAL,
      data: generateVideoSchemaPayloadFromResponse(video)
    }))
}

export function sliceText({ text = '', limit = Infinity }) {
  if (!isString(text)) return ''

  return text.length > limit ? `${text.slice(0, limit)}...` : text
}

export function getAltTextForMediaCenterImage(image) {
  if (!isObject(image)) return null

  const remappedImage = processResponse(image, IMAGE_OBJECT_REMAP)

  const { isOld, altText, title } = remappedImage

  return isOld ? altText : altText || title
}

export function formatPrice(value) {
  if (!value) return ''

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function isLinkWithTrailingSlash(link) {
  if (!link) return false

  return endsWith('/', link)
}

export function getPageSourceByRouteName(routeName) {
  if (FL_DIR_ROUTE_NAMES.includes(routeName)) return SOURCE.FL_DIR

  return SOURCE.FL
}

export function prettifyPath(path) {
  if (!path) return '/'

  return wrapIntoSlashes(removeEndingSlashes(path)).toLowerCase()
}

export function stringToArray(str = '') {
  return str && typeof str === 'string'
    ? str.split(',').map(item => item.trim())
    : []
}

export function getFullPath(ctx) {
  return `${ctx.$env.DOMAIN_URL}${prettifyPath(ctx.$route.path)}`
}

export function mailto(mailTo) {
  if (!mailTo || !process.client) return

  const a = document.createElement('a')
  a.href = mailTo
  a.setAttribute('target', '_blank')
  a.click()
}

/**
 * Replaces given substring case-insensitively with a new substring
 *
 * # Example:
 * caseInsensitiveReplace("tEsT-string", "test", "Test") -> "Test-string"
 * caseInsensitiveReplace("tEsT-string", "TEST", "hello") -> "hello-string"
 * caseInsensitiveReplace("/CATEGORY/article", "category", "Category") -> /Category/article
 */
export function caseInsensitiveReplace(str, substr, newSubstr) {
  if (!str) return ''

  if (!substr || newSubstr == null) return str

  const paramRegExp = new RegExp(`${substr}`, 'i')
  return str.replace(paramRegExp, newSubstr)
}

const FIGURE_REGEXP = /<figure[^>]*?>.+?<\/figure>/gims
const IMAGE_SELF_CLOSING_TAG_REGEXP = /<img[^>]+?\/?>/gims
const IMAGE_CLOSING_TAG_REGEXP = /<\/img>/gims

function removeImagesFromHtml(html) {
  if (!html) return ''

  return html
    .replace(FIGURE_REGEXP, '')
    .replace(IMAGE_SELF_CLOSING_TAG_REGEXP, '')
    .replace(IMAGE_CLOSING_TAG_REGEXP, '')
}

export function htmlToText(html) {
  if (!html) return ''

  if (process.client) {
    /**
     * In the scope of this bug: https://adraba.atlassian.net/browse/FMP-13592
     * we've found that if the innerHTML of a created div has images, they are
     * getting loaded. To avoid that, we remove images before the div is
     * created.
     */
    const htmlWithoutImages = removeImagesFromHtml(html)

    const elem = document.createElement('div')
    elem.innerHTML = htmlWithoutImages
    return elem.textContent.trim()
  } else {
    return removeImagesFromHtml(html)
      .replace(/<[^>]+>/g, '')
      .trim()
  }
}

export function countWordsAmount(text) {
  if (!text) return 0

  const delimiters = /[.,:;?!]/gi
  const oneOrMoreSpaces = / {2,}/gi
  return text
    .replace(delimiters, ' ')
    .replace(oneOrMoreSpaces, ' ')
    .replace(/[\r\n]+/gi, ' ')
    .split(/\s/)
    .filter(Boolean).length
}

const AVERAGE_WORDS_PER_SECOND = 4.2
export function calculateTimeRequiredToRead({ wordCount, text = 0 }) {
  const seconds =
    (wordCount || countWordsAmount(text)) / AVERAGE_WORDS_PER_SECOND
  const date = new Date(0, 0, 0, 0, 0, seconds)
  return `PT${date.getMinutes()}M${date.getSeconds()}S`
}

export function generateSlugFromName(name) {
  const resultingSlug = name
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z \-\d]/g, '') // remove all symbols except letters, spaces and dashes
    .replace(/\s+/g, '-') // replace all spaces with dashes
    .replace(/\.+/g, '-') // replace all dots with dashes
    .replace(/-+/g, '-') // remove repetitive dashes with one dash
    .replace(/^-/, '') // remove starting dash
    .replace(/-$/, '') // remove ending dash

  const isValid = !!resultingSlug.replace('-', '').length

  return isValid ? resultingSlug : null
}

export function getTextWidth(string, font) {
  if (!string) return 0

  const charSizeMap = storeCopy.getters.getCharSizeMapByFont
  const textWidth = string
    .split('')
    .reduce(
      (acc, char) => acc + charSizeMap(font)[char] || FALLBACK_CHAR_WIDTH,
      0
    )
  return Math.ceil(textWidth)
}

export function closeModal(modalName, delay = 0) {
  if (delay === 0) {
    return storeCopy.$bus.$emit(`close-modal-${modalName}`)
  }

  return setTimeout(() => {
    storeCopy.$bus.$emit(`close-modal-${modalName}`)
  }, delay)
}

/**
 * This function close all opened modals such as AModalWrapper and AModalWidget
 * To close AModalWidget components we have to use click-away behavior and click
 * somewhere outside modal component. That's why we use header-menu for emulating
 * click.
 */
export function closeAllModals() {
  Object.keys(MODAL).forEach(modalName => {
    closeModal(modalName)
  })
  document.getElementById('header-menu').click()
}

export function openModal(modalName, payload, closeTimeout) {
  storeCopy.$bus.$emit(`open-modal-${modalName}`, payload)

  /**
   * Be aware that we don't handle clearTimeout because in our application we
   * don't have cases when user can reopen the same modal window which was
   * automatically opened before. For manually opened modal windows we don't use
   * timeout at all.
   */
  if (closeTimeout) {
    setTimeout(() => {
      closeModal(modalName)
    }, closeTimeout)
  }
}

function getPagePathWithoutHash() {
  const { href, origin } = window.location
  const urlWithoutHash = href.split('#')[0]
  const pathWithoutHash = urlWithoutHash.replace(origin, '')
  return {
    pathWithoutHash
  }
}

export function registerPageView() {
  if (
    !process.client ||
    process.env.NODE_ENV !== 'production' ||
    !isConsentGivenForEntity(COOKIE_ENTITY_TYPE.GOOGLE_ANALYTICS)
  ) {
    return
  }

  Vue.nextTick().then(() => {
    const { pathWithoutHash } = getPagePathWithoutHash()

    window.dataLayer.push({
      event: 'Pageview',
      pagePath: pathWithoutHash,
      pageTitle: document.title
    })
  })
}

export function registerPV() {
  if (!process.client || process.env.NODE_ENV !== 'production') {
    return
  }

  Vue.nextTick().then(() => {
    const { pathWithoutHash } = getPagePathWithoutHash()

    window.dataLayer.push({
      event: `PV ${pathWithoutHash}`
    })
  })
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function getTopStickyElement() {
  if (!process.client) return null

  return document.getElementById(TOP_STICKY_CONTAINER_ID)
}

export function getWindowScrollTop() {
  if (!process.browser) return

  return (
    (window.pageYOffset || document.documentElement.scrollTop) -
    (document.documentElement.clientTop || 0)
  )
}

export function getWindowWidth() {
  if (!process.browser) return

  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  )
}

export function getWindowHeight() {
  if (!process.browser) return

  return (
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
  )
}

const DEFAULT_MOBILE_WIDTH = 320
const DEFAULT_DESKTOP_WIDTH = 1460

export const percentage = {
  mobile(contentWidth, containerWidth = DEFAULT_MOBILE_WIDTH) {
    return Math.round((contentWidth / containerWidth) * 100 * 100) / 100
  },
  desktop(contentWidth, containerWidth = DEFAULT_DESKTOP_WIDTH) {
    return Math.round((contentWidth / containerWidth) * 100 * 100) / 100
  }
}

export function generateAspectRatioStyle(aspectRatio = 1, width) {
  const heightPadding = Math.round((100 * 100) / +aspectRatio) / 100

  return {
    height: 0,
    ...(width ? { width: `${width}px` } : {}),
    paddingBottom: `${heightPadding}%`
  }
}

export const escapeHTMLTags = inputString => {
  if (!inputString) return ''

  return inputString.replace(/</gm, '&lt;').replace(/>/gm, '&gt;')
}

export function compareArrays(arr1, arr2) {
  if (
    !arr1 ||
    !arr2 ||
    arr1.length !== arr2.length ||
    !Array.isArray(arr1) ||
    !Array.isArray(arr2)
  )
    return false

  const sortedArr1 = arr1.sort()
  const sortedArr2 = arr2.sort()

  return sortedArr1.every((value, index) => value === sortedArr2[index])
}

export function isFile(file) {
  const isObjectFile =
    typeof file === 'object' &&
    (file.constructor === Blob || file.constructor === File)
  const isStringFile = typeof file === 'string' && file.slice(0, 5) === 'blob:'

  return isObjectFile || isStringFile
}

export function blurActiveElement() {
  if (!process.client) return

  document.activeElement.blur()
}

export function parseJSON(json) {
  let parsed

  try {
    parsed = JSON.parse(json)
  } catch (e) {
    return null
  }
  return parsed
}

/* eslint-disable */
function makeCancelablePromise(promise) {
  let hasCanceled_ = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => (hasCanceled_ ? resolve({ isCanceled: true }) : resolve(val)),
      error => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
    )
  })

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true
    }
  }
}
/* eslint-enable */

export function forceUrlFetch(url) {
  if (!url) return ''
  return `${url}?v=${Date.now()}`
}

function scrollToTop(component) {
  if (!component) return

  if (process.client) {
    return component.$scrollTo('body')
  }
}
export function replaceDoubleQuotesBySingle(text) {
  if (!text || typeof text !== 'string') return text

  return text.replace(/"/g, "'")
}

/**
 * Applies Promise.all to all promises but doesn't throw error in case
 * promise is not required. Returns null in such case and log the error.
 *
 * @param {Object[]} promiseSettingsArray           - array of settings
 * @param {promise} promiseSettingsArray[].promise  - promise
 * @param {boolean} promiseSettingsArray[].required - is promise required
 */
/* eslint no-async-promise-executor: 0 */
function promiseAllWithFallback(promiseSettingsArray, context) {
  let errorHandler = () => {}
  if (context) {
    errorHandler =
      context.$errorHandler || pathOr(null, ['app', '$errorHandler'], context)
  }

  const wrappedPromises = promiseSettingsArray.map(
    ({ promise, required, errorMessage, fallbackData }) =>
      new Promise(async (resolve, reject) => {
        try {
          const result = await promise
          resolve({ error: false, data: result })
        } catch (err) {
          if (required) {
            reject(err)
          } else {
            errorHandler(err, context, {
              showMessage: true,
              showErrorPage: false,
              ...(errorMessage ? { userMessage: errorMessage } : {})
            })
            resolve({ error: true, data: fallbackData || null })
          }
        }
      })
  )

  return Promise.all(wrappedPromises)
}

export async function getImageDimensions({ file, url = '' }) {
  if (!isFile(file) && !url) return null

  return new Promise(resolve => {
    const img = new Image()

    img.src = isFile(file) ? window.URL.createObjectURL(file) : url
    img.onload = function() {
      resolve({
        width: this.naturalWidth,
        height: this.naturalHeight
      })
    }
  })
}

function parseTldrJSONToText(tldr) {
  try {
    const parsedTldr = JSON.parse(tldr)
    return Array.isArray(parsedTldr) ? parsedTldr.join('\n') : ''
  } catch (err) {
    /* No need to catch this error */
    return ''
  }
}

export function getFirstRegexGroups(regex, str) {
  if (!regex) return []

  if (!(regex.flags && regex.flags.includes('g'))) {
    console.error(
      'The infinite loop will occur unless /g is specified. ' +
        'More info: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/match#description'
    )

    return []
  }

  const groups = []
  let nextResult

  while ((nextResult = regex.exec(str)) !== null) {
    groups.push(nextResult[1])
  }

  return groups
}

export function isElementCaptchaOverlay(target) {
  /**
   * Since google doesn't provide any identifier for its captcha challenge overlay, we need to exclude
   * direct parents of the challenge (google appends it to the body)
   */
  const exclusionRuleTagNames = ['html', 'body']
  const parentTagName = pathOr('', ['parentElement', 'tagName'], target)

  if (
    !target.parentElement ||
    !parentTagName ||
    exclusionRuleTagNames.includes(parentTagName.toLowerCase())
  ) {
    return false
  }

  return !!target.parentElement.querySelector(GOOGLE_CAPTCHA_CHALLENGE_SELECTOR)
}

function removeNode(node) {
  if (!node || !node.parentNode) return

  node.parentNode.removeChild(node)
}

export function isPreviewMode() {
  return storeCopy.getters.isPreviewMode
}

/**
 * Repeatedly checks for a given condition. If it results in "true" - runs a
 * given callback (fn). If the timeout is reached and the condition is not
 * truthy, the callback never runs.
 *
 * @param fn { Function }
 * @param condition { Function }
 * @param interval { Number }
 * @param timeout { Number }
 * @param executeOnTimeoutFn { Function }
 * @returns {{clearInterval: clearInterval} | undefined}
 */
export function pollUntil({
  fn,
  condition,
  interval = 200,
  timeout = 60 * 1000,
  executeOnTimeoutFn = () => {}
}) {
  if (!fn || !condition) return

  if (condition()) {
    fn()
    return
  }

  let timePassed = 0
  let timeoutId

  function clearInterval() {
    clearTimeout(timeoutId)
  }

  ;(function runWithInterval() {
    timeoutId = setTimeout(() => {
      timePassed += interval

      if (condition()) {
        fn()
        return
      }

      if (timePassed >= timeout) {
        if (checkIfFunction(executeOnTimeoutFn)) {
          executeOnTimeoutFn()
        }

        return
      }

      runWithInterval()
    }, interval)
  })()

  return { clearInterval }
}

export function generateServerCacheKey(componentName = '') {
  if (!componentName) return guid()
  /** Apply component cache only for production environment.
   * (if we return componentName - component is cached, and cache invalidated only by timeout (see
   * @nuxtjs/component-cache module config)
   * (if we return new guid - component is not cached) **/
  return process.env.NODE_ENV === 'production' ? componentName : guid()
}

/**
 * Convert url (could be image) to blob file. Use with async/await
 * @param url
 * @returns Promise
 */
export function urlToBlob(url) {
  if (!url) return null

  try {
    return storeCopy.dispatch('requestUrlToBlob', url)
  } catch (err) {
    throw err
  }
}

function isScriptAlreadyInContainer(url, selector) {
  return document.querySelector(`${selector} script[src="${url}"]`)
}

export function insertScriptToHead(scriptElement) {
  if (
    !scriptElement ||
    !scriptElement.src ||
    isScriptAlreadyInContainer(scriptElement.src, 'head')
  ) {
    return
  }

  document.head.appendChild(scriptElement)
}

export function removeEmptyObjectEntries(obj) {
  return Object.entries(obj)
    .filter(([key, value]) => value != null && value !== '')
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
}

export function serializeQueryParams(params) {
  const validParams = removeEmptyObjectEntries(params)
  return querystring.stringify(validParams)
}

/**
 * ToDo: Complete and check this function
 * @param {function}  fn
 * @param {number}    timeout
 * @param {boolean}   immediate
 */
export function runWithInterval(fn, timeout, immediate = true) {
  if (!fn || !timeout) return

  if (immediate) {
    fn()
  }

  let timeoutId

  function clearInterval() {
    clearTimeout(timeoutId)
  }

  ;(function runWithIntervalInner() {
    timeoutId = setTimeout(() => {
      fn()
      runWithIntervalInner()
    }, timeout)
  })()

  return {
    clearInterval
  }
}

export function isInnerLink(link) {
  const reg = new RegExp(`${storeCopy.$env.DOMAIN_URL}/?`, 'i')

  return reg.test(link) || !checkIfLinkHasProtocol(link)
}

export function replaceDeepObjectValuesByKey({ data, key, fn }) {
  if (!key || !fn) return data

  if (isObject(data)) {
    return Object.entries(data).reduce((acc, [currentKey, value]) => {
      if (isObject(data[currentKey]) || isArray(data[currentKey])) {
        const params = { data: data[currentKey], key, fn }
        return { ...acc, [currentKey]: replaceDeepObjectValuesByKey(params) }
      }
      return { ...acc, [currentKey]: key === currentKey ? fn(value) : value }
    }, {})
  }
  if (isArray(data)) {
    return data.map(arrayItem => {
      if (isObject(arrayItem) || isArray(arrayItem)) {
        const params = { data: arrayItem, key, fn }
        return replaceDeepObjectValuesByKey(params)
      } else {
        return arrayItem
      }
    })
  }

  return data
}

export function getQueryObjectFromUrl(url) {
  if (!url) return {}

  const urlObject = new URL(url)
  const queryString = urlObject.search.replace('?', '')
  const queryParams = new URLSearchParams(queryString)
  return [...queryParams].reduce(
    (acc, [param, value]) => ({ ...acc, [param]: value }),
    {}
  )
}

export function isRecaptchaChallenge(element) {
  if (!element) return false

  return (
    element?.tagName?.toLowerCase() === HTML_TAG.IFRAME &&
    element?.src?.includes('google.com/recaptcha/')
  )
}

export function toCamelCase(string) {
  if (!string || typeof string !== 'string') return string

  return `${string[0].toLowerCase()}${string.slice(1)}`
}

export function processResponse(original, remap = {}) {
  return remapObjectKeys(original, remap, toCamelCase)
}

export function isTrimmedStringNotEmpty(str) {
  if (!str) return false

  return str.trim().length > 0
}

export function getPageNumberFromUrl(url) {
  if (!url) return null

  const regExp = /page\/(\d+)/gi
  const groups = getFirstRegexGroups(regExp, url)
  return groups.length ? +groups[0] : null
}

export function getPageNumberFromRoute(route) {
  if (!route) {
    console.error(
      'route is not passed to getPageNumberFromRoute() helper method'
    )
    return 0
  }

  const { path } = route
  const pageNumber = getPageNumberFromUrl(path)

  return pageNumber && pageNumber > 0 ? pageNumber - 1 : 0
}

export function getFullName(firstName, lastName) {
  return `${firstName || ''} ${lastName || ''}`.trim()
}

function addPersonalAreaTabQuery(url, tab = TAB_NAME.FL) {
  if (!url) return url

  return `${url}?${QUERY_PARAM_NAME}=${QUERY_PARAM_VALUE[tab]}`
}

export function isUrlWithPagination(url = window && window.location.href) {
  return url !== url.replace(/page\/\d+\/+/, '')
}

export function isRouteWithoutSidebar(route) {
  const { name } = route
  return PAGES_WITHOUT_SIDEBAR.includes(name)
}

export function createPrefilledArray(length, fillValue) {
  return new Array(length).fill(fillValue)
}

export function replaceElementInArrayByIndex(arr, index, element) {
  return [...arr.slice(0, index), element, ...arr.slice(index + 1)]
}

export async function wait(timeout) {
  await new Promise(resolve => setTimeout(resolve, timeout))
}

export function handleResponseImage(image = {}) {
  return processResponse(image, { URL: 'url' })
}

export function generateUrlFromObject({
  address,
  hash,
  query,
  addressModifierFn = null
} = {}) {
  if (!address) return `${query || ''}${hash || ''}`

  const processedAddress =
    addressModifierFn && checkIfFunction(addressModifierFn)
      ? addressModifierFn(address)
      : address

  return `${processedAddress}${query || ''}${hash || ''}`
}

export function getBackendErrorData(err) {
  return pathOr(null, ['response', 'data'], err)
}

export function queryStringToObject(str) {
  if (!isString(str) || str === '') return {}

  return JSON.parse(
    '{"' +
      decodeURI(str)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  )
}

export function compareRoutes(oldRoute, newRoute) {
  if (!oldRoute || !newRoute) {
    console.error('oldRoute and newRoute are required!')
    return {}
  }

  try {
    const {
      name: oldRouteName,
      path: oldRoutePath,
      fullPath: oldRouteFullPath
    } = oldRoute
    const {
      name: newRouteName,
      path: newRoutePath,
      fullPath: newRouteFullPath
    } = newRoute
    const isRouteNameChanged = oldRouteName !== newRouteName

    const pageRouteNamePart = '-page-number'
    const pageRoutePathPart = new RegExp(/page\/\d+\//, 'gmi')
    const pageRouteFullPathPart = new RegExp(/\/?page\/\d+\/?/, 'gmi')
    const isParentRouteNameChanged =
      newRouteName.replace(pageRouteNamePart, '') !==
      oldRouteName.replace(pageRouteNamePart, '')
    const isFromPaginatedToParent =
      newRoutePath ===
      (oldRoutePath.match(pageRoutePathPart) &&
        oldRoutePath.replace(pageRoutePathPart, ''))

    const oldRoutePathWithoutPagination = addEndingSlash(
      addEndingSlash(oldRoutePath).replace(pageRoutePathPart, '')
    )
    const newRoutePathWithoutPagination = addEndingSlash(
      addEndingSlash(newRoutePath).replace(pageRoutePathPart, '')
    )
    const isParentRoutePathChanged =
      oldRoutePathWithoutPagination !== newRoutePathWithoutPagination

    const oldRouteFullPathWithoutPagination = oldRouteFullPath.replace(
      pageRouteFullPathPart,
      '/'
    )
    const newRouteFullPathWithoutPagination = newRouteFullPath.replace(
      pageRouteFullPathPart,
      '/'
    )

    const isParentRouteFullPathChanged =
      oldRouteFullPathWithoutPagination !== newRouteFullPathWithoutPagination

    const currentPathWithoutHash = newRoute.fullPath.split('#')[0]
    const previousPathWithoutHash = oldRoute.fullPath.split('#')[0]

    const isOnlyHashChanged = currentPathWithoutHash === previousPathWithoutHash

    return {
      isRouteNameChanged,
      isParentRouteNameChanged,
      isFromPaginatedToParent,
      isParentRoutePathChanged,
      isParentRouteFullPathChanged,
      isOnlyHashChanged
    }
  } catch (err) {
    console.error(err)
    return {}
  }
}

/**
 *  Get the "previous" and "next" links in the page head, in order to give
 *  search crawler ability to work correctly with infinity scroll or pages with
 *  pagination
 **/
export function getPaginationSeoLinks(
  nonPaginatedUrl,
  pagesCount,
  currentUserPage
) {
  const link = []
  const hasPreviousPage = currentUserPage > 1
  const hasNextPage = currentUserPage < pagesCount
  const linkPath = `${nonPaginatedUrl}${
    isStringEndsWith('/', nonPaginatedUrl) ? '' : '/'
  }page`

  if (hasPreviousPage) {
    const previousPageNumber = currentUserPage - 1
    link.push({
      rel: 'previous',
      href:
        previousPageNumber === 1
          ? nonPaginatedUrl
          : `${linkPath}/${previousPageNumber}/`
    })
  }

  if (hasNextPage) {
    const nextPageNumber = +currentUserPage + 1
    link.push({
      rel: 'next',
      href: `${linkPath}/${nextPageNumber}/`
    })
  }

  return link
}

export const saveValueInClosure = val => ({
  getValue: () => val
})

function decodeAccessToken(token) {
  const char = token.slice(-1)
  const rawToken = token.slice(0, -1)
  const position = rawToken.length - (char.charCodeAt(0) - 96)
  const tokenFirstPart = rawToken.slice(position)
  const tokenSecondPart = rawToken.slice(0, position)

  return `${tokenFirstPart}${tokenSecondPart}`
}

export function getTokenValue(token) {
  if (!token) return token

  console.log('token', token)

  return decodeAccessToken(token.getValue())
}

export function fallbackIfUndefined(value, fallbackValue) {
  return value === undefined ? fallbackValue : value
}

/**
 * Create dynamic watcher, fire method if condition function returns true and
 * remove watcher.
 * @param ctx         - component this to access this.$watch
 * @param field       - field to watch
 * @param method      - method to fire on watch
 * @param conditionFn - handle newVal and oldVal function, returns Boolean
 * @param immediate   - condition is checked immediately if set to true
 * @param timeoutFn   - execute on timeout
 * @param timeout     - timeout in ms
 */
export function watchAndExecuteOnce({
  ctx,
  field,
  handlerFn = () => {},
  conditionFn = () => true,
  immediate = false,
  timeoutFn = null,
  timeout = 2 * 60 * 1000
}) {
  if (!ctx || !ctx.$watch || !field) return

  if (immediate) {
    const currentValue = ctx[field]
    if (conditionFn(currentValue)) {
      handlerFn()
      return
    }
  }

  const unwatch = ctx.$watch(field, (newVal, oldVal) => {
    if (conditionFn(newVal, oldVal)) {
      handlerFn()
      unwatch()
    }
  })

  if (checkIfFunction(timeoutFn)) {
    setTimeout(() => {
      timeoutFn()
      unwatch()
    }, timeout)
  }
}

export function isAmpPage(routeName) {
  return AMP_PAGES.includes(routeName)
}

export function isNotAmpPage(routeName) {
  return !isAmpPage(routeName)
}

export function addAmpSuffix(str = '') {
  if (!str) return ''

  const url = addEndingSlash(str)
  return endsWith('/amp/', url) ? url : `${url}amp/`
}

/**
 * Used to find if some Object/Array (not primitive) has some parent in the provided
 * data structure (checked by function)
 *
 * @param dataStructure     - any data structure with Objects, Arrays or primitives
 * @param targetElement     - Object or Array (not primitive)
 * @param parentConditionFn - function to check parent item for condition
 * @returns {boolean|*}     - returns true if targetElement has at least one parent item
 *                            which matches the condition
 */
export function hasParentInDataStructureDeep({
  dataStructure,
  targetElement,
  parentConditionFn
}) {
  if (!targetElement || !parentConditionFn) return false

  function hasEl(elements, elToFind) {
    if (!elements) {
      return { isTargetElementFound: false, parentCondition: false }
    }

    if (Array.isArray(elements)) {
      return elements.reduce(
        (acc, element) => {
          if (acc.isTargetElementFound && acc.parentCondition) return acc

          const { isTargetElementFound, parentCondition } = hasEl(
            element,
            elToFind
          )
          if (isTargetElementFound && parentCondition) {
            return { isTargetElementFound, parentCondition }
          }
          if (isTargetElementFound) {
            return {
              isTargetElementFound,
              parentCondition: parentConditionFn(element)
            }
          }
          return acc
        },
        { isTargetElementFound: false, parentCondition: false }
      )
    }

    if (isObject(elements)) {
      if (elements === elToFind) {
        return { isTargetElementFound: true, parentCondition: false }
      }

      if (elements.content) {
        return hasEl(elements.content, elToFind)
      }

      return { isTargetElementFound: false, parentCondition: false }
    }

    return { isTargetElementFound: false, parentCondition: false }
  }

  const { isTargetElementFound, parentCondition } = hasEl(
    dataStructure,
    targetElement
  )

  if (!isTargetElementFound) {
    console.log(
      'hasParentInDataStructureDeep():\nElement was not found in the provided data structure'
    )
    return false
  }

  return parentCondition
}

/**
 * @link https://stackoverflow.com/questions/986937/how-can-i-get-the-browsers-scrollbar-sizes
 * Gets actual scrollbar width (15px on desktop, 0px on mobile)
 */
export function getScrollBarWidth() {
  if (!process.client) return null

  const inner = document.createElement('p')
  inner.style.width = '100%'
  inner.style.height = '200px'

  const outer = document.createElement('div')
  outer.style.position = 'absolute'
  outer.style.top = '0px'
  outer.style.left = '0px'
  outer.style.visibility = 'hidden'
  outer.style.width = '200px'
  outer.style.height = '150px'
  outer.style.overflow = 'hidden'
  outer.appendChild(inner)

  document.body.appendChild(outer)
  const w1 = inner.offsetWidth
  outer.style.overflow = 'scroll'
  let w2 = inner.offsetWidth
  if (w1 === w2) w2 = outer.clientWidth

  document.body.removeChild(outer)

  return w1 - w2
}

export function getEmailDomain(email) {
  try {
    if (!email || !isString(email)) return null

    return email.slice(email.lastIndexOf('@') + 1).toLowerCase()
  } catch (err) {
    return null
  }
}

/**
 * This function is an async wrapper for redirecting a user.
 * It resolves the issue when ctx.redirect inside try/catch block causes
 * a quick redirect to the 404 error page and only then - redirect to the
 * expected route.
 * To address the issue we use ctx.redirect only on server-side. On the
 * client-side we use existing concurrentRouterPush action that calls
 * router.push under the hood.
 * Please note that we use force: true option not to wait other requests are
 * finished.
 * @link  https://github.com/nuxt/nuxt/issues/8594
 */
export async function asyncRedirectHandler(
  ctx,
  { type, redirectToUrl, redirectToObject, redirectToQueryParams }
) {
  if (!ctx || !(redirectToUrl || redirectToObject)) return

  const redirect = ctx.redirect
  const dispatch = ctx.store.dispatch

  return new Promise(resolve => {
    try {
      if (process.client) {
        const redirectTo = redirectToObject || {
          path: redirectToUrl.replace(ctx.$env.DOMAIN_URL, '') || '/',
          query: redirectToQueryParams
        }
        const args = {
          router: ctx.store.$router,
          to: redirectTo,
          force: true
        }
        dispatch('router/concurrentRouterPush', { args })
      } else {
        const redirectTo = redirectToObject || redirectToUrl

        if (type) {
          redirect(type, redirectTo, redirectToQueryParams)
        } else {
          redirect(redirectTo, redirectToQueryParams)
        }
      }
    } catch (err) {
      console.log('err: ', err)
      ctx.app.$errorHandler(err, ctx)
    } finally {
      resolve()
    }
  })
}

export function getBoundingClientRectCustom(el) {
  if (!el) return {}

  const width = el.offsetWidth
  const height = el.offsetHeight

  let _x = 0
  let _y = 0
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft
    _y += el.offsetTop - el.scrollTop
    el = el.offsetParent
  }

  return {
    top: _y,
    bottom: _y + height,
    left: _x,
    right: _x + width,
    width,
    height
  }
}

export function runOnRefResize({ dataRef = REFS.LAYOUT_PAGE_WRAP, fn }) {
  const el = getElByDataRef(dataRef)
  if (!el) {
    console.error(
      `runOnRefResize cannot be called as element cannot be found by the provided dataRef (${dataRef})`
    )
    return
  }
  let isCancelled = false

  function handleResize(entries) {
    if (isCancelled) {
      resizeObserver.disconnect()
      return
    }

    for (const entry of entries) {
      const { target, contentRect } = entry
      const el = getElByDataRef(dataRef)
      const rectData = getBoundingClientRectCustom(el)
      fn({ target, contentRect, rectData })
    }
  }

  const resizeObserver = new ResizeObserver(handleResize)
  resizeObserver.observe(el)

  return function removeResizeListener() {
    resizeObserver.disconnect()
    isCancelled = true
  }
}

export function getElByDataRef(ref, elementToSearchIn = null) {
  return (elementToSearchIn || document).querySelector(`[data-ref="${ref}"]`)
}

const helperMethods = {
  formatDate: {
    /** Phase 1: It was decided to always show timestamps in user local time.
     *  If you are looking for the solution which allows to show time
     *  in specific Timezone - please consider the Luxon library
     *  (and check the previous commits - such a solution was used previously)
     *  **/
    toDefault: dateStr => {
      if (!dateStr) return ''

      const { EEEE, MM, yyyy, dd, HH, mm, ZZZZ } = getParsedDateString(dateStr)

      return `${EEEE}, ${dd}/${MM}/${yyyy} | ${HH}:${mm} ${ZZZZ}`
    },
    toDateAndHours: dateStr => {
      if (!dateStr) return ''

      const { MM, yyyy, dd, HH, mm, ZZZZ } = getParsedDateString(dateStr)

      return `${dd}/${MM}/${yyyy} | ${HH}:${mm} ${ZZZZ}`
    },
    toMonthDayYear: dateStr => {
      if (!dateStr) return ''

      const { MMMM, yyyy, dd } = getParsedDateString(dateStr)

      return `${MMMM} ${dd}, ${yyyy}`
    },
    toIntervalFromNow: fromNow
  },
  resetComponentData,
  guid,
  removeSpaces,
  formatPhone,
  isValidationFailed,
  replaceImageUrlSuffix,
  isImageUrlHasSizeSuffixes,
  replaceImageUrlSuffixWithOriginal,
  getLinkToFmBucketFile,
  getLinkToFlBucketFile,
  getExtension,
  addEndingSlash,
  removeEndingSlashes,
  closeModal,
  closeAllModals,
  openModal,
  addStartingSlash,
  removeStartingSlash,
  wrapIntoSlashes,
  prettifyPath,
  getMediaFileType,
  fileTypeChecker,
  formatPrice,
  applyFallback,
  isMiddlewareSkipped,
  checkIfRefreshTokenIsRelevant,
  stringToArray,
  isString,
  isStringEndsWith,
  isObject,
  isArray,
  isFile,
  valuesToLowercase,
  getFullPath,
  htmlToText,
  countWordsAmount,
  calculateTimeRequiredToRead,
  generateSlugFromName,
  capitalize,
  getWindowScrollTop,
  percentage,
  generateAspectRatioStyle,
  compareArrays,
  blurActiveElement,
  makeCancelablePromise,
  scrollToTop,
  replaceDoubleQuotesBySingle,
  promiseAllWithFallback,
  checkIfFunction,
  getImageDimensions,
  parseTldrJSONToText,
  parseJSON,
  getPageSourceByRouteName,
  getFirstRegexGroups,
  generateServerCacheKey,
  urlToBlob,
  isInteger,
  removeEmptyObjectEntries,
  serializeQueryParams,
  runWithInterval,
  isElementCaptchaOverlay,
  isInnerLink,
  getQueryObjectFromUrl,
  checkIfLinkHasProtocol,
  remapObjectKeys,
  isTrimmedStringNotEmpty,
  forceUrlFetch,
  mailto,
  toCamelCase,
  processResponse,
  getFullName,
  getPageNumberFromUrl,
  getPageNumberFromRoute,
  addPersonalAreaTabQuery,
  isStringStartsWith,
  isUrlWithPagination,
  isRouteWithoutSidebar,
  caseInsensitiveReplace,
  createPrefilledArray,
  replaceElementInArrayByIndex,
  wait,
  pollUntil,
  parseUrl,
  removePaginationPartFromPath,
  getBinaryStringFromBooleanArray,
  getBackendErrorCode,
  getBackendErrorEvidence,
  getBackendErrorData,
  queryStringToObject,
  joinStringsWithSpaces,
  generateUrlFromObject,
  getPaginationSeoLinks,
  isLinkWithTrailingSlash,
  runTask,
  encodeURIValues,
  getOptanonActiveGroups,
  insertScriptToHead,
  getAltTextForMediaCenterImage,
  compareRoutes,
  saveValueInClosure,
  getTokenValue,
  getLastPathParam,
  watchAndExecuteOnce,
  getUserDetailsFromAccessToken,
  randomIntFromInterval,
  isHrefValid,
  getUrlHost,
  fallbackIfUndefined,
  removeAmpSuffix,
  isAmpPage,
  isNotAmpPage,
  addAmpSuffix,
  getWindowWidth,
  getWindowHeight,
  getTextWidth,
  removeNode,
  getScrollBarWidth,
  generateParsedDomElementsHandlerDeep,
  formatYoutubeIdToEmbedUrl,
  generateVideoSchemaPayloadFromResponse,
  generateVideoSchemasFromResponse,
  getEmailDomain,
  getTopStickyElement,
  getElByDataRef,
  getBoundingClientRectCustom,
  runOnRefResize,
  isRecaptchaChallenge
}

/**
 * Test implementation
 */
export function runTask(fn) {
  if (!fn) return

  return new IdleValue(fn)
}

/**
 * Test implementation
 */
const queueHelperMethods = process.client
  ? Object.entries(helperMethods).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]:
          checkIfFunction(value) && value.name !== 'runTask'
            ? (...args) => {
                return runTask(() => value(...args)).getValue()
              }
            : value
      }),
      {}
    )
  : helperMethods

export default (ctx, inject) => {
  context = ctx
  storeCopy = ctx.store
  inject('helper', queueHelperMethods)
}

function fromNow(dateStr, withPrefix = true) {
  const diff = new Date(dateStr) - new Date()
  const diffAbs = Math.abs(diff)
  let dimension = ''

  const dimensions = {
    minutes: 'minutes',
    hours: 'hours',
    days: 'days',
    weeks: 'weeks',
    months: 'months',
    years: 'years'
  }

  const parsedDiff = {
    [dimensions.minutes]: Math.round(diffAbs / (1000 * 60)),
    [dimensions.hours]: Math.round(diffAbs / (1000 * 60 * 60)),
    [dimensions.days]: Math.round(diffAbs / (1000 * 60 * 60 * 24)),
    [dimensions.weeks]: Math.round(diffAbs / (1000 * 60 * 60 * 24 * 7)),
    [dimensions.months]: Math.round(diffAbs / (1000 * 60 * 60 * 24 * 30)),
    [dimensions.years]: Math.round(diffAbs / (1000 * 60 * 60 * 24 * 30 * 12))
  }

  if (parsedDiff.minutes < 60) {
    dimension = dimensions.minutes
  } else if (parsedDiff.hours < 24) {
    dimension = dimensions.hours
  } else if (parsedDiff.days < 7) {
    dimension = dimensions.days
  } else if (parsedDiff.weeks < 4) {
    dimension = dimensions.weeks
  } else if (parsedDiff.months < 12) {
    dimension = dimensions.months
  } else {
    dimension = dimensions.years
  }

  const val = parsedDiff[dimension]

  if (val <= 10 && dimension === dimensions.minutes) {
    return 'just now'
  } else {
    dimension = val > 1 ? dimension : dimension.replace('s', '')
    if (withPrefix) {
      return diff < 0 ? `${val} ${dimension} ago` : `in ${val} ${dimension}`
    } else {
      return `${val} ${dimension}`
    }
  }
}
