<template>
  <iframe
    v-if="isIframeVisible"
    :src="iframeSrc"
    class="device-id-cookies-iframe"
    @load="onLoad"
  />
</template>

<script>
import Cookies from 'js-cookie'

import { SET_DEVICE_ID } from '~/config/serverMiddleware'
import {
  DEVICE_ID_COOKIE_EXPIRES_IN_DAYS,
  DEVICE_ID_COOKIE_NAME
} from '~/server/enums'
import { generateDeviceId } from '~/server/helper'

export default {
  name: 'ADeviceIdCookieIframe',
  data() {
    return {
      isIframeVisible: false
    }
  },
  computed: {
    iframeSrc() {
      return `${this.$env.DOMAIN_URL}${SET_DEVICE_ID}`
    }
  },
  methods: {
    showIframe() {
      this.isIframeVisible = true
    },
    hideIframe() {
      this.isIframeVisible = false
    },
    onLoad() {
      if (!Cookies.get(DEVICE_ID_COOKIE_NAME)) {
        Cookies.set(DEVICE_ID_COOKIE_NAME, generateDeviceId(), {
          path: '/',
          expires: DEVICE_ID_COOKIE_EXPIRES_IN_DAYS,
          secure: true,
          sameSite: 'lax'
        })
      }

      this.hideIframe()
    }
  },
  mounted() {
    this.showIframe()
  }
}
</script>

<style scoped>
.device-id-cookies-iframe {
  width: 0;
  height: 0;
  visibility: hidden;
  position: absolute;
  right: -5000px;
}
</style>
