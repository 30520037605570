<template>
  <!-- Google Tag Manager (noscript) -->
  <client-only v-if="$env.GTM_CONTAINER_ID">
    <noscript
      ><iframe
        :src="
          `https://www.googletagmanager.com/ns.html?id=${$env.GTM_CONTAINER_ID}`
        "
        height="0"
        width="0"
        style="display:none;visibility:hidden"
      ></iframe
    ></noscript>
  </client-only>
  <!-- End Google Tag Manager (noscript) -->
</template>

<script>
export default {
  name: 'GtmNoScript'
}
</script>
