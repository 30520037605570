import { isString } from '@/plugins/helper'
import { removeScripts } from '@/utils/helpers/processHtml/parseHtmlAndRemoveScripts'
import { sanitizeAttributes } from '@/utils/helpers/processHtml/parseHtmlAndSanitizeAttributes'
import { parseHtml } from '@/utils/helpers/processHtml/parseHtml'
import render from 'posthtml-render'
import { pipe } from 'ramda'

export function parseAndSanitizeHtml(html) {
  if (!html || !isString(html)) return ''

  return pipe(parseHtml, removeScripts, sanitizeAttributes, render)(html)
}
