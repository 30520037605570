export const CONNECTION_STATE = {
  CONNECTING: 'CONNECTING',
  CONNECTED: 'CONNECTED',
  RECONNECTING: 'RECONNECTING',
  DISCONNECTING: 'DISCONNECTING',
  DISCONNECTED: 'DISCONNECTED',
  UNKNOWN: 'UNKNOWN',
  PAUSED: 'PAUSED' // our own state to pause websockets when browser tab is inactive
}

/**
 * SignalR enum values for connection state
 * @link https://docs.microsoft.com/en-us/javascript/api/@microsoft/signalr/hubconnectionstate?view=signalr-js-latest
 */
export const CONNECTION_VALUE = {
  CONNECTED: 'Connected',
  CONNECTING: 'Connecting',
  DISCONNECTED: 'Disconnected',
  DISCONNECTING: 'Disconnecting',
  RECONNECTING: 'Reconnecting'
}

export const CONNECTION_STATE_BY_NEW_VALUE = {
  [CONNECTION_VALUE.CONNECTED]: CONNECTION_STATE.CONNECTED,
  [CONNECTION_VALUE.CONNECTING]: CONNECTION_STATE.CONNECTING,
  [CONNECTION_VALUE.DISCONNECTED]: CONNECTION_STATE.DISCONNECTED,
  [CONNECTION_VALUE.DISCONNECTING]: CONNECTION_STATE.DISCONNECTING,
  [CONNECTION_VALUE.RECONNECTING]: CONNECTION_STATE.RECONNECTING
}

/**
 * SignalR works properly in inactive tab during the first 5 minutes.
 *
 * If the browser tab becomes inactive or offline, we keep connection
 * on background for 4 minutes and then we pause the connection (if the
 * tab is still inactive or offline)
 */
const PAUSE_CONNECTION_TIMEOUT = 4 * 60 * 1000 // 4 minutes

export const REQUIRED_ACTION = {
  CONNECT: 'CONNECT',
  DISCONNECT: 'DISCONNECT',
  PAUSE_ON_TIMEOUT: 'PAUSE_ON_TIMEOUT',
  CANCEL_PAUSE_TIMEOUT: 'CANCEL_PAUSE_TIMEOUT',
  RECONNECT: 'RECONNECT',
  NOTHING: 'NOTHING'
}

/**
 *          x1                      x2                        x3                  x4
 * isSignalrInitialized  isOnlineAndActiveBrowserTab   isHostConnected   isConnectionRequired
 * @link https://docs.google.com/spreadsheets/d/1syJFU5axegff3PIN6kF8ICttyqGZaHerlvZFnFqWl58/edit?usp=sharing
 */
export const REQUIRED_ACTION_BY_DYNAMIC_STATE = {
  '1010': REQUIRED_ACTION.DISCONNECT,
  '1011': REQUIRED_ACTION.PAUSE_ON_TIMEOUT,
  '1101': REQUIRED_ACTION.CONNECT,
  '1110': REQUIRED_ACTION.DISCONNECT,
  '1111': REQUIRED_ACTION.CANCEL_PAUSE_TIMEOUT
}

export class SignalR {
  constructor({ settings }) {
    this.settings = settings
    this.hostPath = settings.hostPath
    this.hubPath = settings.hubPath
    this.connection = null
    this.isConnectionPaused = false
    this.listeners = settings.listeners || []
    this.startupInvokes = settings.startupInvokes || []
    this.onDisconnect = settings.onDisconnect
    this._browserTabInactiveTimeoutId = null
    this.init()
  }
  get isConnecting() {
    return this.connectionState === CONNECTION_STATE.CONNECTING
  }
  get isConnected() {
    return this.connectionState === CONNECTION_STATE.CONNECTED
  }
  get isDisconnected() {
    return this.connectionState === CONNECTION_STATE.DISCONNECTED
  }
  get isPaused() {
    return this.isConnectionPaused
  }
  get isConnectionRequired() {
    const { shouldHubBeConnected } = this.settings

    if (!shouldHubBeConnected) return false

    return shouldHubBeConnected()
  }
  get connectionUrl() {
    return `${this.hostPath}${this.hubPath}`
  }
  get connectionState() {
    if (!this.connection) return CONNECTION_STATE.DISCONNECTED

    if (this.isConnectionPaused) return CONNECTION_STATE.PAUSED

    return (
      CONNECTION_STATE_BY_NEW_VALUE[this.connection.state] ||
      CONNECTION_STATE.UNKNOWN
    )
  }
  async init() {
    if (!this.isConnectionRequired) return

    this.createConnection()
    if (!this.connection) return

    await this.connect()
  }
  createConnection() {
    try {
      const hubConnection = new window.signalR.HubConnectionBuilder()
        .withUrl(this.connectionUrl)
        .build()

      if (!hubConnection) {
        const errorMessage = 'SignalR: cannot create connection'
        this.settings.onError({ errorMessage })
        return
      }
      this.connection = hubConnection
      this.addConnectionListeners()
      this.createHubListeners()
    } catch (err) {
      this.handleError(err)
    }
  }
  addConnectionListeners() {
    // this.connection.onclose(this.handleDisconnect.bind(this))
    // this.connection.onreconnecting(() => {})
    // this.connection.onreconnected(() => {})
  }
  createHubListeners() {
    this.listeners.forEach(({ name, handler }) => {
      this.connection.on(name, handler)
    })
  }
  createHubStartupInvokes() {
    this.startupInvokes.forEach(this.invoke.bind(this))
  }
  invoke({ name, payloadFn }) {
    const payload = payloadFn()
    if (Array.isArray(payload)) {
      this.connection.invoke(name, ...payload)
    } else {
      this.connection.invoke(name, payload)
    }
  }
  handleSlowConnection() {
    // not really needed at the moment
  }
  handleError(err) {
    console.error(err)
    const errorMessage = 'SignalR: Error occurred during some action'
    this.settings.onError({ err, errorMessage })
  }
  handleDisconnect() {
    // not really needed at the moment as we already handle errors
  }
  emitAllHandlers() {
    this.listeners.forEach(listener => {
      if (listener.handler) {
        listener.handler(null)
      }
    })
  }
  startPauseTimeout() {
    this.cancelPauseTimeout()
    this._browserTabInactiveTimeoutId = setTimeout(() => {
      this.pause()
    }, PAUSE_CONNECTION_TIMEOUT)
  }
  cancelPauseTimeout() {
    if (this._browserTabInactiveTimeoutId) {
      clearTimeout(this._browserTabInactiveTimeoutId)
      this._browserTabInactiveTimeoutId = null
    }
  }
  async startConnection() {
    /**
     * Do not start connection if it is already connected
     */
    if (this.isConnected || this.isConnecting) return

    const onConnectHandlers = [
      this.createHubStartupInvokes.bind(this),
      ...(this.isPaused ? [this.emitAllHandlers.bind(this)] : [])
    ]

    try {
      await this.connection.start()
      this.isConnectionPaused = false
      if (onConnectHandlers && onConnectHandlers.length) {
        onConnectHandlers.forEach(handler => {
          handler()
        })
      }
    } catch (err) {
      this.handleError(err)
    }
  }
  async connect() {
    if (!this.connection) {
      this.createConnection()

      if (!this.connection) return
    }

    this.cancelPauseTimeout()
    await this.startConnection()
  }
  async reconnect() {
    /**
     * To add new listeners to existing connection we have to stop it, add listeners
     * and then start again
     */
    if (!this.isDisconnected) {
      await this.stop()
    }
    await this.connect()
  }
  async pause() {
    this.cancelPauseTimeout()
    await this.stop()
    this.isConnectionPaused = true
  }
  async disconnect() {
    await this.stop()
    this.fireOnDisconnectCallback()
  }
  async stop() {
    if (!this.connection) return

    try {
      await this.connection.stop()
    } catch (err) {
      this.handleError(err)
    }
  }
  fireOnDisconnectCallback() {
    if (this.onDisconnect) {
      this.onDisconnect()
    }
  }
}
