import api from '~/utils/api'
import { processResponse } from '@/plugins/helper'
import { generateArticleSlotResponse } from '@/utils/response-handling/article'
import * as types from '~/store/mutation-types'

export const state = () => ({
  skipSearchTrackOnSearchResultsPage: false
})

export const getters = {
  skipSearchTrackOnSearchResultsPage: state =>
    state.skipSearchTrackOnSearchResultsPage
}

export const actions = {
  async requestTermsQuicksearch(_, params) {
    try {
      const { data } = await this.$axios.get(
        api.globalSearch.termsQuickSearch(),
        {
          params
        }
      )
      return data
    } catch (e) {
      throw e
    }
  },
  async requestTermsFullSearch(_, params) {
    try {
      const { data } = await this.$axios.get(
        api.globalSearch.termsFullSearch(),
        {
          params
        }
      )
      return data
    } catch (e) {
      throw e
    }
  },
  async requestFullSearch(_, params) {
    try {
      const { data } = await this.$axios.get(api.globalSearch.getAllResults(), {
        params
      })

      data.Articles = data.Articles.map(article =>
        generateArticleSlotResponse(article)
      )

      return data
    } catch (err) {
      throw err
    }
  },
  async requestFlQuickSearchResults(_, params) {
    try {
      const { data } = await this.$axios.get(api.globalSearch.quickSearch(), {
        params
      })

      return data
    } catch (err) {
      throw err
    }
  },
  async requestTagsQuicksearch(_, params) {
    try {
      const { data } = await this.$axios.get(
        api.globalSearch.tagsQuickSearch(),
        {
          params
        }
      )
      return data
    } catch (e) {
      throw e
    }
  },
  async requestTagsFullSearch(_, params) {
    try {
      const { data } = await this.$axios.get(
        api.globalSearch.tagsFullSearch(),
        { params }
      )
      return processResponse(data)
    } catch (e) {
      throw e
    }
  },
  async requestCompaniesQuicksearch(_, params) {
    try {
      const { data } = await this.$axios.get(
        api.globalSearch.companiesQuickSearch(),
        {
          params
        }
      )
      return data
    } catch (e) {
      throw e
    }
  }
}

export const mutations = {
  [types.SET_SKIP_SEARCH_TRACK_ON_SEARCH_RESULTS_PAGE_STATUS]: (
    state,
    value
  ) => {
    state.skipSearchTrackOnSearchResultsPage = value
  }
}
