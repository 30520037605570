import { parseHtml } from '@/utils/helpers/processHtml/parseHtml'
import { isArray, isObject } from '@/plugins/helper'
import render from 'posthtml-render'
import { pipe } from 'ramda'

export function sanitizeAttributes(elements) {
  if (isArray(elements)) {
    return elements.map(element => sanitizeAttributes(element))
  }

  if (isObject(elements)) {
    return {
      ...elements,
      attrs: sanitizeAttrValues(elements.attrs),
      content: sanitizeAttributes(elements.content)
    }
  }

  return elements

  function sanitizeAttrValues(attrs) {
    if (!isObject(attrs)) return attrs

    return Object.entries(attrs).reduce((acc, [attrName, attrValue]) => {
      const isAttrNameForbidden = attrName && !!attrName.match(/^on/)

      if (isAttrNameForbidden) {
        return {
          ...acc
        }
      }

      return {
        ...acc,
        [attrName]: attrValue
      }
    }, {})
  }
}

export function parseHtmlAndSanitizeAttributes(html) {
  return pipe(parseHtml, sanitizeAttributes, render)(html)
}
