export const SOURCE = {
  FM: 'FM',
  FMDir: 'FmDir',
  FL: 'FL',
  FL_DIR: 'FLDir'
}

export const SOURCE_VALUES = Object.values(SOURCE)

export const PROJECT_SITE = {
  FM: 'FM',
  FM_DIR: 'FMDir',
  FL: 'FL',
  FL_DIR: 'FLDir'
}
