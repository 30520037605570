export default {
  methods: {
    $_oneTrustListener_dispatchUpdate(activeGroups) {
      this.$store.dispatch(
        `one-trust/updateOptanonConsentActiveGroups`,
        activeGroups
      )
    }
  },
  mounted() {
    window.addEventListener('OneTrustGroupsUpdated', event => {
      this.$_oneTrustListener_dispatchUpdate(event.detail)
    })
  }
}
