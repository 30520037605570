export default {
  methods: {
    $_articleDataHandler_updateIsSavedValue(
      { id, value },
      articles = this.articles
    ) {
      return articles.map(article => ({
        ...article,
        ...(article.Id === id ? { IsSaved: value } : {})
      }))
    },
    $_articleDataHandler_resetActionIconValues(articles = this.articles) {
      return articles.map(article => ({
        ...article,
        IsSaved: false
      }))
    },
    $_articleDataHandler_getArticleIndexById({ id, articles }) {
      return articles.findIndex(({ articleId }) => articleId === id)
    },
    $_articleDataHandler_generatePaginationArray({
      initialPage,
      take,
      length,
      defaultTakeFn = null
    }) {
      if (!defaultTakeFn) {
        return this.$_articleDataHandler_generateGeneralPaginationArray({
          initialPage,
          take,
          length
        })
      } else {
        return this.$_articleDataHandler_generatePaginationArrayWithDefaultTake(
          {
            initialPage,
            length,
            defaultTakeFn
          }
        )
      }
    },
    $_articleDataHandler_generateGeneralPaginationArray({
      initialPage,
      take,
      length
    }) {
      const pageCount = Math.floor((length - 1) / take) + 1
      return this.$helper
        .createPrefilledArray(pageCount)
        .map((_, index) => ({ take, page: initialPage + index }))
    },
    $_articleDataHandler_generatePaginationArrayWithDefaultTake({
      initialPage,
      length,
      defaultTakeFn
    }) {
      const paginationArray = []
      let currentPage = initialPage
      let articleCount = 0
      do {
        const defaultTake = defaultTakeFn(currentPage)
        paginationArray.push({
          defaultTake,
          page: currentPage
        })
        articleCount += defaultTake
        currentPage++
      } while (articleCount < length)

      return paginationArray
    },
    async $_articleDataHandler_fetchLoadedArticles({
      action,
      initialPage,
      take,
      defaultTakeFn,
      length,
      payload = {}
    }) {
      const paginationArray = this.$_articleDataHandler_generatePaginationArray(
        { initialPage, take, defaultTakeFn, length }
      )

      const promises = paginationArray.map(pagination =>
        action({ ...payload, ...pagination })
      )
      return Promise.all(promises)
    },
    $_articleDataHandler_replaceArticleInArticles({
      articles,
      article,
      index
    }) {
      return this.$helper.replaceElementInArrayByIndex(articles, index, article)
    }
  }
}
