import { getWindowHeight, getWindowWidth } from '@/plugins/helper'

export default function({ store: { dispatch } }) {
  if (process.server) return

  const windowWidth = getWindowWidth()
  const windowHeight = getWindowHeight()

  dispatch('setWindowWidth', windowWidth)
  dispatch('setWindowHeight', windowHeight)
}
