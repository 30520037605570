import { REDIRECT_TYPE_CODES } from 'enums/seo-shared'
import {
  prettifyPath,
  getPageSourceByRouteName,
  removeAmpSuffix
} from '@/plugins/helper'
import { MIDDLEWARE } from 'enums/middleware'
import { SOURCE } from 'enums/source'
import { pipe } from 'ramda'

const ACTION_BY_PAGE_SOURCE = {
  [SOURCE.FL]: 'seo/requestFlRedirect',
  [SOURCE.FL_DIR]: 'seo/requestFlDirRedirect'
}

export default async function({ app, store, route, redirect }) {
  console.time(`seo-redirect-middleware - ${route.path}`)

  if (app.$helper.isMiddlewareSkipped(MIDDLEWARE.SEO_REDIRECT, route)) return

  try {
    if (route.query.previewId) return redirect()
    const pageSource = getPageSourceByRouteName(route.name)

    const from = pipe(prettifyPath, removeAmpSuffix)(route.path) || '/'
    const { Type, Value } = await store.dispatch(
      ACTION_BY_PAGE_SOURCE[pageSource],
      {
        from
      }
    )

    if (Type && Value) {
      const route = Value.replace(app.$env.DOMAIN_URL, '')
      redirect(REDIRECT_TYPE_CODES[Type], route)
    }
  } catch (err) {
    redirect()
  }
  console.timeEnd(`seo-redirect-middleware - ${route.path}`)
}

// TODO - do not remove - try to fix infinite loop redirects
// export default async function({ app, store, route, redirect, from, to }) {
//   const fromRoute = wrapIntoSlashes(prop('path', to) || route.path)
//   const { Type: type, Value: toRoute } = await store.dispatch(
//     'seo/requestRedirect',
//     { from: fromRoute }
//   )
//   console.log('from.path', prop('path', from))
//   console.log('to.path', prop('path', to))
//   console.log('route.path', route.path)
//   console.log('fromRoute', fromRoute)
//   console.log('toRoute')
//   console.log('lastRedirects', lastRedirects)
//   console.log('----------------------------------------')
//   if (!toRoute) {
//     lastRedirects.clear()
//     return redirect()
//   }
//   /** Last redirects check is needed to prevent the circle redirects.
//    * For example "all-news -> contact-us -> about -> all-news -> contact-us ..."
//    * **/
//   const toRouteWithoutDomain = toRoute.replace(app.$env.DOMAIN_URL, '')
//
//   if (fromRoute === toRouteWithoutDomain) {
//     lastRedirects.clear()
//     return redirect(toRouteWithoutDomain)
//   }
//
//   const isInternalUrl = toRoute.includes(app.$env.DOMAIN_URL)
//
//   if (type && toRoute && !lastRedirects.has(toRouteWithoutDomain)) {
//     if (isInternalUrl) {
//       lastRedirects.add(fromRoute)
//       lastRedirects.add(toRouteWithoutDomain)
//       redirect(REDIRECT_TYPE_CODES[type], toRouteWithoutDomain)
//     } else {
//       lastRedirects.clear()
//       redirect(REDIRECT_TYPE_CODES[type], toRoute)
//     }
//   }
// }
