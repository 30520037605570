import { isInteger } from '@/plugins/helper'

/**
 * Used to validate pages with pagination number
 */
export const paginatedPageValidation = {
  validate({ params: { number } }) {
    return !!number && isInteger(+number)
  }
}
