import { importRedirectSettings } from '@/utils/helpers/redirects/cache'
import { MIDDLEWARE } from 'enums/middleware'
import { REDIRECT_TYPE_CODES } from 'enums/seo-shared'
import { ROUTE } from 'enums/routes'

export default async function({ app, store, route, redirect }) {
  try {
    const isMiddlewareSkipped = app.$helper.isMiddlewareSkipped(
      MIDDLEWARE.MIGRATED_REDIRECTS,
      route
    )

    if (isMiddlewareSkipped) {
      return redirect()
    }

    const fullPath = route.fullPath || ROUTE.ALL_NEWS

    let redirectSettings

    if (process.client) {
      redirectSettings = await store.dispatch(
        'seo/requestMigratedRedirectSettings',
        {
          queryParams: { fullPath }
        }
      )
    } else {
      const getRedirectSettings = await importRedirectSettings()

      redirectSettings = await getRedirectSettings({ fullPath })
    }

    if (!redirectSettings) {
      return redirect()
    }

    const { to, type } = redirectSettings

    if (to === fullPath) {
      return redirect()
    }

    if (process.client) {
      window.history.pushState({}, '', window.location.href)
      window.location.replace(to)
      /**
       * We use "return new Promise" to stop the current navigation to the original
       * URL.
       * If we don't do this, the user will be redirected to the redirectFrom URL
       * first and only then to the redirectTo URL. It causes bad UX.
       */
      return new Promise(() => {})
    } else {
      /**
       * We use redirect only on the server side because on the client it rewrites
       * the current URL. Example:
       * 1. We are on the Homepage
       * 2. We open an article with a link
       * 3. We click the link
       * 4. redirect() replace the current URL with the new one
       * 5. If we click the Back button, we will get to the Homepage, not to the
       * article with the link.
       */
      redirect(REDIRECT_TYPE_CODES[type], to)
    }
  } catch (err) {
    redirect()
  }
}
