import { ERROR_CODE, ERROR_CODE_V2 } from 'enums/errorCodes'
import { CONTRIBUTOR_TYPES, USER_STATUS, USER_TYPE } from 'enums/users'

export const AUTH_ERROR_TYPE = {
  SIGN_IN: 'login error',
  SIGN_UP: 'registration error'
}

export const TRACKING_MESSAGE_GENERATOR_FN_BY_ERROR_CODE = {
  [ERROR_CODE_V2.USER_NOT_FOUND]: () =>
    'We could not find this account in our system. Please sign-up for an account',
  [ERROR_CODE_V2.USER_INVALID_CREDENTIALS]: () =>
    'Current password is incorrect',
  [ERROR_CODE_V2.EMAIL_IS_ALREADY_TAKEN]: () =>
    'User with this email already exists',
  [ERROR_CODE_V2.INVALID_LOGIN_CREDENTIALS]: () =>
    'The Password you have entered is incorrect',
  [ERROR_CODE_V2.USER_INVALID_STATE]: ({ Type, Status, IsMigrating }) => {
    const isContributor = CONTRIBUTOR_TYPES.includes(Type)
    const isSubscriber = Type === USER_TYPE.SUBSCRIBER
    const isDeactivated = Status === USER_STATUS.DEACTIVATED
    const isDraft = Status === USER_STATUS.DRAFT

    if (isDeactivated && isContributor && IsMigrating) {
      return 'To continue contributing content, you must reactivate your account. If you wish to reactivate it, click the button below and then confirm in our confirmation email.'
    }

    if (isDeactivated && isSubscriber && IsMigrating) {
      return 'Migrated subscriber first login attempt'
    }

    if (isDeactivated) {
      return 'Your account was recently deactivated. If you wish to reactivate it, click the button below and then confirm in our confirmation email.'
    }

    if (isDraft) {
      return 'Please confirm your account in the confirmation email in order to activate your account'
    }

    return ''
  },
  // TODO - awaiting BE implementation
  [ERROR_CODE.LOGIN_ATTEMPTS_LIMIT_EXCEDEED]: () =>
    'User exceeded login attempts limit. Please try to login later'
}

export const SUCCESS_AUTH_EVENT_TYPE = {
  SIGN_IN: 'login',
  SIGN_UP: 'registration'
}
