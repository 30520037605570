<template>
  <div class="amp-layout__wrapper">
    <amp-header />
    <div class="amp-layout__content-container">
      <div class="amp-layout__content">
        <nuxt />
      </div>
    </div>
    <amp-footer :last-modified="footerLastModified" />
  </div>
</template>

<script>
import AmpHeader from '~/components/_sections/amp/AmpHeader'
import AmpFooter from '~/components/_sections/amp/AmpFooter'
import { mapGetters } from 'vuex'

export default {
  name: 'AmpLayout',
  components: { AmpHeader, AmpFooter },
  computed: {
    ...mapGetters({
      footerMenuItemsLastModified: 'footerMenuItemsLastModified',
      stayConnectedLastModified: 'stayConnectedLastModified'
    }),
    footerLastModified() {
      return this.footerMenuItemsLastModified + this.stayConnectedLastModified
    }
  }
}
</script>

<style lang="scss">
.amp-layout__wrapper {
  max-width: 800px;
  margin: 0 auto;
}

.amp-layout__content-container {
  padding: 60px 16px 0; /* 60px - mobile header height */
}

.amp-layout__content {
  margin-top: 30px;
  position: relative;
}

amp-consent {
  background-color: $c--dark-semi-transparent;

  .amp-consent__popup {
    margin: 0 auto;
    position: relative;
    width: 100%;
    height: 100vh;

    .amp-active {
      display: none;
    }

    amp-iframe {
      background-color: transparent;
    }
  }
}
</style>
