/**
 * https://docs.prebid.org/
 */

export const PREBID_TIMEOUT = 1000

export const CURRENCY_MODULE_CONFIG = {
  priceGranularity: 'low',
  currency: {
    adServerCurrency: 'ILS',
    granularityMultiplier: 1,
    defaultRates: { USD: { ILS: 3.2 } }
  }
}

export const BIDS = [
  {
    bidder: 'appnexus',
    params: {
      placementId: 16794755
    }
  },
  {
    bidder: 'openx',
    params: {
      unit: '556669826',
      delDomain: 'adnimation-d.openx.net'
    }
  },
  {
    bidder: 'criteo',
    params: {
      networkId: '7314'
    }
  },
  {
    bidder: 'amx',
    params: {
      tagId: 'YWRuaW1hdGlvbi5jb20'
    }
  },
  {
    bidder: 'pubmatic',
    params: {
      publisherId: '160685'
    }
  },
  {
    bidder: 'sonobi',
    params: {
      placementId: 'aae3129c5a3793b07946'
    }
  }
]
