<template>
  <div class="gdpr_container">
    <a-checkbox
      class="gdpr__checkbox"
      :checkbox-style="checkboxStyle"
      :checkbox-size="checkboxSize"
      :value="value"
      :label="gdprWithDefault"
      :disabled="disabled"
      :error="error"
      label-as-html
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'

export { CHECKBOX_STYLE, CHECKBOX_SIZE } from 'shared/ACheckbox'

export default {
  name: 'AGdpr',
  props: {
    gdpr: propValidator([PROP_TYPES.STRING], false),
    error: propValidator([PROP_TYPES.OBJECT], false, () => ({})),
    value: propValidator([PROP_TYPES.BOOLEAN], false, false),
    disabled: propValidator([PROP_TYPES.BOOLEAN], false, false),
    checkboxStyle: propValidator([PROP_TYPES.STRING], false),
    checkboxSize: propValidator([PROP_TYPES.STRING], false)
  },
  computed: {
    gdprWithDefault() {
      return (
        this.gdpr ||
        `By submitting I agree to Forexlive’s <a href="${this.$env.DOMAIN_URL}/terms-of-use/" target="_blank">Terms, Cookies and Privacy Notice</a>.`
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.gdpr_container {
  .gdpr__checkbox {
    width: 100%;
    max-height: inherit;
    color: inherit;
  }
}
</style>
