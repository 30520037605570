/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'envelope-with-content': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data:
      '<g _fill="#FFF"><path pid="0" d="M18 7.139v-.002c0-.015 0-.03-.002-.045l-.001-.012-.005-.034-.003-.013a.273.273 0 00-.01-.041v-.004l-.015-.041-.006-.015-.012-.027-.008-.016-.021-.036-.009-.013-.018-.025-.01-.013-.03-.033-.002-.002a.347.347 0 00-.034-.032l-.005-.004-.899-.746V3.692c0-.679-.552-1.23-1.23-1.23h-3.016L10.234.444a1.936 1.936 0 00-2.469 0L5.335 2.46H2.32c-.678 0-1.23.552-1.23 1.23v2.294l-.9.746-.004.004a.34.34 0 00-.034.032l-.002.002-.03.033-.01.013-.018.025-.009.013-.021.036-.008.016-.012.027-.006.015c-.006.013-.01.027-.014.04l-.002.005-.01.041-.002.013-.005.034-.001.012L0 7.137v8.929c0 .553.233 1.051.606 1.404l.033.03c.343.31.797.5 1.295.5h14.132c.498 0 .952-.19 1.295-.5l.033-.03c.373-.352.606-.851.606-1.404V7.139zM2.32 3.516h13.36c.097 0 .175.079.175.176v4.102l-.298.247-5.996 4.976a.878.878 0 01-1.122 0L2.443 8.042l-.298-.248V3.692c0-.097.078-.176.175-.176zm-1.265 12.55V8.26l.227.188 5.15 4.274-5.323 3.647a.866.866 0 01-.054-.303zm6.22-2.644l.49.407a1.935 1.935 0 002.47 0l.49-.407 5.14 3.523H2.135l5.14-3.523zm4.294-.7l5.149-4.273.227-.189v7.806a.866.866 0 01-.054.303l-5.322-3.647zM8.439 1.257a.878.878 0 011.122 0l1.452 1.204H6.987L8.44 1.257z"/><path pid="1" d="M4.623 5.8c0 .292.236.528.527.528h4.993a.528.528 0 000-1.055H5.15a.528.528 0 00-.527.527zm.527 2.637h7.7a.528.528 0 000-1.055h-7.7a.529.529 0 000 1.055z"/></g>'
  }
})
