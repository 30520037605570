<template>
  <iframe
    v-if="isIframeVisible"
    :src="iframeSrc"
    class="google-analytics-cookies-iframe"
    @load="hideIframe"
  />
</template>

<script>
import Cookies from 'js-cookie'

import { PROLONGATE_GA_COOKIE_LIFETIME } from '~/config/serverMiddleware'
import { GA_COOKIE_NAME } from '~/server/enums'

const POLL_TIMEOUT = 60 * 1000

export default {
  name: 'AGoogleAnalyticsCookiesIframe',
  data() {
    return {
      isIframeVisible: false
    }
  },
  computed: {
    iframeSrc() {
      return `${this.$env.DOMAIN_URL}${PROLONGATE_GA_COOKIE_LIFETIME}`
    }
  },
  methods: {
    showIframe() {
      this.isIframeVisible = true
    },
    hideIframe() {
      this.isIframeVisible = false
    },
    pollGaCookie() {
      this.$helper.pollUntil({
        fn: this.showIframe,
        condition: () => !!Cookies.get(GA_COOKIE_NAME),
        timeout: POLL_TIMEOUT
      })
    }
  },
  mounted() {
    // if (Cookies.get('prolongateGaCookieLifetime')) {
    //   this.pollGaCookie()
    // }
    this.pollGaCookie()
  }
}
</script>

<style scoped>
.google-analytics-cookies-iframe {
  width: 0;
  height: 0;
  visibility: hidden;
  position: absolute;
  right: -5000px;
}
</style>
