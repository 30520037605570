import { PENDING_ACTION_EVENT } from 'enums/pending-actions'

export default handlerByEvent => {
  if (!process.client) return {}

  const generateRefreshHandlersByActionEvent = ctx => {
    return Object.entries(handlerByEvent).reduce(
      (acc, [actionEvent, value]) => {
        const handler = ctx[value]

        return { ...acc, [actionEvent]: handler }
      },
      {}
    )
  }

  return {
    data() {
      return {
        refreshHandlerByEvent: generateRefreshHandlersByActionEvent(this),
        listenerRemovers: []
      }
    },
    methods: {
      $_pendingAuthActionsListeners_listenForPendingActionsUpdate() {
        Object.values(PENDING_ACTION_EVENT).forEach(actionEvent => {
          const updateHandler = this.refreshHandlerByEvent[actionEvent]
          if (this.$helper.checkIfFunction(updateHandler)) {
            this.$bus.$on(actionEvent, updateHandler)
            this.listenerRemovers.push(() => {
              this.$bus.$off(actionEvent, updateHandler)
            })
          }
        })
      }
    },
    beforeMount() {
      this.$_pendingAuthActionsListeners_listenForPendingActionsUpdate()
    },
    beforeDestroy() {
      this.listenerRemovers.forEach(remover => {
        remover()
      })
    }
  }
}
