export const ICON = {
  ENLARGE: 'enlarge',
  SUCCESS_ROUND_BLUE: 'success-round-blue',
  MORE_INFO_ROUND_BLUE: 'more-info-round-blue',
  SENT_LETTER: 'sent-letter',
  NEWSLETTER_WHITE: 'newsletter-white',
  ENVELOPE: 'envelope',
  ENVELOPE_2: 'envelope-2',
  ENVELOPE_WITH_CONTENT: 'envelope-with-content',
  ENVELOPE_ROUND_BLACK: 'envelope-round-black',
  ENVELOPE_ROUND_BLUE: 'envelope-round-blue',
  ENVELOPE_ROUND_GRAY: 'envelope-round-gray',
  ENVELOPE_RECT_BLUE: 'envelope-rect-blue',
  NEWSLETTER_RECT_BLUE: 'newsletter-rect-blue',
  TWITTER: 'twitter',
  TWITTER_ROUND_BLACK: 'twitter-round-black',
  TWITTER_ROUND_BLUE: 'twitter-icon-color',
  THUMB_UP: 'thumb-up',
  LINKEDIN: 'linkedin',
  LINKEDIN_ROUND_BLACK: 'linkedin-round-black',
  LINKEDIN_ROUND_BLUE: 'linkedin-round-blue',
  TELEGRAM: 'telegram',
  TELEGRAM_ROUND_BLACK: 'telegram-round-black',
  TELEGRAM_ROUND_BLUE: 'telegram-round-blue',
  FACEBOOK: 'facebook',
  FACEBOOK_ROUND_BLACK: 'facebook-round-black',
  FACEBOOK_ROUND_BLUE: 'facebook-round-blue',
  YOUTUBE_ROUND_BLACK: 'youtube-round-black',
  WHATSUP_ROUND_GREEN: 'whatsapp-round-green',
  WHATSUP_ROUND_BLACK: 'whatsapp-round-black',
  SAVE_ROUND_BLACK: 'save-round-black',
  SAVE_WHITE: 'save-white',
  TRIANGLE_IN_CIRCLE_TO_RIGHT: 'triangle-in-circle-to-right',
  SHARE: 'share',
  LOCK: 'lock',
  GOOGLE: 'google',
  FILTER: 'filter',
  PENCIL: 'pencil',
  SEARCH: 'search',
  MAGNIFIER: 'magnifier',
  MAGNIFIER_2: 'magnifier-2',
  ADD_COMPANY_RECT_GREEN: 'add-company-rect-green',
  PIN: 'pin',
  FL: 'fl',
  REGISTRATION_COMPLETE: 'registration-complete',
  RSS: 'rss',
  RSS_ORANGE: 'rss-orange',
  PLUS: 'plus',
  COMMENT_COUNT_ROUND_BLACK: 'comments-count-round-black',
  REDDIT_ROUND_ORANGE: 'reddit-round-orange',
  REDDIT_ROUND_BLACK: 'reddit-round-black',
  EYE: 'eye',
  FILM: 'film-icon',
  ARROW_RIGHT_WHITE: 'white-arrow-right',
  BELL_ACTIVE: 'bell-active',
  BELL_INACTIVE: 'bell-inactive',
  ONE_SIGNAL_ACTIVE: 'one-signal-active',
  ONE_SIGNAL_INACTIVE: 'one-signal-inactive',
  REDO: 'redo',
  WALKING_FINGERS_ROUND_BLACK: 'walking-fingers-round-black',
  LABEL: 'label',
  DOWNLOAD: 'download',
  UPLOADED_ROUND_GREEN: 'uploaded-round-green',
  UPLOAD_ROUND_BLACK: 'upload-round-black',
  COMPARE_BROKERS: 'compare-brokers-icon',
  EDUCATION_FROM_BROKERS: 'education-from-broke-icon',
  TRUSTED_GURUS: 'trusted-gurus-icon',
  UNUSUAL_MARKETS: 'unusual-markets-icon',
  MARKET_WISDOM: 'market-wisdom-icon',
  ECONOMICS: 'economics-icon',
  VIDEO: 'videos-icon',
  TRADING_TECHNIQUES: 'trading-techniques-icon',
  WAR_STORIES: 'war-stories-icon',
  TRADING_PSYCHOLOGY: 'trading-psychology-icon',
  BASICS: 'basics-icon',
  FOREX_OPTIONS: 'forex-options-icon',
  TECHNICAL_ANALYSIS: 'technical-analysis-icon',
  FUNDAMENTAL_STRATEGY: 'fundamental-strategy-icon',
  TRADING_RULES: 'trading-rules-icon',
  EDUCATION_HUB_DESKTOP: 'education-hub-desktop',
  EDUCATION_HUB_MOBILE: 'education-hub-mobile',
  PREMIUM: 'premium',
  EDUCATION_HUB_BANNER_LG: 'education-hub-banner-lg',
  EDUCATION_HUB_BANNER_SM: 'education-hub-banner-sm'
}
