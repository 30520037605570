import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      accessToken: 'auth/accessToken',
      userDetails: 'auth/userDetails'
    })
  },
  methods: {
    $_authTracking_trackAuthentication({ event, method }) {
      const userId = this.$_authTracking_getUserId()

      if (!userId) {
        console.warn(
          'Authentication was not tracked due to the absence of userId'
        )

        return
      }

      window.dataLayer &&
        window.dataLayer.push({
          event,
          authenticationMethod: method,
          userId
        })
    },
    $_authTracking_getUserId() {
      return (
        this.userDetails?.Id ||
        this.$helper.getUserDetailsFromAccessToken(this.accessToken)?.Id ||
        null
      )
    }
  }
}
