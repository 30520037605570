import { render, staticRenderFns } from "./error-layout.vue?vue&type=template&id=d6f34482&scoped=true&"
import script from "./error-layout.vue?vue&type=script&lang=js&"
export * from "./error-layout.vue?vue&type=script&lang=js&"
import style0 from "./error-layout.vue?vue&type=style&index=0&id=d6f34482&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/nuxt/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6f34482",
  null
  
)

export default component.exports