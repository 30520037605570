import { mapActions } from 'vuex'

const ONLINE_EVENT = 'online'
const OFFLINE_EVENT = 'offline'

export default {
  methods: {
    ...mapActions({
      setIsOnlineState: 'setIsOnlineState'
    }),
    $_internetConnectionState_setIsOnlineState(
      isOnline = window.navigator.onLine
    ) {
      this.setIsOnlineState(isOnline)
    },
    $_internetConnectionState_setOnlineState() {
      this.$_internetConnectionState_setIsOnlineState(true)
    },
    $_internetConnectionState_setOfflineState() {
      this.$_internetConnectionState_setIsOnlineState(false)
    },
    $_internetConnectionState_registerListeners() {
      window.addEventListener(
        ONLINE_EVENT,
        this.$_internetConnectionState_setOnlineState
      )
      window.addEventListener(
        OFFLINE_EVENT,
        this.$_internetConnectionState_setOfflineState
      )
    },
    $_internetConnectionState_removeListeners() {
      window.removeEventListener(
        ONLINE_EVENT,
        this.$_internetConnectionState_setOnlineState
      )
      window.removeEventListener(
        OFFLINE_EVENT,
        this.$_internetConnectionState_setOfflineState
      )
    }
  },
  mounted() {
    this.$_internetConnectionState_setIsOnlineState()
    this.$_internetConnectionState_registerListeners()
  },
  beforeDestroy() {
    this.$_internetConnectionState_removeListeners()
  }
}
