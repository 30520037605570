import {
  checkIfParsedElementIsFigure,
  checkIfParsedElementIsIframe,
  checkIfParsedElementIsImage,
  checkIfParsedElementIsLink,
  checkIfParsedElementIsScript,
  processHtml
} from '@fmpedia/html-tools'

export {
  checkIfParsedElementIsFigure,
  checkIfParsedElementIsIframe,
  checkIfParsedElementIsImage,
  checkIfParsedElementIsLink,
  checkIfParsedElementIsScript,
  processHtml
}
