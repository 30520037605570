export const ARTICLE_SLOT_MODEL = {
  Id: '',
  Title: '',
  ExpandedContent: '',
  Slug: '',
  Tldr: '[]',
  PublishedOn: '',
  ModifiedOn: '',
  IsPinned: false,
  IsSessionWrap: false,
  IsMigrated: false,
  Category: {
    Name: '',
    Slug: ''
  },
  Author: {
    Id: '',
    Name: '',
    Slug: '',
    PhotoUrl: null
  },
  SponsorshipCompany: null,
  ThumbnailImage: null,
  FeaturedVideo: null,
  Terms: []
}
