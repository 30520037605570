/**
 * OneTrust
 * @link https://app-eu.onetrust.com/
 */
import {
  pollUntil,
  getOptanonActiveGroups,
  isArray,
  isPreviewMode
} from '@/plugins/helper'
import { REQUIRED_COOKIE_CATEGORIES_BY_COOKIE_ENTITY_TYPE } from 'enums/oneTrust'

export function toggleCookiePreferencesPopup() {
  if (isPreviewMode()) return

  pollUntil({
    fn: window.OneTrust.ToggleInfoDisplay,
    condition: () => !!window.OptanonWrapperTriggered
  })
}

export function initializeCookiePolicyHtml() {
  if (isPreviewMode()) return

  pollUntil({
    fn: window.OneTrust.initializeCookiePolicyHtml,
    condition: () => !!window.OptanonWrapperTriggered
  })
}

function isConsentGivenForGroups(groupIds = []) {
  if (process.server) return false

  return groupIds.every(groupId => getOptanonActiveGroups().includes(groupId))
}

/**
 * Checks if prior blocking of a specific entity is required
 *
 * (!) Important note: for legal purposes,  we block every script/iframe
 * in case the site is being previewed from FM. In case when site is being
 * previewed from BO, we don't block anything.
 * @param entity
 */
export function isConsentGivenForEntity(entity) {
  if (!entity) return false

  const requiredGroups =
    REQUIRED_COOKIE_CATEGORIES_BY_COOKIE_ENTITY_TYPE[entity]

  if (!requiredGroups || !isArray(requiredGroups)) return true

  return isConsentGivenForGroups(requiredGroups)
}

export default {
  methods: {
    $_oneTrust_isConsentGivenForEntity: isConsentGivenForEntity,
    $_oneTrust_initializeCookiePolicyHtml: initializeCookiePolicyHtml,
    $_oneTrust_toggleCookiePreferencesPopup: toggleCookiePreferencesPopup
  }
}
