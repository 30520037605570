import api from '@/utils/api'
import { generateArticleSlotResponse } from '@/utils/response-handling/article'

const state = () => ({})

const getters = {}

const actions = {
  async requestTermBySlug(_, params) {
    try {
      const { data } = await this.$axios.get(api.terms.getTerm(), { params })
      return data
    } catch (e) {
      throw e
    }
  },
  async requestTermsByLetter(_, params) {
    try {
      const { data } = await this.$axios.get(api.terms.getTermsByLetter(), {
        params
      })
      return data
    } catch (e) {
      throw e
    }
  },
  async requestTermOfTheDay() {
    try {
      const { data } = await this.$axios.get(api.terms.getTermOfTheDay())
      return data
    } catch (e) {
      throw e
    }
  },
  async requestRelatedArticles(_, params) {
    try {
      const { data } = await this.$axios.get(
        api.articles.getArticleByTermSlug(),
        {
          params
        }
      )
      data.Articles = data.Articles.map(article =>
        generateArticleSlotResponse(article)
      )
      return data
    } catch (e) {
      throw e
    }
  },
  async requestCreateUserSuggestion(_, { payload, captchaKey }) {
    const settings = {
      headers: { GoogleCaptchaResponseKey: captchaKey }
    }

    try {
      await this.$axios.post(
        api.terms.createUserSuggestion(),
        payload,
        settings
      )
    } catch (e) {
      throw e
    }
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
