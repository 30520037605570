import apiV2 from '~/utils/apiV2'
import { forceUrlFetch } from '@/plugins/helper'

export const state = {}

export const getters = {}

export const actions = {
  async requestCurrentCountryInfo({ commit }) {
    try {
      const {
        data: { Country }
      } = await this.$fmAxiosV2.get(
        forceUrlFetch(apiV2.countries.getCurrentCountyInfo())
      )

      return Country
    } catch (err) {
      throw err
    }
  }
}

export const mutations = {}
