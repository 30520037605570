<template>
  <a-icon :icon="ICON.FL" original :width="100" :height="20" />
</template>

<script>
import AIcon, { ICON } from 'shared/AIcon'

export default {
  name: 'FooterLogo',
  components: { AIcon },
  data() {
    return { ICON }
  }
}
</script>
