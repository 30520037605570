/**
 * Api available routes
 */

const auth = {
  login() {
    return 'public/login'
  },
  refreshToken() {
    return 'public/refresh-token'
  },
  register() {
    return 'public/registration'
  }
}

const users = {
  slug: 'users',
  loginWithEmail: () => `${users.slug}/login`,
  getAuthStatus: () => `${users.slug}/get-auth-status`,
  getCurrentUserDetails: () => `${users.slug}/get-current-user-details`,
  registrationWithEmail: () => `${users.slug}/register`,
  forgotPassword: () => `${users.slug}/forgot-password`,
  resetPassword: () => `${users.slug}/reset-password`,
  registrationWithFacebook: () => `${users.slug}/facebook/register`,
  loginWithFacebook: () => `${users.slug}/facebook/login`,
  registrationWithGoogle: () => `${users.slug}/google/register`,
  loginWithGoogle: () => `${users.slug}/google/login`,
  twitterAuthUrl: () => `${users.slug}/twitter/request-auth-url`,
  twitterRequestAccessToken: () => `${users.slug}/twitter/request-access-token`,
  twitterGetUserDetails: () => `${users.slug}/twitter/get-user-details`,
  twitterLogin: () => `${users.slug}/twitter/login`,
  twitterRegister: () => `${users.slug}/twitter/register`,
  getOnboardingDetails: () => `${users.slug}/get-onboarding-details`,
  registerUserSession: () => `${users.slug}/register-user-session`,
  requestUserProfileReactivation: () =>
    `${users.slug}/request-user-profile-reactivation`,
  reactivateUserProfile: () => `${users.slug}/reactivate-user-profile`,
  reactivateFacebookUserProfile: () =>
    `${users.slug}/facebook/reactivate-user-profile`,
  reactivateGoogleUserProfile: () =>
    `${users.slug}/google/reactivate-user-profile`,
  reactivateTwitterUserProfile: () =>
    `${users.slug}/twitter/reactivate-user-profile`,
  followCompanies: () => `${users.slug}/follow-companies`,
  unfollowCompanies: () => `${users.slug}/unfollow-companies`,
  followTags: () => `${users.slug}/follow-tags`,
  unfollowTags: () => `${users.slug}/unfollow-tags`,
  saveArticleForLater: () => `${users.slug}/save-article-for-later`,
  deleteSavedArticle: () => `${users.slug}/delete-saved-article`,
  updateProfileInfo: () => `${users.slug}/update-profile-info`,
  saveDirectoryArticleForLater: () =>
    `${users.slug}/save-directory-article-for-later`,
  deleteDirectorySavedArticle: () =>
    `${users.slug}/delete-directory-saved-article`,
  saveSearchHistory: () => `${users.slug}/save-search-history`,
  getAuthor: () => `${users.slug}/get-author`,
  getAuthorArticles: () => `${users.slug}/get-author-articles`,
  followAuthors: () => `${users.slug}/follow-authors`,
  unfollowAuthors: () => `${users.slug}/unfollow-authors`
}

const layout = {
  slug: 'layout',
  getHeaderMenuList: () => `${layout.slug}/get-header`,
  getFooterMenuList: () => `${layout.slug}/get-footer`,
  getTermsOfUse: () => `${layout.slug}/get-terms-of-use-page`,
  getPrivacyNotice: () => `${layout.slug}/get-privacy-notice-page`,
  getBecomeAContributerExpertises: () =>
    `${layout.slug}/get-become-a-contributor-expertises`,
  getStayConnecetd: () => `${layout.slug}/get-stay-connected-info`,
  getSidebarMustReadItems: () => `${layout.slug}/sidebar/get-must-read-items`,
  getSidebarCryptocurrencyMustReadItems: () =>
    `${layout.slug}/sidebar/get-cryptocurrency-must-read-items`,
  getButtonsSettings: () => `${layout.slug}/widget/get-buttons-settings`,
  getEbook: () => `${layout.slug}/get-ebook`
}

const seo = {
  slug: 'seo',
  getRedirect: () => `${seo.slug}/get-redirect`,
  getPageSeo: () => `${seo.slug}/get-page-seo`
}

const homePage = {
  slug: 'directory-homepage',
  getHomePageData() {
    return `${this.slug}/get-homepage`
  }
}

const directory = {
  slug: 'directory',
  createLead: () => `${directory.slug}/company/submit-contact-us-directly-lead`,
  quickSearch: () => `${directory.slug}/globalsearch/quick-search`,
  getAllResults: () => `${directory.slug}/globalsearch/view-all-results`,
  getCategoriesForNavigation: () =>
    `${directory.slug}/get-categories-for-navigation`
}

const directorySeo = {
  slug: 'directory/seo',
  getRedirect: () => `${directorySeo.slug}/get-redirect`,
  getPageSeo: () => `${directorySeo.slug}/get-page-seo`
}

const globalSearch = {
  slug: 'globalsearch',
  quickSearch: () => `${globalSearch.slug}/quick-search`,
  termsQuickSearch: () => `${globalSearch.slug}/terms-quick-search`,
  termsFullSearch: () => `${globalSearch.slug}/terms-full-search`,
  getAllResults: () => `${globalSearch.slug}/view-all-results`,
  tagsQuickSearch: () => `${globalSearch.slug}/tags-quick-search`,
  tagsFullSearch: () => `${globalSearch.slug}/tags-full-search`,
  companiesQuickSearch: () => `${globalSearch.slug}/companies-quick-search`
}

const directoryCompany = {
  slug: 'directory/company',
  getCompanyProfile: () => `${directoryCompany.slug}/get-company`,
  getCompanyPreview: () => `${directoryCompany.slug}/get-company-preview`,
  removeCompanyPreview: () => `${directoryCompany.slug}/remove-company-preview`
}

const directoryCategories = {
  slug: 'directory/categories',
  getCategoryFilters: () => `${directoryCategories.slug}/get-filters`,
  getCategoryCompanies: () => `${directoryCategories.slug}/companies`
}

const directoryNews = {
  slug: 'directory/articles',
  getArticleBySlug: () => `${directoryNews.slug}/get-article-by-slug`,
  getCompanyNews: () => `${directoryNews.slug}/get-company-news`,
  getTradingNews: () => `${directoryNews.slug}/get-trading-news`,
  getPrFeed: () => `${directoryNews.slug}/get-pr-feed`
}

const leads = {
  slug: 'leads',
  submitContactUsLead: () => `${leads.slug}/submit-contact-us-lead`,
  newsletterSubscription: () => `${leads.slug}/newsletter-subscription`,
  getSubscriptionSettings: () => `${leads.slug}/get-subscription-settings`,
  becomeAContributor: () => `${leads.slug}/become-a-contributor`,
  becomeAContributorFileUpload: () =>
    `${leads.slug}/become-a-contributor-file-upload`,
  submitEbookLead: () => `${leads.slug}/submit-ebook-lead`,
  verifyPhone: () => `${leads.slug}/verify-phone`
}

const publicInfo = {
  slug: 'public',
  getCountryCode: () => `${publicInfo.slug}/countries/calling-code`
}

const notifications = {
  slug: 'notifications',
  getBellIconItems: () => `${notifications.slug}/get-bell-icon-items`,
  markReadBellIcon: () => `${notifications.slug}/mark-read-bell-icon`,
  deleteBellIconItem: () => `${notifications.slug}/delete-bell-icon-item`,
  clearAllBellIcon: () => `${notifications.slug}/clear-all-bell-icon`
}

const videos = {
  slug: 'directory/videos',
  getVideos: () => `${videos.slug}/get-videos`
}

const terms = {
  slug: 'terms',
  getTerm: () => `${terms.slug}/get-term`,
  getTermOfTheDay: () => `${terms.slug}/get-term-of-the-day`,
  getTermsByLetter: () => `${terms.slug}/get-terms-by-letter`,
  createUserSuggestion: () => `${terms.slug}/create-user-suggestion`
}

const tags = {
  slug: 'tags',
  getTag: () => `articles/get-tag`
}

const articles = {
  slug: 'articles',
  getAllNews: () => `${articles.slug}/get-all-news`,
  getArticleByTagSlug: () => `${articles.slug}/get-articles-by-tag-slug`,
  getArticleByTermSlug: () => `${articles.slug}/get-articles-by-term-slug`,
  getArticleByLegacySlug: legacySlug => `${articles.slug}/slugs/${legacySlug}`,
  getArticleContentBySlug: () => `${articles.slug}/get-article-content-by-slug`,
  getPreviewArticleContent: () =>
    `${articles.slug}/get-preview-article-content`,
  deletePreviewArticleContent: () =>
    `${articles.slug}/delete-preview-article-content`,
  getPreviewArticleEbook: () => `${articles.slug}/get-preview-article-ebook`,
  getArticlesByCategory: () => `${articles.slug}/get-articles-by-category`,
  getSessionWrapArticles: () => `${articles.slug}/get-session-wrap-articles`,
  getMostCommentedArticles: () => `${articles.slug}/get-most-commented-articles`
}

const categories = {
  slug: 'categories',
  getCategoryVideo: () => `${categories.slug}/get-category-video`,
  getSessionWrapsVideo: () => `${categories.slug}/get-session-wraps-video`,
  getAllNewsVideo: () => `${categories.slug}/get-all-news-video`
}

const assets = {
  slug: 'assets',
  getStocks: () => `${assets.slug}/stocks`,
  getStock: symbol => `${assets.slug}/stocks/${symbol}`
}

export default {
  auth,
  users,
  layout,
  seo,
  homePage,
  directory,
  globalSearch,
  directoryCompany,
  directoryCategories,
  directoryNews,
  leads,
  publicInfo,
  notifications,
  videos,
  terms,
  tags,
  articles,
  categories,
  directorySeo,
  assets
}
