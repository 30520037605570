import { ROUTE, ROUTE_NAME } from 'enums/routes'
import { handleCategorySlug, MIGRATED_CATEGORY_SLUG } from 'enums/categories'

export const EBOOK_LOCATION_TYPE = {
  ALL_NEWS: 'AllNews',
  ARTICLE: 'CategoryArticles',
  CATEGORY: 'Categories',
  LIVE_QUOTES: 'LiveQuotes',
  LIVE_CHARTS: 'LiveCharts',
  ECONOMIC_CALENDAR: 'EconomicCalendar'
}

export const PAGES_WITH_EBOOK = [
  ROUTE_NAME.ALL_NEWS,
  ROUTE_NAME.EDUCATION,
  ROUTE_NAME.CATEGORY,
  ROUTE_NAME.CATEGORY_ARTICLE,
  ROUTE_NAME.LIVE_QUOTES,
  ROUTE_NAME.LIVE_CHARTS,
  ROUTE_NAME.ECONOMIC_CALENDAR
]

export const GET_LOCATION_TYPE_BY_ROUTE_NAME = {
  [ROUTE_NAME.ALL_NEWS]: EBOOK_LOCATION_TYPE.ALL_NEWS,
  [ROUTE_NAME.EDUCATION]: EBOOK_LOCATION_TYPE.CATEGORY,
  [ROUTE_NAME.SESSION_WRAPS]: EBOOK_LOCATION_TYPE.CATEGORY,
  [ROUTE_NAME.CATEGORY]: EBOOK_LOCATION_TYPE.CATEGORY,
  [ROUTE_NAME.CATEGORY_ARTICLE]: EBOOK_LOCATION_TYPE.ARTICLE,
  [ROUTE_NAME.LIVE_QUOTES]: EBOOK_LOCATION_TYPE.LIVE_QUOTES,
  [ROUTE_NAME.LIVE_QUOTES_ASSET]: EBOOK_LOCATION_TYPE.LIVE_QUOTES,
  [ROUTE_NAME.LIVE_CHARTS]: EBOOK_LOCATION_TYPE.LIVE_CHARTS,
  [ROUTE_NAME.ECONOMIC_CALENDAR]: EBOOK_LOCATION_TYPE.ECONOMIC_CALENDAR
}

export const GET_VALUE_METHOD_BY_ROUTE_NAME = {
  [ROUTE_NAME.ALL_NEWS]: () => ROUTE.ALL_NEWS,
  [ROUTE_NAME.EDUCATION]: () => MIGRATED_CATEGORY_SLUG.EDUCATION,
  [ROUTE_NAME.SESSION_WRAPS]: () => MIGRATED_CATEGORY_SLUG.NEWS,
  [ROUTE_NAME.CATEGORY]: ({ category }) => category,
  [ROUTE_NAME.CATEGORY_ARTICLE]: ({ category, article }) =>
    `${handleCategorySlug(category)}/${article.toLowerCase()}`,
  [ROUTE_NAME.LIVE_QUOTES]: () => ROUTE_NAME.LIVE_QUOTES,
  [ROUTE_NAME.LIVE_QUOTES_ASSET]: () => ROUTE_NAME.LIVE_QUOTES,
  [ROUTE_NAME.LIVE_CHARTS]: () => ROUTE_NAME.LIVE_CHARTS,
  [ROUTE_NAME.ECONOMIC_CALENDAR]: () => ROUTE_NAME.ECONOMIC_CALENDAR
}
