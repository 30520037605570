import { ROUTE_NAME } from 'enums/routes'

export const FL_DIR_GROUP_TYPE = {
  DIRECTORY_CATEGORY: 'DirectoryCategory',
  DIRECTORY_COMPANY: 'Company'
}

const FL_DIR_GROUP_TYPES_BY_ROUTE_NAME = {
  [ROUTE_NAME.FOREX_BROKERS]: [FL_DIR_GROUP_TYPE.DIRECTORY_CATEGORY],
  [ROUTE_NAME.DIRECTORY_COMPANY]: [FL_DIR_GROUP_TYPE.DIRECTORY_COMPANY]
}

export const getFlDirGroupTypesByPageName = pageName =>
  FL_DIR_GROUP_TYPES_BY_ROUTE_NAME[pageName]

const FL_DIR_TEMPLATE_PARAM = {
  [FL_DIR_GROUP_TYPE.DIRECTORY_CATEGORY]: {
    META_TITLE: 'DIRECTORY_CATEGORY_NAME',
    META_DESCRIPTION: 'DIRECTORY_CATEGORY_NAME'
  },
  [FL_DIR_GROUP_TYPE.DIRECTORY_COMPANY]: {
    META_TITLE: 'COMPANY_NAME',
    META_DESCRIPTION: 'COMPANY_NAME'
  }
}

const FL_DIR_TEMPLATE_PARAMS_LIST = Object.values(FL_DIR_TEMPLATE_PARAM)

export const FL_DIR_META_TITLE_PARAMS_REGEX = new RegExp(
  FL_DIR_TEMPLATE_PARAMS_LIST.map(({ META_TITLE }) => META_TITLE).join('|'),
  'g'
)

export const FL_DIR_META_DESCRIPTION_PARAMS_REGEX = new RegExp(
  FL_DIR_TEMPLATE_PARAMS_LIST.map(
    ({ META_DESCRIPTION }) => META_DESCRIPTION
  ).join('|'),
  'g'
)
