import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _74569d52 = () => interopDefault(import('../pages/become-an-author/index.vue' /* webpackChunkName: "pages/become-an-author/index" */))
const _0cce6818 = () => interopDefault(import('../pages/contact-us/index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _74c9a8d8 = () => interopDefault(import('../pages/cookies/index.vue' /* webpackChunkName: "pages/cookies/index" */))
const _7c98cadd = () => interopDefault(import('../pages/EconomicCalendar.vue' /* webpackChunkName: "pages/EconomicCalendar" */))
const _72ab830e = () => interopDefault(import('../pages/Education.vue' /* webpackChunkName: "pages/Education" */))
const _9b02ad2a = () => interopDefault(import('../pages/ForexBrokers/index.vue' /* webpackChunkName: "pages/ForexBrokers/index" */))
const _37296ece = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _b389bca4 = () => interopDefault(import('../pages/LiveCharts/index.vue' /* webpackChunkName: "pages/LiveCharts/index" */))
const _50180c50 = () => interopDefault(import('../pages/LiveQuotes/index.vue' /* webpackChunkName: "pages/LiveQuotes/index" */))
const _753932de = () => interopDefault(import('../pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _54cc4b94 = () => interopDefault(import('../pages/reactivation/index.vue' /* webpackChunkName: "pages/reactivation/index" */))
const _0d6930f4 = () => interopDefault(import('../pages/reset-password/index.vue' /* webpackChunkName: "pages/reset-password/index" */))
const _2441afdb = () => interopDefault(import('../pages/rss/index.vue' /* webpackChunkName: "pages/rss/index" */))
const _1657b604 = () => interopDefault(import('../pages/search-results/index.vue' /* webpackChunkName: "pages/search-results/index" */))
const _4a5a32c0 = () => interopDefault(import('../pages/SessionWraps/index.vue' /* webpackChunkName: "pages/SessionWraps/index" */))
const _69705276 = () => interopDefault(import('../pages/SignUp/index.vue' /* webpackChunkName: "pages/SignUp/index" */))
const _1130774a = () => interopDefault(import('../pages/stocks/index.vue' /* webpackChunkName: "pages/stocks/index" */))
const _500e56e6 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _44b60560 = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _a3dd727a = () => interopDefault(import('../pages/terms/search-results.vue' /* webpackChunkName: "pages/terms/search-results" */))
const _f98f3a7a = () => interopDefault(import('../pages/terms/_letter.vue' /* webpackChunkName: "pages/terms/_letter" */))
const _89dae9f4 = () => interopDefault(import('../pages/terms/_letter/index.vue' /* webpackChunkName: "pages/terms/_letter/index" */))
const _abb10a02 = () => interopDefault(import('../pages/terms/_letter/_term.vue' /* webpackChunkName: "pages/terms/_letter/_term" */))
const _b957b678 = () => interopDefault(import('../pages/terms-of-use/index.vue' /* webpackChunkName: "pages/terms-of-use/index" */))
const _497a1270 = () => interopDefault(import('../pages/Tag/search-results.vue' /* webpackChunkName: "pages/Tag/search-results" */))
const _ba03e03e = () => interopDefault(import('../pages/auth/google/callback/index.vue' /* webpackChunkName: "pages/auth/google/callback/index" */))
const _c49eece0 = () => interopDefault(import('../pages/author/_author.vue' /* webpackChunkName: "pages/author/_author" */))
const _3566e740 = () => interopDefault(import('../pages/Brokers/_company/index.vue' /* webpackChunkName: "pages/Brokers/_company/index" */))
const _455d8326 = () => interopDefault(import('../pages/LiveQuotes/_asset/index.vue' /* webpackChunkName: "pages/LiveQuotes/_asset/index" */))
const _44671f62 = () => interopDefault(import('../pages/stocks/_stock.vue' /* webpackChunkName: "pages/stocks/_stock" */))
const _68cefc3a = () => interopDefault(import('../pages/Tag/_tag.vue' /* webpackChunkName: "pages/Tag/_tag" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1e6ca7a6 = () => interopDefault(import('../pages/_category/index.vue' /* webpackChunkName: "pages/_category/index" */))
const _51360056 = () => interopDefault(import('../pages/_category/_article/index.vue' /* webpackChunkName: "pages/_category/_article/index" */))
const _385003cb = () => interopDefault(import('../pages/_category/_article/amp/index.vue' /* webpackChunkName: "pages/_category/_article/amp/index" */))
const _4f845bcd = () => interopDefault(import('../pages/_category/_article/index' /* webpackChunkName: "pages/_category/_article/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/become-an-author",
    component: _74569d52,
    name: "become-an-author"
  }, {
    path: "/contact-us",
    component: _0cce6818,
    name: "contact-us"
  }, {
    path: "/cookies",
    component: _74c9a8d8,
    name: "cookies"
  }, {
    path: "/EconomicCalendar",
    component: _7c98cadd,
    name: "EconomicCalendar"
  }, {
    path: "/Education",
    component: _72ab830e,
    alias: ["/Education/page/:number(\\d+)"],
    name: "Education"
  }, {
    path: "/ForexBrokers",
    component: _9b02ad2a,
    alias: ["/ForexBrokers/page/:number(\\d+)"],
    name: "ForexBrokers"
  }, {
    path: "/forgot-password",
    component: _37296ece,
    name: "forgot-password"
  }, {
    path: "/LiveCharts",
    component: _b389bca4,
    alias: ["/LiveCharts/(/*)"],
    name: "LiveCharts"
  }, {
    path: "/LiveQuotes",
    component: _50180c50,
    alias: ["/LiveQuotes/(/*)"],
    name: "LiveQuotes"
  }, {
    path: "/privacy",
    component: _753932de,
    name: "privacy"
  }, {
    path: "/reactivation",
    component: _54cc4b94,
    name: "reactivation"
  }, {
    path: "/reset-password",
    component: _0d6930f4,
    name: "reset-password"
  }, {
    path: "/rss",
    component: _2441afdb,
    name: "rss"
  }, {
    path: "/search-results",
    component: _1657b604,
    name: "search-results"
  }, {
    path: "/SessionWraps",
    component: _4a5a32c0,
    alias: ["/SessionWraps/page/:number(\\d+)"],
    name: "SessionWraps"
  }, {
    path: "/SignUp",
    component: _69705276,
    name: "SignUp"
  }, {
    path: "/stocks",
    component: _1130774a,
    name: "stocks"
  }, {
    path: "/terms",
    component: _500e56e6,
    children: [{
      path: "",
      component: _44b60560,
      name: "terms"
    }, {
      path: "search-results",
      component: _a3dd727a,
      name: "terms-search-results"
    }, {
      path: ":letter",
      component: _f98f3a7a,
      children: [{
        path: "",
        component: _89dae9f4,
        name: "terms-letter"
      }, {
        path: ":term",
        component: _abb10a02,
        alias: [":term/page/:number(\\d+)"],
        name: "terms-letter-term"
      }]
    }]
  }, {
    path: "/terms-of-use",
    component: _b957b678,
    name: "terms-of-use"
  }, {
    path: "/Tag/search-results",
    component: _497a1270,
    name: "Tag-search-results"
  }, {
    path: "/auth/google/callback",
    component: _ba03e03e,
    name: "auth-google-callback"
  }, {
    path: "/author/:author?",
    component: _c49eece0,
    alias: ["/author/:author?/page/:number(\\d+)"],
    name: "author-author"
  }, {
    path: "/Brokers/:company",
    component: _3566e740,
    name: "Brokers-company"
  }, {
    path: "/LiveQuotes/:asset",
    component: _455d8326,
    alias: ["/LiveQuotes/:asset/(/*)"],
    name: "LiveQuotes-asset"
  }, {
    path: "/stocks/:stock",
    component: _44671f62,
    name: "stocks-stock"
  }, {
    path: "/Tag/:tag?",
    component: _68cefc3a,
    alias: ["/Tag/:tag?/page/:number(\\d+)"],
    name: "Tag-tag"
  }, {
    path: "/",
    component: _2dfb1658,
    alias: ["/page/:number(\\d+)"],
    name: "index"
  }, {
    path: "/:category",
    component: _1e6ca7a6,
    alias: ["/:category/page/:number(\\d+)"],
    name: "category"
  }, {
    path: "/:category/:article",
    component: _51360056,
    alias: ["/:category/!/:article/(/*)","/:category/!/:article","/:category/:article/(/*)"],
    name: "category-article"
  }, {
    path: "/:category/:article/amp",
    component: _385003cb,
    alias: ["/:category/!/:article/amp/"],
    name: "category-article-amp"
  }, {
    path: "/:category/:article/:gibberish",
    component: _4f845bcd,
    name: "category-article-gibberish"
  }, {
    path: "/:category/!/:article/:gibberish",
    component: _4f845bcd,
    name: "category-article-migrated-gibberish"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
