import api from '~/utils/api'
import { generateArticleSlotResponse } from '@/utils/response-handling/article'

export const state = () => ({})

export const getters = {}

export const actions = {
  async requestAuthor(_, params) {
    try {
      const { data } = await this.$fmAxios.get(api.users.getAuthor(), {
        params
      })
      return data
    } catch (e) {
      throw e
    }
  },
  async requestAuthorArticles(_, params) {
    try {
      const { data } = await this.$axios.get(api.users.getAuthorArticles(), {
        params
      })

      data.Articles = data.Articles.map(article =>
        generateArticleSlotResponse(article)
      )

      return data
    } catch (e) {
      throw e
    }
  }
}

export const mutations = {}
