import { makeHydrationBlocker } from './utils/hydration-blocker'

/**
 * When the wrapper is created, it initially doesn't recognize component
 * props from the template.
 * Thus, all props passed from the template are stored in $attrs.
 * However, when the hydrated component is used inside an AVisibility component,
 * an issue arises: as the slot is hydrated, it's replaced with the component itself.
 * This component has its own props, causing passed props to disappear from $attrs
 * and move to $props.
 * But when the AVisibility slot becomes hidden, $attrs remains unchanged (without props).
 * Consequently, when the slot reappears, the hydrated component doesn't receive props
 * because they're missing in $attrs.
 * To address this, we've added a props array to the options to pass them to the wrapper
 * component and treat them as props.
 * Now, the wrapper component will store the passed props in $props instead of $attrs,
 * which will be bound to the final component in the render function.
 */
export function hydrateWhenIdle(
  componentOrFactory,
  { timeout = 2000, props = [] } = {}
) {
  return makeHydrationBlocker(componentOrFactory, {
    beforeCreate() {
      this.whenIdle = true
      this.idleTimeout = timeout
    },
    props
  })
}

export function hydrateWhenVisible(
  componentOrFactory,
  { observerOptions = undefined, props = [] } = {}
) {
  return makeHydrationBlocker(componentOrFactory, {
    beforeCreate() {
      this.whenVisible = observerOptions || true
    },
    props
  })
}

export function hydrateNever(componentOrFactory) {
  return makeHydrationBlocker(componentOrFactory, {
    beforeCreate() {
      this.never = true
    }
  })
}

export function hydrateOnInteraction(
  componentOrFactory,
  { event = `focus`, props = [] } = {}
) {
  const events = Array.isArray(event) ? event : [event]

  return makeHydrationBlocker(componentOrFactory, {
    beforeCreate() {
      this.interactionEvents = events
    },
    props
  })
}

const Placeholder = {
  render() {
    return this.$slots.default
  }
}

export default makeHydrationBlocker(Placeholder, {
  props: {
    idleTimeout: {
      default: 2000,
      type: Number
    },
    never: {
      type: Boolean
    },
    onInteraction: {
      type: [Array, Boolean, String]
    },
    triggerHydration: {
      default: false,
      type: Boolean
    },
    whenIdle: {
      type: Boolean
    },
    whenVisible: {
      type: [Boolean, Object]
    }
  },
  computed: {
    interactionEvents() {
      if (!this.onInteraction) return []
      if (this.onInteraction === true) return [`focus`]

      return Array.isArray(this.onInteraction)
        ? this.onInteraction
        : [this.onInteraction]
    }
  },
  watch: {
    triggerHydration: {
      immediate: true,
      handler(isTriggered) {
        if (isTriggered) this.hydrate()
      }
    }
  }
})
