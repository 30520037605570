import { MIDDLEWARE } from 'enums/middleware'

export const SEARCH_FILTER = {
  COMPANIES: 'companies',
  NEWS: 'news',
  TERMS: 'terms',
  VIDEOS: 'videos'
}

export const ANCHOR_TITLE = {
  ALL: 'All',
  COMPANIES: 'Companies',
  NEWS: 'News',
  TERMS: 'Terms'
}

export const skipMiddleware = [MIDDLEWARE.PAGE_SEO, MIDDLEWARE.SEO_REDIRECT]
