import * as types from './mutation-types'

const state = () => ({
  openedModalNamesStack: []
})

const getters = {
  isModalVisibleByName: state => modalName =>
    !!state.openedModalNamesStack.includes(modalName),
  openedModalCount: state => state.openedModalNamesStack.length,

  /**
   * The last modal in the stack is considered "active"
   */
  activeModalName: (state, getters) =>
    state.openedModalNamesStack[getters.openedModalCount - 1]
}

const actions = {
  setModalToOpenState({ commit, getters }, modalName) {
    if (getters.isModalVisibleByName(modalName)) return

    commit(types.ADD_MODAL_NAME_TO_OPENED, modalName)
  },
  setModalToCloseState({ commit }, modalName) {
    if (!getters.isModalVisibleByName(modalName)) return

    commit(types.REMOVE_MODAL_NAME_FROM_OPENED, modalName)
  }
}

const mutations = {
  [types.ADD_MODAL_NAME_TO_OPENED](state, modalName) {
    state.openedModalNamesStack = [
      ...new Set([...state.openedModalNamesStack, modalName])
    ]
  },
  [types.REMOVE_MODAL_NAME_FROM_OPENED](state, modalName) {
    const indexToRemove = state.openedModalNamesStack.indexOf(modalName)

    if (indexToRemove === -1) return

    state.openedModalNamesStack = [
      ...state.openedModalNamesStack.slice(0, indexToRemove),
      ...state.openedModalNamesStack.slice(indexToRemove + 1)
    ]
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
