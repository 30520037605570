import { getImageSuffixFromUrlRegexp } from '@/plugins/helper'
import {
  BREAKPOINT_RANGE,
  IMAGE_SIZE,
  IMAGE_SUFFIX,
  IMAGE_PLACEHOLDER_WIDTH,
  SIZE_DATA_BY_SRCSET_LOCATION
} from 'enums/images'

function getExtension(path) {
  if (!path) return ''

  return path.slice(path.lastIndexOf('.') + 1)
}

function generateSrcsetBreakpointData(imageSize, extension) {
  const width = BREAKPOINT_RANGE[imageSize][1]
  const widthString = width === Infinity ? 1920 : width

  return {
    width,
    srcsetBaseLine: `${IMAGE_SUFFIX[imageSize]}${extension} ${widthString}w`
  }
}

function generateSrcsetDataForAllBreakpoints(url) {
  const extension = getExtension(url)
  return [
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_50, extension),
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_150, extension),
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_260, extension),
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_360, extension),
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_500, extension),
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_775, extension),
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_900, extension),
    generateSrcsetBreakpointData(IMAGE_SIZE.ORIGINAL, extension)
  ]
}
export class Srcset {
  constructor(settings) {
    const {
      originalUrl,
      srcsetLocation,
      maxWidth,
      isImagePlaceholder
    } = settings
    this._originalUrl = originalUrl || null
    this._srcsetBreakpointData = generateSrcsetDataForAllBreakpoints(
      this._originalUrl
    )
    this._srcsetLocation = srcsetLocation
    this._maxWidth = maxWidth || null
    this._isImagePlaceholder = isImagePlaceholder || false
  }
  get sizeData() {
    if (!this._srcsetLocation) return null

    return SIZE_DATA_BY_SRCSET_LOCATION[this._srcsetLocation]
  }
  get maxImageBreakpoint() {
    if (!this._maxWidth) return null

    const breakpoints = this._srcsetBreakpointData.map(({ width }) => width)

    return breakpoints
      .sort((a, b) => a - b)
      .find(breakpoint => this._maxWidth <= breakpoint)
  }
  getBinaryStringFromBooleanArray(arr) {
    return arr.reduce((acc, value) => acc + +!!value, '')
  }
  generateSrcsetLine() {
    if (!this._originalUrl) return null

    const mainUrlPart = this.getMainUrlPart(this._originalUrl)

    const maxImageBreakpoint = this._isImagePlaceholder
      ? IMAGE_PLACEHOLDER_WIDTH
      : this.maxImageBreakpoint

    const srcsetBaseLines = maxImageBreakpoint
      ? this._srcsetBreakpointData
          .filter(({ width }) => width <= maxImageBreakpoint)
          .map(({ srcsetBaseLine }) => srcsetBaseLine)
      : this._srcsetBreakpointData.map(({ srcsetBaseLine }) => srcsetBaseLine)

    return srcsetBaseLines
      .map(baseLine => `${mainUrlPart}${baseLine}`)
      .join(', ')
  }
  getMainUrlPart(url) {
    const regexp = getImageSuffixFromUrlRegexp(
      IMAGE_SUFFIX[IMAGE_SIZE.ORIGINAL]
    )
    return url.replace(regexp, '$1')
  }
  generateSizesLine() {
    if (!this.sizeData) return null

    const sizeData = this.maxImageBreakpoint
      ? this.sizeData.filter(({ imageWidth }) => {
          return imageWidth <= this.maxImageBreakpoint
        })
      : this.sizeData

    if (!sizeData.length) return `${IMAGE_PLACEHOLDER_WIDTH}px`

    return sizeData
      .map(({ minWidth, maxWidth, imageWidth }) => {
        const minWidthLine = minWidth ? `(min-width: ${minWidth}px)` : null
        const maxWidthLine =
          maxWidth && sizeData.length > 1 ? `(max-width: ${maxWidth}px)` : null
        const boolString = this.getBinaryStringFromBooleanArray([
          minWidthLine,
          maxWidthLine
        ])
        const imageWidthValue =
          imageWidth === IMAGE_SIZE.ORIGINAL ? 1920 : imageWidth

        const resultMap = {
          '00': `${imageWidthValue}px`,
          '01': `${maxWidthLine} ${imageWidthValue}px`,
          '10': `${minWidthLine} ${imageWidthValue}px`,
          '11': `(${minWidthLine} and ${maxWidthLine}) ${imageWidthValue}px`
        }
        return resultMap[boolString]
      })
      .join(', ')
  }
}
