<template>
  <client-only v-if="!isConsentGivenForCaptcha">
    <p class="blocked-captcha__message text-caption">
      This feature is not working due to disabled Functional or Targeting
      cookies. To use this and other services, please enable
      <span
        class="blocked-captcha__cookies-trigger text-underline"
        @click="$_oneTrust_toggleCookiePreferencesPopup"
        >cookies</span
      >.
    </p>
  </client-only>
</template>

<script>
import mixins from '@/utils/mixins'
import { COOKIE_ENTITY_TYPE } from 'enums/oneTrust'

export default {
  name: 'ABlockedCaptchaMessage',
  mixins: [mixins.oneTrust],
  computed: {
    isConsentGivenForCaptcha() {
      return this.$_oneTrust_isConsentGivenForEntity(
        COOKIE_ENTITY_TYPE.RE_CAPTCHA
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.blocked-captcha__message {
  color: $c--error;
  font-size: 11px;

  .blocked-captcha__cookies-trigger {
    cursor: pointer;
  }
}
</style>
