import Vue from 'vue'
import scss from '@/utils/scss'

const { breakpoints } = scss

const plugin = {}

plugin.install = (Vue, options) => {
  Vue.prototype.$breakpoint = breakpoints
}

Vue.use(plugin)
