const middleware = {}

middleware['async-middlewares'] = require('../middleware/async-middlewares.js')
middleware['async-middlewares'] = middleware['async-middlewares'].default || middleware['async-middlewares']

middleware['async-middlewares/page-seo'] = require('../middleware/async-middlewares/page-seo.js')
middleware['async-middlewares/page-seo'] = middleware['async-middlewares/page-seo'].default || middleware['async-middlewares/page-seo']

middleware['async-middlewares/redirects'] = require('../middleware/async-middlewares/redirects.js')
middleware['async-middlewares/redirects'] = middleware['async-middlewares/redirects'].default || middleware['async-middlewares/redirects']

middleware['async-middlewares/seo-redirect'] = require('../middleware/async-middlewares/seo-redirect.js')
middleware['async-middlewares/seo-redirect'] = middleware['async-middlewares/seo-redirect'].default || middleware['async-middlewares/seo-redirect']

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['content-refresh-in-background'] = require('../middleware/content-refresh-in-background.js')
middleware['content-refresh-in-background'] = middleware['content-refresh-in-background'].default || middleware['content-refresh-in-background']

middleware['prevent-routing'] = require('../middleware/prevent-routing.js')
middleware['prevent-routing'] = middleware['prevent-routing'].default || middleware['prevent-routing']

middleware['preview-navigation'] = require('../middleware/preview-navigation.js')
middleware['preview-navigation'] = middleware['preview-navigation'].default || middleware['preview-navigation']

middleware['remove-local-one-trust-handlers'] = require('../middleware/remove-local-one-trust-handlers.js')
middleware['remove-local-one-trust-handlers'] = middleware['remove-local-one-trust-handlers'].default || middleware['remove-local-one-trust-handlers']

middleware['router-navigation-details'] = require('../middleware/router-navigation-details.js')
middleware['router-navigation-details'] = middleware['router-navigation-details'].default || middleware['router-navigation-details']

middleware['soft-render'] = require('../middleware/soft-render.js')
middleware['soft-render'] = middleware['soft-render'].default || middleware['soft-render']

export default middleware
