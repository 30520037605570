export const lazyLoadModal = function(modalName) {
  return {
    data() {
      return {
        isLazyLoaded: false,
        modalProps: null
      }
    },
    methods: {
      $_lazyLoadModal_onComponentMounted() {
        this.$helper.openModal(modalName, this.modalProps)
      }
    },
    mounted() {
      this.$bus.$on(`open-modal-${modalName}`, params => {
        this.modalProps = params
        this.$bus.$off(`open-modal-${modalName}`)
        this.isLazyLoaded = true
      })
    },
    beforeDestroy() {
      this.$bus.$off(`open-modal-${modalName}`)
    }
  }
}
