import api from '~/utils/api'
import { cachedRequest } from '@/utils/jobRequestApiMethods'
import { API_VERSION } from 'enums/api'

export const state = () => ({})

export const getters = {}

export const actions = {
  async requestCategoryCompanies(_, payload) {
    try {
      const { data } = await this.$axios.post(
        api.directoryCategories.getCategoryCompanies(),
        payload
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestCategoryFilters(_, params) {
    try {
      const { data } = await this.$axios.get(
        api.directoryCategories.getCategoryFilters(),
        {
          params
        }
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestCategoryVideo(_, params) {
    try {
      const { data } = await this.$axios.get(
        api.categories.getCategoryVideo(),
        { params }
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestSessionWrapsVideo() {
    try {
      const { data } = await this.$axios.get(
        api.categories.getSessionWrapsVideo()
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestAllNewsVideo() {
    try {
      const { data } = await cachedRequest({
        method: this.$axios.get,
        args: [api.categories.getAllNewsVideo()],
        apiVersion: API_VERSION.V1
      })

      return data
    } catch (err) {
      throw err
    }
  }
}

export const mutations = {}
