import { mapGetters } from 'vuex'

export const METHOD_NAME_TO_RUN_ON_LOGOUT = 'runOnLogout'

export const runMethodOnLogout = {
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn'
    })
  },
  watch: {
    isLoggedIn: {
      handler(newVal) {
        if (!newVal) {
          const method = this[METHOD_NAME_TO_RUN_ON_LOGOUT]

          if (method && this.$helper.checkIfFunction(method)) {
            method()
          }
        }
      }
    }
  }
}
