export const MIN_COMMENT_COUNT_TO_SHOW_COUNTER = 1
export const REFRESH_COMMENT_COUNT_VALUE_INTERVAL = 60 * 1000 // 1 minute

export const DISQUS_HASH = '#disqus_thread'
export const DISQUS_SCROLL_OFFSET = -80
export const DISQUS_SCROLL_DELAY = 500

export const DISQUS_SOURCE = {
  FM: 'FM',
  FL: 'FL'
}

export const UPDATE_COMMENT_COUNT_EVENT = 'update-comment-count'

export const DISQUS_SCRIPT_IDENTIFY_STRING = '.disqus.com/count-data.js'
