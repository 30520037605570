export const AUTH_PROVIDER = {
  EMAIL: 'Email',
  GOOGLE: 'Google',
  FACEBOOK: 'Facebook',
  TWITTER: 'Twitter'
}

export const AUTH_TYPE = {
  SIGN_IN: 'SIGN_IN',
  SIGN_UP: 'SIGN_UP'
}

export const COMPONENT_BY_AUTH_TYPE = {
  [AUTH_TYPE.SIGN_IN]: 'sign-in',
  [AUTH_TYPE.SIGN_UP]: 'sign-up'
}

export const OAUTH_BASE_URL_BY_AUTH_TYPE = {
  [AUTH_PROVIDER.GOOGLE]: 'https://accounts.google.com/o/oauth2/v2/auth'
}

export const CALLBACK_PATH_BY_AUTH_PROVIDER = {
  [AUTH_PROVIDER.GOOGLE]: '/auth/google/callback'
}

export const COMPLETE_AUTH_ACTION_BY_AUTH_TYPE = {
  [AUTH_PROVIDER.GOOGLE]: 'COMPLETE_GOOGLE_OAUTH'
}

export const CLEAN_USER_DETAILS = {
  Id: null,
  FirstName: null,
  LastName: null,
  PhotoUrl: null,
  Type: null,
  Email: null,
  SessionCount: 0,
  IsOnboardingFlowCompleted: null,
  HasNotFollowedItems: false,
  HasMissingDetails: false,
  FollowerCount: 0,
  Progress: 0
}
