/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'newsletter-rect-blue': {
    width: 35,
    height: 35,
    viewBox: '0 0 35 35',
    data:
      '<g _fill="none" fill-rule="evenodd"><path pid="0" _fill="#069ACF" d="M35 0v35H0V0z"/><g _fill="#FFF" fill-rule="nonzero"><path pid="1" d="M23.608 7.77a.772.772 0 00-.773-.77H8.772A.771.771 0 008 7.77v18.322c0 .899.73 1.627 1.631 1.627h14.484a2.85 2.85 0 01-.507-1.627V7.771zm-2.599 17.982H10.6a.625.625 0 11-.001-1.25H21.01a.626.626 0 110 1.25zm0-2.666H10.6a.625.625 0 11-.001-1.249H21.01a.626.626 0 110 1.249zm0-2.666H10.6a.625.625 0 11-.001-1.249H21.01a.625.625 0 110 1.249zm0-2.665H10.6a.625.625 0 11-.001-1.25H21.01a.626.626 0 110 1.25zm.627-3.29c0 .344-.28.624-.627.624H10.6a.626.626 0 01-.627-.625V9.592c0-.345.28-.624.626-.624H21.01c.346 0 .627.28.627.624v4.872zm5.614-1.71h-2.415v13.348c0 .904.73 1.637 1.62 1.616.864-.02 1.545-.764 1.545-1.646V13.52a.758.758 0 00-.75-.766z"/><path pid="2" d="M11.165 10.309h9.065v3.597h-9.065z"/></g></g>'
  }
})
