import { mapGetters } from 'vuex'

import pendingAuthActions from '@/utils/mixins/pending-auth-actions'
import { MODAL } from 'modals/AModalWrapper'
import { ROUTE_NAME } from 'enums/routes'
import {
  ACTIVATOR_ID,
  ACTIVATOR_SELECTOR_BY_ACTIVATOR_ID
} from 'enums/activator-id'

export const ACTION_QUERY_PARAM = {
  OPEN_AUTH_FORM: 'openAuthForm',
  OPEN_NEWSLETTER_FORM: 'openNewsletterForm',
  OPEN_EBOOK_FORM: 'openEbookForm'
}

export default {
  mixins: [pendingAuthActions],
  data() {
    return {
      actionsByQueryParams: {
        [ACTION_QUERY_PARAM.OPEN_AUTH_FORM]: this
          .$_actionsOnQueryParams_openAuthModal,
        [ACTION_QUERY_PARAM.OPEN_NEWSLETTER_FORM]: this
          .$_actionsOnQueryParams_openNewsletterModal,
        [ACTION_QUERY_PARAM.OPEN_EBOOK_FORM]: this
          .$_actionsOnQueryParams_openEbookForm
      },
      isEbookLoadedResolveFn: null
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      isEbookRequestInProgress: 'ebooks/isEbookRequestInProgress',
      ebook: 'ebooks/ebook'
    })
  },
  watch: {
    '$route.query'() {
      this.$_actionsOnQueryParams_handleQueryParams()
    },
    isEbookRequestInProgress: {
      handler(newVal) {
        if (newVal) return

        if (this.isEbookLoadedResolveFn) {
          this.isEbookLoadedResolveFn()
        }
      }
    }
  },
  methods: {
    $_actionsOnQueryParams_handleQueryParams() {
      Object.keys(this.actionsByQueryParams).forEach(queryParam => {
        const queryParamLowerCased = queryParam.toLowerCase()
        const queryParamsLowerCased = this.$helper.valuesToLowercase(
          Object.keys(this.$route.query || {})
        )
        if (queryParamsLowerCased.includes(queryParamLowerCased)) {
          requestAnimationFrame(this.actionsByQueryParams[queryParam])
        }
      })
    },
    async $_actionsOnQueryParams_openAuthModal() {
      await this.$_pendingAuthActions_waitForAuthStatusIsReceived()
      if (this.isLoggedIn) return

      this.$helper.openModal(MODAL.AUTH_FORM, {
        activatorSelector: ACTIVATOR_SELECTOR_BY_ACTIVATOR_ID[ACTIVATOR_ID.AUTH]
      })
    },
    $_actionsOnQueryParams_openNewsletterModal() {
      this.$helper.openModal(MODAL.NEWSLETTER_FORM)
    },
    async $_actionsOnQueryParams_openEbookForm() {
      if (this.$route.name !== ROUTE_NAME.ALL_NEWS) return

      await new Promise(resolve => {
        this.isEbookLoadedResolveFn = resolve
      })
      if (!this.ebook) return

      this.$helper.openModal(MODAL.EBOOK_FORM)
    }
  },
  mounted() {
    this.$_actionsOnQueryParams_handleQueryParams()
  }
}
