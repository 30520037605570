import { ERROR_CODE_V2, getErrorSettingsByBackendError } from 'enums/errorCodes'
import {
  CONTRIBUTOR_TYPES,
  REACTIVATION_TYPE,
  USER_STATUS,
  USER_TYPE
} from 'enums/users'
import { TRACKING_MESSAGE_GENERATOR_FN_BY_ERROR_CODE } from 'enums/events-tracking'

export const authError = {
  methods: {
    $_authError_getErrorMessageToTrack({
      errCode,
      errEvidence,
      errorSettings
    }) {
      const trackingMessageGeneratorFn =
        TRACKING_MESSAGE_GENERATOR_FN_BY_ERROR_CODE[errCode]

      return trackingMessageGeneratorFn
        ? trackingMessageGeneratorFn(errEvidence) || errorSettings.message
        : errorSettings.message
    },
    $_authError_handleAuthError(err, errorType) {
      const errCode = this.$helper.getBackendErrorCode(err)
      const errEvidence = this.$helper.getBackendErrorEvidence(err)
      const errorSettings = getErrorSettingsByBackendError(err)

      this.$_authError_trackError({
        errorType,
        errCode,
        errEvidence,
        errorSettings
      })

      if (errCode === ERROR_CODE_V2.USER_INVALID_STATE) {
        const isContributor = CONTRIBUTOR_TYPES.includes(errEvidence.Type)
        const isSubscriber = errEvidence.Type === USER_TYPE.SUBSCRIBER
        const isDeactivated = errEvidence.Status === USER_STATUS.DEACTIVATED
        const tokenId = errEvidence.TokenId

        if (isDeactivated && isContributor && errEvidence.IsMigrating) {
          return {
            reactivationType:
              REACTIVATION_TYPE.FIRST_LOGIN_MIGRATED_CONTRIBUTOR,
            tokenId
          }
        }

        if (isDeactivated && isSubscriber && errEvidence.IsMigrating) {
          return {
            reactivationType: REACTIVATION_TYPE.FIRST_LOGIN_MIGRATED_SUBSCRIBER,
            tokenId
          }
        }

        if (isDeactivated) {
          return {
            reactivationType: REACTIVATION_TYPE.GENERAL_REACTIVATION,
            tokenId
          }
        }
      }

      this.serverError = err
      this.$errorHandler(err, this)
      this.$emit('auth-failed')
      return { reactivationType: null }
    },
    $_authError_trackError({ errorType, errCode, errEvidence, errorSettings }) {
      const errorMessage = this.$_authError_getErrorMessageToTrack({
        errCode,
        errEvidence,
        errorSettings
      })

      if (this.$helper.isArray(window.dataLayer)) {
        window.dataLayer.push({
          event: 'error',
          errorType,
          errorMessage
        })
      }
    }
  }
}
