let getRedirectSettingsImported = null

export const importRedirectSettings = async () => {
  if (!getRedirectSettingsImported) {
    const { getRedirectSettings } = await import('./index')
    getRedirectSettingsImported = getRedirectSettings
  }

  return getRedirectSettingsImported
}
