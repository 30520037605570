import { equals, clone } from 'ramda'
import LRU from 'lru-cache'

import { pollUntil } from '@/plugins/helper'
import { API_PATH_PREFIX_BY_API_VERSION } from 'enums/api'

const SERVER_CACHE_MAX_AGE = 1000 * 1000000000000000
const SERVER_CACHE_MAX_SIZE = 1000

const serverCache = new LRU({
  max: SERVER_CACHE_MAX_SIZE,
  maxAge: SERVER_CACHE_MAX_AGE
})

const REQUEST_PERIOD = 2 * 1000
const POLL_INTERVAL = 50
const POLL_TIMEOUT = 10 * 1000

const requestIsRunningForCacheKey = {}
const recursiveRequestIsInvokedForCacheKey = {}

function getAbsoluteUrl(relativePath, apiVersion) {
  return `http://localhost:${process.env.CACHING_APP_PORT}/${API_PATH_PREFIX_BY_API_VERSION[apiVersion]}/${relativePath}`
}

async function getData({ method, args }) {
  const { data } = await method(...args)
  const lastModified = new Date().getTime()
  return new Promise(resolve => resolve({ data, lastModified }))
}

function createPollPromiseForCacheKey(cacheKey) {
  return new Promise(resolve => {
    pollUntil({
      fn: resolve,
      condition: () => !!serverCache.get(cacheKey),
      interval: POLL_INTERVAL,
      timeout: POLL_TIMEOUT,
      executeOnTimeoutFn: resolve
    })
  })
}

export async function cachedRequest({ method, args, apiVersion }) {
  if (!apiVersion) {
    throw new Error('apiVersion is required for cachedRequest()')
  }

  let cacheKey

  try {
    cacheKey = JSON.stringify(args)
  } catch (err) {
    console.log('cachedRequest: Error occurred while stringifying args!')
    throw err
  }

  try {
    if (process.client || process.env.NODE_ENV !== 'production') {
      return getData({ method, args })
    }

    let cachedResponse = serverCache.get(cacheKey)

    /**
     * Concurrency handling
     */
    if (!cachedResponse && requestIsRunningForCacheKey[cacheKey]) {
      await createPollPromiseForCacheKey(cacheKey)

      cachedResponse = serverCache.get(cacheKey)

      /**
       * If cache is empty even after polling is done - manually perform a request
       */
      if (!cachedResponse) {
        return getData({ method, args })
      }
    }

    if (!cachedResponse && !requestIsRunningForCacheKey[cacheKey]) {
      requestIsRunningForCacheKey[cacheKey] = true
    }

    if (!cachedResponse && !recursiveRequestIsInvokedForCacheKey[cacheKey]) {
      recursiveRequestIsInvokedForCacheKey[cacheKey] = true

      return await invokeApiMethodRecursively({ method, args, apiVersion })
    }

    const { err } = cachedResponse

    if (err) throw err

    return new Promise(resolve => resolve(clone(cachedResponse)))
  } catch (err) {
    console.log('cachedRequest: Error: ', err)
    throw err
  }
}

export async function invokeApiMethodRecursively({ method, args, apiVersion }) {
  let cacheKey

  try {
    cacheKey = JSON.stringify(args)
  } catch (err) {
    console.log(
      'invokeApiMethodRecursively: Error occurred while stringifying args!'
    )
    requestIsRunningForCacheKey[cacheKey] = false
    throw err
  }

  try {
    requestIsRunningForCacheKey[cacheKey] = true

    /** If CACHING_APP_PORT is passed - we perform requests to caching-app,
     * if not - perform requests to BE-API;
     * Caching-app returns data together with the lastModified value;
     * BE-API returns data, and we need to additionally calculate the lastModified value;
     * **/
    if (process.env.CACHING_APP_PORT) {
      const { data } = await method(
        getAbsoluteUrl(args[0], apiVersion),
        ...args.slice(1)
      )
      serverCache.set(cacheKey, data)
      return new Promise(resolve => resolve(clone(data)))
    } else {
      const { data } = await method(...args)
      const cachedResponse = serverCache.get(cacheKey) || {}

      const lastModified = equals(cachedResponse.data, data)
        ? cachedResponse.lastModified
        : new Date().getTime()

      const handledResponse = { data, lastModified }

      serverCache.set(cacheKey, handledResponse)

      return new Promise(resolve => resolve(clone(handledResponse)))
    }
  } catch (err) {
    serverCache.set(cacheKey, { err })
  } finally {
    requestIsRunningForCacheKey[cacheKey] = false

    setTimeout(() => {
      invokeApiMethodRecursively({
        method,
        args,
        apiVersion
      })
    }, REQUEST_PERIOD)
  }
}
