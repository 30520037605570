import * as types from '@/store/mutation-types'

const state = () => ({
  isFacebookApiInitialized: false
})

const getters = {
  isFacebookApiInitialized: state => state.isFacebookApiInitialized
}

const mutations = {
  [types.SET_FACEBOOK_API_INITIALIZED](state) {
    state.isFacebookApiInitialized = true
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
