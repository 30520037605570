import { ADBLOCKER_ELEMENT_PLACEHOLDER } from 'enums/ad-blocker/element-placeholder'

export const BANNER_ALLOCATOR_PLACEHOLDER_BY_BREAKPOINT = {
  mobile: ADBLOCKER_ELEMENT_PLACEHOLDER.MOBILE_MODIFIER,
  tablet: ADBLOCKER_ELEMENT_PLACEHOLDER.TABLET_MODIFIER,
  desktopSm: ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_SM_MODIFIER,
  desktopSm2: ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_SM2_MODIFIER,
  desktopSm3: ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_SM3_MODIFIER,
  desktopMd: ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_MD_MODIFIER,
  desktopLg: ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_LG_MODIFIER,
  desktopXl: ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_XL_MODIFIER,
  aboveDesktopXl: ADBLOCKER_ELEMENT_PLACEHOLDER.ABOVE_DESKTOP_XL_MODIFIER
}
