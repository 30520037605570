import { getUrlHost } from '../helper'

const URL =
  typeof window !== 'undefined' && window.URL ? window.URL : require('url').URL
const URLSearchParams =
  typeof window !== 'undefined' && window.URLSearchParams
    ? window.URLSearchParams
    : require('url').URLSearchParams
/**
 * Query params which will be used for cookie syncing
 */
export const QUERY_PARAM = {
  ACCESS_TOKEN: 'at',
  REFRESH_TOKEN: 'rt',
  REFRESH_TOKEN_LAST_MODIFIED: 'rtlm'
}

const ALLOWED_QUERY_PARAMS = Object.values(QUERY_PARAM)

/**
 * This method could parse url string on server (without WebAPI)
 * @param url
 * @returns {Object} object of query params
 */
function getQueryObjectFromUrl(url) {
  if (!url) return {}

  const urlObject = new URL(url)
  const queryString = urlObject.search.replace('?', '')
  const queryParams = new URLSearchParams(queryString)
  return [...queryParams].reduce(
    (acc, [param, value]) => ({ ...acc, [param]: value }),
    {}
  )
}

const TRUSTED_URLS = [
  process.env.DOMAIN_URL,
  process.env.FM_DOMAIN_URL,
  process.env.FM_DIR_DOMAIN_URL
]

/**
 * This is general validation function which should be used for every
 * server middleware api. Check if referer host is allowed for
 * cookie sync.
 * @param req
 * @param trustedUrls
 * @returns null | object with query params
 */
export function validateReferer(req, trustedUrls = TRUSTED_URLS) {
  try {
    if (!req.headers.referer) return false

    const TRUSTED_HOSTS = trustedUrls.map(targetUrl => getUrlHost(targetUrl))
    const refererHost = getUrlHost(req.headers.referer)

    /**
     * Verifying that we can trust this request
     */
    return TRUSTED_HOSTS.includes(refererHost)
  } catch (err) {
    return false
  }
}

/**
 * Parse query params from url string, validate them and returns query object
 * @param req
 * @param next
 * @returns {null|Object}
 */
export function getQueryParams(req) {
  /**
   * Nuxt creates a connect instance which leads to some pitfalls.
   * Its req is the Node.js http request object, so it doesn't have the query
   * property and request.body is also being skipped in get requests.
   * So, we need to parse url to get a query.
   */
  const query = getQueryObjectFromUrl(`${process.env.DOMAIN_URL}${req.url}`)
  /**
   * Check if query params are allowed for cookie middleware
   */
  if (
    !Object.keys(query).every(queryParam =>
      ALLOWED_QUERY_PARAMS.includes(queryParam)
    )
  ) {
    return null
  }

  return query
}
