import api from '~/utils/api'

export const state = () => ({})

export const getters = {}

export const actions = {
  async requestStocks(_, { queryParams }) {
    try {
      const { data } = await this.$axios.get(api.assets.getStocks(), {
        params: queryParams
      })
      return data
    } catch (e) {
      throw e
    }
  },
  async requestStock(_, { pathParams }) {
    try {
      const { data } = await this.$axios.get(
        api.assets.getStock(pathParams.symbol)
      )
      return data
    } catch (e) {
      throw e
    }
  }
}

export const mutations = {}
