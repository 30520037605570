import { FILE_EXTENSION, FILE_FIELD, MEDIA_TYPE } from '@fmpedia/enums'
import { PROJECT_SITE } from './source'

export const DEFAULT_IFRAME_WIDTH = 560
export const DEFAULT_IFRAME_HEIGHT = 315
export const MEDIA_CENTER_SCOPE = {
  FM: 'FM',
  FM_DIR: 'FM_DIR',
  FL: 'FL',
  FL_DIR: 'FL_DIR'
}

export const PROJECT_SITE_BY_MEDIA_CENTER_SCOPE = {
  [MEDIA_CENTER_SCOPE.FM]: PROJECT_SITE.FM,
  [MEDIA_CENTER_SCOPE.FM_DIR]: PROJECT_SITE.FM_DIR,
  [MEDIA_CENTER_SCOPE.FL]: PROJECT_SITE.FL,
  [MEDIA_CENTER_SCOPE.FL_DIR]: PROJECT_SITE.FL_DIR
}

export const MEDIA_EXTENSIONS = {
  [MEDIA_TYPE.IMAGE]: [FILE_EXTENSION.JPEG, FILE_EXTENSION.JPG],
  [MEDIA_TYPE.VIDEO]: [FILE_EXTENSION.MP4],
  [MEDIA_TYPE.PDF]: [FILE_EXTENSION.PDF],
  [MEDIA_TYPE.MS_WORD]: [FILE_EXTENSION.DOC, FILE_EXTENSION.DOCX],
  [MEDIA_TYPE.MS_EXCEL]: [FILE_EXTENSION.XLS, FILE_EXTENSION.XLSX],
  [MEDIA_TYPE.UNKNOWN]: ['']
}

export const DEFAULT_VISIBLE_FILE_FIELDS = [
  {
    field: FILE_FIELD.TITLE,
    required: true
  },
  {
    field: FILE_FIELD.ALT_TEXT,
    required: false
  },
  {
    field: FILE_FIELD.CAPTION,
    required: false
  }
]

const FILE_FIELDS = Object.values(FILE_FIELD)

const FILE_SETTINGS_KEY = {
  FIELD: 'field',
  REQUIRED: 'required',
  MAX_LENGTH: 'maxLength'
}
export const FILE_SETTINGS_KEYS = Object.values(FILE_SETTINGS_KEY)

export const FILE_SETTINGS_VALUE_VALIDATOR = {
  [FILE_SETTINGS_KEY.FIELD]: value => FILE_FIELDS.includes(value),
  [FILE_SETTINGS_KEY.REQUIRED]: value => typeof value === 'boolean',
  [FILE_SETTINGS_KEY.MAX_LENGTH]: value => Number.isInteger(value)
}

export const PDF_WARNING_TEXT =
  'Do not upload PDFs that are protected by password: Make sure there’s no password for ‘read’ and no password for ‘edit’ on the uploaded PDF.'

export const IMAGE_TYPE = {
  FACE: 'FACE',
  INFOGRAPHIC: 'INFOGRAPHIC',
  FEATURED_IMAGE: 'FEATURED_IMAGE'
}

export const IMAGE_TYPE_VALUES = Object.values(IMAGE_TYPE)
