/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bell-active': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data:
      '<g _fill="none" fill-rule="evenodd" transform="translate(1 1)"><circle pid="0" cx="15" cy="15" r="15" _fill="#292929" _stroke="#FFF"/><path pid="1" _fill="#FFF" fill-rule="nonzero" d="M16.082 8.49a5.352 5.352 0 014.092 5.196v2.941c0 .579.47 1.056 1.055 1.056.225 0 .402.183.402.401v2.193a.42.42 0 01-.416.401H17.34a2.47 2.47 0 01-2.462 2.26 2.49 2.49 0 01-2.475-2.26H8.429a.402.402 0 01-.402-.401v-2.193c0-.224.184-.401.402-.401.579 0 1.055-.47 1.055-1.056v-2.941a5.352 5.352 0 014.079-5.193V8.3c0-.694.565-1.26 1.26-1.26.694 0 1.259.566 1.259 1.26v.19zm-.804-.13V8.3a.45.45 0 00-.45-.449.454.454 0 00-.455.45v.06a5.278 5.278 0 01.906-.001zm1.26 12.318h-3.323a1.677 1.677 0 003.322 0zm.482-.81h3.794v-1.43a1.868 1.868 0 01-1.457-1.818v-2.94c0-2.5-2.036-4.535-4.535-4.535s-4.534 2.035-4.534 4.534v2.941c0 .886-.627 1.634-1.457 1.818v1.43h3.889a.44.44 0 01.073-.007h4.153c.026 0 .05.003.074.007z"/></g>'
  }
})
