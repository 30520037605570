import { mapActions, mapMutations } from 'vuex'
import * as types from '@/store/mutation-types'

export default (settings = { runOnMount: true }) => ({
  data() {
    return {
      isEbookLoaded: false
    }
  },
  computed: {
    $_ebookDataFetch_isEbookLoaded() {
      return this.isEbookLoaded
    }
  },
  methods: {
    ...mapActions({
      requestEbook: 'ebooks/requestEbook'
    }),
    ...mapMutations({
      setEbookRequestStarted: `ebooks/${types.SET_EBOOK_REQUEST_STARTED}`,
      setEbookRequestFinished: `ebooks/${types.SET_EBOOK_REQUEST_FINISHED}`
    }),
    async $_ebookDataFetch_getEbook() {
      this.setEbookRequestStarted()
      try {
        return await this.requestEbook(this.$route)
      } catch (err) {
        return null
      } finally {
        this.setEbookRequestFinished()
        this.isEbookLoaded = true
      }
    },
    checkIfRunOnMount() {
      return !!settings?.runOnMount
    }
  },
  mounted() {
    if (this.checkIfRunOnMount()) {
      this.$_ebookDataFetch_getEbook()
    }
  }
})
