import * as types from '@/store/mutation-types'

export const state = () => ({
  isArticleOnboardingInProgress: false,
  isAllNewsOnboardingInProgress: false
})

export const getters = {
  isArticleOnboardingInProgress: state => state.isArticleOnboardingInProgress,
  isAllNewsOnboardingInProgress: state => state.isAllNewsOnboardingInProgress,
  isOnboardingInProgress: state =>
    [
      state.isArticleOnboardingInProgress,
      state.isAllNewsOnboardingInProgress
    ].some(inProgress => inProgress)
}

export const mutations = {
  [types.SET_IS_ARTICLE_ONBOARDING_IN_PROGRESS](state, value) {
    state.isArticleOnboardingInProgress = value
  },
  [types.SET_IS_ALL_NEWS_ONBOARDING_IN_PROGRESS](state, value) {
    state.isAllNewsOnboardingInProgress = value
  }
}
