module.exports = function({ store, from, route }) {
  const routeFromName = from ? from.name : ''
  const routeToName = route.name
  const paginationRegex = new RegExp('-page-number$')
  const isPaginationRoute =
    routeFromName.replace(paginationRegex, '') ===
    routeToName.replace(paginationRegex, '')
  if (!isPaginationRoute) {
    store.dispatch('setPageLoaded', false)
  }
}
