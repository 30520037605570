<template>
  <ul class="footer-socials__wrapper">
    <li v-for="item in items" :key="item.Id" class="footer-socials__item">
      <div class="footer-socials__image-wrapper">
        <a :href="item.Url" target="_blank">
          <amp-img
            :src="item.Logo.URL"
            :alt="item.Logo.AltText"
            layout="responsive"
            width="1"
            height="1"
          ></amp-img>
        </a>
      </div>
    </li>
  </ul>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import { aspectRatios } from 'enums/aspectRatios'

export default {
  name: 'FooterSocials',
  props: {
    items: propValidator([PROP_TYPES.ARRAY])
  },
  data() {
    return { aspectRatios }
  }
}
</script>

<style lang="scss" scoped>
.footer-socials__wrapper {
  display: flex;
  justify-content: space-between;

  /deep/ .footer-socials__icon .a-icon__icon {
    fill: $c--white;

    &:hover {
      fill: $c--main;
    }
  }

  .footer-socials__image-wrapper {
    width: 25px;
    height: 25px;
  }
}
</style>
