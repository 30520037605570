import api from '@/utils/api'
import { generateArticleSlotResponse } from '@/utils/response-handling/article'

const state = () => ({})

const getters = {}

const actions = {
  async requestTagBySlug(_, params) {
    try {
      const { data } = await this.$axios.get(api.tags.getTag(), { params })
      return data
    } catch (e) {
      throw e
    }
  },
  async requestArticleByTagSlug(_, params) {
    try {
      const { data } = await this.$axios.get(
        api.articles.getArticleByTagSlug(),
        {
          params
        }
      )

      data.Articles = data.Articles.map(article =>
        generateArticleSlotResponse(article)
      )
      return data
    } catch (err) {
      throw err
    }
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
