import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'

export default {
  mixins: [hydrationHelpers],
  data() {
    return {
      refDimensions: {}
    }
  },
  watch: {
    $_hydrationHelpers_windowWidth: {
      immediate: true,
      handler() {
        if (process.client) {
          this.$nextTick(this.$_refDimensions_setDimensions)
        }
      }
    }
  },
  methods: {
    $_refDimensions_setDimensions() {
      if (this.$helper.isObject(this.$refs)) {
        Object.entries(this.$refs).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            this.$set(
              this.refDimensions,
              key,
              value.map(el => this.$_refDimensions_getElementDimensionInfo(el))
            )
          } else if (value) {
            this.$set(
              this.refDimensions,
              key,
              this.$_refDimensions_getElementDimensionInfo(value)
            )
          }
        })
      }

      if (
        this.$_refDimensions_runOnChange &&
        this.$helper.checkIfFunction(this.$_refDimensions_runOnChange)
      ) {
        this.$_refDimensions_runOnChange()
      }
    },
    $_refDimensions_getElementDimensionInfo(el) {
      const element = (el && el.$el) || el

      if (!element)
        return {
          width: 0,
          height: 0
        }

      const { width, height } = element.getBoundingClientRect()

      return {
        width,
        height
      }
    }
  }
}
