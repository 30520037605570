import debounce from 'debounce'

const TRACK_SEARCH_DEBOUNCE_TIMEOUT = 2000
export const MIN_CHAR_COUNT_FOR_TRACKING_SEARCH = 3

export default {
  data() {
    return {
      debouncedTrackSearch: debounce(function(searchPhrase, eventType) {
        window.dataLayer.push({
          event: eventType,
          customSearchInput: searchPhrase
        })
      }, TRACK_SEARCH_DEBOUNCE_TIMEOUT)
    }
  },
  methods: {
    $_trackSearch_debouncedTrackSearch(searchPhrase, eventType) {
      this.debouncedTrackSearch(searchPhrase, eventType)
    }
  }
}
