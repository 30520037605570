import Vue from 'vue'

function maxLength(text, textLength) {
  const length = textLength > 3 ? textLength : 75
  if (!text) return ''

  if (text.length > length) {
    const visibleText = text.slice(0, textLength)
    let lastDotIndex = 0
    let lastSpaceIndex = 0
    visibleText.replace(/[.!?\s]/g, (str, offset) => {
      str === ' ' ? (lastSpaceIndex = offset) : (lastDotIndex = offset)
      return ''
    })

    const lastChar = lastDotIndex || lastSpaceIndex
    return lastChar ? text.slice(0, lastChar + 1) : `${visibleText}...`
  }

  return text
}

Vue.filter('maxLength', maxLength)
