import { SET_OPTANON_CONSENT_ACTIVE_GROUPS_FROM_COOKIE } from '@/store/mutation-types'
import Cookies from 'js-cookie'
import { OPTANON_CONSENT_COOKIE_NAME } from 'enums/oneTrust'

export default function({ store }) {
  if (process.server) return

  store.commit(
    `one-trust/${SET_OPTANON_CONSENT_ACTIVE_GROUPS_FROM_COOKIE}`,
    Cookies.get(OPTANON_CONSENT_COOKIE_NAME)
  )
}
