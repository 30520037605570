/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'one-signal-active': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data:
      '<g _fill="none" fill-rule="evenodd" transform="translate(1 1)"><circle pid="0" cx="15" cy="15" r="15" _fill="#292929" _stroke="#FFF"/><path pid="1" _fill="#FFBC00" fill-rule="nonzero" d="M21.994 11.242V7.438a.447.447 0 00-.212-.36.532.532 0 00-.401-.063l-8.391 3.34H8.847a2.156 2.156 0 00-1.86 1.14l-.38.149A2.388 2.388 0 005 13.905a2.345 2.345 0 001.606 2.24l.254.107c.195.505.572.92 1.057 1.162l1.12 4.058a2.072 2.072 0 003.657.698 2.183 2.183 0 00.338-1.84l-.761-2.79h.719l8.39 3.404.149.042a.508.508 0 00.253-.063.403.403 0 00.212-.338v-4.016a2.664 2.664 0 000-5.327zM6.776 15.3a1.415 1.415 0 01-.888-1.395 1.416 1.416 0 01.888-1.395v2.79zm5.241 6.341a1.2 1.2 0 01-.993.486 1.268 1.268 0 01-1.184-.93l-.993-3.656h2.557l.804 3.001c.11.376.04.782-.19 1.1zm.677-4.946H8.847a1.162 1.162 0 01-1.225-1.097c-.001-.029-.002-.057-.001-.086V12.51a1.267 1.267 0 011.224-1.31h3.849v5.495zm8.454 3.255l-7.609-3.043v-5.792l7.61-3.043V19.95zm.846-4.227v-3.635a1.817 1.817 0 010 3.635z"/></g>'
  }
})
