export const REDIRECT_TYPE = {
  MOVED_PERMANENTLY: 'MovedPermanently',
  MOVED_TEMPORARILY: 'MovedTemporarily'
}

export const REDIRECT_TYPE_NAME = {
  [REDIRECT_TYPE.MOVED_PERMANENTLY]: '301 (Moved permanently)',
  [REDIRECT_TYPE.MOVED_TEMPORARILY]: '302 (Moved temporarily)'
}

export const REDIRECT_TYPE_CODES = {
  [REDIRECT_TYPE.MOVED_PERMANENTLY]: 301,
  [REDIRECT_TYPE.MOVED_TEMPORARILY]: 302
}

export const REDIRECT_TYPES_LIST = Object.values(REDIRECT_TYPE).map(type => ({
  name: REDIRECT_TYPE_NAME[type],
  value: type
}))

export const NO_DIRECTIVE = 'Nodirectives'
