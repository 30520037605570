import * as types from './mutation-types'
import api from '~/utils/api'
import { MIGRATED_CATEGORY_SLUG } from 'enums/categories'
import { processResponse, isRouteWithoutSidebar } from '@/plugins/helper'
import { cachedRequest } from '@/utils/jobRequestApiMethods'
import { API_VERSION } from 'enums/api'

const API_METHOD_BY_CATEGORY_SLUG = {
  [MIGRATED_CATEGORY_SLUG.CRYPTOCURRENCY.toLowerCase()]: api.layout.getSidebarCryptocurrencyMustReadItems()
}
const DEFAULT_API_METHOD = api.layout.getSidebarMustReadItems()

function getApiMethodByCategorySlug(categorySlug) {
  return API_METHOD_BY_CATEGORY_SLUG[categorySlug] || DEFAULT_API_METHOD
}

export const state = () => ({
  isNextRequestShouldBeSkipped: false,
  mustReadItems: [],
  mustReadItemsLastModified: null
})

export const getters = {
  mustReadItems: ({ mustReadItems }) => mustReadItems,
  mustReadItemsLastModified: ({ mustReadItemsLastModified }) =>
    mustReadItemsLastModified
}

export const actions = {
  async requestSidebarContent({ commit, dispatch, state }) {
    try {
      const route = this.$router.currentRoute
      if (isRouteWithoutSidebar(route)) return

      if (process.server) {
        commit(types.IS_NEXT_REQUEST_SHOULD_BE_SKIPPED, true)
      } else if (state.isNextRequestShouldBeSkipped) {
        commit(types.IS_NEXT_REQUEST_SHOULD_BE_SKIPPED, false)
        return
      }

      await dispatch('requestMustReadItems')
    } catch (err) {
      throw err
    }
  },
  async requestMustReadItems({ commit }) {
    try {
      const route = this.$router.currentRoute
      const categorySlug = (route.params.category || '').toLowerCase()
      const { data, lastModified } = await cachedRequest({
        method: this.$axios.get,
        args: [getApiMethodByCategorySlug(categorySlug)],
        apiVersion: API_VERSION.V1
      })

      commit(types.SET_MUST_READ_ITEMS, { data, lastModified })

      return data
    } catch (err) {
      throw err
    }
  }
}

export const mutations = {
  [types.SET_MUST_READ_ITEMS](state, { data: { Items } = {}, lastModified }) {
    state.mustReadItems = processResponse(Items)
    state.mustReadItemsLastModified = lastModified
  },
  [types.IS_NEXT_REQUEST_SHOULD_BE_SKIPPED](state, val) {
    state.isNextRequestShouldBeSkipped = val
  }
}
