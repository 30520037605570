import { mapActions } from 'vuex'

const VISIBILITY_CHANGE_EVENT = 'visibilitychange'

export default {
  methods: {
    ...mapActions({
      setDocumentVisibilityState: 'setDocumentVisibilityState'
    }),
    $_documentVisibility_setVisibilityState() {
      this.setDocumentVisibilityState(!document.hidden)
    },
    $_documentVisibility_registerListener() {
      document.addEventListener(
        VISIBILITY_CHANGE_EVENT,
        this.$_documentVisibility_setVisibilityState
      )
    },
    $_documentVisibility_removeListener() {
      document.removeEventListener(
        VISIBILITY_CHANGE_EVENT,
        this.$_documentVisibility_setVisibilityState
      )
    }
  },
  mounted() {
    this.$_documentVisibility_setVisibilityState()
    this.$_documentVisibility_registerListener()
  },
  beforeDestroy() {
    this.$_documentVisibility_removeListener()
  }
}
