/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'white-arrow-right': {
    width: 16,
    height: 28,
    viewBox: '0 0 16 28',
    data:
      '<path pid="0" _fill="#FFF" fill-rule="nonzero" d="M0 2.637L2.912 0 16 14.08 2.904 28 .008 25.346l10.614-11.282z"/>'
  }
})
