export const IMAGE_SIZE = {
  ORIGINAL: 'original',
  WIDTH_50: 'WIDTH_50',
  WIDTH_150: 'WIDTH_150',
  WIDTH_260: 'WIDTH_260',
  WIDTH_360: 'WIDTH_360',
  WIDTH_500: 'WIDTH_500',
  WIDTH_775: 'WIDTH_775',
  WIDTH_900: 'WIDTH_900',
  WIDTH_975: 'WIDTH_975'
}

export const IMAGE_SIZE_VALUE = {
  [IMAGE_SIZE.WIDTH_50]: 50,
  [IMAGE_SIZE.WIDTH_150]: 150,
  [IMAGE_SIZE.WIDTH_260]: 260,
  [IMAGE_SIZE.WIDTH_360]: 360,
  [IMAGE_SIZE.WIDTH_500]: 500,
  [IMAGE_SIZE.WIDTH_775]: 775,
  [IMAGE_SIZE.WIDTH_900]: 900,
  [IMAGE_SIZE.WIDTH_975]: 975
}

export const IMAGE_SUFFIX = {
  [IMAGE_SIZE.ORIGINAL]: '_original.',
  [IMAGE_SIZE.WIDTH_50]: '_size50.',
  [IMAGE_SIZE.WIDTH_150]: '_size150.',
  [IMAGE_SIZE.WIDTH_260]: '_size260.',
  [IMAGE_SIZE.WIDTH_360]: '_size360.',
  [IMAGE_SIZE.WIDTH_500]: '_size500.',
  [IMAGE_SIZE.WIDTH_775]: '_size775.',
  [IMAGE_SIZE.WIDTH_900]: '_size900.',
  [IMAGE_SIZE.WIDTH_975]: '_size975.'
}

export const BREAKPOINT_RANGE = {
  [IMAGE_SIZE.WIDTH_50]: [0, 50],
  [IMAGE_SIZE.WIDTH_150]: [51, 150],
  [IMAGE_SIZE.WIDTH_260]: [151, 260],
  [IMAGE_SIZE.WIDTH_360]: [261, 360],
  [IMAGE_SIZE.WIDTH_500]: [361, 500],
  [IMAGE_SIZE.WIDTH_775]: [501, 775],
  [IMAGE_SIZE.WIDTH_900]: [776, 900],
  [IMAGE_SIZE.WIDTH_975]: [901, 975],
  [IMAGE_SIZE.ORIGINAL]: [976, Infinity]
}

export const OVER_CONTENT_IMAGE_WIDTH = 2000

export const SRCSET_LOCATION = {
  ARTICLE_PAGE: 'ARTICLE_PAGE',
  AMP_ARTICLE_PAGE: 'AMP_ARTICLE_PAGE',
  STOCK_HUB_PAGE: 'STOCK_HUB_PAGE',
  COMPANY_TOP_SECTION: 'COMPANY_TOP_SECTION',
  AUTHOR_PAGE: 'AUTHOR_PAGE'
}

export const SIZE_DATA_BY_SRCSET_LOCATION = {
  [SRCSET_LOCATION.AMP_ARTICLE_PAGE]: [
    { minWidth: 546, imageWidth: 775 },
    { minWidth: 406, maxWidth: 545, imageWidth: 500 },
    { minWidth: 306, maxWidth: 405, imageWidth: 360 },
    { minWidth: 196, maxWidth: 305, imageWidth: 260 },
    { minWidth: 96, maxWidth: 195, imageWidth: 150 },
    { maxWidth: 95, imageWidth: 50 }
  ],
  [SRCSET_LOCATION.ARTICLE_PAGE]: [
    { minWidth: 1811, imageWidth: 900 },
    { minWidth: 1701, maxWidth: 1810, imageWidth: 775 },
    { minWidth: 1641, maxWidth: 1700, imageWidth: 900 },
    { minWidth: 1260, maxWidth: 1640, imageWidth: 775 },
    { minWidth: 1223, maxWidth: 1259, imageWidth: 900 },
    { minWidth: 1024, maxWidth: 1222, imageWidth: 775 },
    { minWidth: 901, maxWidth: 1023, imageWidth: 900 },
    { minWidth: 546, maxWidth: 900, imageWidth: 775 },
    { minWidth: 406, maxWidth: 545, imageWidth: 500 },
    { minWidth: 306, maxWidth: 405, imageWidth: 360 },
    { minWidth: 206, maxWidth: 305, imageWidth: 260 },
    { minWidth: 106, maxWidth: 205, imageWidth: 160 },
    { maxWidth: 105, imageWidth: 50 }
  ],
  [SRCSET_LOCATION.STOCK_HUB_PAGE]: [
    { minWidth: 1811, imageWidth: IMAGE_SIZE.ORIGINAL },
    { minWidth: 1701, maxWidth: 1810, imageWidth: 775 },
    { minWidth: 1641, maxWidth: 1700, imageWidth: 900 },
    { minWidth: 1260, maxWidth: 1640, imageWidth: 775 },
    { minWidth: 1223, maxWidth: 1259, imageWidth: 900 },
    { minWidth: 1024, maxWidth: 1222, imageWidth: 775 },
    { minWidth: 901, maxWidth: 1023, imageWidth: 900 },
    { minWidth: 546, maxWidth: 900, imageWidth: 775 },
    { minWidth: 406, maxWidth: 545, imageWidth: 500 },
    { minWidth: 306, maxWidth: 405, imageWidth: 360 },
    { minWidth: 206, maxWidth: 305, imageWidth: 260 },
    { minWidth: 106, maxWidth: 205, imageWidth: 160 },
    { maxWidth: 105, imageWidth: 50 }
  ],
  [SRCSET_LOCATION.COMPANY_TOP_SECTION]: [
    { minWidth: 768, imageWidth: 260 },
    { maxWidth: 767, imageWidth: 150 }
  ],
  [SRCSET_LOCATION.AUTHOR_PAGE]: [
    { minWidth: 768, imageWidth: 360 },
    { maxWidth: 767, imageWidth: 260 }
  ]
}

const IMAGE_PLACEHOLDER_SIZE = IMAGE_SIZE.WIDTH_50
export const IMAGE_PLACEHOLDER_WIDTH =
  BREAKPOINT_RANGE[IMAGE_PLACEHOLDER_SIZE][1]
