import {
  processResponse,
  checkIfLinkHasProtocol,
  removeStartingSlash
} from '@/plugins/helper'
import { findMigratedCategorySlug } from 'enums/categories'
import { DEFAULT_FALLBACK_LINK } from 'enums/links'
import { checkIfMigratedTag } from 'enums/tags'

export function generateFlUrl(link, ctx) {
  return generateFullUrl(link, ctx.$env.DOMAIN_URL)
}

export function generateFmUrl(link, ctx) {
  return generateFullUrl(link, ctx.$env.FM_DOMAIN_URL)
}

export function generateFmDirUrl(link, ctx) {
  return generateFullUrl(link, ctx.$env.FM_DIR_DOMAIN_URL)
}

export function generateFullUrl(link, defaultDomain) {
  if (!link) return defaultDomain

  const linkWithoutStartSlash = removeStartingSlash(link)
  return checkIfLinkHasProtocol(linkWithoutStartSlash)
    ? link
    : `${defaultDomain}/${linkWithoutStartSlash}`
}

export function generateAuthorPath(author) {
  if (!author) return null

  const { slug } = processResponse(author)
  return `/author/${slug}`
}

export function generateAuthorUrl(author, ctx) {
  return generateFlUrl(generateAuthorPath(author), ctx)
}

export function generateCategoryPath(category) {
  const { slug } = processResponse(category)
  const oldCategorySlug = findMigratedCategorySlug(slug)

  return oldCategorySlug ? `/${oldCategorySlug}` : `/${slug}/`
}

export function generateCategoryUrl(category, ctx) {
  return generateFlUrl(generateCategoryPath(category), ctx)
}

export function generateTagPath(tag = {}) {
  const { slug } = processResponse(tag)

  if (!slug) return DEFAULT_FALLBACK_LINK

  return checkIfMigratedTag(slug)
    ? `/Tag/${slug.toLowerCase()}`
    : `/Tag/${slug.toLowerCase()}/`
}

export function generateTagUrl(tag, ctx) {
  return generateFlUrl(generateTagPath(tag), ctx)
}

export function generateTermPath(params = {}) {
  const formattedParams = processResponse(params)
  const { slug: termSlug } = formattedParams

  if (!termSlug) return DEFAULT_FALLBACK_LINK

  return `/terms/${termSlug.charAt(0)}/${termSlug}/`
}

export function generateTermUrl(params = {}, ctx) {
  return generateFlUrl(generateTermPath(params), ctx)
}
