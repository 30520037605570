import { mapActions } from 'vuex'

import { FOLLOWED_ITEM } from '@/utils/enums/personal-area'

export const followInterests = {
  data() {
    return {
      followActions: {
        [FOLLOWED_ITEM.AUTHORS]: {
          FOLLOW: this.requestFollowAuthors,
          UNFOLLOW: this.requestUnfollowAuthors
        }
      }
    }
  },
  computed: {
    $_followInterests_countArray() {
      return [this.FollowedAuthorCount]
    },
    $_followInterests_isFollowedCountsReceived() {
      return this.$_followInterests_countArray.every(value => value !== null)
    },
    $_followInterests_isNoFollowedItems() {
      return this.$_followInterests_countArray.every(value => value === 0)
    }
  },
  methods: {
    ...mapActions({
      requestFollowAuthors: 'personal-area/requestFollowAuthors',
      requestUnfollowAuthors: 'personal-area/requestUnfollowAuthors'
    }),
    async $_followInterests_toggleFollowingItem(itemName, id, isFollowed) {
      try {
        const Ids = Array.isArray(id) ? [...id] : [id]
        await (isFollowed
          ? this.followActions[itemName].FOLLOW({ Ids })
          : this.followActions[itemName].UNFOLLOW({ Ids }))
        this.$emit('refresh-profile-summary')
      } catch (e) {
        throw e
      }
    }
  }
}
