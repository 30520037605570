import { mapActions } from 'vuex'

const SAVED_ENTITY = {
  ARTICLE: 'Article',
  COMPANY: 'Company',
  TAG: 'Tag',
  CATEGORY: 'Category',
  AUTHOR: 'Author'
}

export default {
  computed: {
    $_refreshDataHandler_savedEntity() {
      return SAVED_ENTITY
    }
  },
  methods: {
    ...mapActions({
      requestAuthor: 'author/requestAuthor',
      requestTagBySlug: 'tag/requestTagBySlug'
    }),
    $_refreshDataHandler_updateIsSavedValue(
      { id, value },
      articles = this.articles
    ) {
      return articles.map(article => ({
        ...article,
        ...(article.Id === id ? { IsSaved: value } : {})
      }))
    },
    $_refreshDataHandler_updateIsFollowedTagValue(
      { id, value },
      articles = this.articles
    ) {
      return articles.map(article => {
        if (!article.Tag || article.Tag.Id !== id) return article

        return {
          ...article,
          Tag: {
            ...article.Tag,
            IsFollowed: value
          }
        }
      })
    },
    $_refreshDataHandler_resetActionIconValues(articles = this.articles) {
      return articles.map(article => ({
        ...article,
        IsSaved: false,
        Tag: { ...article.Tag, IsFollowed: false }
      }))
    },
    $_refreshDataHandler_updateArticleSavedEntities(
      { articleIds, tagIds },
      articles = this.articles
    ) {
      return articles.map(article => ({
        ...article,
        ...(articleIds && articleIds.includes(article.Id)
          ? { IsSaved: true }
          : {}),
        ...(tagIds && tagIds.includes(article.Tag.Id)
          ? { Tag: { ...article.Tag, IsFollowed: true } }
          : {})
      }))
    },
    async $_refreshDataHandler_getIsFollowedAuthorValue(payload) {
      try {
        const { IsFollowed } = await this.requestAuthor(payload)
        return IsFollowed
      } catch (err) {
        this.$errorHandler(err, this)
        return false
      }
    },
    async $_refreshDataHandler_getSingleArticleEntities(payload) {
      try {
        const {
          isSaved,
          author: { isFollowed },
          tags
        } = await this.requestArticleContentBySlug(payload)

        return {
          isSaved,
          isFollowed,
          tags
        }
      } catch (err) {
        this.$errorHandler(err, this)
        return {}
      }
    },
    async $_refreshDataHandler_getIsFollowedTagValue(payload) {
      try {
        const { IsFollowed } = await this.requestTagBySlug(payload)
        return IsFollowed
      } catch (err) {
        this.$errorHandler(err, this)
        return false
      }
    }
  }
}
