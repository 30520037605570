<template>
  <div class="form" v-observe-visibility="visibilityOptions">
    <div v-if="inProgress" class="form__overlay" />

    <a-modal-wrapper
      :modal-name="successMessageModal"
      :width="559"
      close-button
    >
      <a-success-message :id="successMsgId" v-bind="successMessageProps" />
    </a-modal-wrapper>

    <slot v-if="isSuccessMsgVisible" name="successMessage">
      <a-success-message :id="successMsgId" v-bind="successMessageProps" />
    </slot>

    <form v-else @submit.prevent="onSubmit" v-bind="formAttrs">
      <h2 v-if="title" :class="titleClasses">
        {{ title }}
      </h2>
      <p v-if="topText" :class="topTextClasses">
        {{ topText }}
      </p>

      <div :key="slotKey">
        <slot />
      </div>

      <div class="form__row form__row-gdpr">
        <slot name="gdpr">
          <a-gdpr
            class="form__gdpr"
            :gdpr="gdpr"
            :value="isTermAccepted"
            :error="errors.isTermAccepted"
            @input="$emit('update:isTermAccepted', $event)"
          />
        </slot>
      </div>

      <a-invisible-captcha
        v-if="isCaptchaEnabled"
        ref="invisibleCaptcha"
        :captcha-key="captchaKey"
        :sitekey="sitekey"
        @update:captchaKey="$emit('update:captchaKey', $event)"
        @verify="onVerifyCaptcha"
      />

      <a-google-captcha-terms-of-use v-if="!disableCaptcha" />

      <a-blocked-captcha-message class="form__captcha-blocked-message" />

      <div v-if="!hideSubmitButton" class="form__row">
        <slot
          v-if="$slots.buttons || $scopedSlots.buttons"
          name="buttons"
          :submit-button-text="submitButtonText"
          :in-progress="inProgress"
          :is-captcha-loaded="isCaptchaLoaded"
        />
        <a-button
          v-else
          type="submit"
          :button-style="submitButtonStyle"
          :in-progress="inProgress"
          :disabled="!isCaptchaLoaded"
          class="form__submit-button"
        >
          {{ submitButtonText }}
        </a-button>
      </div>
    </form>
  </div>
</template>

<script>
import { pathOr } from 'ramda'
import { mapGetters } from 'vuex'

import { ICON } from 'shared/AIcon'
import AGdpr from 'shared/AGdpr'
import ASuccessMessage from 'shared/ASuccessMessage'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import { FORM_TYPE } from 'enums/form-type'
import AGoogleCaptchaTermsOfUse from 'shared/AGoogleCaptchaTermsOfUse/index'
import AInvisibleCaptcha from 'shared/AInvisibleCaptcha/index'
import { BUTTON_STYLE } from 'shared/AButton'
import AModalWrapper from 'modals/AModalWrapper'
import mixins from '@/utils/mixins'
import ABlockedCaptchaMessage from 'shared/ABlockedCaptchaMessage'

export { FORM_TYPE }

const TITLE_CLASS_MODIFIER = {
  [FORM_TYPE.CONTACT_US]: 'form__title_lg subtitle-1',
  [FORM_TYPE.NEWSLETTER]: 'form__title_md title',
  [FORM_TYPE.CONTACT_COMPANY]: 'form__title_md title'
}

const TOP_TEXT_CLASS_MODIFIER = {
  [FORM_TYPE.CONTACT_US]: 'form__top-text_md text-body',
  [FORM_TYPE.NEWSLETTER]: 'form__top-text_md text-body',
  [FORM_TYPE.CONTACT_COMPANY]: 'form__top-text_md text-body',
  [FORM_TYPE.BECOME_AN_AUTHOR]: 'form__top-text_lg subtitle-1'
}

const FORM_TYPE_VALUES = Object.values(FORM_TYPE)

const DEFAULT_ICON = ICON.SENT_LETTER

export default {
  name: 'AForm',
  mixins: [mixins.captchaLazyLoad],
  components: {
    ABlockedCaptchaMessage,
    AModalWrapper,
    AInvisibleCaptcha,
    AGoogleCaptchaTermsOfUse,
    ASuccessMessage,
    AGdpr
  },
  props: {
    formType: propValidator([PROP_TYPES.STRING], false, null, type =>
      FORM_TYPE_VALUES.includes(type)
    ),
    errors: propValidator([PROP_TYPES.OBJECT], false, () => ({})),
    captchaKey: propValidator([PROP_TYPES.STRING], false, ''),
    sitekey: propValidator([PROP_TYPES.ANY], false, undefined),
    disableCaptcha: propValidator([PROP_TYPES.BOOLEAN], false, false),
    isTermAccepted: propValidator([PROP_TYPES.BOOLEAN]),
    successMsgDisplayTime: propValidator([PROP_TYPES.NUMBER], false, 3000),
    scrollToSuccessMessage: propValidator([PROP_TYPES.BOOLEAN], false, false),
    inProgress: propValidator([PROP_TYPES.BOOLEAN], false, false),
    submitButtonText: propValidator([PROP_TYPES.STRING], false, 'Submit'),
    hideTitle: propValidator([PROP_TYPES.BOOLEAN], false, false),
    hideDescription: propValidator([PROP_TYPES.BOOLEAN], false, false),
    successMsgId: propValidator([PROP_TYPES.STRING], false, 'success-message'),
    prefilledForm: propValidator([PROP_TYPES.BOOLEAN], false, true),
    /**
     * You can pass external settins instead of getting them from a backend
     * @param {Object} externalSettings         - external settings object
     * @param {string} externalSettings.Title   - form title
     * @param {string} externalSettings.TopText - top text
     * @param {string} externalSettings.Gdpr    - Gdpr html text
     **/
    externalSettings: propValidator([PROP_TYPES.OBJECT], false, null),
    hideSubmitButton: propValidator([PROP_TYPES.BOOLEAN], false, false),
    submitButtonStyle: propValidator(
      [PROP_TYPES.STRING],
      false,
      BUTTON_STYLE.BLUE
    ),

    /**
     * When we use captcha, we need to know the current state of form validation,
     * since there is no point in executing a captcha when form is invalid
     */
    formValidationFn: propValidator([PROP_TYPES.FUNCTION], false, () => () =>
      true
    ),
    successMessageModal: propValidator([PROP_TYPES.STRING], false, ''),
    useDefaultGdpr: propValidator([PROP_TYPES.BOOLEAN], false, false),
    formId: propValidator([PROP_TYPES.STRING], false)
  },
  data() {
    return {
      // TODO remove this functionality, if not used
      successMessagesIcons: {
        // [FORM_TYPE.NEWSLETTER]: ICON.CHECK_MARK_ROUND_BLACK
      },
      slotKey: 'initial-key',
      isSuccessMsgVisible: false,
      successMessageTimeoutId: null,
      successMessageCloseResolver: null
    }
  },
  computed: {
    ...mapGetters({
      getFormSettingsByType: 'leads/getFormSettingsByType',
      userDetails: 'auth/userDetails',
      isLoggedIn: 'auth/isLoggedIn'
    }),
    formAttrs() {
      return {
        ...(this.formId ? { id: this.formId } : {})
      }
    },
    isCaptchaEnabled() {
      return this.isCaptchaLoaded && !this.disableCaptcha
    },
    visibilityOptions() {
      if (this.disableCaptcha) return false

      return this.formVisibilityOptions
    },
    formSettings() {
      return this.externalSettings || this.getFormSettingsByType(this.formType)
    },
    gdpr() {
      return this.useDefaultGdpr ? undefined : this.formSettings.Gdpr
    },
    title() {
      return this.hideTitle ? null : this.formSettings.Title
    },
    titleClasses() {
      return ['form__title', TITLE_CLASS_MODIFIER[this.formType]]
    },
    topTextClasses() {
      return ['form__top-text', TOP_TEXT_CLASS_MODIFIER[this.formType]]
    },
    topText() {
      return this.hideDescription ? null : this.formSettings.TopText
    },
    successMessageProps() {
      return {
        icon: pathOr(DEFAULT_ICON, [this.formType], this.successMessagesIcons),
        title: pathOr('', ['SuccessMessageTitle'], this.formSettings),
        text: pathOr('', ['SuccessMessageText'], this.formSettings)
      }
    }
  },
  methods: {
    pathOr,
    onSubmit() {
      if (this.disableCaptcha) {
        this.$emit('submit')
        return
      }

      if (this.formValidationFn()) {
        this.$refs.invisibleCaptcha.execute()
      }
    },
    onVerifyCaptcha() {
      this.$emit('submit')
    },
    scrollToSuccessMessageContainer() {
      this.$nextTick(() =>
        this.$scrollTo(`#${this.successMsgId}`, 500, { offset: -200 })
      )
    },
    showSuccessMessageInModal() {
      this.$helper.openModal(this.successMessageModal)

      return new Promise(resolve => {
        this.successMessageCloseResolver = resolve

        this.successMessageTimeoutId = setTimeout(() => {
          this.$helper.closeModal(this.successMessageModal)
          resolve()
          this.successMessageCloseResolver = null
        }, this.successMsgDisplayTime)
      })
    },
    showSuccessMessage() {
      this.isSuccessMsgVisible = true
      if (this.scrollToSuccessMessage) {
        this.scrollToSuccessMessageContainer()
      }

      return new Promise(resolve => {
        this.successMessageCloseResolver = resolve

        this.successMessageTimeoutId = setTimeout(() => {
          this.hideSuccessMessage()
          resolve()
          this.successMessageCloseResolver = null
        }, this.successMsgDisplayTime)
      })
    },
    hideSuccessMessage() {
      this.$nextTick(() => {
        this.isSuccessMsgVisible = false
      })
    },
    runPreFilledValuesWatcher() {
      const areDetailsFetched = !!this.userDetails.Id
      if (!this.prefilledForm || !this.isLoggedIn || areDetailsFetched) return

      const unwatch = this.$watch('userDetails', ({ Id }) => {
        if (Id) this.slotKey = this.$helper.guid()
        unwatch()
      })
    }
  },
  beforeMount() {
    this.runPreFilledValuesWatcher()
  },
  beforeDestroy() {
    clearTimeout(this.successMessageTimeoutId)

    if (this.successMessageCloseResolver) {
      this.successMessageCloseResolver()
    }
  }
}
</script>

<style lang="scss">
.form {
  position: relative;

  .form__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }

  .form__title {
    &_sm {
      margin: 0 0 5px 0;
    }

    &_md {
      margin: 0 0 20px 0;
    }

    &_lg {
      margin: 0 0 30px 0;

      @include mobile {
        margin: 0 0 10px 0;
      }
    }

    &_legend {
      margin-bottom: 20px;
      max-width: 476px;
    }
  }

  .form__top-text {
    margin: 0 0 18px 0;

    &_pre-line {
      white-space: pre-line;
    }

    &_lg {
      font-size: 19px;
      line-height: 29px;
      font-weight: 300;

      @include mobile {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .form__row {
    position: relative;
    display: flex;
    margin-bottom: 30px;

    > *:not(:last-child) {
      margin-right: 20px;
    }

    @include mobile {
      flex-wrap: wrap;

      > *:not(:last-child) {
        margin-right: inherit;
        margin-bottom: 30px;
      }

      > .form__recaptcha {
        margin-bottom: 0;
      }

      .btn--link {
        font-size: 16px;
      }
    }
  }

  .form__row:last-child {
    margin-bottom: 0;
  }

  .form__submit-button {
    width: 140px;
    margin-top: 13px;

    @include mobile {
      width: 100%;
      margin: 10px auto 0;
    }
  }

  .form__row .form__checkbox .checkbox__label {
    color: #626262;

    a {
      color: inherit;
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .form__captcha-blocked-message {
    margin: 20px 0;
  }

  .form__gdpr {
    width: 100%;
    margin-top: 30px;
  }
}
</style>
