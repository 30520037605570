import { isConsentGivenForEntity } from '@/utils/mixins/one-trust'
import { HANDLER_TYPE, HANDLER_TYPE_BY_ENTITY_TYPE } from 'enums/oneTrust/index'
import { checkIfFunction } from '@/plugins/helper'

class OneTrustHandler {
  constructor(handler, entityType) {
    this._handler = handler
    this._entityType = entityType
  }
  get entityType() {
    return this._entityType
  }
  get isLocal() {
    if (!HANDLER_TYPE_BY_ENTITY_TYPE[this.entityType]) return true

    return HANDLER_TYPE_BY_ENTITY_TYPE[this.entityType] === HANDLER_TYPE.LOCAL
  }
  isConsentGiven() {
    return isConsentGivenForEntity(this.entityType)
  }
  execute() {
    if (checkIfFunction(this._handler)) {
      this._handler()
    }
  }
}

export function createOneTrustHandlerInstance({ handler, entityType }) {
  return new OneTrustHandler(handler, entityType)
}
