import { REFS } from 'enums/external-refs'

export const SPECIAL_MENU_ITEM_ID = 'SPECIAL_MENU_ITEM'

const HEADER_ITEM_NAME = {
  MORE: 'More',
  FOREX_TERMS: 'Forex Terms'
}

const HEADER_ITEM_NAME_LOWER_CASED = Object.entries(HEADER_ITEM_NAME).reduce(
  (acc, [k, v]) => {
    acc[k] = v.toLowerCase()

    return acc
  },
  {}
)

export const HEADER_ITEM_OPTIONS_BY_NAME = {
  [HEADER_ITEM_NAME_LOWER_CASED.MORE]: {
    Id: SPECIAL_MENU_ITEM_ID,
    attrs: {
      'data-ref': REFS.HEADER_ITEM_MORE
    }
  },
  [HEADER_ITEM_NAME_LOWER_CASED.FOREX_TERMS]: {
    attrs: {
      'data-ref': REFS.HEADER_ITEM_FOREX_TERMS
    }
  }
}
