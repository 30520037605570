import * as types from '@/store/mutation-types'
import parser from 'ua-parser-js'
import { getDeviceInfo } from '@/utils/helpers/userAgent'
import {
  BOT_USER_AGENT_LIST,
  BOT_USER_AGENT_REGEX
} from 'enums/bot-user-agents'

export default function({ store: { commit } }) {
  if (process.server) return

  const userAgent = navigator.userAgent || ''
  const userAgentDetails = parser(userAgent)
  const isBot = checkIfBot(userAgent)

  commit(types.SET_IS_CURRENT_USER_BOT, isBot)
  commit(types.SET_USER_AGENT, userAgent)
  commit(types.SET_USER_AGENT_DETAILS, userAgentDetails)
  commit(types.SET_INITIAL_DEVICE, {
    ...getDeviceInfo(userAgent)
  })
}

function checkIfBot(userAgent) {
  const isBotFoundByRegex = userAgent.match(BOT_USER_AGENT_REGEX)
  const isBotFoundByKnownAgents = BOT_USER_AGENT_LIST.includes(userAgent)
  return !userAgent || isBotFoundByRegex || isBotFoundByKnownAgents
}
