export const ARTICLE_MODEL = {
  ArticleUrl: '',
  ArticleId: '',
  ArticleDetails: {
    Author: {
      Id: '',
      Name: '',
      Slug: '',
      Status: '',
      Bio: '',
      Photo: '',
      FollowersCount: 0,
      ArticlesCount: 0,
      IsFollowed: false,
      IsSponsored: false,
      IsCompany: false,
      CompanyLogoUrl: null
    },
    StickerName: '',
    StickerUrl: '',
    ThoughtLeadershipLogo: null,
    IsSessionWrap: false,
    BodyImages: [],
    BodyVideos: []
  },
  Categories: {
    CategoryName: '',
    CategorySlug: '',
    SubCategoryName: '',
    SubCategorySlug: ''
  },
  Body: '',
  Brief: '',
  Topic: '',
  Tags: [
    {
      Id: '',
      Slug: '',
      Name: '',
      IsMain: true
    }
  ],
  AutomaticLink: null,
  DirectoryCategory: {
    Slug: '',
    Id: '',
    Name: '',
    Description: ''
  },
  Companies: [
    {
      Id: '',
      Name: '',
      Slug: '',
      LogoURL: ''
    }
  ],
  Ebook: null,
  Offer: null,
  Terms: [{ Id: '', Name: '', Slug: '', Description: '' }]
}
