<template>
  <span />
</template>

<script>
import { removeEndingSlashes } from '@fmpedia/helpers'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import { parseUrl } from '@/plugins/helper'
import { COOKIE_ENTITY_TYPE } from 'enums/oneTrust'
import { mapActions } from 'vuex'
import { createOneTrustHandlerInstance } from 'enums/oneTrust/one-trust-handler'

const COOKIE_ENTITY_BY_SCRIPT_ADDRESS = {
  'https://platform.twitter.com/widgets.js': COOKIE_ENTITY_TYPE.TWITTER_CARD
}

export default {
  name: 'AScript',
  props: {
    src: propValidator([PROP_TYPES.STRING], false)
  },
  data() {
    return { script: null }
  },
  computed: {
    srcAddress() {
      return removeEndingSlashes(parseUrl(this.src).address)
    },
    relevantCookieEntity() {
      return (
        COOKIE_ENTITY_BY_SCRIPT_ADDRESS[this.srcAddress] ||
        COOKIE_ENTITY_TYPE.UNKNOWN
      )
    }
  },
  methods: {
    ...mapActions({
      registerHandlerThatRequiresConsent:
        'one-trust/registerHandlerThatRequiresConsent'
    }),
    addScript() {
      if (!this.src && !this.$slots.default) return

      this.script = document.createElement('script')
      this.script.type = 'text/javascript'

      if (this.src) {
        this.script.src = this.src
      } else {
        this.script.innerText = this.$slots.default[0].text
      }

      this.$el.parentNode.replaceChild(this.script, this.$el)
    }
  },
  mounted() {
    this.registerHandlerThatRequiresConsent(
      createOneTrustHandlerInstance({
        handler: this.addScript,
        entityType: this.relevantCookieEntity
      })
    )
  },
  beforeDestroy() {
    if (this.script) {
      this.$helper.removeNode(this.script)
    }
  }
}
</script>
