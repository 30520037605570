import api from '~/utils/api'
import * as types from '~/store/mutation-types'

export const state = () => ({
  newNotificationCount: 0
})

export const getters = {
  newNotificationCount: ({ newNotificationCount }) => newNotificationCount
}

export const actions = {
  async requestBellIconItems() {
    try {
      const { data } = await this.$fmAxios.get(
        api.notifications.getBellIconItems()
      )
      return data
    } catch (e) {
      throw e
    }
  },
  async requestMarkReadBellIcon() {
    try {
      await this.$fmAxios.get(api.notifications.markReadBellIcon())
    } catch (e) {
      throw e
    }
  },
  async requestDeleteBellIconItem(_, payload) {
    try {
      await this.$fmAxios.delete(api.notifications.deleteBellIconItem(), {
        data: payload
      })
    } catch (e) {
      throw e
    }
  },
  async requestClearAllBellIcon() {
    try {
      await this.$fmAxios.delete(api.notifications.clearAllBellIcon())
    } catch (e) {
      throw e
    }
  },
  requestSetNewNotificationCount({ commit }, payload) {
    commit(types.SET_NEW_NOTIFICATION_COUNT, payload)
  }
}

export const mutations = {
  [types.SET_NEW_NOTIFICATION_COUNT](state, count) {
    state.newNotificationCount = count
  }
}
