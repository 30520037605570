// Lighthouse requires using { passive: true } when adding scroll/touch events
let supportsPassive = false

try {
  window.addEventListener(
    'test',
    null,
    Object.defineProperty({}, 'passive', {
      get: function() {
        supportsPassive = true
      }
    })
  )
} catch (e) {}

export const scrollTouchEventListenerOptions = supportsPassive
  ? { passive: true }
  : false
