<template>
  <div
    v-if="!disable"
    ref="parent"
    class="a-scrollable-content__parent-wrapper"
    :class="dynamicClass"
  >
    <div
      ref="scroll"
      class="a-scrollable-content__scroll-wrapper"
      :style="scrollWrapperStyles"
    >
      <div ref="content" class="a-scrollable-content__content-wrapper">
        <slot />
      </div>
    </div>
  </div>

  <div class="a-scrollable-content__parent-wrapper" v-else>
    <slot />
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'

export default {
  name: 'AScrollableContent',
  props: {
    vertical: propValidator([PROP_TYPES.BOOLEAN], false, true),
    horizontal: propValidator([PROP_TYPES.BOOLEAN], false, false),
    isCentered: propValidator([PROP_TYPES.BOOLEAN], false, false),
    scrollWrapperStyles: propValidator([PROP_TYPES.OBJECT], false, null),
    disable: propValidator([PROP_TYPES.BOOLEAN], false, false)
  },
  computed: {
    dynamicClass() {
      return {
        vertical: this.vertical,
        horizontal: this.horizontal
      }
    }
  },
  methods: {
    centerScroll() {
      if (!this.$refs.parent || !this.$refs.content) return

      const parentWidth = this.$refs.parent.clientWidth
      const contentWidth = this.$refs.content.clientWidth
      const scroll = this.$refs.scroll

      const scrollValue = Math.round((contentWidth - parentWidth) / 2)
      if (scrollValue > 0) {
        scroll.scroll(scrollValue, 0)
      }
    }
  },
  mounted() {
    if (this.horizontal && this.isCentered) {
      this.$nextTick(this.centerScroll)
    }
  }
}
</script>

<style lang="scss" scoped>
.a-scrollable-content__parent-wrapper {
  width: 100%;
  overflow: hidden;

  .a-scrollable-content__scroll-wrapper {
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .a-scrollable-content__content-wrapper {
    width: 100%;
    height: 100%;
    max-height: 100vh;
  }

  &.vertical {
    > .a-scrollable-content__scroll-wrapper {
      overflow-y: scroll;
      padding-right: 5px;
      width: calc(100% + 5px); /* 5px of padding */

      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar /* Safari and Chrome */ {
        width: 0;
      }

      @include safari-browser {
        width: 100%;
      }

      & > .a-scrollable-content__content-wrapper {
        max-width: 100vw;
        width: 100%;
      }
    }
  }

  &.horizontal {
    > .a-scrollable-content__scroll-wrapper {
      overflow-x: scroll;
      padding-bottom: 5px;
      height: calc(100% + 20px); /* 5px of padding + 15px of scrollbar */

      > .a-scrollable-content__content-wrapper {
        width: max-content;
        height: calc(100% - 15px);
      }
    }
  }
}
</style>
