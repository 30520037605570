export const ROUTE = {
  ALL_NEWS: '/',
  RSS: '/rss',
  TERMS_OF_USE: '/terms-of-use',
  PRIVACY: '/privacy',
  NEWSLETTER: '/signup',
  CONTACT_US: '/contact-us',
  FORGOT_PASSWORD: '/forgot-password',
  FL_DIR_HP: '/ForexBrokers',
  TERMS: '/terms',
  TERMS_SEARCH_RESULTS: '/terms/search-results/',
  TAG_SEARCH_RESULTS: '/tag/search-results/',
  ABOUT: '/About', // used in redirects https://adraba.atlassian.net/browse/FMP-11588
  ECONOMIC_CALENDAR: '/EconomicCalendar',
  EDUCATION: '/Education',
  SEARCH_RESULTS: '/search-results/'
}

export const FM_DIR_ROUTE = {
  SEARCH_RESULTS: '/search-results',
  COMPANY_NEWS: '/news-and-articles'
}

export const FM_ROUTE = {
  HOMEPAGE: '/',
  ALL_NEWS: '/all-news',
  INTELLIGENCE: '/intelligence',
  INTELLIGENCE_PRODUCTS: '/intelligence/products',
  INTELLIGENCE_PRODUCTS_ANNUAL: '/intelligence/products/annual',
  INTELLIGENCE_PRODUCTS_QIR: '/intelligence/products/qir',
  EVENTS: '/events',
  INTERVIEW: '/executives/interviews',
  PERSONAL_PROFILE: '/profile',
  PERSONAL_PROFILE_ANNOUNCEMENTS: '/profile/announcements',
  PERSONAL_PROFILE_FOLLOWED_AUTHORS: '/profile/followed-authors',
  PERSONAL_PROFILE_FOLLOWED_COMPANIES: '/profile/followed-companies',
  PERSONAL_PROFILE_FOLLOWED_TOPICS: '/profile/followed-topics',
  PERSONAL_PROFILE_MY_COMPANIES: '/profile/my-companies',
  PERSONAL_PROFILE_ADD_COMPANY: '/profile/my-companies/add-company',
  PERSONAL_PROFILE_MY_CONTRIBUTIONS: '/profile/my-contributions',
  PERSONAL_PROFILE_NOTIFICATIONS: '/profile/notifications',
  PERSONAL_PROFILE_SAVED_FOR_LATER: '/profile/saved',
  FORGOT_PASSWORD: '/forgot-password',
  TERMS: '/terms',
  SEARCH_RESULTS: '/search-results'
}

export const ROUTE_NAME = {
  ALL_NEWS: 'index',
  CATEGORY: 'category',
  CATEGORY_ARTICLE: 'category-article',
  CATEGORY_ARTICLE_AMP: 'category-article-amp',
  CATEGORY_ARTICLE_GIBBERISH: 'category-article-gibberish',
  CATEGORY_MIGRATED_ARTICLE_GIBBERISH: 'category-article-migrated-gibberish',
  SHARED_COMPONENTS: 'shared-components',
  RSS: 'rss',
  TERMS_OF_USE: 'terms-of-use',
  PRIVACY: 'privacy',
  COOKIES: 'cookies',
  CONTACT_US: 'contact-us',
  TERMS: 'terms',
  TERMS_LETTER: 'terms-letter',
  TERMS_LETTER_TERM: 'terms-letter-term',
  TERMS_SEARCH_RESULTS: 'terms-search-results',
  NEWSLETTER: 'SignUp',
  SEARCH_RESULTS: 'search-results',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  AUTHOR: 'author',
  AUTHOR_AUTHOR: 'author-author',
  TAG: 'Tag',
  TAG_TAG: 'Tag-tag',
  TAG_SEARCH_RESULTS: 'Tag-search-results',
  FOREX_BROKERS: 'ForexBrokers',
  BROKERS: 'Brokers',
  DIRECTORY_COMPANY: 'Brokers-company',
  BECOME_AN_AUTHOR: 'become-an-author',
  EDUCATION: 'Education',
  ECONOMIC_CALENDAR: 'EconomicCalendar',
  LIVE_CHARTS: 'LiveCharts',
  LIVE_QUOTES: 'LiveQuotes',
  LIVE_QUOTES_ASSET: 'LiveQuotes-asset',
  SESSION_WRAPS: 'SessionWraps',
  REACTIVATION: 'reactivation',
  STOCKS: 'stocks',
  STOCKS_STOCK: 'stocks-stock'
}

export const AMP_PAGES = [ROUTE_NAME.CATEGORY_ARTICLE_AMP]

export const FL_DIR_ROUTE_NAMES = [
  ROUTE_NAME.FOREX_BROKERS,
  ROUTE_NAME.DIRECTORY_COMPANY
]

export const GUARDED_ROUTES = [ROUTE_NAME.BECOME_AN_AUTHOR]
