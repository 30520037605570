import * as types from './mutation-types'

export const state = () => ({
  dynamicRoutesBreadcrumbs: []
})

export const getters = {
  breadcrumbs: state => state.dynamicRoutesBreadcrumbs
}

export const actions = {
  setupBreadcrumbs({ commit }, breadcrumbs) {
    commit(types.SET_DYNAMIC_PAGES_BREADCRUMBS, breadcrumbs)
  }
}

export const mutations = {
  [types.SET_DYNAMIC_PAGES_BREADCRUMBS](state, breadcrumbs) {
    state.dynamicRoutesBreadcrumbs = breadcrumbs
  }
}
