import { mapGetters } from 'vuex'

export const METHOD_NAME_TO_RUN_ON_LOGOUT = 'runOnLogout'
export const METHOD_NAME_TO_RUN_ON_LOGIN = 'runOnLogin'

export const runMethodOnLoginLogout = {
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn'
    })
  },
  watch: {
    isLoggedIn: {
      handler(newVal) {
        const method = newVal
          ? this[METHOD_NAME_TO_RUN_ON_LOGIN]
          : this[METHOD_NAME_TO_RUN_ON_LOGOUT]

        if (method && this.$helper.checkIfFunction(method)) {
          method()
        }
      }
    }
  }
}
