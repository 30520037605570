export const HEADER_STATE = {
  COLLAPSED: 'collapsed',
  EXPANDED: 'expanded'
}

export const HEADER_ID = 'header-menu'

export const TOP_STICKY_CONTAINER_ID = 'top-sticky-container'
//
// export const HEADER_ICON_SIZE = {
//   DESKTOP: 32,
//   MOBILE: 20
// }
//
export const HEADER_HEIGHT = {
  DESKTOP_EXPANDED: 100,
  DESKTOP_COLLAPSED: 65,
  TABLET: 60,
  MOBILE: 60
}
