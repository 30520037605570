const forexLiveTm = 'FOREXLIVE™'
export const HIGH_RISK_WARNING_TITLE = 'High risk warning:'
export const HIGH_RISK_WARNING_TEXT =
  'Foreign exchange trading carries a high level of risk that may not be suitable for all investors. Leverage creates additional risk and loss exposure. Before you decide to trade foreign exchange, carefully consider your investment objectives, experience level, and risk tolerance. You could lose some or all your initial investment; do not invest money that you cannot afford to lose. Educate yourself on the risks associated with foreign exchange trading and seek advice from an independent financial or tax advisor if you have any questions.'

export const ADVISORY_WARNING_TITLE = 'Advisory warning:'
export const ADVISORY_WARNING_TEXT = `${forexLiveTm} is not an investment advisor, ${forexLiveTm} provides references and links to selected news, blogs and other sources of economic and market information for informational purposes and as an educational service to its clients and prospects and does not endorse the opinions or recommendations of the blogs or other sources of information. Clients and prospects are advised to carefully consider the opinions and analysis offered in the blogs or other information sources in the context of the client or prospect's individual analysis and decision making. None of the blogs or other sources of information is to be considered as constituting a track record. Past performance is no guarantee of future results and ${forexLiveTm} specifically hereby acknowledges clients and prospects to carefully review all claims and representations made by advisors, bloggers, money managers and system vendors before investing any funds or opening an account with any Forex dealer. Any news, opinions, research, data, or other information contained within this website is provided on an "as-is" basis as a general market commentary and does not constitute investment or trading advice, and we do not purport to present the entire relevant or available public information with respect to a specific market or security. ${forexLiveTm} expressly disclaims any liability for any lost principal or profits which may arise directly or indirectly from the use of or reliance on such information, or with respect to any of the content presented within its website, nor its editorial choices.`
export const BILLING_ADDRESS = 'Finance Magnates CY Limited'
export const DISCLAIMER_TITLE = 'Disclaimer:'
export const DISCLAIMER_TEXT = `${forexLiveTm} may be compensated by the advertisers that appear on the website, based on your interaction with the advertisements or advertisers.`
