export const PENDING_ACTION = {
  SAVE_ARTICLE: 'SAVE_ARTICLE',
  SAVE_DIR_ARTICLE: 'SAVE_DIR_ARTICLE',
  SAVE_SEARCH_HISTORY: 'SAVE_SEARCH_HISTORY',
  FOLLOW_TAG: 'FOLLOW_TAG',
  FOLLOW_AUTHOR: 'FOLLOW_AUTHOR',
  FOLLOW_COMPANY: 'FOLLOW_COMPANY',
  ADD_COMPANY: 'ADD_COMPANY',
  BECOME_AN_AUTHOR: 'BECOME_AN_AUTHOR',
  REDIRECT_BACK_TO_PROFILE_URL: 'REDIRECT_BACK_TO_PROFILE_URL',
  OPEN_ARTICLE_NEWSLETTER_WIDGET: 'OPEN_ARTICLE_NEWSLETTER_WIDGET'
}

export const PENDING_ACTION_EVENT = {
  START_PENDING_ACTION: 'start-pending-action',
  END_PENDING_ACTION: 'end-pending-action',
  REFRESH_SAVE_FOR_LATER: 'refresh-save-for-later',
  REFRESH_SAVE_FOR_LATER_DIR: 'refresh-save-for-later-dir',
  REFRESH_FOLLOW_TAG: 'refresh-follow-tag',
  REFRESH_FOLLOW_AUTHOR: 'refresh-follow-author',
  REFRESH_FOLLOW_COMPANY: 'refresh-follow-company'
}

export const REFRESH_EVENT_BY_PENDING_ACTION = {
  [PENDING_ACTION.SAVE_ARTICLE]: PENDING_ACTION_EVENT.REFRESH_SAVE_FOR_LATER,
  [PENDING_ACTION.FOLLOW_TAG]: PENDING_ACTION_EVENT.REFRESH_FOLLOW_TAG,
  [PENDING_ACTION.FOLLOW_AUTHOR]: PENDING_ACTION_EVENT.REFRESH_FOLLOW_AUTHOR,
  [PENDING_ACTION.SAVE_DIR_ARTICLE]:
    PENDING_ACTION_EVENT.REFRESH_SAVE_FOR_LATER_DIR,
  [PENDING_ACTION.FOLLOW_COMPANY]: PENDING_ACTION_EVENT.REFRESH_FOLLOW_COMPANY
}

export const METHOD_NAME = {
  HANDLE_START_PENDING_ACTION: 'handleStartPendingAction',
  HANDLE_END_PENDING_ACTION: 'handleEndPendingAction',
  UPDATE_IS_SAVED_VALUE: 'updateIsSavedValue',
  UPDATE_IS_FOLLOWED_TAG_VALUE: 'updateIsFollowedTagValue',
  UPDATE_IS_FOLLOWED_AUTHOR_VALUE: 'updateIsFollowedAuthorValue'
}
