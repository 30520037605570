<template>
  <client-only>
    <collapse-transition>
      <div
        v-if="$_validation_hasValidationError(error)"
        class="input-group__error"
      >
        {{ $_validation_errorMessages(error)[0] }}
      </div>
    </collapse-transition>
  </client-only>
</template>

<script>
import mixins from '@/utils/mixins'
import { propValidator, PROP_TYPES } from '@/utils/validators'

export default {
  name: 'AValidationMessage',
  mixins: [mixins.validation],
  props: {
    error: propValidator([PROP_TYPES.OBJECT], false, () => ({}))
  }
}
</script>
