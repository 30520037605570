/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'envelope-rect-blue': {
    width: 35,
    height: 35,
    viewBox: '0 0 35 35',
    data:
      '<g _fill="none" fill-rule="evenodd"><path pid="0" _fill="#069ACF" d="M35 0v35H0V0z"/><g _fill="#FFF" fill-rule="nonzero"><path pid="1" d="M8.417 11.217c2.745 2.325 7.562 6.414 8.978 7.69.19.172.393.26.605.26.211 0 .415-.087.604-.259 1.417-1.276 6.234-5.366 8.979-7.691a.417.417 0 00.058-.575A1.656 1.656 0 0026.333 10H9.667c-.511 0-.988.234-1.308.642a.418.418 0 00.058.575z"/><path pid="2" d="M27.758 13.039a.414.414 0 00-.444.06c-3.044 2.58-6.93 5.888-8.152 6.99-.686.62-1.638.62-2.325-.001-1.303-1.174-5.667-4.883-8.151-6.989a.416.416 0 00-.686.318v9.478c0 .92.747 1.667 1.667 1.667h16.666c.92 0 1.667-.747 1.667-1.667v-9.478a.416.416 0 00-.242-.378z"/></g></g>'
  }
})
