<template>
  <div :class="cardWrapperClass">
    <div class="card__content">
      <slot />
    </div>
    <slot name="bottom-section" />
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'

export default {
  name: 'ACard',
  props: {
    applyOnDesktop: propValidator([PROP_TYPES.BOOLEAN], false, true),
    applyOnMobile: propValidator([PROP_TYPES.BOOLEAN], false, true)
  },
  computed: {
    cardWrapperClass() {
      return [
        'card__wrapper',
        { 'apply-on-mobile': this.applyOnMobile },
        { 'apply-on-desktop': this.applyOnDesktop }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.card__wrapper {
  &.apply-on-desktop {
    @include tablet-and-higher {
      padding: 20px;
      background: $c--disabled;

      & > .card__content {
        padding: 20px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        background: $c--white;
      }
    }
  }

  &.apply-on-mobile {
    @include mobile {
      padding: 15px;
      margin-left: -#{$mobile-body-padding};
      margin-right: -#{$mobile-body-padding};
      background: $c--disabled;

      & > .card__content {
        padding: 0;
        box-shadow: none;
        background: $c--disabled;
      }
    }
  }
}
</style>
