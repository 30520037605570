function getIdSelector(id) {
  return `#${id}`
}

export const ACTIVATOR_ID = {
  AUTH: 'auth-btn',
  SUGGEST_A_TERM: 'suggest-a-term-button',
  NEWSLETTER_WIDGET_BUTTON: 'contact-widget__newsletter-button',
  NEWSLETTER_WIDGET_ERROR_PAGE: 'newsletter-button-error-page',
  CONTACT_US_WIDGET_BUTTON: 'contact-widget__contact-us-button',
  CONTACT_US_WIDGET_ERROR_PAGE: 'contact-us-button-error-page',
  HEADER_NOTIFICATIONS: 'header-notification-icon'
}

export const ACTIVATOR_SELECTOR_BY_ACTIVATOR_ID = Object.entries(
  ACTIVATOR_ID
).reduce(
  (acc, [, value]) => ({
    ...acc,
    [value]: getIdSelector(value)
  }),
  {}
)
