import * as general from '@/utils/mixins/general'
import * as googleBanners from '@/utils/mixins/banners'
import * as personalProfile from '@/utils/mixins/personal-profile'
import { oneSignal } from '@/utils/mixins/one-signal'
import { prefilledForm } from '@/utils/mixins/prefilled-form'
import { userDetails } from '@/utils/mixins/user-details'
import { runMethodOnLogout } from '@/utils/mixins/run-method-on-logout'
import articleDataHandler from '@/utils/mixins/article-data-handler'
import pendingAuthActions from '@/utils/mixins/pending-auth-actions'
import pendingAuthActionsListenersFactory from '@/utils/mixins/pending-auth-actions-listeners-factory'
import { lazyLoadModal } from '@/utils/mixins/lazy-load-modal'
import refDimensions from '@/utils/mixins/ref-dimensions'
import { authError } from '@/utils/mixins/auth-error'
import { componentValidation } from '@/utils/mixins/component-validation'
import dataValidator from '@/utils/mixins/data-validator'
import refreshDataHandler from '@/utils/mixins/refresh-data-handler'
import { paginatedPageValidation } from '@/utils/mixins/pageValidation'
import { runMethodOnLoginLogout } from '@/utils/mixins/run-method-on-login-logout'
import signalr from '@/utils/mixins/signalr'
import documentVisibility from '@/utils/mixins/document-visibility'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import { captchaLazyLoad } from '@/utils/mixins/captcha-lazy-load'
import { bannerRotation } from '@/utils/mixins/banners-rotation'
import oneTrust from '@/utils/mixins/one-trust'
import trackSearch from '@/utils/mixins/track-search'
import ebookDataFetchMixinFactory from '@/utils/mixins/ebook-data-fetch-mixin-factory'
import authTracking from '@/utils/mixins/auth-tracking'
import disqusCommentCountMixinFactory from '@/utils/mixins/disqus-comment-count-mixin-factory'
import internetConnectionState from '@/utils/mixins/internet-connection-state'
import oneTrustListener from '@/utils/mixins/one-trust-listener'
import actionsOnQueryParams from '@/utils/mixins/actions-on-query-params'
import articleScheme from '@/utils/mixins/article-scheme'
import rootContainer from '@/utils/mixins/root-container'

export default {
  ...general,
  ...googleBanners,
  oneSignal,
  prefilledForm,
  personalProfile,
  runMethodOnLogout,
  userDetails,
  pendingAuthActions,
  pendingAuthActionsListenersFactory,
  lazyLoadModal,
  refDimensions,
  authError,
  componentValidation,
  dataValidator,
  refreshDataHandler,
  paginatedPageValidation,
  articleDataHandler,
  runMethodOnLoginLogout,
  signalr,
  documentVisibility,
  hydrationHelpers,
  captchaLazyLoad,
  bannerRotation,
  oneTrust,
  trackSearch,
  ebookDataFetchMixinFactory,
  authTracking,
  disqusCommentCountMixinFactory,
  internetConnectionState,
  oneTrustListener,
  actionsOnQueryParams,
  articleScheme,
  rootContainer
}
