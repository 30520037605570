export const FORM_ID = {
  BECOME_AN_AUTHOR: 'become-an-author-form',
  CONTACT_US: 'contact-us-form',
  CONTACT_US_WIDGET: 'contact-us-widget-form',
  CONTACT_US_MODAL: 'contact-us-modal-form',
  COMPANY_CONTACT_US: 'company-contact-us-widget-form',
  NEWSLETTER: 'newsletter-form',
  NEWSLETTER_SIDEBAR: 'newsletter-sidebar-form',
  NEWSLETTER_MODAL: 'newsletter-modal-form',
  NEWSLETTER_WIDGET: 'newsletter-widget-form',
  FORGOT_PASSWORD: 'forgot-password-form',
  RESET_PASSWORD: 'reset-password-form',
  SUGGEST_A_TERM: 'suggest-a-term-form',
  SIGN_IN_EMAIL: 'sign-in-email-form',
  SIGN_UP_EMAIL: 'sign-up-email-form',
  EBOOK: 'ebook-form',
  EBOOK_SIDEBAR: 'ebook-sidebar-form',
  EBOOK_MODAL: 'ebook-modal-form'
}
