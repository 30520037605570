import { addEndingSlash } from '@fmpedia/helpers'
import {
  prettifyPath,
  removePaginationPartFromPath,
  asyncRedirectHandler
} from '@/plugins/helper'
import { REDIRECT_TYPE, REDIRECT_TYPE_CODES } from 'enums/seo-shared'
import { findArticleCategorySlugByCategorySlug } from 'enums/categories'

export function generateCommonCanonicalLink(ctx, { pagination = true } = {}) {
  const { path } = ctx.$route || ctx.route
  const processedPath = pagination ? path : removePaginationPartFromPath(path)

  const generatedLinkPath = prettifyPath(processedPath)
  const generatedLink = `${ctx.$env.DOMAIN_URL}${generatedLinkPath}`

  return handlePaginationOfCanonicalLink(generatedLink, processedPath)
}

export async function redirectIfNonCanonicalUrl(ctx, canonicalUrlWithDomain) {
  const pathWithDomain = `${ctx.$env.DOMAIN_URL}${ctx.route.path}`
  const canonicalUrl =
    canonicalUrlWithDomain || generateCommonCanonicalLink(ctx)

  if (pathWithDomain !== canonicalUrl) {
    await asyncRedirectHandler(ctx, {
      type: REDIRECT_TYPE_CODES[REDIRECT_TYPE.MOVED_PERMANENTLY],
      redirectToUrl: canonicalUrl,
      redirectToQueryParams: (ctx.route || ctx.$route).query
    })
    return true
  }
  return false
}

const FIRST_PAGINATED_PAGE_NUMBER = 1
const PAGINATION_REGEX = /\/page\/([0-9]+)\/*$/i

export function handlePaginationOfCanonicalLink(canonicalLink, path) {
  const paginationMatch = path.match(PAGINATION_REGEX)
  const canonicalLinkWithoutPagination = canonicalLink.replace(
    PAGINATION_REGEX,
    ''
  )

  if (!paginationMatch) return canonicalLinkWithoutPagination

  const paginationPageNumber = parseInt(paginationMatch[1])

  if (paginationPageNumber === FIRST_PAGINATED_PAGE_NUMBER)
    return canonicalLinkWithoutPagination

  return `${addEndingSlash(
    canonicalLinkWithoutPagination
  )}page/${paginationPageNumber}/`
}

export function generateArticleCanonicalUrl({
  articleSlug,
  categorySlug,
  env,
  isMigrated
}) {
  const canonicalArticleCategorySlug =
    findArticleCategorySlugByCategorySlug(categorySlug) || categorySlug

  const articlePath = isMigrated
    ? `/${canonicalArticleCategorySlug}/!/${articleSlug.toLowerCase()}`
    : `/${canonicalArticleCategorySlug}/${articleSlug.toLowerCase()}/`

  return `${env.DOMAIN_URL}${articlePath}`
}
