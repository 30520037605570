<template>
  <div class="a-sticky__container">
    <div class="a-sticky__wrapper" :style="dynamicStyle">
      <slot />
    </div>
    <div
      v-if="isMockedSpaceVisible"
      class="a-sticky__mocked-space"
      :style="mockedSpaceDynamicStyle"
    ></div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'

/**
 * @component ASticky
 * @description
 * This component is used to manage the behavior of elements with a sticky position
 * when a modal window is opened, causing the scrollbar to be hidden. In such cases,
 * the entire page becomes fixed with the provided margin-top negative attribute value.
 * This component ensures that the sticky element is correctly positioned, sized,
 * and that space is reserved for it when it becomes fixed.
 *
 * Please note that currently the component supports only top attribute for sticky.
 */
export default {
  name: 'ASticky',
  mixins: [hydrationHelpers],
  data() {
    return {
      isDynamicStylesApplied: false,
      fixedStyles: {
        position: 'fixed',
        top: null,
        left: null,
        width: null,
        height: null,
        marginTop: 0,
        transition: 'none'
      }
    }
  },
  computed: {
    ...mapGetters({
      isScrollbarHidden: 'isScrollbarHidden',
      scrollTop: 'scrollTop'
    }),
    dynamicStyle() {
      if (!this.isScrollbarHidden || !this.isDynamicStylesApplied) return null

      return this.fixedStyles
    },
    isMockedSpaceVisible() {
      return this.isScrollbarHidden && !!this.dynamicStyle
    },
    mockedSpaceDynamicStyle() {
      return {
        width: this.fixedStyles.width,
        height: this.fixedStyles.height
      }
    }
  },
  watch: {
    isScrollbarHidden: {
      async handler(newVal) {
        if (!this.$el) return

        await this.$nextTick()

        if (!newVal) {
          document.removeEventListener('scroll', this.calculatePosition)
          this.isDynamicStylesApplied = false
          return
        }

        this.calculatePosition()
        document.addEventListener('scroll', this.calculatePosition)
        this.isDynamicStylesApplied = true
      }
    },
    $_hydrationHelpers_windowWidth: {
      async handler() {
        if (!this.$el || !this.isScrollbarHidden) return

        await this.$nextTick()

        this.calculatePosition()
        this.isDynamicStylesApplied = true
      }
    }
  },
  methods: {
    calculatePosition() {
      try {
        const {
          top: rectTop,
          left: rectLeft,
          width: rectWidth,
          height: rectHeight
        } = this.$el.getBoundingClientRect()
        const { top: calculatedTop } = window.getComputedStyle(this.$el)
        const parentEl = this.$el.parentNode
        const { bottom: parentRectBottom } = parentEl.getBoundingClientRect()
        /**
         * minTop is a CSS top attribute value for the sticky element.
         * It is consistently provided for the <a-sticky> component through CSS styles.
         */
        const minTop = parseInt(calculatedTop)
        const topLimitedByMin = Math.max(rectTop, minTop)
        /**
         * If the sticky component is positioned below minTop (CSS top value),
         * it behaves as a static element, respecting the $el DOM position
         * and not being below the $el. Otherwise, as a sticky element,
         * it should be kept at the minTop level.
         */
        const maxTopByRectTop = rectTop >= minTop ? rectTop : minTop
        /**
         * Additionally, we restrict the top position of the sticky element by
         * the parent container. This implies that the sticky element cannot go
         * beyond the boundaries of the parent container.
         * The maximum value for the top attribute should be the parent's bottom
         * minus the height of the sticky element.
         */
        const maxTop = Math.min(parentRectBottom - rectHeight, maxTopByRectTop)
        const result = Math.min(topLimitedByMin, maxTop)

        this.fixedStyles.top = `${result}px`
        this.fixedStyles.left = `${rectLeft}px`
        this.fixedStyles.width = `${rectWidth}px`
        this.fixedStyles.height = `${rectHeight}px`

        return result
      } catch (err) {
        this.isDynamicStylesApplied = false
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.calculatePosition)
  }
}
</script>

<style scoped lang="scss">
.a-sticky__container {
  position: sticky;
}

.a-sticky__mocked-space {
  visibility: hidden;
}
</style>
