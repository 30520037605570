<template>
  <a-modal-wrapper
    :modal-name="MODAL.NEWSLETTER_FORM"
    close-button
    :width="568"
    class="news-letter-modal"
  >
    <a-newsletter-form
      :close-on-submit="closeModal"
      :form-id="$options.consts.FORM_ID.NEWSLETTER_MODAL"
      :focus-on-mount="true"
      class="news-letter-modal__form"
    />
  </a-modal-wrapper>
</template>

<script>
import AModalWrapper, { MODAL } from 'modals/AModalWrapper'
import { FORM_ID } from 'enums/form-id'
import { hydrateWhenVisible } from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'

export default {
  name: 'ANewsLetterModal',
  components: {
    AModalWrapper,
    ANewsletterForm: hydrateWhenVisible(
      () => import('shared/ANewsletterForm'),
      { props: ['close-on-submit', 'form-id', 'focus-on-mount'] }
    )
  },
  consts: {
    FORM_ID
  },
  data() {
    return {
      MODAL
    }
  },
  methods: {
    closeModal() {
      this.$helper.closeModal(MODAL.NEWSLETTER_FORM)
    }
  }
}
</script>

<style lang="scss">
.news-letter-modal {
  .news-letter-modal__form {
    position: relative;
    padding: 50px 30px 25px 25px;

    @include mobile {
      padding: 50px 20px 15px;
    }
  }
}

.modal-wrapper__overlay.news-letter-modal {
  z-index: 99999;
}
</style>
