<template>
  <div v-if="src" class="iframe__wrapper" :style="iframeWrapperStyles">
    <amp-youtube-iframe
      v-if="isYoutubeAmpIframeVisible"
      :src="iframeSrc"
      v-bind="dimensionsAttrs"
      :class="iframeDynamicClass"
    />

    <iframe
      v-else
      :src="iframeSrc"
      v-bind="iframeAttrs"
      :class="iframeDynamicClass"
    />
  </div>
</template>

<script>
import { removeEndingSlashes } from '@fmpedia/helpers'
import mixins from '@/utils/mixins'
import { parseUrl } from '@/plugins/helper'
import { COOKIE_ENTITY_TYPE } from 'enums/oneTrust'
import { mapActions } from 'vuex'
import { createOneTrustHandlerInstance } from 'enums/oneTrust/one-trust-handler'
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { DEFAULT_IFRAME_WIDTH, DEFAULT_IFRAME_HEIGHT } from 'enums/media-center'
import AmpYoutubeIframe from 'shared/AmpYoutubeIframe/index.vue'
import { urlFormatters } from '@/utils/mixins/general'

const COOKIE_ENTITY_BY_SOURCE = {}

export default {
  name: 'AIframe',
  components: { AmpYoutubeIframe },
  mixins: [mixins.oneTrust, urlFormatters],
  inheritAttrs: false,
  props: {
    src: propValidator([PROP_TYPES.STRING], false),
    entityType: propValidator([PROP_TYPES.STRING], false),
    ignoreIframeSizes: propValidator([PROP_TYPES.BOOLEAN], false, false)
  },
  data() {
    return {
      iframeSrc: null
    }
  },
  computed: {
    isYoutubeAmpIframeVisible() {
      return (
        this.$helper.isAmpPage(this.$route.name) && this.isYoutubeUrl(this.src)
      )
    },
    srcAddress() {
      return removeEndingSlashes(parseUrl(this.src).address)
    },
    isConsentGiven() {
      return this.$_oneTrust_isConsentGivenForEntity(this.relevantCookieEntity)
    },
    relevantCookieEntity() {
      if (this.entityType) return this.entityType

      return (
        COOKIE_ENTITY_BY_SOURCE[this.srcAddress] || COOKIE_ENTITY_TYPE.UNKNOWN
      )
    },
    iframeDynamicClass() {
      return {
        mocked: !this.isConsentGiven
      }
    },
    parsedWidthFromAttrs() {
      return +String(this.$attrs.width || '').replace(/px$/im, '')
    },
    parsedHeightFromAttrs() {
      return +String(this.$attrs.height || '').replace(/px$/im, '')
    },
    iframeWidth() {
      return this.isWidthAndHeightValid
        ? this.$attrs.width
        : DEFAULT_IFRAME_WIDTH
    },
    iframeHeight() {
      return this.isWidthAndHeightValid
        ? this.$attrs.height
        : DEFAULT_IFRAME_HEIGHT
    },
    isWidthAndHeightValid() {
      return this.parsedWidthFromAttrs > 0 && this.parsedHeightFromAttrs > 0
    },
    iframeAspectRatio() {
      return this.iframeWidth / this.iframeHeight
    },
    iframeAttrs() {
      return {
        ...this.$attrs,
        ...this.dimensionsAttrs
      }
    },
    dimensionsAttrs() {
      return this.$helper.isNotAmpPage(this.$route.name) ||
        this.ignoreIframeSizes
        ? { width: undefined, height: undefined }
        : { width: this.iframeWidth, height: this.iframeHeight }
    },
    iframeWrapperStyles() {
      return this.ignoreIframeSizes
        ? {}
        : this.$helper.generateAspectRatioStyle(this.iframeAspectRatio)
    }
  },
  methods: {
    ...mapActions({
      registerHandlerThatRequiresConsent:
        'one-trust/registerHandlerThatRequiresConsent'
    }),
    setIframeSource() {
      if (!this.src) return

      this.iframeSrc = this.src
    }
  },
  created() {
    if (process.server && this.$helper.isAmpPage(this.$route.name)) {
      this.setIframeSource()
    }
  },
  mounted() {
    this.registerHandlerThatRequiresConsent(
      createOneTrustHandlerInstance({
        handler: this.setIframeSource,
        entityType: this.relevantCookieEntity
      })
    )
  }
}
</script>

<style lang="scss" scoped>
.iframe__wrapper {
  width: 100%;
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    &.mocked {
      background: $c--black;
    }
  }
}
</style>
