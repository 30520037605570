<template>
  <div class="layout-banners__wrapper" :style="wrapperInlineStyle">
    <div
      v-if="isSettingsByPageExist"
      class="layout-banners__first-banner-wrapper"
    >
      <a-sticky class="layout-banners__banner__wrapper first">
        <a-banner :banner-settings="bannerSettings.RIGHT_SIDE_1" />
      </a-sticky>
    </div>

    <div
      v-if="isSettingsByPageExist"
      class="layout-banners__second-banner-wrapper"
    >
      <a-sticky class="layout-banners__banner__wrapper second">
        <a-banner :banner-settings="bannerSettings.RIGHT_SIDE_2" />
      </a-sticky>
    </div>
  </div>
</template>

<script>
import ABanner from 'shared/ABanner/index.vue'
import { BANNER_SETTINGS_BY_ROUTE_NAME } from 'enums/banners/banner-settings-by-route-name'
import { PROP_TYPES, propValidator } from '@/utils/validators'
import ASticky from 'shared/ASticky/index.vue'

export default {
  name: 'ALayoutBannersSection',
  components: { ASticky, ABanner },
  props: {
    layoutTopPadding: propValidator(
      [PROP_TYPES.STRING, PROP_TYPES.NUMBER],
      false
    )
  },
  computed: {
    wrapperInlineStyle() {
      if (!this.layoutTopPadding) return {}

      return {
        paddingTop: `${this.layoutTopPadding}px`
      }
    },
    isSettingsByPageExist() {
      return !!BANNER_SETTINGS_BY_ROUTE_NAME[this.$route.name]
    },
    bannerSettings() {
      return BANNER_SETTINGS_BY_ROUTE_NAME[this.$route.name] || {}
    }
  }
}
</script>

<style lang="scss" scoped>
$first-banner-container-height: 700px;
$banners-gap: 50px;

.layout-banners__wrapper {
  width: $banner-width-xl;
  height: 100%;

  @include desktop-sm3 {
    width: $banner-width-small;
  }

  @include tablet {
    display: none;
  }

  .layout-banners__first-banner-wrapper {
    height: $first-banner-container-height;
    position: relative;
  }

  .layout-banners__second-banner-wrapper {
    position: relative;
    height: calc(100% - #{$first-banner-container-height} - #{$banners-gap});
    margin-top: $banners-gap;
  }

  .layout-banners__banner__wrapper {
    width: $banner-width-xl;
    height: 600px;

    @include desktop-sm3 {
      width: $banner-width-small;
    }

    &.first {
      top: 120px;
    }

    &.second {
      top: 120px;
    }
  }
}
</style>
