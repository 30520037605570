import api from '~/utils/api'
import * as types from './mutation-types'
import { encodeURIValues, valuesToLowercase } from '@/plugins/helper'
import {
  generateArticleSlotResponse,
  generateSingleArticlePageResponse
} from '@/utils/response-handling/article'
import { cachedRequest } from '@/utils/jobRequestApiMethods'
import { pipe } from 'ramda'
import { API_VERSION } from 'enums/api'

const ALL_NEWS_PAGE_TO_CACHE = 0

export const state = () => ({})

export const getters = {}

export const actions = {
  async requestAllNews(_, params) {
    try {
      let res
      if (params.page === ALL_NEWS_PAGE_TO_CACHE) {
        res = await cachedRequest({
          method: this.$axios.get,
          args: [
            api.articles.getAllNews(),
            {
              params
            }
          ],
          apiVersion: API_VERSION.V1
        })
      } else {
        res = await this.$axios.get(api.articles.getAllNews(), {
          params
        })
      }

      const {
        data: { PagesCount, Articles },
        lastModified
      } = res

      return {
        allNews: Articles.map(article => generateArticleSlotResponse(article)),
        pagesCount: PagesCount,
        lastModified
      }
    } catch (err) {
      throw err
    }
  },
  async requestNewsByCategory(_, params) {
    try {
      const {
        data: { Articles, CategoryName, CategorySlug, PagesCount }
      } = await this.$axios.get(api.articles.getArticlesByCategory(), {
        params
      })

      return {
        articles: Articles.map(article => generateArticleSlotResponse(article)),
        categoryName: CategoryName,
        categorySlug: CategorySlug,
        pagesCount: PagesCount
      }
    } catch (err) {
      throw err
    }
  },
  async requestSessionWrapNews(_, params) {
    try {
      const {
        data: { Articles, PagesCount }
      } = await this.$axios.get(api.articles.getSessionWrapArticles(), {
        params
      })

      return {
        articles: Articles.map(article => generateArticleSlotResponse(article)),
        pagesCount: PagesCount
      }
    } catch (err) {
      throw err
    }
  },
  async requestArticleContentBySlug(
    { commit, dispatch },
    { previewId, ...slugs }
  ) {
    const method = previewId
      ? 'getPreviewArticleContent'
      : 'getArticleContentBySlug'

    const payload = {
      params: previewId
        ? { id: previewId }
        : pipe(valuesToLowercase, encodeURIValues)(slugs)
    }

    try {
      const d = new Date()
      var datestring =
        d.getDate() +
        '-' +
        (d.getMonth() + 1) +
        '-' +
        d.getFullYear() +
        ' ' +
        d.getHours() +
        ':' +
        d.getMinutes() +
        ':' +
        d.getSeconds() +
        ' ' +
        d.getTimezoneOffset()

      console.time(
        `article-content-by-slug-request, ${datestring}, ${slugs.articleSlug}`
      )
      /**  Request article metaData from backend**/
      const { data: articleMetaData } = await this.$axios.get(
        api.articles[method](),
        payload
      )
      console.timeEnd(
        `article-content-by-slug-request, ${datestring}, ${slugs.articleSlug}`
      )

      console.time(`S3-request, ${datestring}, ${slugs.articleSlug}`)

      /** Request article content from S3Bucket **/
      const { data: articleContent } = await this.$axios.get(
        articleMetaData.ArticleUrl,
        {
          params: { date: new Date() }
        }
      )

      console.timeEnd(`S3-request, ${datestring}, ${slugs.articleSlug}`)

      if (previewId) {
        commit(`seo/${types.SET_PAGE_SEO}`, articleMetaData.Seo, {
          root: true
        })
        dispatch('removePreviewArticleContent', payload)
      }

      return generateSingleArticlePageResponse(articleContent, articleMetaData)
    } catch (e) {
      throw e
    }
  },
  async removePreviewArticleContent(_, params) {
    try {
      await this.$axios.delete(
        api.articles.deletePreviewArticleContent(),
        params
      )
    } catch (e) {
      throw e
    }
  },
  async requestMostCommentedArticles(_) {
    try {
      const { data } = await this.$axios.get(
        api.articles.getMostCommentedArticles()
      )

      return data
    } catch (err) {
      throw err
    }
  }
}

export const mutations = {}
