import { mapGetters, mapActions, mapMutations } from 'vuex'
import * as types from '@/store/mutation-types'
import { setSentryUserDetails } from '@/plugins/error'

export const userDetails = {
  data() {
    return {
      exclusionRules: [
        () =>
          !!this.$route.query.becomeAContributorToken ||
          !!this.$route.query.becomeASponsoredContributorToken
      ]
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      userDetails: 'auth/userDetails'
    })
  },
  watch: {
    isLoggedIn: {
      async handler(newVal) {
        if (newVal) {
          await this.$_userDetails_requestUserDetails()
        } else {
          this.clearUserDetails()
          setSentryUserDetails(null)
        }
      }
    }
  },
  methods: {
    ...mapActions({
      requestCurrentUserDetails: 'auth/requestCurrentUserDetails',
      requestRegisterUserSession: 'auth/requestRegisterUserSession'
    }),
    ...mapMutations({
      clearUserDetails: `auth/${types.CLEAR_USER_DETAILS}`
    }),
    async $_userDetails_requestUserDetails() {
      try {
        if (this.isLoggedIn && !this.userDetails.Id) {
          await this.requestCurrentUserDetails()
          setSentryUserDetails(this.userDetails)
        }
      } catch (e) {
        console.error(e)
      }
    }
  },
  async beforeMount() {
    if (this.exclusionRules.map(rule => rule()).every(v => !v)) {
      await this.$_userDetails_requestUserDetails()
    }
  }
}
