<template>
  <div class="checkbox__wrap" :class="checkboxStyleModificators">
    <label
      v-if="labelAsHtml"
      v-focusable="{ attachTabIndex: false }"
      :tabindex="labelTabIndex"
      class="checkbox__label checkbox__html"
      key="html-label"
    >
      <input
        tabindex="-1"
        type="checkbox"
        class="checkbox__input"
        :checked="value"
        :disabled="disabled"
        @click="toggle"
      />
      <span v-html="sanitizedLabel" class="checkbox__label-text" />
    </label>

    <label
      v-else
      v-focusable="{ attachTabIndex: false }"
      :tabindex="labelTabIndex"
      class="checkbox__label"
      key="text-label"
    >
      <input
        tabindex="-1"
        type="checkbox"
        class="checkbox__input"
        :checked="value"
        :disabled="disabled"
        @click="toggle"
      />
      <slot v-if="hasSlot" />
      <a-link
        v-else-if="labelHref"
        :href="labelHref"
        :rel="rel"
        :target="target"
      >
        <span class="checkbox__label-text">{{ label }}</span>
      </a-link>
      <span v-else class="checkbox__label-text">
        {{ label }}
      </span>
    </label>
    <a-validation-message :error="error" />
  </div>
</template>

<script>
import { prop } from 'ramda'
import { HTML_TAG } from '@/utils/helpers/processHtml/enums'
import AValidationMessage from 'shared/AValidationMessage'
import { parseAndSanitizeHtml } from '@/utils/helpers/processHtml/parseAndSanitizeHtml'

export const CHECKBOX_STYLE = {
  BORDERED: 'BORDERED',
  REGULAR: 'REGULAR'
}

const CHECKBOX_STYLE_VALUES = Object.values(CHECKBOX_STYLE)

export const CHECKBOX_SIZE = {
  SMALL: 'SMALL',
  REGULAR: 'REGULAR'
}

const CHECKBOX_SIZE_VALUES = Object.values(CHECKBOX_SIZE)

export default {
  name: 'ACheckbox',
  components: { AValidationMessage },
  props: {
    label: {
      type: String
    },
    labelHref: {
      type: String,
      default: ''
    },
    rel: {
      type: String
    },
    error: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    target: {
      type: Boolean
    },
    labelAsHtml: {
      type: Boolean,
      default: false
    },
    checkboxStyle: {
      validator: value => CHECKBOX_STYLE_VALUES.includes(value),
      default: CHECKBOX_STYLE.BORDERED
    },
    checkboxSize: {
      validator: value => CHECKBOX_SIZE_VALUES.includes(value),
      default: CHECKBOX_SIZE.REGULAR
    }
  },
  consts: {
    HTML_TAG
  },
  computed: {
    labelTabIndex() {
      return this.disabled ? -1 : 0
    },
    checkboxStyleModificators() {
      return {
        'checkbox--style-regular':
          this.checkboxStyle === CHECKBOX_STYLE.REGULAR,
        'checkbox--size-small': this.checkboxSize === CHECKBOX_SIZE.SMALL,
        'checkbox--disabled': this.disabled,
        'checkbox--checked': this.value,
        'checkbox--read-only': this.readOnly
      }
    },
    sanitizedLabel() {
      return parseAndSanitizeHtml(this.label)
    },
    hasSlot() {
      return prop('length', this.$slots.default)
    }
  },
  methods: {
    toggle() {
      this.$emit('input', !this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox__wrap {
  width: 100%;
  min-height: 20px;
  user-select: none;
  color: #000;
  position: relative;
  display: inline-block;
  cursor: pointer;

  @include mobile {
    font-size: 11px;
    line-height: 15px;
  }

  .checkbox__input {
    position: absolute;
    opacity: 0;
    cursor: inherit;
  }

  .checkbox__label {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    font-family: $font-sans;
    font-size: 14px;
    line-height: 19px;
    max-height: inherit;
    min-height: inherit;
    overflow: hidden;
    padding-left: 30px;
    cursor: inherit;

    &:focus {
      outline: none;

      &::before {
        border: 2px solid $c--main;
      }
    }

    @include mobile {
      padding-left: 26px;
    }
  }

  .checkbox__label * {
    margin-top: 1px;
    display: block;
  }

  .checkbox__label::before,
  .checkbox__label::after {
    content: '';
    position: absolute;
    display: inline-block;
  }

  .checkbox__label::before {
    height: 20px;
    width: 20px;
    background-color: white;
    border: 2px solid black;
    left: 0;
    top: 0;
  }

  &.checkbox--disabled {
    cursor: not-allowed;

    .checkbox__label {
      color: $c--disabled-text;

      &::before {
        border-color: $c--disabled-border;
      }
    }
  }

  &.checkbox--read-only {
    cursor: default;

    .checkbox__label::before {
      border: none;
    }

    &.checkbox--checked {
      .checkbox__label::before {
        background-color: transparent;
      }

      .checkbox__label::after {
        color: $c--main;
      }
    }
  }

  &.checkbox--checked {
    .checkbox__label {
      &::after {
        content: '';
        height: 6px;
        width: 11px;
        border-left: 2px solid;
        border-bottom: 2px solid;
        color: #fff;
        transform: rotate(-45deg);
        left: 5px;
        top: 5px;
      }

      &::before {
        background-color: $c--main;
        border-color: transparent;
      }

      &:focus {
        outline: none;

        &::before {
          border: 2px solid $c--navy;
        }
      }
    }

    &.checkbox--disabled .checkbox__label::before {
      background-color: $c--disabled-border;
    }
  }

  .checkbox__label-text {
    font-family: $font-sans;

    /deep/ a {
      text-decoration: underline;
    }
  }

  &.checkbox--style-regular .checkbox__label-text {
    /deep/ * {
      color: $c--white;
    }
  }

  &.checkbox--size-small {
    .checkbox__label {
      padding-left: 22px;

      &:focus {
        outline: none;

        &::before {
          border: 2px solid $c--main;
        }
      }
    }

    .checkbox__label::before {
      height: 16px;
      width: 16px;
      background-color: white;
      border: 1px solid $c--black;
      left: 0;
      top: 0;
    }

    &.checkbox--checked {
      .checkbox__label::before {
        background: $c--main;
        border-color: transparent;
      }

      .checkbox__label::after {
        top: 3px;
        left: 3px;
      }
    }
  }
}
</style>
