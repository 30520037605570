import { mapGetters } from 'vuex'

import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import { FORM_TYPE } from 'enums/form-type'
import { CLEAN_USER_DETAILS } from 'enums/auth'
import { getFullName } from '@/plugins/helper'

function getModelByType(formType, userDetails) {
  const FirstName = userDetails.FirstName || ''
  const LastName = userDetails.LastName || ''
  const Email = userDetails.Email || ''
  const FullName = getFullName(FirstName, LastName)

  if (!formType) return { FirstName, LastName, Email }

  const FORM_MODEL = {
    [FORM_TYPE.CONTACT_US]: {
      FirstName,
      LastName,
      Email,
      Phone: '',
      Message: ''
    },
    [FORM_TYPE.NEWSLETTER]: {
      FirstName,
      LastName,
      Email
    },
    [FORM_TYPE.SUGGEST_A_TERM]: {
      Email
    },
    [FORM_TYPE.CONTACT_COMPANY]: {
      FullName,
      Email,
      Phone: '',
      Message: ''
    },
    [FORM_TYPE.BECOME_AN_AUTHOR]: {
      FirstName,
      LastName,
      Email,
      CompanyName: '',
      PositionName: '',
      LinkedInProfileUrl: '',
      TwitterProfileUrl: '',
      MessageText: ''
    }
  }

  return FORM_MODEL[formType]
}

export const prefilledForm = function(formType, updateFieldName = 'userForm') {
  return {
    mixins: [hydrationHelpers],
    data() {
      return {
        prefilledForm_needUpdate: true,
        prefilledForm_reRenderKey: this.$helper.guid()
      }
    },
    computed: {
      ...mapGetters({
        userDetails: 'auth/userDetails',
        isLoggedIn: 'auth/isLoggedIn'
      })
    },
    watch: {
      userDetails: {
        immediate: true,
        async handler({ Id }) {
          if (!process.client) return

          this.$helper.watchAndExecuteOnce({
            ctx: this,
            field: 'isMounted',
            conditionFn: newVal => !!newVal,
            immediate: true,
            handlerFn: () => {
              this.$_prefilledForm_handlePrefill(Id)
            }
          })
        }
      }
    },
    methods: {
      $_prefilledForm_handlePrefill(Id) {
        if (!Id) {
          this.$_prefilledForm_clearUserForm()
          this.prefilledForm_needUpdate = true
          this.$nextTick(this.$_prefilledForm_changeRenderKey)
          return
        }

        if (!this.prefilledForm_needUpdate) return

        this.$_prefilledForm_assignUserForm()
        this.prefilledForm_needUpdate = false
        this.$nextTick(this.$_prefilledForm_changeRenderKey)
      },
      $_prefilledForm_assignUserForm() {
        const {
          FullName,
          FirstName,
          LastName,
          Email
        } = this.$_prefilledForm_getPrefilledModel()
        const userForm = this[updateFieldName]
        this[updateFieldName] = {
          ...userForm,
          FullName: userForm.FullName || FullName,
          FirstName: userForm.FirstName || FirstName,
          LastName: userForm.LastName || LastName,
          Email: userForm.Email || Email
        }
      },
      $_prefilledForm_clearUserForm() {
        const userForm = this[updateFieldName]
        this[updateFieldName] = {
          ...userForm,
          FullName: '',
          FirstName: '',
          LastName: '',
          Email: ''
        }
      },
      $_prefilledForm_getPrefilledModel() {
        const userDetails = this.isMounted
          ? this.$store.getters['auth/userDetails']
          : CLEAN_USER_DETAILS

        return getModelByType(formType, userDetails)
      },
      $_prefilledForm_changeRenderKey() {
        this.prefilledForm_reRenderKey = this.$helper.guid()
      }
    }
  }
}
