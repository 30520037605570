import * as types from '@/store/mutation-types'

export const ELEMENT = {
  FOOTER: 'footer'
}

export const state = () => ({
  elementsInView: {
    [ELEMENT.FOOTER]: false
  }
})

export const getters = {
  isFooterInView: state => state.elementsInView[ELEMENT.FOOTER]
}

export const mutations = {
  [types.SET_FOOTER_VISIBILITY_STATE](state, value) {
    state.elementsInView[ELEMENT.FOOTER] = value
  }
}
