import Vue from 'vue'
import AVisibility from 'shared/AVisibility'
import ALink from 'shared/ALink'
import ATextInput from 'shared/ATextInput'
import ACheckbox from 'shared/ACheckbox'
import AButton from 'shared/AButton'
import AImage from 'shared/AImage'
import AEnlargeableImage from 'shared/AEnlargeableImage'
import AScript from 'shared/AScript'
import AIframe from 'shared/AIframe'
import ACard from 'shared/ACard'
import ABreadcrumbs from 'shared/ABreadcrumbs'
import LazyHydrate from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'

Vue.component('a-visibility', AVisibility)
Vue.component('a-link', ALink)
Vue.component('a-text-input', ATextInput)
Vue.component('a-checkbox', ACheckbox)
Vue.component('a-button', AButton)
Vue.component('a-image', AImage)
Vue.component('a-enlargeable-image', AEnlargeableImage)
Vue.component('a-iframe', AIframe)
Vue.component('a-script', AScript)
Vue.component('a-card', ACard)
Vue.component('a-breadcrumbs', ABreadcrumbs)
Vue.component('a-lazy-hydrate', LazyHydrate)
