import { ROUTE_NAME } from 'enums/routes'
import { MIGRATED_CATEGORY_SLUG } from 'enums/categories'

const OMITTED_ROUTES = [
  ROUTE_NAME.AUTHOR,
  ROUTE_NAME.TAG,
  ROUTE_NAME.LIVE_QUOTES_ASSET
]

const CONDITIONALLY_OMITTED_ROUTE_HANDLER = {
  [ROUTE_NAME.CATEGORY]: params =>
    params.category.toLowerCase() === MIGRATED_CATEGORY_SLUG.NEWS
}

const BREADCRUMB_BY_ROUTE_NAME = {}

function generateRouteObject(isDynamic, prevRoute, routeName, value) {
  return {
    routeName: (prevRoute ? `${prevRoute.routeName}-` : '') + routeName,
    name: BREADCRUMB_BY_ROUTE_NAME[routeName] || value.replace(/-/g, ' '),
    params: {
      ...(prevRoute ? prevRoute.params : {}),
      ...(isDynamic ? { [routeName]: value } : {})
    }
  }
}

function generateBreadcrumbs(route) {
  if (!route.matched.length) return []

  const { path } = route.matched[route.matched.length - 1]

  const pathsList = route.fullPath
    .slice(1)
    .replace(/\?.*$/g, '')
    .split('/')

  return path
    .replace(/\?$/, '')
    .split('/')
    .filter(Boolean)
    .reduce((arr, val, index) => {
      const isDynamic = val.charAt(0) === ':'
      const routeName = isDynamic ? val.slice(1) : val
      const prevRoute = arr.length ? arr[arr.length - 1] : null
      const route = generateRouteObject(
        isDynamic,
        prevRoute,
        routeName,
        pathsList[index]
      )

      return [...arr, route]
    }, [])
}

export default function({ to, store: { dispatch } }) {
  try {
    const breadcrumbs = generateBreadcrumbs(to).filter(
      ({ routeName, params }) => {
        const routeRegEx = new RegExp(`^${routeName}\\??$`, 'gi')
        const isRouteConditionallyOmitted = (
          CONDITIONALLY_OMITTED_ROUTE_HANDLER[routeName] || (() => false)
        )(params)

        return (
          !OMITTED_ROUTES.some(route => route.match(routeRegEx)) &&
          !isRouteConditionallyOmitted
        )
      }
    )
    dispatch('breadcrumbs/setupBreadcrumbs', breadcrumbs)
  } catch (e) {
    console.error('breadcrumbs', e)
  }
}
