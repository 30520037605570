import * as types from './mutation-types'

const state = () => ({
  isRouteChangeRequested: false,
  routerChangeRequestPromise: null,
  routerChangeRequestResolver: null
})

const getters = {
  isRouteChangeRequested: state => state.isRouteChangeRequested
}

const actions = {
  requestRouteChange({ commit }) {
    commit(types.SET_IS_ROUTE_CHANGE_REQUESTED, true)
  },
  cancelRouteChange({ commit }) {
    commit(types.SET_IS_ROUTE_CHANGE_REQUESTED, false)
  },
  saveRouteChangeRequestPromise({ commit }, promise) {
    commit(types.SET_CHANGE_ROUTE_REQUEST_PROMISE, promise)
  },
  saveRouteChangeRequestResolver({ commit }, resolver) {
    commit(types.SET_CHANGE_ROUTE_REQUEST_RESOLVER, resolver)
  },
  async concurrentRouterPush(
    { dispatch, state },
    { args: { router, to, force = false, replace = false } }
  ) {
    if (force) {
      dispatch('saveRouteChangeRequestPromise', null)
      dispatch('saveRouteChangeRequestResolver', null)
    }

    if (state.routerChangeRequestPromise) {
      await state.routerChangeRequestPromise
    }

    const promise = new Promise(resolve => {
      dispatch('saveRouteChangeRequestResolver', resolve)
    })
    dispatch('saveRouteChangeRequestPromise', promise)
    dispatch('requestRouteChange')
    const callback = () => {
      dispatch('cancelRouteChange')
      if (
        state.routerChangeRequestPromise &&
        state.routerChangeRequestResolver
      ) {
        state.routerChangeRequestResolver()
        dispatch('saveRouteChangeRequestPromise', null)
        dispatch('saveRouteChangeRequestResolver', null)
      }
    }

    if (replace) {
      router.replace(to, callback, callback)
    } else {
      router.push(to, callback, callback)
    }
  }
}

const mutations = {
  [types.SET_IS_ROUTE_CHANGE_REQUESTED](state, data) {
    state.isRouteChangeRequested = data
  },
  [types.SET_CHANGE_ROUTE_REQUEST_PROMISE](state, data) {
    state.routerChangeRequestPromise = data
  },
  [types.SET_CHANGE_ROUTE_REQUEST_RESOLVER](state, data) {
    state.routerChangeRequestResolver = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
