<template>
  <div id="post-consent-ui" class="manage-cookies__wrapper">
    <a on="tap:consent-element.prompt();" href="#" class="manage-cookies__link"
      ><amp-img
        :src="$helper.getLinkToFlBucketFile('manage-cookies.png')"
        alt="Manage cookies"
        width="120px"
        height="30px"
    /></a>
  </div>
</template>

<script>
export default {
  name: 'FooterManageCookies'
}
</script>

<style lang="scss" scoped>
.manage-cookies__wrapper {
  display: flex;
  margin-top: 14px;
  justify-content: center;
}
</style>
