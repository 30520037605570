import { clone, pipe } from 'ramda'

import * as types from './mutation-types'
import api from '~/utils/api'
import {
  GET_LOCATION_TYPE_BY_ROUTE_NAME,
  GET_VALUE_METHOD_BY_ROUTE_NAME
} from 'enums/ebook'
import { encodeURIValues } from '@/plugins/helper'

export const state = () => ({
  ebook: null,
  isEbookRequestInProgress: false
})

export const getters = {
  ebook: ({ ebook }) => ebook,
  isEbookRequestInProgress: ({ isEbookRequestInProgress }) =>
    isEbookRequestInProgress
}

function generateRequestParams({ name, params }) {
  return {
    type: GET_LOCATION_TYPE_BY_ROUTE_NAME[name],
    value: GET_VALUE_METHOD_BY_ROUTE_NAME[name](params)
  }
}

export const actions = {
  async requestEbook({ commit }, route) {
    try {
      const { previewId } = route.query
      const apiRoute = previewId
        ? api.articles.getPreviewArticleEbook()
        : api.layout.getEbook()

      const params = previewId
        ? { id: previewId }
        : pipe(
            encodeURIValues,
            generateRequestParams
          )({ name: route.name, params: route.params })

      const { data } = await this.$axios.get(apiRoute, { params })
      const ebook = generateEbook(data)
      commit(types.SET_EBOOK, ebook)
      return ebook
    } catch (err) {
      throw err
    }
  }
}

export const mutations = {
  [types.SET_EBOOK](state, ebook) {
    state.ebook = clone(ebook)
  },
  [types.SET_EBOOK_REQUEST_STARTED](state) {
    state.isEbookRequestInProgress = true
  },
  [types.SET_EBOOK_REQUEST_FINISHED](state) {
    state.isEbookRequestInProgress = false
  }
}

function generateEbook(data) {
  if (!data) return null

  return {
    title: data.Title,
    subTitle: data.SubTitle,
    description: data.Description,
    image: data.Image,
    logo: data.Logo,
    video: data.Video,
    buttonText: data.ButtonText,
    formSettings: {
      id: data.Id,
      buttonText: data.FormButtonText,
      gdpr: data.Gdpr,
      sponsorGdpr: data.SponsorGdpr,
      newsletterSubscriptionText: data.NewsletterSubscriptionText,
      ringCaptchaEnabled: false, // temporary disable ring captcha, FMP-16439
      isFirstNameVisible: data.IsFirstNameVisible,
      isFirstNameRequired: data.IsFirstNameRequired,
      isLastNameVisible: data.IsLastNameVisible,
      isLastNameRequired: data.IsLastNameRequired
    },
    successMessage: {
      Title: data.SuccessMessageTitle,
      Subtitle: data.SuccessMessageText
    }
  }
}
