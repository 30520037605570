<template>
  <div class="amp-header__wrapper">
    <div class="amp-header__container">
      <a-link
        :to="linkToHomepage"
        :open-in-new-tab="false"
        class="amp-header__logo"
      >
        <amp-img
          :src="$helper.getLinkToFlBucketFile('fl-logo-white.svg')"
          alt="forexlive.com"
          title="Forex News, Technical Analysis & Trading Tools"
          layout="fixed"
          width="90"
          height="18"
        />
      </a-link>
    </div>
  </div>
</template>

<script>
import { ROUTE_NAME } from 'enums/routes'

export default {
  name: 'AmpHeader',
  data() {
    return {
      linkToHomepage: { name: ROUTE_NAME.ALL_NEWS }
    }
  }
}
</script>

<style lang="scss" scoped>
.amp-header__wrapper {
  position: fixed;
  width: 100%;
  max-width: 800px;
  height: 60px;
  top: 0;
  z-index: $z-index-header;
  background: $c--header;

  .amp-header__container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
