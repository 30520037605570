export const MIGRATED_TAG_SLUG = {
  FUNDAMENTAL_STRATEGY: 'fundamental-strategy',
  TECHNICAL_ANALYSIS: 'technical-analysis',
  FOREX_OPTIONS: 'forex-options-orders-fixes',
  BASICS: 'basics',
  TRADING_RULES: 'trading-rules',
  TRADING_PSYCHOLOGY: 'trading-psychology',
  WAR_STORIES: 'war-stories',
  TRADING_TECHNIQUES: 'trading-techniques',
  VIDEOS: 'videos',
  ECONOMICS: 'economics',
  MARKET_WISDOM: 'market-wisdom',
  UNUSUAL_MARKETS: 'unusual-markets',
  TRUSTED_GURUS: 'trusted-gurus',
  EDUCATION_FROM_BROKERS: 'education-from-brokers'
}

const MIGRATED_TAG_SLUG_VALUES = Object.values(MIGRATED_TAG_SLUG)

export function checkIfMigratedTag(slug = '') {
  return MIGRATED_TAG_SLUG_VALUES.includes(slug.toLowerCase())
}
