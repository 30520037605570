import { ROUTE_NAME } from 'enums/routes'

export const META_PARAM = {
  ALL_NEWS: 'Homepage'
}

export const FL_GROUP_TYPE = {
  STATIC: 'Static',
  ARTICLE: 'Article',
  CATEGORY: 'Category',
  TAG: 'Tag',
  TERM: 'Term',
  AUTHOR: 'Author'
}

const FL_GROUP_TYPES_BY_ROUTE_NAME = {
  [ROUTE_NAME.CATEGORY]: [FL_GROUP_TYPE.CATEGORY],
  [ROUTE_NAME.EDUCATION]: [FL_GROUP_TYPE.CATEGORY],
  [ROUTE_NAME.CATEGORY_ARTICLE]: [FL_GROUP_TYPE.ARTICLE],
  [ROUTE_NAME.CATEGORY_ARTICLE_AMP]: [FL_GROUP_TYPE.ARTICLE],
  [ROUTE_NAME.TAG_TAG]: [FL_GROUP_TYPE.TAG],
  [ROUTE_NAME.TERMS_LETTER_TERM]: [FL_GROUP_TYPE.TERM],
  [ROUTE_NAME.AUTHOR_AUTHOR]: [FL_GROUP_TYPE.AUTHOR]
}

export const getFlGroupTypesByPageName = pageName => {
  return FL_GROUP_TYPES_BY_ROUTE_NAME[pageName] || [FL_GROUP_TYPE.STATIC]
}

export const FL_TEMPLATE_PARAM = {
  [FL_GROUP_TYPE.STATIC]: {
    META_TITLE: 'PAGE_NAME',
    META_DESCRIPTION: 'PAGE_NAME'
  },
  [FL_GROUP_TYPE.ARTICLE]: {
    META_TITLE: 'ARTICLE_TITLE',
    META_DESCRIPTION: 'ARTICLE_TLDR'
  },
  [FL_GROUP_TYPE.CATEGORY]: {
    META_TITLE: 'CATEGORY_NAME',
    META_DESCRIPTION: 'CATEGORY_NAME'
  },
  [FL_GROUP_TYPE.TAG]: {
    META_TITLE: 'TAG_NAME',
    META_DESCRIPTION: 'TAG_NAME'
  },
  [FL_GROUP_TYPE.TERM]: {
    META_TITLE: 'TERM_NAME',
    META_DESCRIPTION: 'TERM_NAME'
  },
  [FL_GROUP_TYPE.AUTHOR]: {
    META_TITLE: 'AUTHOR_NAME',
    META_DESCRIPTION: 'AUTHOR_NAME'
  }
}

const FL_TEMPLATE_PARAMS_LIST = Object.values(FL_TEMPLATE_PARAM)

export const FL_META_TITLE_PARAMS_REGEX = new RegExp(
  FL_TEMPLATE_PARAMS_LIST.map(({ META_TITLE }) => META_TITLE).join('|'),
  'g'
)

export const FL_META_DESCRIPTION_PARAMS_REGEX = new RegExp(
  FL_TEMPLATE_PARAMS_LIST.map(({ META_DESCRIPTION }) => META_DESCRIPTION).join(
    '|'
  ),
  'g'
)

/** Needed to request SEO settings for some pages for global usage **/
export const FL_STATIC_PAGE = {
  ERROR: 'error/',
  HOMEPAGE: '/'
}
