const ROOT_CONTAINER_ID = '__nuxt'

export default {
  data() {
    return {
      rootContainer: null
    }
  },
  methods: {
    $_rootContainer_getRootContainer() {
      this.rootContainer =
        this.rootContainer || document.getElementById(ROOT_CONTAINER_ID)

      return this.rootContainer
    },
    $_rootContainer_addClassToRootContainer(className) {
      this.$_rootContainer_getRootContainer().classList.add(className)
    },
    $_rootContainer_removeClassFromRootContainer(className) {
      this.$_rootContainer_getRootContainer().classList.remove(className)
    }
  }
}
