export const NEWS_SOURCE = {
  FM: 'FM',
  FL: 'FL'
}

export const NEWS_TYPE = {
  FM: 'FM',
  FM_DIR: 'FMDirectory',
  FL: 'FL'
}

export const NOTIFICATION_TYPE = {
  FOLLOWED_TAGS: 'FollowedTags',
  FOLLOWED_COMPANIES: 'FollowedCompanies',
  FOLLOWED_AUTHORS: 'FollowedAuthors'
}

export const FOLLOWED_ITEM = {
  TAGS: 'Tags',
  CATEGORIES: 'Categories',
  AUTHORS: 'Authors',
  COMPANIES: 'Companies'
}

export const ANNOUNCEMENT_TYPE = {
  EVENTS: 'Events',
  NEWS: 'News',
  PROMOTIONAL: 'Promotional',
  OTHER: 'Other'
}

export const TAB_NAME = {
  ALL: 'All',
  FM: 'Finance Magnates',
  FL: 'Forex Live'
}

export const SOURCE_MAP = {
  [TAB_NAME.ALL]: null,
  [TAB_NAME.FM]: NEWS_SOURCE.FM,
  [TAB_NAME.FL]: NEWS_SOURCE.FL
}

export const TAB_NAME_BY_SOURCE = {
  [NEWS_SOURCE.FM]: TAB_NAME.FM,
  [NEWS_SOURCE.FL]: TAB_NAME.FL
}

export const COMPANY_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  UNDER_MODERATION: 'UnderModeration',
  EXPIRING: 'Expiring',
  EXPIRED: 'Expired'
}

export const COMPANY_FEATURE_STATUS = {
  EXPIRING: 'Expiring',
  EXPIRED: 'Expired',
  NONE: 'None'
}

export const COMPANY_CONTENT_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  UNDER_MODERATION: 'UnderModeration'
}

export const COMPANY_CONTENT_STATUS_TITLE = {
  [COMPANY_CONTENT_STATUS.ACTIVE]: 'Active',
  [COMPANY_CONTENT_STATUS.INACTIVE]: 'Inactive',
  [COMPANY_CONTENT_STATUS.UNDER_MODERATION]: 'Under Moderation'
}

export const FILTER_TYPE = {
  PHONE: 'Phone',
  EMAIL: 'Email',
  URL: 'Url',
  TEXT_AREA: 'TextArea',
  SELECT: 'Select',
  MULTISELECT: 'MultiSelect',
  RADIO: 'Radio',
  CHECKBOX: 'Checkbox',
  BOOLEAN: 'Boolean'
}

export const QUERY_PARAM_NAME = 'source'

export const QUERY_PARAM_VALUE = {
  [TAB_NAME.FM]: 'fm',
  [TAB_NAME.FL]: 'fl',
  [TAB_NAME.ALL]: 'all'
}
