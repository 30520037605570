const me = {
  slug: 'f/me',
  changePassword: () => `${me.slug}/password`,
  resetPassword: () => `${me.slug}/password/reset`,
  confirmPassword: () => `${me.slug}/password/confirm`,
  getProfileInfo: () => `${me.slug}/profile`,
  registerUserSession: () => `${me.slug}/sessions`,
  requestUserProfileReactivation: () => `${me.slug}/profile/reactivation/email`,
  reactivateUserProfile: () => `${me.slug}/profile/reactivation/email/confirm`,
  confirmBecomeAContributorFlow: () =>
    `${me.slug}/promotion/contributor/confirm`,
  confirmBecomeASponsoredContributorFlow: () =>
    `${me.slug}/promotion/sponsored-contributor/confirm`,
  updateAnnouncementSubscription: () => `${me.slug}/announcements`,
  reactivateFacebookUserProfile: () =>
    `${me.slug}/profile/reactivation/facebook`,
  reactivateTwitterUserProfile: () => `${me.slug}/profile/reactivation/twitter`,
  reactivateGoogleUserProfile: () => `${me.slug}/profile/reactivation/google`
}

export default me
