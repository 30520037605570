import { removeStartingSlash, removeEndingSlashes } from '@fmpedia/helpers'
import { getPageSourceByRouteName, encodeURIValues } from '@/plugins/helper'
import { getFlGroupTypesByPageName } from 'enums/fl-seo'
import { getFlDirGroupTypesByPageName } from 'enums/fl-dir-seo'
import { MIDDLEWARE } from 'enums/middleware'
import { ROUTE_NAME } from 'enums/routes'
import { MIGRATED_CATEGORY_SLUG } from 'enums/categories'
import { FOREX_BROKERS_SLUG } from 'enums/directory-categories'
import { SOURCE } from 'enums/source'

const isMiddlewareSkipped = (app, route) =>
  app.$helper.isMiddlewareSkipped(MIDDLEWARE.PAGE_SEO, route)

const SEO_HANDLER_BY_PAGE_SOURCE = {
  [SOURCE.FL]: {
    getGroupTypesByPageName: getFlGroupTypesByPageName,
    getPageSlug: getFlPageSlug
  },
  [SOURCE.FL_DIR]: {
    getGroupTypesByPageName: getFlDirGroupTypesByPageName,
    getPageSlug: getFlDirPageSlug
  }
}

const REQUEST_PAGE_SEO_ACTION_BY_PAGE_SOURCE = {
  [SOURCE.FL]: 'seo/requestFlPageSeo',
  [SOURCE.FL_DIR]: 'seo/requestFlDirPageSeo'
}

export default async function({ app, store, route, redirect }) {
  console.time(`page-seo-middleware - ${route.path}`)

  if (isMiddlewareSkipped(app, route)) {
    return
  }

  try {
    const pageSource = getPageSourceByRouteName(route.name)
    const { getGroupTypesByPageName, getPageSlug } = SEO_HANDLER_BY_PAGE_SOURCE[
      pageSource
    ]
    const getPageSeoAction = REQUEST_PAGE_SEO_ACTION_BY_PAGE_SOURCE[pageSource]

    const possibleTypes = getGroupTypesByPageName(route.name)
    const value = getPageSlug(route)

    const requestPageSeoFn = () =>
      store.dispatch(getPageSeoAction, {
        possibleTypes,
        value
      })

    /**
     * We always fetch Homepage SEO since it is used for
     * general purposes, such as og:site_name value.
     * To avoid multiple requests for HP SEO, we check
     * current route name.
     */

    const requestHomepageSeoFn = () => store.dispatch('seo/requestHomepageSeo')

    const promises = [requestHomepageSeoFn()]

    if (route.name !== ROUTE_NAME.ALL_NEWS) {
      promises.push(requestPageSeoFn())
    }

    await Promise.all(promises)
  } catch (err) {
    redirect()
  }
  console.timeEnd(`page-seo-middleware - ${route.path}`)
}

function getFlDirPageSlug(route) {
  const { company } = encodeURIValues(route.params)

  if (route.name === ROUTE_NAME.FOREX_BROKERS) return FOREX_BROKERS_SLUG

  return `${FOREX_BROKERS_SLUG}/${company}`
}

function getFlPageSlug(route) {
  const { article, category, tag, author, term } = encodeURIValues(route.params)

  switch (route.name) {
    case ROUTE_NAME.CATEGORY_ARTICLE:
      return `${category}/${article}`
    case ROUTE_NAME.CATEGORY_ARTICLE_AMP:
      return `${category}/${article}`
    case ROUTE_NAME.CATEGORY:
      return `${category}`
    case ROUTE_NAME.TAG_TAG:
      return `${tag}`
    case ROUTE_NAME.EDUCATION:
      return MIGRATED_CATEGORY_SLUG.EDUCATION
    case ROUTE_NAME.LIVE_QUOTES_ASSET:
      return `${ROUTE_NAME.LIVE_QUOTES}/`
    case ROUTE_NAME.LIVE_CHARTS: {
      const { tvwidgetsymbol } = route.query
      const isSymbolPage = !!tvwidgetsymbol

      if (isSymbolPage) {
        return `${ROUTE_NAME.LIVE_CHARTS}?tvwidgetsymbol=${tvwidgetsymbol}/`
      }

      return `${ROUTE_NAME.LIVE_CHARTS}/`
    }
    default: {
      const pageSlug =
        article ||
        category ||
        tag ||
        author ||
        term ||
        route.path.replace(/page\/([0-9]*)\/*$/, '')

      return `${removeStartingSlash(
        removeEndingSlashes(pageSlug)
      )}/`.toLowerCase()
    }
  }
}
