import { QUERY_PARAM } from 'enums/query-param'

export default function({ app, route, $gtm }) {
  if (
    process.env.NODE_ENV !== 'production' ||
    !!route.query[QUERY_PARAM.DISABLE_GTM]
  ) {
    return
  }

  $gtm.init(app.$env.GTM_CONTAINER_ID)
}
