import api from '~/utils/api'

export const state = () => ({})

export const getters = {}

export const actions = {
  async requestCompanyProfile(_, params) {
    try {
      let handlerApi, handlerParams
      if (params.id) {
        handlerApi = api.directoryCompany.getCompanyPreview()
        handlerParams = { id: params.id }
      } else {
        handlerApi = api.directoryCompany.getCompanyProfile()
        handlerParams = {
          slug: params.slug,
          categorySlug: params.categorySlug
        }
      }

      const { data } = await this.$axios.get(handlerApi, {
        params: handlerParams
      })

      return data
    } catch (err) {
      throw err
    }
  },
  async requestRemoveCompanyPreview(_, params) {
    try {
      const { data } = await this.$axios.delete(
        api.directoryCompany.removeCompanyPreview(),
        {
          params
        }
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async sendRequestToCompany(_, { captchaKey, payload }) {
    try {
      const requestSettings = {
        headers: { GoogleCaptchaResponseKey: captchaKey }
      }
      await this.$axios.post(
        api.directory.createLead(),
        payload,
        requestSettings
      )
    } catch (err) {
      throw err
    }
  },
  async checkIfForbiddenContactCompanyEmailDomain(_, { emailDomain }) {
    try {
      const forbiddenEmailDomains = JSON.parse(
        atob(this.$env.FORBIDDEN_CONTACT_COMPANY_EMAIL_DOMAINS)
      )

      return forbiddenEmailDomains.includes(emailDomain)
    } catch (err) {
      return false
    }
  }
}

export const mutations = {}
