import { mapActions, mapGetters } from 'vuex'
import { PENDING_ACTION } from 'enums/pending-actions'

export default {
  data() {
    return {
      getUpdateHandlerByActionType: {
        [PENDING_ACTION.SAVE_ARTICLE]: ({ newValue }) =>
          newValue ? this.requestSaveForLater : this.requestDeleteSavedArticle,
        [PENDING_ACTION.FOLLOW_TAG]: ({ newValue }) =>
          newValue ? this.requestFollowTags : this.requestUnfollowTags,
        [PENDING_ACTION.FOLLOW_AUTHOR]: ({ newValue }) =>
          newValue ? this.requestFollowAuthors : this.requestUnfollowAuthors,
        [PENDING_ACTION.SAVE_SEARCH_HISTORY]: () =>
          this.requestSaveSearchHistory
      },
      generateUpdatePayloadByActionType: {
        [PENDING_ACTION.SAVE_ARTICLE]: ({ id }) => ({
          payload: { Id: id }
        }),
        [PENDING_ACTION.FOLLOW_TAG]: ({ id }) => ({ payload: { Ids: [id] } }),
        [PENDING_ACTION.FOLLOW_AUTHOR]: ({ id }) => ({
          payload: { Ids: [id] }
        }),
        [PENDING_ACTION.SAVE_SEARCH_HISTORY]: ({
          source,
          searchPhrase,
          categorySlug,
          entityRecordId
        }) => {
          const URL = `${categorySlug}/?searchPhrase=${searchPhrase}`

          return {
            payload: {
              Source: source,
              SearchPhrase: searchPhrase,
              URL,
              EntityRecordId: entityRecordId
            }
          }
        }
      },
      /**
       * Note that refresh payload should be an object as we extend it with
       * the field "isSuccess" that represents if the handler was called without
       * errors
       */
      generateRefreshPayloadByActionType: {
        [PENDING_ACTION.SAVE_ARTICLE]: ({ id, newValue }) => ({
          id,
          value: newValue
        }),
        [PENDING_ACTION.FOLLOW_TAG]: ({ id, newValue }) => ({
          id,
          value: newValue
        }),
        [PENDING_ACTION.FOLLOW_AUTHOR]: ({
          id,
          newValue,
          newFollowerCount
        }) => ({
          id,
          value: newValue,
          newFollowerCount
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      isAuthStatusRequested: 'auth/isAuthStatusRequested',
      isAuthStatusRequestInProgress: 'auth/isAuthStatusRequestInProgress'
    })
  },
  methods: {
    ...mapActions({
      requestSaveForLater: 'profile/requestSaveForLater',
      requestDeleteSavedArticle: 'profile/requestDeleteSavedArticle',
      requestFollowTags: 'profile/requestFollowTags',
      requestUnfollowTags: 'profile/requestUnfollowTags',
      requestFollowAuthors: 'profile/requestFollowAuthors',
      requestUnfollowAuthors: 'profile/requestUnfollowAuthors',
      requestSaveSearchHistory: 'profile/requestSaveSearchHistory'
    })
  }
}
