<template>
  <div class="global-wrapper" :class="globalWrapDynamicClass">
    <a-410-page v-if="is410Page" />

    <template v-else>
      <a-sticky
        :id="$options.consts.TOP_STICKY_CONTAINER_ID"
        class="top-sticky-container"
      >
        <a-header :last-modified="headerMenuItemsLastModified" />
      </a-sticky>
      <div class="page-wrap">
        <div
          class="content-wrapper"
          :data-ref="$options.consts.REFS.CONTENT_WRAPPER"
        >
          <nuxt ref="nuxt" />
        </div>
      </div>
      <a-news-letter-modal />
      <a-contact-us-modal />
      <a-auth-form />
      <client-only>
        <a-error-handler-notifier v-if="isDevelopment" />
      </client-only>
      <a-cross-domain-cookies />
      <a-device-id-cookie-iframe />
      <a-google-analytics-cookies-iframe />
      <a-footer />
      <a-char-width />
      <gtm-no-script />
    </template>
  </div>
</template>

<script>
import defaultLayout from './default'
import AContactUsModal from 'shared/AContactUsModal'
import { mapGetters } from 'vuex'

export default {
  name: 'ErrorLayout',
  components: {
    AContactUsModal,
    A410Page: () => import('@/components/_layout/Error/A410Page')
  },
  extends: defaultLayout,
  computed: {
    ...mapGetters({
      is410Page: 'errors/is410Page',
      isPreviewMode: 'isPreviewMode'
    }),
    globalWrapDynamicClass() {
      return {
        'preview-mode': this.isPreviewMode
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-wrap {
  .content-wrapper {
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}
</style>
