<template>
  <footer class="footer__wrapper">
    <div class="footer__content-wrapper">
      <footer-logo class="footer__logo" />
      <div class="footer__main-content-wrapper">
        <div class="footer__main-content">
          <footer-follow-us-section :footer-socials="stayConnected" />
        </div>
        <footer-manage-cookies />
        <i class="horizontal-divider" />
        <footer-bottom-text class="footer__bottom-text" />
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

import FooterLogo from './FooterLogo'
import FooterFollowUsSection from './FooterFollowUsSection'
import FooterBottomText from './FooterBottomText'
import { serverCacheKey } from '@/utils/mixins/serverCacheKey'
import FooterManageCookies from './FooterManageCookies'

export default {
  name: 'AmpFooter',
  mixins: [serverCacheKey],
  components: {
    FooterManageCookies,
    FooterLogo,
    FooterFollowUsSection,
    FooterBottomText
  },
  computed: {
    ...mapGetters({
      footerLeftSection: 'footerLeftSection',
      footerRightSection: 'footerRightSection',
      stayConnected: 'stayConnected'
    })
  }
}
</script>

<style lang="scss" scoped>
.footer__wrapper {
  width: 100%;
  background: $c--footer;
  color: $c--white;
  margin-top: 20px;
  padding: 30px 15px 20px;

  .footer__content-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 0 auto;
  }

  .footer__logo {
    margin: 0 auto 30px;
  }

  .footer__main-content-wrapper {
    width: 100%;
  }

  .footer__main-content {
    display: flex;
    align-items: stretch;
  }

  .horizontal-divider {
    display: block;
    width: 100%;
    height: 2px;
    margin-top: 12px;
    background: $c--white;
    content: '';
  }

  .footer__bottom-text {
    margin-top: 10px;
  }
}
</style>
