import { isArray, isObject } from '@/plugins/helper'
import { HTML_TAG } from '@/utils/helpers/processHtml/enums'
import { getTag } from '@/utils/helpers/processHtml/prettifyHtml'

export function removeScripts(elements) {
  if (isArray(elements)) {
    return elements
      .filter(el => getTag(el) !== HTML_TAG.SCRIPT)
      .map(el => removeScripts(el))
  }

  if (isObject(elements)) {
    return {
      ...elements,
      content: removeScripts(elements.content)
    }
  }

  return elements
}
