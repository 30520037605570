import Vue from 'vue'
import { KEY_CODE } from '@fmpedia/enums'
import { scrollTouchEventListenerOptions } from '@/utils/helpers/events'

const WHEEL_BUTTON_CODE = 1
const plugin = {}

plugin.install = Vue => {
  const keys = {
    [KEY_CODE.SPACEBAR]: 1,
    [KEY_CODE.PAGEUP]: 1,
    [KEY_CODE.PAGEDOWN]: 1,
    [KEY_CODE.END]: 1,
    [KEY_CODE.HOME]: 1,
    [KEY_CODE.LEFT]: 1,
    [KEY_CODE.UP]: 1,
    [KEY_CODE.RIGHT]: 1,
    [KEY_CODE.DOWN]: 1
  }

  function preventDefault(e) {
    e.preventDefault()
  }

  function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
      preventDefault(e)
      return false
    }
  }

  function preventDefaultForMouseDown(e) {
    window.addEventListener('mousemove', preventDefault, false)

    if (e.button === WHEEL_BUTTON_CODE) {
      e.preventDefault()
    }
  }

  function removeMousemoveListener() {
    window.removeEventListener('mousemove', preventDefault, false)
  }

  const wheelEvent =
    'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'

  function lockScroll() {
    window.addEventListener('DOMMouseScroll', preventDefault, false) // older FF
    window.addEventListener(
      wheelEvent,
      preventDefault,
      scrollTouchEventListenerOptions
    ) // modern desktop
    window.addEventListener(
      'touchmove',
      preventDefault,
      scrollTouchEventListenerOptions
    ) // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false)
    window.addEventListener('mousedown', preventDefaultForMouseDown, false)
    window.addEventListener('mouseup', removeMousemoveListener, false)
  }

  function unlockScroll() {
    window.removeEventListener('DOMMouseScroll', preventDefault, false)
    window.removeEventListener(
      wheelEvent,
      preventDefault,
      scrollTouchEventListenerOptions
    )
    window.removeEventListener(
      'touchmove',
      preventDefault,
      scrollTouchEventListenerOptions
    )
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false)
    window.removeEventListener('mousedown', preventDefaultForMouseDown, false)
    window.removeEventListener('mouseup', removeMousemoveListener, false)
  }

  Vue.prototype.$lockScroll = lockScroll
  Vue.prototype.$unlockScroll = unlockScroll
}

Vue.use(plugin)
