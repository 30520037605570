import { ROUTE_NAME } from 'enums/routes'
import {
  findCategorySlugByArticleCategorySlug,
  MIGRATED_CATEGORY_SLUG
} from 'enums/categories'
import { SEARCH_FILTER } from 'enums/search-results'

export const TESTING_TARGETING_VALUE = 'test-banners'

export const SEARCH_RESULTS_GENERAL_TARGETING_VALUE = 'search'
export const SEARCH_RESULTS_SINGLE_DATA_TARGETING_VALUE =
  'Search_Results_Single_Data'

export const TARGETING_VALUE_BY_CATEGORY_SLUG = {
  [MIGRATED_CATEGORY_SLUG.CRYPTOCURRENCY.toLowerCase()]: 'cryptocurrency',
  [MIGRATED_CATEGORY_SLUG.EDUCATION.toLowerCase()]: 'education',
  [MIGRATED_CATEGORY_SLUG.ORDERS.toLowerCase()]: 'orders',
  [MIGRATED_CATEGORY_SLUG.CENTRAL_BANKS.toLowerCase()]: 'CentralBanks'
}

export const TARGETING_KEY = {
  DEFAULT: 'catName_FL',
  COMPANY: 'company_FL',
  TERM: 'fl_term'
}

export const TARGETING_SETTINGS_BY_PAGE_NAME = {
  [ROUTE_NAME.ALL_NEWS]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: ctx =>
        ctx.$_hydrationHelpers_isLayoutMobile ? 'HP_mobile' : 'HP'
    }
  ],
  [ROUTE_NAME.RSS]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'HP'
    }
  ],
  [ROUTE_NAME.TERMS]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'Terms_HP'
    }
  ],
  [ROUTE_NAME.TERMS_LETTER]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'Terms_Single_Letter'
    }
  ],
  [ROUTE_NAME.TERMS_SEARCH_RESULTS]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'Terms_Search'
    }
  ],
  [ROUTE_NAME.AUTHOR_AUTHOR]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'Author_Page'
    }
  ],
  [ROUTE_NAME.TAG_TAG]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'Tag_Page'
    }
  ],
  [ROUTE_NAME.TAG_SEARCH_RESULTS]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'Tags_Search'
    }
  ],
  [ROUTE_NAME.CONTACT_US]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'Contact_Us'
    }
  ],
  [ROUTE_NAME.BECOME_AN_AUTHOR]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'Become_an_author'
    }
  ],
  [ROUTE_NAME.LIVE_CHARTS]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'LiveCharts'
    }
  ],
  [ROUTE_NAME.LIVE_QUOTES]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'LiveQuotes'
    }
  ],
  [ROUTE_NAME.LIVE_QUOTES_ASSET]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'LiveQuotes'
    }
  ],
  [ROUTE_NAME.ECONOMIC_CALENDAR]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'EconomicCalendar'
    }
  ],
  [ROUTE_NAME.FOREX_BROKERS]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'ForexBrokers'
    }
  ],
  [ROUTE_NAME.EDUCATION]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'education'
    }
  ],
  [ROUTE_NAME.SESSION_WRAPS]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'SessionWraps'
    }
  ],
  [ROUTE_NAME.DIRECTORY_COMPANY]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'ForexBrokers'
    },
    {
      key: TARGETING_KEY.COMPANY,
      valueHandler: ctx => ctx.$route.params.company?.toLowerCase()
    }
  ],
  [ROUTE_NAME.TERMS_LETTER_TERM]: [
    {
      key: TARGETING_KEY.TERM,
      valueHandler: ctx => {
        const termSlug = ctx.$route.params.term
        return ctx.$helper.capitalize(termSlug.toLowerCase())
      }
    }
  ],
  [ROUTE_NAME.SEARCH_RESULTS]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: ctx => {
        const searchFilterValues = Object.values(SEARCH_FILTER)

        if (searchFilterValues.includes(ctx.$route.query.filter)) {
          return SEARCH_RESULTS_SINGLE_DATA_TARGETING_VALUE
        }

        return SEARCH_RESULTS_GENERAL_TARGETING_VALUE
      }
    }
  ],
  [ROUTE_NAME.CATEGORY]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: ctx => {
        const categoryInLowerCase = ctx.$route.params.category.toLowerCase()

        return (
          TARGETING_VALUE_BY_CATEGORY_SLUG[categoryInLowerCase] ||
          categoryInLowerCase
        )
      }
    }
  ],
  [ROUTE_NAME.CATEGORY_ARTICLE]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: articlePageValueHandler
    }
  ],
  [ROUTE_NAME.CATEGORY_ARTICLE_AMP]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: articlePageValueHandler
    }
  ]
}

function articlePageValueHandler(ctx) {
  const articleCategoryInLowerCase = ctx.$route.params.category.toLowerCase()

  return (
    TARGETING_VALUE_BY_CATEGORY_SLUG[
      findCategorySlugByArticleCategorySlug(
        articleCategoryInLowerCase
      )?.toLowerCase()
    ] || articleCategoryInLowerCase
  )
}

export function generateTargetingJSON(routeName, ctx) {
  const targetingSettings = TARGETING_SETTINGS_BY_PAGE_NAME[routeName]

  if (!targetingSettings) return ''

  const targetingObj = targetingSettings.reduce(
    (acc, { key, valueHandler }, i) => {
      return {
        ...acc,
        [key]: [valueHandler(ctx)]
      }
    },
    {}
  )

  return `{"targeting":${JSON.stringify(targetingObj)}}`
}
